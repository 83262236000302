import * as Types from "../../../generated/types";

import {
  PatrolFragment,
  PatrolEventFragment,
  PatrolEventTaskFragment,
} from "./fragments.generated";
import { gql } from "@apollo/client";
import {
  PatrolFragmentDoc,
  PatrolEventFragmentDoc,
  PatrolEventTaskFragmentDoc,
} from "./fragments.generated";
import * as Apollo from "@apollo/client";
export type GetPatrolsQueryVariables = Types.Exact<{
  paginationData: Types.PaginationData;
  workplaceId: Types.Scalars["UUID"];
  filters?: Types.Maybe<Types.PatrolFilters>;
}>;

export type GetPatrolsQuery = {
  __typename?: "Query";
  getPatrols: {
    __typename?: "GetPatrolsResponse";
    totalCount: number;
    items: Array<{
      __typename?: "Patrol";
      id: any;
      startDate: any;
      endDate?: Types.Maybe<any>;
      location?: Types.Maybe<string>;
      assignees?: Types.Maybe<
        Array<{
          __typename?: "User";
          id: any;
          firstName?: Types.Maybe<string>;
          lastName?: Types.Maybe<string>;
        }>
      >;
    }>;
  };
};

export type GetPatrolQueryVariables = Types.Exact<{
  id: Types.Scalars["UUID"];
}>;

export type GetPatrolQuery = {
  __typename?: "Query";
  getPatrol: { __typename?: "Patrol" } & PatrolFragment;
  getPatrolEvents: Array<{ __typename?: "PatrolEvent" } & PatrolEventFragment>;
};

export type GetExtendedPatrolEventDataQueryVariables = Types.Exact<{
  patrolEventId: Types.Scalars["UUID"];
}>;

export type GetExtendedPatrolEventDataQuery = {
  __typename?: "Query";
  getPatrolEventTasks: Array<{ __typename?: "Task" } & PatrolEventTaskFragment>;
  getPatrolEventAttachments: Array<{
    __typename?: "PatrolEventAttachment";
    id: any;
    createdAt: any;
    updatedAt: any;
    attachment: {
      __typename?: "Attachment";
      id: any;
      filename: string;
      mimetype: Types.MimeType;
      uri: string;
      createdAt: any;
      encoding: string;
      updatedAt: any;
    };
  }>;
};

export const GetPatrolsDocument = gql`
  query getPatrols(
    $paginationData: PaginationData!
    $workplaceId: UUID!
    $filters: PatrolFilters
  ) {
    getPatrols(
      paginationData: $paginationData
      workplaceId: $workplaceId
      filters: $filters
    ) {
      items {
        id
        startDate
        endDate
        location
        assignees {
          id
          firstName
          lastName
        }
      }
      totalCount
    }
  }
`;

/**
 * __useGetPatrolsQuery__
 *
 * To run a query within a React component, call `useGetPatrolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatrolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatrolsQuery({
 *   variables: {
 *      paginationData: // value for 'paginationData'
 *      workplaceId: // value for 'workplaceId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPatrolsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPatrolsQuery, GetPatrolsQueryVariables>
) {
  return Apollo.useQuery<GetPatrolsQuery, GetPatrolsQueryVariables>(
    GetPatrolsDocument,
    baseOptions
  );
}
export function useGetPatrolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatrolsQuery, GetPatrolsQueryVariables>
) {
  return Apollo.useLazyQuery<GetPatrolsQuery, GetPatrolsQueryVariables>(
    GetPatrolsDocument,
    baseOptions
  );
}
export type GetPatrolsQueryHookResult = ReturnType<typeof useGetPatrolsQuery>;
export type GetPatrolsLazyQueryHookResult = ReturnType<typeof useGetPatrolsLazyQuery>;
export type GetPatrolsQueryResult = Apollo.QueryResult<
  GetPatrolsQuery,
  GetPatrolsQueryVariables
>;
export const GetPatrolDocument = gql`
  query getPatrol($id: UUID!) {
    getPatrol(id: $id) {
      ...Patrol
    }
    getPatrolEvents(patrolId: $id) {
      ...PatrolEvent
    }
  }
  ${PatrolFragmentDoc}
  ${PatrolEventFragmentDoc}
`;

/**
 * __useGetPatrolQuery__
 *
 * To run a query within a React component, call `useGetPatrolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatrolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatrolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPatrolQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPatrolQuery, GetPatrolQueryVariables>
) {
  return Apollo.useQuery<GetPatrolQuery, GetPatrolQueryVariables>(
    GetPatrolDocument,
    baseOptions
  );
}
export function useGetPatrolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatrolQuery, GetPatrolQueryVariables>
) {
  return Apollo.useLazyQuery<GetPatrolQuery, GetPatrolQueryVariables>(
    GetPatrolDocument,
    baseOptions
  );
}
export type GetPatrolQueryHookResult = ReturnType<typeof useGetPatrolQuery>;
export type GetPatrolLazyQueryHookResult = ReturnType<typeof useGetPatrolLazyQuery>;
export type GetPatrolQueryResult = Apollo.QueryResult<
  GetPatrolQuery,
  GetPatrolQueryVariables
>;
export const GetExtendedPatrolEventDataDocument = gql`
  query getExtendedPatrolEventData($patrolEventId: UUID!) {
    getPatrolEventTasks(patrolEventId: $patrolEventId) {
      ...PatrolEventTask
    }
    getPatrolEventAttachments(patrolEventId: $patrolEventId) {
      id
      attachment {
        id
        filename
        mimetype
        uri
        createdAt
        encoding
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
  ${PatrolEventTaskFragmentDoc}
`;

/**
 * __useGetExtendedPatrolEventDataQuery__
 *
 * To run a query within a React component, call `useGetExtendedPatrolEventDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtendedPatrolEventDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtendedPatrolEventDataQuery({
 *   variables: {
 *      patrolEventId: // value for 'patrolEventId'
 *   },
 * });
 */
export function useGetExtendedPatrolEventDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetExtendedPatrolEventDataQuery,
    GetExtendedPatrolEventDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetExtendedPatrolEventDataQuery,
    GetExtendedPatrolEventDataQueryVariables
  >(GetExtendedPatrolEventDataDocument, baseOptions);
}
export function useGetExtendedPatrolEventDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExtendedPatrolEventDataQuery,
    GetExtendedPatrolEventDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetExtendedPatrolEventDataQuery,
    GetExtendedPatrolEventDataQueryVariables
  >(GetExtendedPatrolEventDataDocument, baseOptions);
}
export type GetExtendedPatrolEventDataQueryHookResult = ReturnType<
  typeof useGetExtendedPatrolEventDataQuery
>;
export type GetExtendedPatrolEventDataLazyQueryHookResult = ReturnType<
  typeof useGetExtendedPatrolEventDataLazyQuery
>;
export type GetExtendedPatrolEventDataQueryResult = Apollo.QueryResult<
  GetExtendedPatrolEventDataQuery,
  GetExtendedPatrolEventDataQueryVariables
>;
