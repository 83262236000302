import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { DateRangePicker, DateRangePickerShape } from "react-dates";
import { useTranslation } from "react-i18next";
import theme from "../../utils/theme";

const useStyles = makeStyles({
  boxWrapper: {
    "& .DateRangePickerInput": {
      display: "flex",
    },
    "& .DateInput": {
      width: "auto",
    },
    "& .DateInput_input": {
      borderBottom: "none",
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.body2.fontWeight,
      fontSize: theme.typography.body2.fontSize,
      "&::placeholder": {
        color: theme.palette.grey[400],
      },
    },
    "& .DateRangePickerInput_arrow": {
      display: "none",
    },
  },
});

export default function StyledDateRangePicker(props: DateRangePickerShape): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.boxWrapper}>
      <DateRangePicker
        small
        noBorder
        displayFormat="DD/MM/YYYY"
        appendToBody
        showClearDates
        isOutsideRange={(): boolean => false}
        startDatePlaceholderText={t("Start date")}
        endDatePlaceholderText={t("End date")}
        {...props}
      />
    </Box>
  );
}
