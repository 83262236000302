import { TFunction } from "i18next";
import { TaskStatusType } from "../../../generated/types";

export default function statusTypeValueDisplay(
  type: TaskStatusType,
  t: TFunction
): string {
  switch (type) {
    case TaskStatusType.Todo:
      return t("TODO");
    case TaskStatusType.InProgress:
      return t("INPROGESS");
    case TaskStatusType.Done:
      return t("DONE");
    default:
      return "";
  }
}
