import { TFunction } from "i18next";
import moment from "moment";
import React from "react";
import { MimeType } from "../../../generated/types";
import font from "../../../images/Roboto-Regular.ttf";
import { SHORT_DATE_FORMAT_WITH_HOUR } from "../../../utils/consts";
import generateUserNameString from "../../../utils/generateUserNameString";
import theme from "../../../utils/theme";
import {
  Document,
  Font as xd,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import {
  TaskAttachmentFragment,
  TaskDetailsFragment,
} from "../graphql/fragments.generated";

interface Props {
  taskData: TaskDetailsFragment;
  t: TFunction;
  companyName: string;
  workplaceName: string;
  workplaceShortName: string;
  attachments?: TaskAttachmentFragment[];
}

xd.register({
  family: "Roboto",
  src: font,
});

const sectionMarginBottom = 15;

const convertDate = (isoDate: string | null | undefined): string => {
  if (isoDate) {
    return moment(isoDate).format(SHORT_DATE_FORMAT_WITH_HOUR);
  }
  return "-";
};

// TODO there is a problem with polish signs, when we add fontFamilly to styles, than we get error (pdf generator bug) - for this moment polish words dont work here
const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontSize: 12,
    padding: 20,
    paddingLeft: 35,
    paddingRight: 35,
    paddingTop: 25,
    position: "relative",
  },
  caption: {
    top: 5,
    width: "100%",
    paddingLeft: 5,
    position: "absolute",
  },
  companyInfo: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  headerTitle: {
    marginBottom: 5,
    fontSize: 12,
    color: theme.palette.secondary.main,
  },
  title: {
    fontSize: 20,
    marginBottom: 20,
  },
  text: {
    fontSize: 14,
    marginBottom: 20,
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.secondary.main,
    marginBottom: sectionMarginBottom,
  },
  patrolInfoSection: {
    flexDirection: "row",
    marginBottom: sectionMarginBottom,
    flexWrap: "wrap",
  },
  patrolInfoHeader: {
    marginBottom: 5,
    fontSize: 12,
    fontWeight: 800,
    color: theme.palette.secondary.dark,
  },
  patrolInfoWrapper: {
    width: "32.4%",
    marginBottom: 25,
  },
  subTitle: {
    fontSize: 15,
    marginBottom: 10,
  },
});

export default function TaskPdf({
  taskData,
  t,
  companyName,
  workplaceName,
  workplaceShortName,
  attachments,
}: Props): JSX.Element {
  const displayAssignees = taskData.assignees.length
    ? taskData.assignees.map((a) => generateUserNameString(a))
    : "-";

  const displayReporter = taskData.reporter
    ? generateUserNameString(taskData.reporter)
    : "-";

  const displayCreator = taskData.createdBy
    ? generateUserNameString(taskData.createdBy)
    : "-";

  const displayCategories = taskData.categories.length
    ? taskData.categories.map((c) => c.name)
    : "-";

  const displayLabels = taskData.labels.length ? taskData.labels.map((l) => l.name) : "-";

  const attachmentsUrls = attachments
    ?.filter(
      (a) =>
        a.attachment.mimetype !== MimeType.Pdf && a.attachment.mimetype !== MimeType.Mp4
    )
    .map((a) => a.attachment.uri);

  const taskInfos = [
    { headerText: t("Due date"), value: convertDate(taskData.dueDate) },
    { headerText: t("Reported date"), value: convertDate(taskData.reportedDate) },
    { headerText: t("Finished date"), value: convertDate(taskData.finishedDate) },
    { headerText: t("Assignees"), value: displayAssignees },
    { headerText: t("Reporter"), value: displayReporter },
    { headerText: t("Task creator"), value: displayCreator },
    { headerText: t("Categories"), value: displayLabels },
    { headerText: t("Labels"), value: displayCategories },
  ];
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.caption}>
          <Text
            style={{
              fontSize: 8,
              color: theme.palette.secondary.main,
            }}
          >
            {t("Generated in rids.app at")} {moment().format(SHORT_DATE_FORMAT_WITH_HOUR)}
          </Text>
        </View>
        <View style={styles.companyInfo}>
          <View>
            <Text>{companyName}</Text>
            <Text>{workplaceName}</Text>
          </View>
        </View>

        <View style={styles.headerTitle}>
          <Text>{`${t("Task")} ${workplaceShortName}-${taskData.friendlyId}`}</Text>
        </View>
        <View style={styles.title}>
          <Text>{taskData.name}</Text>
        </View>

        <View style={styles.headerTitle}>
          <Text>{t("Location")}</Text>
        </View>
        <View style={styles.text}>
          <Text>{taskData.location || "-"}</Text>
        </View>

        <View style={styles.headerTitle}>
          <Text>{t("Description")}</Text>
        </View>
        <View style={styles.text}>
          <Text>{taskData.description || "-"}</Text>
        </View>

        <View style={styles.divider} />

        <View style={styles.patrolInfoSection}>
          {taskInfos.map((value) => (
            <View key={value.headerText + value.value} style={styles.patrolInfoWrapper}>
              <Text style={styles.patrolInfoHeader}>{value.headerText}:</Text>
              {Array.isArray(value.value) ? (
                value.value.map((v) => <Text key={v}>{v}</Text>)
              ) : (
                <Text>{value.value}</Text>
              )}
            </View>
          ))}
        </View>
        {attachmentsUrls &&
          attachmentsUrls.map((url) => (
            <View key={url}>
              <Image style={{ width: "100%", height: "200px" }} src={url} source={url} />
            </View>
          ))}
      </Page>
    </Document>
  );
}
