import * as Types from '../../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetWorkplaceUsersQueryVariables = Types.Exact<{
  includeInactive?: Types.Maybe<Types.Scalars['Boolean']>;
  workplaceId: Types.Scalars['UUID'];
}>;


export type GetWorkplaceUsersQuery = { __typename?: 'Query', getWorkplaceUsers: Array<{ __typename?: 'User', id: any, email: any, firstName?: Types.Maybe<string>, lastName?: Types.Maybe<string>, isActive: boolean, userWorkplaceRoles?: Types.Maybe<Array<{ __typename?: 'UserWorkplaceRole', id: any, workplaceRole: { __typename?: 'WorkplaceRole', id: any, name: string }, workplace: { __typename?: 'Workplace', id: any } }>> }> };

export type GetWorkplaceRolesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID'];
}>;


export type GetWorkplaceRolesQuery = { __typename?: 'Query', getWorkplaceRoles: Array<{ __typename?: 'WorkplaceRole', id: any, name: string }> };


export const GetWorkplaceUsersDocument = gql`
    query GetWorkplaceUsers($includeInactive: Boolean, $workplaceId: UUID!) {
  getWorkplaceUsers(includeInactive: $includeInactive, workplaceId: $workplaceId) {
    id
    email
    firstName
    lastName
    isActive
    userWorkplaceRoles {
      id
      workplaceRole {
        id
        name
      }
      workplace {
        id
      }
    }
  }
}
    `;

/**
 * __useGetWorkplaceUsersQuery__
 *
 * To run a query within a React component, call `useGetWorkplaceUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplaceUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplaceUsersQuery({
 *   variables: {
 *      includeInactive: // value for 'includeInactive'
 *      workplaceId: // value for 'workplaceId'
 *   },
 * });
 */
export function useGetWorkplaceUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkplaceUsersQuery, GetWorkplaceUsersQueryVariables>) {
        return Apollo.useQuery<GetWorkplaceUsersQuery, GetWorkplaceUsersQueryVariables>(GetWorkplaceUsersDocument, baseOptions);
      }
export function useGetWorkplaceUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkplaceUsersQuery, GetWorkplaceUsersQueryVariables>) {
          return Apollo.useLazyQuery<GetWorkplaceUsersQuery, GetWorkplaceUsersQueryVariables>(GetWorkplaceUsersDocument, baseOptions);
        }
export type GetWorkplaceUsersQueryHookResult = ReturnType<typeof useGetWorkplaceUsersQuery>;
export type GetWorkplaceUsersLazyQueryHookResult = ReturnType<typeof useGetWorkplaceUsersLazyQuery>;
export type GetWorkplaceUsersQueryResult = Apollo.QueryResult<GetWorkplaceUsersQuery, GetWorkplaceUsersQueryVariables>;
export const GetWorkplaceRolesDocument = gql`
    query GetWorkplaceRoles($companyId: UUID!) {
  getWorkplaceRoles(companyId: $companyId) {
    id
    name
  }
}
    `;

/**
 * __useGetWorkplaceRolesQuery__
 *
 * To run a query within a React component, call `useGetWorkplaceRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplaceRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplaceRolesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetWorkplaceRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkplaceRolesQuery, GetWorkplaceRolesQueryVariables>) {
        return Apollo.useQuery<GetWorkplaceRolesQuery, GetWorkplaceRolesQueryVariables>(GetWorkplaceRolesDocument, baseOptions);
      }
export function useGetWorkplaceRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkplaceRolesQuery, GetWorkplaceRolesQueryVariables>) {
          return Apollo.useLazyQuery<GetWorkplaceRolesQuery, GetWorkplaceRolesQueryVariables>(GetWorkplaceRolesDocument, baseOptions);
        }
export type GetWorkplaceRolesQueryHookResult = ReturnType<typeof useGetWorkplaceRolesQuery>;
export type GetWorkplaceRolesLazyQueryHookResult = ReturnType<typeof useGetWorkplaceRolesLazyQuery>;
export type GetWorkplaceRolesQueryResult = Apollo.QueryResult<GetWorkplaceRolesQuery, GetWorkplaceRolesQueryVariables>;