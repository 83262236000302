import { ApolloError } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import Attachments from "../../../../components/Attachments/Attachments";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { TaskAttachmentFragment } from "../../graphql/fragments.generated";
import { GET_TASK_ATTACHMENTS } from "../../graphql/query";
import { GetTaskAttachmentsQuery } from "../../graphql/query.generated";
import {
  useDeleteTaskAttachmentMutation,
  useUploadTaskAttachmentsMutation,
} from "../../graphql/mutation.generated";

interface Props {
  taskId: string;
  canUserEditAttachments?: boolean;
  attachments?: TaskAttachmentFragment[];
  attachmentsLoading?: boolean;
  attachmentsFetchingError?: ApolloError;
}
export default function TaskAttachments({
  taskId,
  canUserEditAttachments,
  attachments,
  attachmentsFetchingError,
  attachmentsLoading,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [
    updateTaskAttachmentsMutation,
    { loading: updateAttachmentsLoading, error: updateAttachmentsError },
  ] = useUploadTaskAttachmentsMutation({
    update: (cache, res) => {
      if (res.data?.uploadTaskAttachments) {
        const resUpload = res.data.uploadTaskAttachments;
        const taskAttachmentsQuery = cache.readQuery<GetTaskAttachmentsQuery>({
          query: GET_TASK_ATTACHMENTS,
          variables: { taskId },
        });
        if (taskAttachmentsQuery?.getTaskAttachments) {
          const newTaskAttachments = [
            ...taskAttachmentsQuery.getTaskAttachments,
            ...resUpload,
          ];
          const newTaskAttachmentsQuery: GetTaskAttachmentsQuery = {
            ...taskAttachmentsQuery,
            getTaskAttachments: newTaskAttachments,
          };

          cache.writeQuery({
            query: GET_TASK_ATTACHMENTS,
            variables: { taskId },
            data: newTaskAttachmentsQuery,
          });
        }
      }
    },
  });

  const [
    deleteTaskAttachmentMutation,
    { error: deleteTaskAttachmentError },
  ] = useDeleteTaskAttachmentMutation();

  const onAttachmentDelete = async (taskAttachmentId: string): Promise<void> => {
    try {
      await deleteTaskAttachmentMutation({
        variables: { id: taskAttachmentId },
        update: (cache, res) => {
          if (res.data?.deleteTaskAttachment) {
            cache.modify({
              fields: {
                getTaskAttachments(existingAttachments = [], { readField }) {
                  return existingAttachments.filter(
                    (attachmentRef) => taskAttachmentId !== readField("id", attachmentRef)
                  );
                },
              },
            });
          }
        },
      });
    } catch (e) {
      //
    }
  };

  const onFileUpload = async (files: File[]): Promise<void> => {
    try {
      await updateTaskAttachmentsMutation({
        variables: {
          files,
          taskId,
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <Attachments
        attachments={
          attachments?.map((taskAttachment) => ({
            attachmentWrapperId: taskAttachment.id,
            ...taskAttachment.attachment,
          })) || []
        }
        onAttachmentDelete={onAttachmentDelete}
        onFileUpload={onFileUpload}
        addingLoading={updateAttachmentsLoading}
        fetchingLoading={attachmentsLoading}
        canUserEdit={canUserEditAttachments}
      />
      <ErrorDisplay
        error={updateAttachmentsError}
        message={t(
          "Error occured while uploading files, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={attachmentsFetchingError}
        message={t(
          "Error occured while getting task attachments, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={deleteTaskAttachmentError}
        message={t(
          "Error occured while deleting task attachment, please try again or contact us."
        )}
      />
    </>
  );
}
