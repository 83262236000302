export const DASHBOARD = "/dashboard";

export const SIGN_IN = "/sign-in";

export const SIGN_UP = "/sign-up";
export const SIGN_UP_FROM_LINK = "/sign-up/:token";
export const VERIFY_EMAIL = "/verify-email";
export const ACTIVATE_ACCOUNT = "/activate-account/:token";
export const SIGN_UP_COMPLETED = "/sign-up-completed";

export const RESET_PASSWORD = "/reset-password";
export const RESET_PASSWORD_EMAIL_SENT = "/reset-password-email-sent";
export const SET_NEW_PASSWORD = "/set-new-password/:token";
export const PASSWORD_CHANGED = "/password-changed";

export const ADMIN_PANEL = "/admin-panel";

export const ACCOUNT_SETTINGS = "/account-settings";

export const SETTINGS = "/settings";
export const SETTINGS_WORKPLACES = `/workplaces`;
export const SETTINGS_USERS = `/users`;
export const SETTINGS_LABELS = `/labels`;
export const SETTINGS_CATEGORIES = `/categories`;

export const WORKPLACE = "/workplace";

export const TASKS = "/tasks";
export const KANBAN = "/kanban";
export const TASKS_LIST = "/list";
export const TASKS_ARCHIVE = "/tasks-archive";
export const TEAM = "/team";
export const PATROLS_LIST = "/patrolslist";
export const PATROL = "/patrol";
export const WORKPLACE_SETTINGS = "/workplace-settings";
