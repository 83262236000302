import Box from "@material-ui/core/Box";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, usePagination, useTable } from "react-table";
import { DangerButton } from "../../../components/Buttons/DangerButton/DangerButton";
import { CustomPaginationFooterRow } from "../../../components/CustomTable/CustomPaginationFooterRow/CustomPaginationFooterRow";
import { CustomTableCell } from "../../../components/CustomTable/CustomTableCell/CustomTableCell";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FullScreenLoader from "../../../components/FullScreenLoader/FullScreenLoader";
import { CompanyContext } from "../../../context/CompanyContext/CompanyContextProvider";
import { Category, Permissions } from "../../../generated/types";
import IsUserAuthorised from "../../../utils/AuthUtils/IsUserAuthorised";
import { useDeleteCategoryMutation } from "../graphql/mutation.generated";
import { GET_CATEGORIES } from "../graphql/query";
import AddCategoryDialog from "./AddCategoryDialog";
import EditCategoryDialog from "./EditCategoryDialog";
import {
  GetCategoriesQuery,
  useGetCategoriesLazyQuery,
} from "../graphql/query.generated";

import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  cell: {
    width: "30%",
  },
});

export default function Categories(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { chosenCompanyId } = useContext(CompanyContext);

  const [addCategoryDialogOpen, setAddCategoryDialogOpen] = useState<boolean>(false);
  const [editCategoryDialogOpen, setEditCategoryDialogOpen] = useState<boolean>(false);
  const [editedCategoryData, setEditedCategoryData] = useState<Category | null>(null);

  const [
    getCategoriesQuery,
    { data: categoriesData, loading: loadingCategories, error: categoriesFetchingError },
  ] = useGetCategoriesLazyQuery();

  const [
    deleteCategoryMutation,
    { error: deleteCategoryError, loading: deleteCategoryLoading },
  ] = useDeleteCategoryMutation();

  useEffect(() => {
    if (chosenCompanyId) {
      getCategoriesQuery({
        variables: {
          companyId: chosenCompanyId,
        },
      });
    }
  }, [chosenCompanyId, getCategoriesQuery]);

  const data = React.useMemo((): Category[] => {
    return categoriesData?.getCategories || [];
  }, [categoriesData]);

  const columns: Column<Category>[] = React.useMemo(
    () => [
      {
        Header: t("Name") as string,
        accessor: "name",
      },
    ],
    [t]
  );

  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable<Category>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    usePagination
  );

  const handleChangePage = (event, newPage): void => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event): void => {
    setPageSize(Number(event.target.value));
  };

  const openEditUserDialog = (rowData: Category): void => {
    setEditedCategoryData(rowData);
    setEditCategoryDialogOpen(true);
  };

  const closeEditUserDialog = (): void => {
    setEditedCategoryData(null);
    setEditCategoryDialogOpen(false);
  };

  const removeCategory = async (id: string): Promise<void> => {
    try {
      await deleteCategoryMutation({
        variables: { id },
        update: (cache, res) => {
          if (res.data?.deleteCategory && chosenCompanyId) {
            const categoriesQuery: GetCategoriesQuery | null = cache.readQuery({
              query: GET_CATEGORIES,
              variables: { companyId: chosenCompanyId },
            });

            if (categoriesQuery) {
              const newCategories: GetCategoriesQuery = {
                ...categoriesQuery,
                getCategories: categoriesQuery.getCategories.filter((c) => c.id !== id),
              };

              cache.writeQuery({
                query: GET_CATEGORIES,
                variables: { companyId: chosenCompanyId },
                data: newCategories,
              });
            }
          }
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <Box>
      <Box pb={3} display="flex">
        {/* <Box mr={3}>
          <TextField
            name="search"
            size="small"
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
            variant="outlined"
          />
        </Box> */}
        <Button
          color="primary"
          variant="contained"
          onClick={(): void => setAddCategoryDialogOpen(true)}
        >
          {t("Add new category")}
        </Button>
      </Box>
      <TableContainer>
        <Table className={classes.table} {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <CustomTableCell size="small" {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </CustomTableCell>
                ))}
                <CustomTableCell />
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell className={classes.cell} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                  <TableCell align="right">
                    <Box display="flex" justifyContent="flex-end">
                      <Box mr={2}>
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={(): void => openEditUserDialog(row.original)}
                        >
                          {t("Edit")}
                        </Button>
                      </Box>
                      <Box>
                        <IsUserAuthorised permission={Permissions.DeleteTerm}>
                          <DangerButton
                            variant="outlined"
                            onClick={(): Promise<void> => removeCategory(row.original.id)}
                          >
                            {t("Remove")}
                          </DangerButton>
                        </IsUserAuthorised>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          <TableFooter>
            <CustomPaginationFooterRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: data.length }]}
                count={data.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </CustomPaginationFooterRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <FullScreenLoader open={loadingCategories || deleteCategoryLoading} />
      <AddCategoryDialog
        open={addCategoryDialogOpen}
        onClose={(): void => setAddCategoryDialogOpen(false)}
        companyId={chosenCompanyId}
      />
      {editedCategoryData && (
        <EditCategoryDialog
          open={editCategoryDialogOpen}
          onClose={(): void => closeEditUserDialog()}
          categoryData={editedCategoryData}
        />
      )}
      <ErrorDisplay
        error={categoriesFetchingError}
        message={t("Error occured while fetching categories, try again or contact us.")}
      />

      <ErrorDisplay
        error={deleteCategoryError}
        message={t("Error occured while deleting category, try again or contact us.")}
      />
    </Box>
  );
}
