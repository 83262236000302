import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, CircularProgress, TextField, Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import { Country } from "../../generated/types";
import { requiredStringValidator } from "../../utils/formValidators";
import { useCreateCompanyMutation } from "./graphql/mutation.generated";

interface FormValue {
  address: string;
  city: string;
  country: Country;
  name: string;
  postCode: string;
  vatNumber: number;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    address: requiredStringValidator(t),
    city: requiredStringValidator(t),
    name: requiredStringValidator(t),
    postCode: requiredStringValidator(t),
  });

export default function CreateCompany(): JSX.Element {
  const { t } = useTranslation();

  const { handleSubmit, errors, register, reset } = useForm<FormValue>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      country: Country.Poland,
    },
  });

  const [
    createCompanyMutation,
    { error: createCompanyError, loading: createCompanyLoading },
  ] = useCreateCompanyMutation();

  const onCompanyCreate = async (value: FormValue): Promise<void> => {
    try {
      const res = await createCompanyMutation({
        variables: {
          companyData: { ...value, country: Country.Poland },
        },
      });
      if (res) {
        reset({});
      }
    } catch (e) {
      //
    }
  };

  return (
    <Box width="400px">
      <Box mb={1}>
        <Typography variant="h5">Create company</Typography>
      </Box>
      <Box>
        <form onSubmit={handleSubmit(onCompanyCreate)}>
          <Box mb={2}>
            <TextField
              variant="outlined"
              name="name"
              inputRef={register}
              fullWidth
              label="Name"
              placeholder="Company name"
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </Box>
          <Box mb={2}>
            <TextField
              variant="outlined"
              name="vatNumber"
              inputRef={register}
              fullWidth
              label="VAT number"
              placeholder="Company VAT number"
            />
          </Box>
          <Box mb={2}>
            <TextField
              variant="outlined"
              name="address"
              inputRef={register}
              fullWidth
              label="Address"
              placeholder="Company Address"
              error={!!errors.address}
              helperText={errors.address?.message}
            />
          </Box>
          <Box mb={2}>
            <TextField
              variant="outlined"
              name="postCode"
              inputRef={register}
              fullWidth
              label="Post code"
              placeholder="Company Post code"
              error={!!errors.postCode}
              helperText={errors.postCode?.message}
            />
          </Box>
          <Box mb={2}>
            <TextField
              variant="outlined"
              name="city"
              inputRef={register}
              fullWidth
              label="City"
              placeholder="Company city"
              error={!!errors.city}
              helperText={errors.city?.message}
            />
          </Box>
          <Box mb={2}>
            <TextField
              variant="outlined"
              name="country"
              inputRef={register}
              fullWidth
              label="Country"
              placeholder="Country"
              disabled
            />
          </Box>
          <Box display="flex">
            <Button variant="outlined" color="primary" type="submit">
              Create company
            </Button>
            <Box ml={2}>{createCompanyLoading && <CircularProgress />}</Box>
          </Box>
        </form>
      </Box>
      <ErrorDisplay error={createCompanyError} message="Error why creating company" />
    </Box>
  );
}
