import * as Types from '../../../generated/types';

import { CompanyUserFragment } from './fragments.generated';
import { gql } from '@apollo/client';
import { CompanyUserFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
export type GetCompanyWorkplacesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID'];
}>;


export type GetCompanyWorkplacesQuery = { __typename?: 'Query', getWorkplaces: Array<{ __typename?: 'Workplace', id: any, name: string, shortName: string }> };

export type GetLabelsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID'];
}>;


export type GetLabelsQuery = { __typename?: 'Query', getLabels: Array<{ __typename?: 'Label', id: any, name: string }> };

export type GetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID'];
}>;


export type GetCategoriesQuery = { __typename?: 'Query', getCategories: Array<{ __typename?: 'Category', id: any, name: string }> };

export type GetCompanyUsersQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID'];
}>;


export type GetCompanyUsersQuery = { __typename?: 'Query', getCompanyUsers: Array<(
    { __typename?: 'User' }
    & CompanyUserFragment
  )> };

export type GetAllRolesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID'];
}>;


export type GetAllRolesQuery = { __typename?: 'Query', getCompanyRoles: Array<{ __typename?: 'CompanyRole', id: any, name: string }>, getWorkplaceRoles: Array<{ __typename?: 'WorkplaceRole', id: any, name: string }> };


export const GetCompanyWorkplacesDocument = gql`
    query GetCompanyWorkplaces($companyId: UUID!) {
  getWorkplaces(companyId: $companyId) {
    id
    name
    shortName
  }
}
    `;

/**
 * __useGetCompanyWorkplacesQuery__
 *
 * To run a query within a React component, call `useGetCompanyWorkplacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyWorkplacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyWorkplacesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyWorkplacesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyWorkplacesQuery, GetCompanyWorkplacesQueryVariables>) {
        return Apollo.useQuery<GetCompanyWorkplacesQuery, GetCompanyWorkplacesQueryVariables>(GetCompanyWorkplacesDocument, baseOptions);
      }
export function useGetCompanyWorkplacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyWorkplacesQuery, GetCompanyWorkplacesQueryVariables>) {
          return Apollo.useLazyQuery<GetCompanyWorkplacesQuery, GetCompanyWorkplacesQueryVariables>(GetCompanyWorkplacesDocument, baseOptions);
        }
export type GetCompanyWorkplacesQueryHookResult = ReturnType<typeof useGetCompanyWorkplacesQuery>;
export type GetCompanyWorkplacesLazyQueryHookResult = ReturnType<typeof useGetCompanyWorkplacesLazyQuery>;
export type GetCompanyWorkplacesQueryResult = Apollo.QueryResult<GetCompanyWorkplacesQuery, GetCompanyWorkplacesQueryVariables>;
export const GetLabelsDocument = gql`
    query GetLabels($companyId: UUID!) {
  getLabels(companyId: $companyId) {
    id
    name
  }
}
    `;

/**
 * __useGetLabelsQuery__
 *
 * To run a query within a React component, call `useGetLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetLabelsQuery(baseOptions?: Apollo.QueryHookOptions<GetLabelsQuery, GetLabelsQueryVariables>) {
        return Apollo.useQuery<GetLabelsQuery, GetLabelsQueryVariables>(GetLabelsDocument, baseOptions);
      }
export function useGetLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLabelsQuery, GetLabelsQueryVariables>) {
          return Apollo.useLazyQuery<GetLabelsQuery, GetLabelsQueryVariables>(GetLabelsDocument, baseOptions);
        }
export type GetLabelsQueryHookResult = ReturnType<typeof useGetLabelsQuery>;
export type GetLabelsLazyQueryHookResult = ReturnType<typeof useGetLabelsLazyQuery>;
export type GetLabelsQueryResult = Apollo.QueryResult<GetLabelsQuery, GetLabelsQueryVariables>;
export const GetCategoriesDocument = gql`
    query GetCategories($companyId: UUID!) {
  getCategories(companyId: $companyId) {
    id
    name
  }
}
    `;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, baseOptions);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, baseOptions);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const GetCompanyUsersDocument = gql`
    query GetCompanyUsers($companyId: UUID!) {
  getCompanyUsers(companyId: $companyId) {
    ...CompanyUser
  }
}
    ${CompanyUserFragmentDoc}`;

/**
 * __useGetCompanyUsersQuery__
 *
 * To run a query within a React component, call `useGetCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUsersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>) {
        return Apollo.useQuery<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>(GetCompanyUsersDocument, baseOptions);
      }
export function useGetCompanyUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>) {
          return Apollo.useLazyQuery<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>(GetCompanyUsersDocument, baseOptions);
        }
export type GetCompanyUsersQueryHookResult = ReturnType<typeof useGetCompanyUsersQuery>;
export type GetCompanyUsersLazyQueryHookResult = ReturnType<typeof useGetCompanyUsersLazyQuery>;
export type GetCompanyUsersQueryResult = Apollo.QueryResult<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>;
export const GetAllRolesDocument = gql`
    query GetAllRoles($companyId: UUID!) {
  getCompanyRoles(companyId: $companyId) {
    id
    name
  }
  getWorkplaceRoles(companyId: $companyId) {
    id
    name
  }
}
    `;

/**
 * __useGetAllRolesQuery__
 *
 * To run a query within a React component, call `useGetAllRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRolesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetAllRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRolesQuery, GetAllRolesQueryVariables>) {
        return Apollo.useQuery<GetAllRolesQuery, GetAllRolesQueryVariables>(GetAllRolesDocument, baseOptions);
      }
export function useGetAllRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRolesQuery, GetAllRolesQueryVariables>) {
          return Apollo.useLazyQuery<GetAllRolesQuery, GetAllRolesQueryVariables>(GetAllRolesDocument, baseOptions);
        }
export type GetAllRolesQueryHookResult = ReturnType<typeof useGetAllRolesQuery>;
export type GetAllRolesLazyQueryHookResult = ReturnType<typeof useGetAllRolesLazyQuery>;
export type GetAllRolesQueryResult = Apollo.QueryResult<GetAllRolesQuery, GetAllRolesQueryVariables>;