import React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { SIGN_IN } from "../../routing/paths";
import { useAuthToken } from "./useAuthToken";

export default function ProtectedRoute(props: RouteProps): JSX.Element {
  const [token] = useAuthToken();
  if (!token) return <Redirect to={SIGN_IN} />;
  // eslint-disable-next-line
  return <Route {...props} />;
}
