import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, MenuItem, TextField, Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import { CompanyContext } from "../../context/CompanyContext/CompanyContextProvider";
import { requiredStringValidator } from "../../utils/formValidators";
import { useDeleteWorkplaceMutation } from "./graphql/mutation.generated";
import { useGetWorkplacesForAdminLazyQuery } from "./graphql/query.generated";

interface FormValue {
  workplaceId: string;
  name: string;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    workplaceId: requiredStringValidator(t),
    name: requiredStringValidator(t),
  });

export default function DeleteWorkplace(): JSX.Element {
  const { companies } = useContext(CompanyContext);
  const { t } = useTranslation();
  const [companyToDeleteWorkplace, setCompanyToDeleteWorkplace] = useState("");

  const { handleSubmit, errors, register, control } = useForm<FormValue>({
    resolver: yupResolver(schema(t)),
  });

  const [
    getWorkplacesQuery,
    { data: workplacesData, error: gettingWorkplacesError },
  ] = useGetWorkplacesForAdminLazyQuery({ fetchPolicy: "network-only" });

  const [deleteWorkplace, { error: deleteWorkplaceError }] = useDeleteWorkplaceMutation();

  useEffect(() => {
    if (companyToDeleteWorkplace) {
      getWorkplacesQuery({
        variables: {
          companyId: companyToDeleteWorkplace,
        },
      });
    }
  }, [companyToDeleteWorkplace, getWorkplacesQuery]);

  const changeCompanyToDeleteWorkplace = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.value) {
      setCompanyToDeleteWorkplace(event.target.value);
    }
  };

  const submitForm = async (value: FormValue): Promise<void> => {
    try {
      const res = await deleteWorkplace({
        variables: {
          deleteWorkplaceData: {
            id: value.workplaceId,
            name: value.name,
          },
        },
      });
      if (res) {
        setCompanyToDeleteWorkplace("");
      }
    } catch (e) {
      //
    }
  };

  return (
    <Box width="300px">
      <Box mb={1}>
        <Typography variant="h5">Delete workplace</Typography>
      </Box>
      <Box mb={1}>
        <TextField
          select
          variant="outlined"
          fullWidth
          value={companyToDeleteWorkplace}
          onChange={changeCompanyToDeleteWorkplace}
          label="Company to delete workplace from"
        >
          {companies &&
            companies.map((company) => (
              <MenuItem key={`companypick${company.id}`} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
        </TextField>
      </Box>
      {companyToDeleteWorkplace && workplacesData?.getWorkplaces.length && (
        <form onSubmit={handleSubmit(submitForm)}>
          <Box mb={1}>
            <Controller
              name="workplaceId"
              control={control}
              as={
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  label="Company to delete workplace"
                  error={!!errors.workplaceId}
                  helperText={errors.workplaceId?.message}
                >
                  {workplacesData.getWorkplaces.map((workplace) => (
                    <MenuItem key={`companypick${workplace.id}`} value={workplace.id}>
                      {workplace.name}
                    </MenuItem>
                  ))}
                </TextField>
              }
            />
          </Box>
          <Box mb={1}>
            <TextField
              inputRef={register}
              name="name"
              variant="outlined"
              fullWidth
              placeholder="Write deleted workplace name"
              label="Deleted workplace name confirm"
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </Box>
          <Button type="submit" variant="outlined" color="primary">
            Delete workplace
          </Button>
        </form>
      )}
      <ErrorDisplay
        error={gettingWorkplacesError}
        message="Error while getting workplaces"
      />
      <ErrorDisplay
        error={deleteWorkplaceError}
        message="Error while deleting workplace"
      />
    </Box>
  );
}
