import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import {
  GetNotificationsQuery,
  useGetNotificationsLazyQuery,
} from "./graphql/query.generated";
import SingleNotification from "./SingleNotification/SingleNotification";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import {
  useSetAllNotificationsAsReadMutation,
  useSetNotificationAsReadMutation,
} from "./graphql/mutation.generated";
import { GET_NOTIFICATIONS } from "./graphql/query";
import { GET_UNREAD_NOTIFICATIONS_COUNT } from "../../layouts/graphql/query";
import { GetUnreadNotificationsCountQuery } from "../../layouts/graphql/query.generated";
import { ApolloCache } from "@apollo/client";

interface Props {
  open: boolean;
  onClose: () => void;
}

const NOTIFICATIONS_PAGE_SIZE = 10;

export default function Notifications({ open, onClose }: Props): JSX.Element {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [
    getNotificationsQuery,
    {
      data: notificationsData,
      error: notificationsFetchingError,
      loading: notificationsLoading,
    },
  ] = useGetNotificationsLazyQuery({ fetchPolicy: "network-only" });

  const [
    setAllNotificationsAsReadMutation,
    { error: setReadError, loading: setReadLoading },
  ] = useSetAllNotificationsAsReadMutation();

  const [
    setNotificationAsReadMutation,
    { error: setSingleReadError, loading: setSingleReadLoading },
  ] = useSetNotificationAsReadMutation();

  const offset = useMemo(() => (pageNumber - 1) * NOTIFICATIONS_PAGE_SIZE, [pageNumber]);

  useEffect(() => {
    if (open) {
      getNotificationsQuery({
        variables: {
          paginationData: {
            limit: NOTIFICATIONS_PAGE_SIZE,
            offset,
          },
        },
      });
    }
  }, [getNotificationsQuery, offset, open, pageNumber]);

  const notificationsToDisplay = useMemo(() => {
    if (notificationsData?.getNotifications.items) {
      return notificationsData.getNotifications.items;
    }
    return [];
  }, [notificationsData]);

  const markAllAsRead = async (): Promise<void> => {
    try {
      await setAllNotificationsAsReadMutation({
        update: (cache, response) => {
          if (response.data?.setAllNotificationsAsRead) {
            const query = cache.readQuery<GetNotificationsQuery>({
              query: GET_NOTIFICATIONS,
              variables: { paginationData: { limit: NOTIFICATIONS_PAGE_SIZE, offset } },
            });
            if (query) {
              const updatedQuery: GetNotificationsQuery = {
                ...query,
                getNotifications: {
                  ...query.getNotifications,
                  items: query.getNotifications.items.map((n) => ({ ...n, read: true })),
                },
              };
              cache.writeQuery({
                query: GET_NOTIFICATIONS,
                data: updatedQuery,
                variables: { paginationData: { limit: NOTIFICATIONS_PAGE_SIZE, offset } },
              });
            }
            changeUnreadNotificationsCount(cache);
          }
        },
      });
    } catch (error) {
      //
    }
  };

  const markSingleAsRead = async (id: string): Promise<void> => {
    try {
      await setNotificationAsReadMutation({
        variables: {
          id,
        },
        update: (cache, response) => {
          if (response.data?.setNotificationAsRead) {
            const query = cache.readQuery<GetNotificationsQuery>({
              query: GET_NOTIFICATIONS,
              variables: { paginationData: { limit: NOTIFICATIONS_PAGE_SIZE, offset } },
            });
            if (query) {
              const updatedQuery: GetNotificationsQuery = {
                ...query,
                getNotifications: {
                  ...query.getNotifications,
                  items: query.getNotifications.items.map((n) => ({
                    ...n,
                    read: n.id === id ? true : n.read,
                  })),
                },
              };
              cache.writeQuery({
                query: GET_NOTIFICATIONS,
                data: updatedQuery,
                variables: { paginationData: { limit: NOTIFICATIONS_PAGE_SIZE, offset } },
              });
            }

            changeUnreadNotificationsCount(cache, 1);
          }
        },
      });
    } catch (error) {
      //
    }
  };

  const changeUnreadNotificationsCount = (
    cache: ApolloCache<any>,
    notificationsToDelete?: number
  ): void => {
    const query = cache.readQuery<GetUnreadNotificationsCountQuery>({
      query: GET_UNREAD_NOTIFICATIONS_COUNT,
    });
    if (query) {
      const updatedQuery: GetUnreadNotificationsCountQuery = {
        getUnreadNotificationsCount: notificationsToDelete
          ? query.getUnreadNotificationsCount - notificationsToDelete
          : 0,
      };
      cache.writeQuery({
        query: GET_UNREAD_NOTIFICATIONS_COUNT,
        data: updatedQuery,
      });
    }
  };

  const pagesCount = useMemo<number>(() => {
    if (notificationsData?.getNotifications.totalCount) {
      return Math.ceil(
        notificationsData.getNotifications.totalCount / NOTIFICATIONS_PAGE_SIZE
      );
    }
    return 0;
  }, [notificationsData]);

  const onPageNumberChange = (_, page: number): void => {
    setPageNumber(page);
  };

  return (
    <>
      <Drawer open={open} anchor="left" onClose={(): void => onClose()}>
        <Box
          pt={2}
          pb={2}
          px={3}
          width="300px"
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
        >
          <Box>
            <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Typography variant="h5">{t("Notifications")}</Typography>
                {(setReadLoading || setSingleReadLoading) && (
                  <CircularProgress size={15} />
                )}
              </Box>
              <Tooltip title={t("Mark all as read") || ""}>
                <IconButton
                  color="primary"
                  onClick={(): Promise<void> => markAllAsRead()}
                >
                  <PlaylistAddCheckIcon />
                </IconButton>
              </Tooltip>
            </Box>
            {notificationsLoading ? (
              <Box>
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                {notificationsToDisplay.length ? (
                  notificationsToDisplay.map((notification) => (
                    <Box mb={2} key={`notiKey${notification.id}`}>
                      <SingleNotification
                        notification={notification}
                        onMarkAsRead={(): Promise<void> =>
                          markSingleAsRead(notification.id)
                        }
                      />
                    </Box>
                  ))
                ) : (
                  <Typography>{t("There are no notifications to display.")}</Typography>
                )}
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="center">
            <Pagination
              count={pagesCount}
              color="primary"
              page={pageNumber}
              onChange={onPageNumberChange}
            />
          </Box>
        </Box>
      </Drawer>
      <ErrorDisplay
        error={notificationsFetchingError}
        message={t(
          "Error occured while getting notifications, please try again later or contact us."
        )}
      />
      <ErrorDisplay
        error={setReadError}
        message={t(
          "Error occured while setting all notifications as read, please try again later or contact us."
        )}
      />
      <ErrorDisplay
        error={setSingleReadError}
        message={t(
          "Error occured while setting notification as read, please try again later or contact us."
        )}
      />
    </>
  );
}
