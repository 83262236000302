import { Box, MenuItem, TextField, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { CompanyContext } from "../../context/CompanyContext/CompanyContextProvider";

export default function PickCompany(): JSX.Element {
  const { companies, setCurrentCompany, chosenCompanyId } = useContext(CompanyContext);

  const changeCompany = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const pickedCompany = event.target.value;
    if (pickedCompany && setCurrentCompany) {
      setCurrentCompany(pickedCompany);
    }
  };
  return (
    <Box>
      <Box mb={1}>
        <Typography variant="h5">Pick viewed company</Typography>
      </Box>
      <Box width="300px">
        <TextField
          select
          variant="outlined"
          fullWidth
          value={chosenCompanyId}
          onChange={changeCompany}
          label="Picked company"
        >
          {companies &&
            companies.map((company) => (
              <MenuItem key={`companypick${company.id}`} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
        </TextField>
      </Box>
    </Box>
  );
}
