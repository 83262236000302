import { Box, Typography } from "@material-ui/core";
import React from "react";
import { User } from "../../generated/types";
import UserIcon from "../UserIcon/UserIcon";

interface Props {
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  userObject?: Partial<User> | null;
}

export default function UserNameDisplay({
  name,
  firstName,
  lastName,
  email,
  userObject,
}: Props): JSX.Element {
  let userName: string | null | undefined;
  if (userObject) {
    const { firstName: fn, email: em, lastName: lm } = userObject;
    userName = `${fn} ${lm}` || em;
  } else {
    userName = name || `${firstName} ${lastName}` || email;
  }
  return (
    <Box display="flex" alignItems="center">
      {userName ? (
        <>
          <Box mr={1}>
            <UserIcon name={userName} />
          </Box>
          <Typography variant="body2">{userName}</Typography>
        </>
      ) : (
        "-"
      )}
    </Box>
  );
}
