import { gql } from "@apollo/client";

export const taskStatusFragment = gql`
  fragment TaskStatus on TaskStatus {
    id
    name
    order
    type
  }
`;
