import { Box, CircularProgress } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import AssigneesSelect from "../../../../components/AssigneesSelect/AssigneesSelect";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { useChangeTaskAssigneesMutation } from "../../graphql/mutation.generated";

interface Props {
  workplaceId: string;
  taskId: string;
  assignees: string[];
  disabled?: boolean;
}

export default function TaskAssignees({
  workplaceId,
  taskId,
  assignees,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [
    changeTaskAssigneesMutation,
    { error: changeAssigneesError, loading: changeAssigneesLoading },
  ] = useChangeTaskAssigneesMutation();

  const changeAssignees = async (value: string[]): Promise<void> => {
    try {
      await changeTaskAssigneesMutation({
        variables: {
          changeTaskAssigneesData: {
            id: taskId,
            assigneesIds: value,
          },
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <>
      <Box position="relative">
        <AssigneesSelect
          disabled={disabled}
          assigneesIds={assignees}
          workplaceId={workplaceId}
          onAssigneesChange={(a): Promise<void> => changeAssignees(a)}
        />
        <Box position="absolute" top="50%" left="50%">
          {changeAssigneesLoading && <CircularProgress size={20} />}
        </Box>
      </Box>

      <ErrorDisplay
        error={changeAssigneesError}
        message={t(
          "Error occured while changing tasks assignees, please try again or contact us."
        )}
      />
    </>
  );
}
