import { Box, Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomSnackbar from "../../../../components/CustomSnackbar/CustomSnackbar";
import CustomDialog from "../../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { Workplace } from "../../../../generated/types";
import { MANAGER_ROLE_ID } from "../../../../utils/consts";
import { CompanyUserFragment } from "../../graphql/fragments.generated";
import { useGetCompanyWorkplacesLazyQuery } from "../../graphql/query.generated";
import { UserRole } from "../interfaces";
import useGetRolesToPick from "../useGetRolesToPick";
import AddNewUserRole from "./AddNewUserRole";
import EditUserRole from "./EditUserRole";

interface Props {
  open: boolean;
  onClose: (message: string) => void;
  userData?: CompanyUserFragment | null;
  companyId: string | undefined;
}

export default function EditUserRolesDialog({
  open,
  onClose,
  userData,
  companyId,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const [addNewRoleMode, setAddNewRoleMode] = useState<boolean>(false);
  const [editUserRoleLoading, setEditUserRoleLoading] = useState<boolean>(false);
  const [workplacesForNewRoles, setWorkplacesForNewRoles] = useState<Workplace[]>([]);
  const {
    data: rolesToPick,
    loading: rolesLoading,
    error: rolesGettingError,
  } = useGetRolesToPick({ companyId });

  const [
    getCompanyWorkplaces,
    { data: workplacesData, loading: workplacesLoading, error: workplacesGettingError },
  ] = useGetCompanyWorkplacesLazyQuery();

  useEffect(() => {
    if (companyId) {
      getCompanyWorkplaces({ variables: { companyId } });
    }
  }, [companyId, getCompanyWorkplaces]);

  useEffect(() => {
    const roles: UserRole[] = [];
    if (userData?.userCompanyRoles?.length) {
      userData.userCompanyRoles.forEach((userCompany) => {
        if (userCompany.companyRole.id === MANAGER_ROLE_ID) {
          roles.push({
            isManager: true,
            roleId: userCompany.companyRole.id,
            userRoleId: userCompany.id,
          });
        }
      });
    }
    if (userData?.userWorkplaceRoles?.length) {
      userData.userWorkplaceRoles.forEach((userWorkplace) => {
        roles.push({
          isManager: false,
          roleId: userWorkplace.workplaceRole.id,
          workplace: userWorkplace.workplace.id,
          userRoleId: userWorkplace.id,
        });
      });
    }

    setUserRoles(roles);
  }, [userData]);

  useEffect(() => {
    if (workplacesData?.getWorkplaces) {
      if (userRoles.length) {
        const alreadyTakenWorkplaces = userRoles
          .filter((ur) => ur.workplace)
          .map((ur) => ur.workplace);
        const freeWorkplaces = workplacesData.getWorkplaces.filter(
          (workplace) => alreadyTakenWorkplaces.indexOf(workplace.id) === -1
        );
        setWorkplacesForNewRoles(freeWorkplaces);
      } else {
        setWorkplacesForNewRoles([...workplacesData.getWorkplaces]);
      }
    }
  }, [userRoles, workplacesData]);

  const dialogHeadingName =
    userData?.firstName && userData?.lastName
      ? `${userData.firstName} ${userData.lastName}`
      : userData?.email;

  const dialogHeading = `${t("Edit user roles")}: ${dialogHeadingName}`;

  const onEditUserRoleLoading = (loading: boolean): void => {
    setEditUserRoleLoading(loading);
  };

  const onAddNewRole = (): void => {
    const managerRoles = userRoles.filter((r) => r.isManager === true);
    if (workplacesForNewRoles.length || !managerRoles.length) {
      setAddNewRoleMode(true);
    } else {
      setErrorMsg(t("There is no workplace left to add role for this user."));
    }
  };

  const onNewRoleAdded = (): void => {
    setAddNewRoleMode(false);
  };

  return (
    <>
      <CustomDialog
        loading={rolesLoading || workplacesLoading || editUserRoleLoading}
        open={open}
        onClose={(): void => onClose("")}
        maxWidth="md"
      >
        <CustomDialogContent heading={dialogHeading} width="600px">
          <Box pt={3}>
            {Boolean(userRoles.length) &&
              userRoles.map((roleInfo) => (
                <Box mb={4} key={roleInfo.roleId + roleInfo.workplace}>
                  <EditUserRole
                    onLoading={onEditUserRoleLoading}
                    rolesToPick={rolesToPick}
                    workplaces={workplacesData?.getWorkplaces}
                    userRole={roleInfo}
                    companyId={companyId}
                    deletable
                  />
                </Box>
              ))}
            {!userRoles.length && (
              <Box mb={4}>
                <Typography>{t("User has not any role in company")}</Typography>
              </Box>
            )}
            {addNewRoleMode && (
              <Box>
                <AddNewUserRole
                  onLoading={onEditUserRoleLoading}
                  onRoleAdded={onNewRoleAdded}
                  rolesToPick={rolesToPick}
                  workplaces={workplacesForNewRoles}
                  companyId={companyId || ""}
                  userEmail={userData?.email}
                  userCompanyRoleId={
                    userData?.userCompanyRoles ? userData.userCompanyRoles[0].id : ""
                  }
                />
              </Box>
            )}
            <Box mt={8} display="flex" justifyContent="space-between">
              <Button color="primary" variant="contained" onClick={onAddNewRole}>
                {t("Add new role")}
              </Button>
              <Button color="secondary" variant="text" onClick={() => onClose("")}>
                {t("Close")}
              </Button>
            </Box>
          </Box>
        </CustomDialogContent>
      </CustomDialog>
      <ErrorDisplay
        error={workplacesGettingError}
        message={t(
          "Error occured while geting companies workplaces, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={rolesGettingError}
        message={t(
          "Error occured while geting user's roles, please try again or contact us."
        )}
      />
      <CustomSnackbar
        open={!!errorMsg}
        message={errorMsg}
        handleClose={(): void => setErrorMsg("")}
      />
    </>
  );
}
