import { TextField, withStyles } from "@material-ui/core";
import theme from "../../utils/theme";

export const WhiteTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },
    "& .MuiSelect-iconOutlined": {
      color: "#fff",
    },
    "& .MuiOutlinedInput-input": {
      color: "#fff",
      fontSize: "0.8rem",
    },
    "& option": {
      color: "#000",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
  },
})(TextField);
