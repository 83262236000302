import * as Types from '../../../generated/types';

import { gql } from '@apollo/client';
export type CompanyUserFragment = { __typename?: 'User', id: any, email: any, firstName?: Types.Maybe<string>, lastName?: Types.Maybe<string>, isActive: boolean, userCompanyRoles?: Types.Maybe<Array<{ __typename?: 'UserCompanyRole', id: any, companyRole: { __typename?: 'CompanyRole', id: any, name: string }, company: { __typename?: 'Company', id: any } }>>, userWorkplaceRoles?: Types.Maybe<Array<{ __typename?: 'UserWorkplaceRole', id: any, workplaceRole: { __typename?: 'WorkplaceRole', id: any, name: string }, workplace: { __typename?: 'Workplace', id: any } }>> };

export type UpdateUserRolesFragment = { __typename?: 'User', userWorkplaceRoles?: Types.Maybe<Array<{ __typename?: 'UserWorkplaceRole', id: any }>> };

export const CompanyUserFragmentDoc = gql`
    fragment CompanyUser on User {
  id
  email
  firstName
  lastName
  isActive
  userCompanyRoles {
    id
    companyRole {
      id
      name
    }
    company {
      id
    }
  }
  userWorkplaceRoles {
    id
    workplaceRole {
      id
      name
    }
    workplace {
      id
    }
  }
}
    `;
export const UpdateUserRolesFragmentDoc = gql`
    fragment UpdateUserRoles on User {
  userWorkplaceRoles {
    id
  }
}
    `;