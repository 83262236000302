import { makeStyles, Typography } from "@material-ui/core";
import UpdateIcon from "@material-ui/icons/Update";
import moment from "moment";
import "moment-duration-format";
import React from "react";
import theme from "../../utils/theme";
import useGetStatusStyles from "../../utils/useGetStatusStyles";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
  },
  icon: {
    paddingRight: theme.spacing(0.5),
    display: "block",
  },
});

export default function DueDateDisplay({ date }: { date?: string }): JSX.Element {
  const classes = useStyles();
  const { getFontColor } = useGetStatusStyles();

  const convertDate = (dateToConvert: string): string => {
    return moment(dateToConvert).format("DD-MM-YYYY");
  };

  if (date) {
    return (
      <div className={classes.wrapper} style={{ color: getFontColor(date) }}>
        <UpdateIcon className={classes.icon} fontSize="small" />
        <Typography variant="body2">{convertDate(date)}</Typography>
      </div>
    );
  }
  return <div />;
}
