import { useContext, useEffect, useMemo, useState } from "react";
import { CompanyContext } from "../context/CompanyContext/CompanyContextProvider";
import { WorkplaceContext } from "../context/WorkplaceContext/WorkplaceContextProvider";

interface ReturnObject {
  currentCompanyName: string;
  currentWorkplaceName: string;
  currentWorkplaceShortName: string;
}

export default function useGetWorkplaceDisplayData(workplaceId?: string): ReturnObject {
  const { chosenCompanyId, companies } = useContext(CompanyContext);
  const { chosenWorkplaceId: contextWorkplaceId, workplaces } = useContext(
    WorkplaceContext
  );

  const [currentCompanyName, setCurrentCompanyName] = useState<string>("");
  const [currentWorkplaceName, setCurrentWorkplaceName] = useState<string>("");
  const [currentWorkplaceShortName, setCurrentWorkplaceShortName] = useState<string>("");

  const chosenWorkplaceId = useMemo(() => {
    return workplaceId || contextWorkplaceId;
  }, [contextWorkplaceId, workplaceId]);

  useEffect(() => {
    if (chosenCompanyId && companies) {
      const companyName = companies.find((c) => c.id === chosenCompanyId)?.name || "";
      setCurrentCompanyName(companyName);
    }
  }, [chosenCompanyId, companies]);

  useEffect(() => {
    if (chosenWorkplaceId && workplaces) {
      const workplaceName =
        workplaces.find((w) => w.id === chosenWorkplaceId)?.name || "";
      setCurrentWorkplaceName(workplaceName);
    }
  }, [chosenWorkplaceId, workplaces]);

  useEffect(() => {
    if (chosenWorkplaceId && workplaces) {
      const workplaceShortName =
        workplaces.find((w) => w.id === chosenWorkplaceId)?.shortName || "";
      setCurrentWorkplaceShortName(workplaceShortName);
    }
  }, [chosenWorkplaceId, workplaces]);

  return {
    currentCompanyName,
    currentWorkplaceName,
    currentWorkplaceShortName,
  };
}
