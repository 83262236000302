import { MenuItem, TextField, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import theme from "../../../../utils/theme";
import { useGetWorkplaceTaskStatusesLazyQuery } from "../../graphql/query.generated";

interface Props {
  taskStatus: string;
  workplaceId: string;
  onStatusChange: (status: string | null) => void;
  withoutBorder?: boolean;
  withEmptyValue?: boolean;
  disabled?: boolean;
}

const emptyValue = "empty";
export default function TaskStatusSelect({
  taskStatus,
  onStatusChange,
  workplaceId,
  withoutBorder,
  withEmptyValue,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const noBorder = withoutBorder ? { disableUnderline: true } : {};
  const [
    getStatusesQuery,
    { data: taskStatusesData, error: statusesLoadingError },
  ] = useGetWorkplaceTaskStatusesLazyQuery();

  useEffect(() => {
    if (workplaceId) {
      getStatusesQuery({
        variables: {
          workplaceId,
        },
      });
    }
  }, [getStatusesQuery, workplaceId]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let newValue: string | null = event.target.value;
    if (newValue === emptyValue) {
      newValue = null;
    }
    onStatusChange(newValue);
  };

  return (
    <>
      {taskStatusesData && (
        <TextField
          disabled={disabled}
          variant={withoutBorder ? ("standard" as any) : ("outlined" as any)}
          size="small"
          select
          value={taskStatus || emptyValue}
          onChange={onChange}
          label={!withoutBorder && t("Task status")}
          fullWidth
          InputProps={{
            ...noBorder,
          }}
        >
          {withEmptyValue && (
            <MenuItem value={emptyValue}>
              <Typography variant="body2" style={{ color: theme.palette.grey[400] }}>
                {t("Pick status")}
              </Typography>
            </MenuItem>
          )}
          {taskStatusesData.getWorkplaceTaskStatuses.map((status) => (
            <MenuItem key={`taskStatus${status.id}`} value={status.id}>
              {status.name}
            </MenuItem>
          ))}
        </TextField>
      )}

      <ErrorDisplay
        error={statusesLoadingError}
        message={t(
          "Error occured while loading tasks statuses for current workplace, please try again or contact us."
        )}
      />
    </>
  );
}
