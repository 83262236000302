import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { Workplace } from "../../../../generated/types";
import { MANAGER_ROLE_ID } from "../../../../utils/consts";
import { GET_COMPANY_USERS } from "../../graphql/query";
import { GetCompanyUsersQuery } from "../../graphql/query.generated";
import { RoleToPick, UserRole } from "../interfaces";
import RoleDisplayWrapper from "./RoleDisplayWrapper";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  useChangeUserWorkplaceRoleMutation,
  useDeleteUserWorkplaceRoleMutation,
} from "../../graphql/mutation.generated";

interface Props {
  userRole: UserRole;
  onLoading: (loading: boolean) => void;
  workplaces?: Workplace[];
  rolesToPick: RoleToPick[];
  companyId?: string;
  deletable: boolean;
}

interface FormData {
  role: string;
}

export default function EditUserRole({
  userRole,
  workplaces,
  rolesToPick,
  onLoading,
  companyId,
  deletable,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { control, formState, handleSubmit, reset } = useForm<FormData>({
    defaultValues: {
      role: userRole.roleId,
    },
  });

  const [
    changeUserWorkplaceRole,
    { error: changeUserError },
  ] = useChangeUserWorkplaceRoleMutation();

  const [
    deleteUserWorkplaceRoleMutation,
    { error: deleteWorkplaceRoleError },
  ] = useDeleteUserWorkplaceRoleMutation();

  const getRoleName = (roleId: string): string => {
    const pickedRole = rolesToPick.filter((r) => r.id === roleId)[0];
    return pickedRole?.name;
  };

  const getWorkplaceName = (workplaceId: string): string => {
    const workplace = workplaces?.filter((w) => w.id === workplaceId)[0];
    return workplace?.name || "";
  };

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    onLoading(true);
    try {
      const res = await changeUserWorkplaceRole({
        variables: {
          changeUserWorkplaceRoleData: {
            id: userRole.userRoleId,
            workplaceRoleId: formData.role,
          },
        },
      });

      if (res.data) {
        reset({ role: formData.role });
      }
      onLoading(false);
    } catch (e) {
      onLoading(false);
    }
  };

  const deleteWorkplaceRole = async (): Promise<void> => {
    if (userRole.workplace) {
      onLoading(true);

      try {
        await deleteUserWorkplaceRoleMutation({
          variables: {
            id: userRole.userRoleId,
          },
          update: (cache, res) => {
            if (res.data?.deleteUserWorkplaceRole && companyId) {
              const users: GetCompanyUsersQuery | null = cache.readQuery({
                query: GET_COMPANY_USERS,
                variables: { companyId },
              });
              if (users) {
                const newUsers = users.getCompanyUsers.map((u) => {
                  if (u.userWorkplaceRoles?.length) {
                    const newWorkplaceRoles = u.userWorkplaceRoles.filter(
                      (role) => role.id !== userRole.userRoleId
                    );
                    return {
                      ...u,
                      userWorkplaceRoles: newWorkplaceRoles,
                    };
                  }
                  return u;
                });
                const newUsersQuery: GetCompanyUsersQuery = {
                  ...users,
                  getCompanyUsers: newUsers,
                };

                cache.writeQuery({
                  query: GET_COMPANY_USERS,
                  variables: { companyId },
                  data: newUsersQuery,
                });
              }
            }
          },
        });
        onLoading(false);
      } catch (e) {
        onLoading(false);
      }
    }
  };

  return (
    <>
      {userRole.isManager && (
        <RoleDisplayWrapper>
          <Box width="20%">
            <Typography variant="body2" color="secondary">
              {t("All workplaces")}
            </Typography>
          </Box>
          <Box width="30%">
            <Typography variant="body1">{getRoleName(userRole.roleId)}</Typography>
          </Box>
        </RoleDisplayWrapper>
      )}
      {userRole.workplace && (
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <RoleDisplayWrapper>
            <Box width="20%">
              <Typography variant="body2">
                {getWorkplaceName(userRole.workplace)}
              </Typography>
            </Box>
            <Box width="30%">
              {rolesToPick.length && (
                <Controller
                  control={control}
                  name="role"
                  as={
                    <TextField
                      size="small"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      label={t("User role")}
                      fullWidth
                      variant="outlined"
                    >
                      {rolesToPick
                        .filter((r) => r.id !== MANAGER_ROLE_ID)
                        .map((role) => (
                          <option key={role.id} value={role.id}>
                            {role.name}
                          </option>
                        ))}
                    </TextField>
                  }
                />
              )}
            </Box>
            <Box display="flex" alignItems="center">
              <Box width="60px">
                {deletable && (
                  <Tooltip title={t("Delete user role") || ""}>
                    <IconButton size="small" onClick={deleteWorkplaceRole}>
                      <DeleteIcon color="secondary" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <Button
                disabled={!formState.isDirty}
                color="primary"
                variant="contained"
                type="submit"
              >
                {t("Save changes")}
              </Button>
            </Box>
          </RoleDisplayWrapper>
        </form>
      )}

      <ErrorDisplay
        error={deleteWorkplaceRoleError}
        message={t(
          "Error occured while deleting user's role, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={changeUserError}
        message={t(
          "Error occured while changing user's role, please try again or contact us."
        )}
      />
    </>
  );
}
