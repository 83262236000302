import * as Types from "../../../generated/types";

import { gql } from "@apollo/client";
export type TaskStatusFragment = {
  __typename?: "TaskStatus";
  id: any;
  name: string;
  order: number;
  type: Types.TaskStatusType;
};

export const TaskStatusFragmentDoc = gql`
  fragment TaskStatus on TaskStatus {
    id
    name
    order
    type
  }
`;
