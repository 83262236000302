import { gql } from "@apollo/client";

export const workplaceUser = gql`
  fragment WorkplaceUser on User {
    id
    firstName
    lastName
  }
`;

export const taskWithoutChildrenAndParentsFragment = gql`
  fragment TaskWithoutChildrenAndParents on Task {
    id
    friendlyId
    name
    description
    location
    status {
      id
      name
      type
    }
    order
    createdAt
    updatedAt
    reportedDate
    dueDate
    finishedDate
    isArchived
    categories {
      id
      name
    }
    labels {
      id
      name
    }
    createdBy {
      id
      firstName
      lastName
    }
    reporter {
      id
      firstName
      lastName
    }
    assignees {
      id
      email
      firstName
      lastName
    }
  }
`;

export const taskListDataWithoutChildrenFragment = gql`
  fragment TaskListDataWithoutChildren on Task {
    id
    friendlyId
    name
    status {
      id
      name
      type
    }
    location
    dueDate
    finishedDate
    assignees {
      id
      firstName
      lastName
      email
    }
    order
  }
`;

export const taskListDataFragment = gql`
  ${taskListDataWithoutChildrenFragment}
  fragment TaskListData on Task {
    ...TaskListDataWithoutChildren
    children {
      ...TaskListDataWithoutChildren
    }
  }
`;

export const taskDetailsChildFragment = gql`
  fragment TaskDetailsChild on Task {
    id
    friendlyId
    name
    dueDate
    finishedDate
    status {
      id
      name
      type
    }
    assignees {
      id
      firstName
      lastName
    }
  }
`;

export const taskDetailsFragment = gql`
  ${taskDetailsChildFragment}
  ${taskWithoutChildrenAndParentsFragment}
  fragment TaskDetails on Task {
    ...TaskWithoutChildrenAndParents
    children {
      ...TaskDetailsChild
    }
    parent {
      id
      friendlyId
      isArchived
    }
  }
`;

export const commentAuthorFragment = gql`
  fragment CommentAuthor on User {
    id
    email
    firstName
    lastName
  }
`;

export const updateCommentContentFragment = gql`
  fragment UpdateCommentContent on Comment {
    id
    content
    updatedAt
  }
`;

export const attachmentUserFragment = gql`
  fragment AttachmentUser on User {
    id
    email
    firstName
    lastName
  }
`;

export const taskAttachmentFragment = gql`
  ${attachmentUserFragment}
  fragment TaskAttachment on TaskAttachment {
    id
    attachment {
      id
      filename
      mimetype
      uri
      createdAt
      updatedAt
      encoding
    }
    createdBy {
      ...AttachmentUser
    }
    createdAt
    updatedAt
  }
`;

export const archivedTaskListFragment = gql`
  fragment ArchivedTaskList on Task {
    id
    friendlyId
    name
    location
    finishedDate
    assignees {
      id
      firstName
      lastName
      email
    }
    updatedAt
  }
`;
