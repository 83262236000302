import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import CustomDialog from "../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import { requiredStringValidator } from "../../../utils/formValidators";
import { useChangeUserTeamRoleMutation } from "../graphql/mutation.generated";
import useGetWorkplacesRoles from "./useGetWorkplacesRoles";

interface Props {
  workplaceName: string;
  open: boolean;
  onClose: () => void;
  userName: string;
  userWorkplaceRoleId: string;
  currentWorkplaceRoleId: string;
}

interface FormData {
  role: string;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    role: requiredStringValidator(t),
  });

export default function EditTeamUserDialog({
  workplaceName,
  open,
  onClose,
  userWorkplaceRoleId,
  currentWorkplaceRoleId,
  userName,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const {
    data: rolesToPick,
    loading: workplaceRolesLoading,
    error: gettingWorkplaceRolesError,
  } = useGetWorkplacesRoles();

  const [
    changeUserTeamRoleMutation,
    { error: changeUserRoleError, loading: changeUserRoleLoading },
  ] = useChangeUserTeamRoleMutation();

  const { control, handleSubmit, errors, formState } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      role: currentWorkplaceRoleId,
    },
  });

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    try {
      const res = await changeUserTeamRoleMutation({
        variables: {
          changeUserWorkplaceRoleData: {
            id: userWorkplaceRoleId,
            workplaceRoleId: formData.role,
          },
        },
      });

      if (res.data) {
        onClose();
      }
    } catch (e) {
      //
    }
  };

  return (
    <CustomDialog
      loading={workplaceRolesLoading || changeUserRoleLoading}
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <CustomDialogContent heading={`${t("Changing user role")}`}>
        <Box mb={0.5}>
          <Typography variant="body2">{`${t(
            "Changing role of"
          )}: ${userName}`}</Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body2">{`${t("Workplace")}: ${workplaceName}`}</Typography>
        </Box>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Box mb={5}>
            {rolesToPick.length && (
              <Controller
                control={control}
                name="role"
                as={
                  <TextField
                    select
                    SelectProps={{
                      native: true,
                    }}
                    label={t("User role")}
                    fullWidth
                    variant="outlined"
                    error={!!errors.role}
                    helperText={errors.role?.message}
                  >
                    {rolesToPick.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </TextField>
                }
              />
            )}
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              size="large"
              disabled={!formState.isDirty}
            >
              {t("Edit user role")}
            </Button>
          </Box>
        </form>
      </CustomDialogContent>

      <ErrorDisplay
        error={gettingWorkplaceRolesError}
        message={t(
          "Error occured while getting workplaces roles, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={changeUserRoleError}
        message={t(
          "Error occured while changing user role, please try again or contact us."
        )}
      />
    </CustomDialog>
  );
}
