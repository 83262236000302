import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetCompaniesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCompaniesQuery = { __typename?: 'Query', getCompanies: Array<{ __typename?: 'Company', id: any, name: string }> };

export type GetWorkplacesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID'];
}>;


export type GetWorkplacesQuery = { __typename?: 'Query', getWorkplaces: Array<{ __typename?: 'Workplace', id: any, name: string, shortName: string }> };

export type GetUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'User', id: any, firstName?: Types.Maybe<string>, email: any, userSystemRole?: Types.Maybe<{ __typename?: 'UserSystemRole', id: any, systemRole: { __typename?: 'SystemRole', id: any, name: string, permissions: Array<Types.Permissions> } }>, userCompanyRoles?: Types.Maybe<Array<{ __typename?: 'UserCompanyRole', id: any, companyRole: { __typename?: 'CompanyRole', id: any, name: string, permissions: Array<Types.Permissions> } }>>, userWorkplaceRoles?: Types.Maybe<Array<{ __typename?: 'UserWorkplaceRole', id: any, workplaceRole: { __typename?: 'WorkplaceRole', id: any, name: string, permissions: Array<Types.Permissions> }, workplace: { __typename?: 'Workplace', id: any } }>> } };


export const GetCompaniesDocument = gql`
    query getCompanies {
  getCompanies {
    id
    name
  }
}
    `;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
        return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, baseOptions);
      }
export function useGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, baseOptions);
        }
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const GetWorkplacesDocument = gql`
    query getWorkplaces($companyId: UUID!) {
  getWorkplaces(companyId: $companyId) {
    id
    name
    shortName
  }
}
    `;

/**
 * __useGetWorkplacesQuery__
 *
 * To run a query within a React component, call `useGetWorkplacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplacesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetWorkplacesQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkplacesQuery, GetWorkplacesQueryVariables>) {
        return Apollo.useQuery<GetWorkplacesQuery, GetWorkplacesQueryVariables>(GetWorkplacesDocument, baseOptions);
      }
export function useGetWorkplacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkplacesQuery, GetWorkplacesQueryVariables>) {
          return Apollo.useLazyQuery<GetWorkplacesQuery, GetWorkplacesQueryVariables>(GetWorkplacesDocument, baseOptions);
        }
export type GetWorkplacesQueryHookResult = ReturnType<typeof useGetWorkplacesQuery>;
export type GetWorkplacesLazyQueryHookResult = ReturnType<typeof useGetWorkplacesLazyQuery>;
export type GetWorkplacesQueryResult = Apollo.QueryResult<GetWorkplacesQuery, GetWorkplacesQueryVariables>;
export const GetUserDocument = gql`
    query GetUser {
  getUser {
    id
    firstName
    email
    userSystemRole {
      id
      systemRole {
        id
        name
        permissions
      }
    }
    userCompanyRoles {
      id
      companyRole {
        id
        name
        permissions
      }
    }
    userWorkplaceRoles {
      id
      workplaceRole {
        id
        name
        permissions
      }
      workplace {
        id
      }
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;