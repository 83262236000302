import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@material-ui/core";
import { TFunction } from "i18next";
import moment from "moment";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import AssigneesSelect from "../../../components/AssigneesSelect/AssigneesSelect";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import CustomDateTimePicker from "../../../components/CustomDateTimePicker/CustomDateTimePicker";
import CustomDialog from "../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FormTextFieldWrapper from "../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import { WorkplaceContext } from "../../../context/WorkplaceContext/WorkplaceContextProvider";
import { CreatePatrolData } from "../../../generated/types";
import { requiredStringValidator } from "../../../utils/formValidators";
import { useCreatePatrolMutation } from "../graphql/mutation.generated";

interface Props {
  onClose: () => void;
  open: boolean;
  onNewPatrolAdded: () => void; // TODO zastanowic sie nad przeniesieniem refetch queriesa tutaj, albo nad updatem cache tutaj
}
interface FormData {
  location?: string;
  description?: string;
  assigneesIds?: string[];
  startDate: string;
  endDate?: string;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    startDate: requiredStringValidator(t),
  });

export default function AddPatrolDialog({
  onClose,
  open,
  onNewPatrolAdded,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { chosenWorkplaceId, loading: workplaceContextLoading } = useContext(
    WorkplaceContext
  );
  const [confirmCloseOpen, setConfirmCloseOpen] = useState<boolean>(false);

  const [
    createPatrolMutation,
    { loading: createPatrolLoading, error: createPatrolError },
  ] = useCreatePatrolMutation();

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
  } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      startDate: moment().toISOString(),
      assigneesIds: [],
      endDate: "",
    },
  });

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    if (chosenWorkplaceId) {
      const patrolData: CreatePatrolData = {
        startDate: formData.startDate,
        workplaceId: chosenWorkplaceId,
        assigneesIds: formData.assigneesIds || [],
        description: formData.description || undefined,
        endDate: formData.endDate || undefined,
        location: formData.location || undefined,
      };
      try {
        await createPatrolMutation({
          variables: {
            createPatrolData: patrolData,
          },
        });
        onNewPatrolAdded();
        onClose();
      } catch (e) {
        //
      }
    }
  };

  const onTryToClose = (): void => {
    const values = getValues();
    const entredValues = Object.values(values).filter((v) => {
      if (Array.isArray(v)) {
        return Boolean(v.length);
      }
      return Boolean(v);
    });
    if (entredValues.length > 2 || values.description) {
      setConfirmCloseOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      <CustomDialog
        open={open}
        loading={workplaceContextLoading || createPatrolLoading}
        onClose={onTryToClose}
      >
        <CustomDialogContent heading={t("Add new patrol")}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <FormTextFieldWrapper>
              <Controller
                control={control}
                name="startDate"
                render={({ value, onChange }): JSX.Element => (
                  <CustomDateTimePicker
                    label={`${t("Start date")}*`}
                    value={value || null}
                    onChange={onChange}
                    fullWidth
                    inputVariant="outlined"
                    error={Boolean(errors.startDate)}
                    helperText={errors.startDate?.message}
                    openTo="hours"
                    showTodayButton
                  />
                )}
              />
            </FormTextFieldWrapper>
            <FormTextFieldWrapper>
              <Controller
                control={control}
                name="endDate"
                render={({ value, onChange }): JSX.Element => (
                  <CustomDateTimePicker
                    label={t("Finish date")}
                    value={value || null}
                    onChange={onChange}
                    fullWidth
                    inputVariant="outlined"
                    clearable
                    openTo="hours"
                    showTodayButton
                  />
                )}
              />
            </FormTextFieldWrapper>
            <FormTextFieldWrapper>
              <TextField
                name="location"
                label={t("Location")}
                fullWidth
                variant="outlined"
                inputRef={register}
              />
            </FormTextFieldWrapper>
            <FormTextFieldWrapper>
              <TextField
                name="description"
                label={t("Description")}
                fullWidth
                variant="outlined"
                inputRef={register}
                multiline
                rows={3}
              />
            </FormTextFieldWrapper>
            <FormTextFieldWrapper>
              <Controller
                control={control}
                name="assigneesIds"
                render={({ value }): JSX.Element => (
                  <AssigneesSelect
                    size="medium"
                    assigneesIds={value}
                    workplaceId={chosenWorkplaceId}
                    onAssigneesChange={(v): void => setValue("assigneesIds", v)}
                    outlined
                  />
                )}
              />
            </FormTextFieldWrapper>
            <Box>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
                size="large"
              >
                {t("Add patrol")}
              </Button>
            </Box>
          </form>
        </CustomDialogContent>
      </CustomDialog>
      <ConfirmDialog
        open={confirmCloseOpen}
        onConfirm={(): void => {
          setConfirmCloseOpen(false);
          onClose();
        }}
        onDecline={(): void => setConfirmCloseOpen(false)}
        dialogContent="You are about to close patrol creation dialog, all entered data will be lost, are you sure?"
        dialogTitle="Cancel patrol creation?"
      />
      <ErrorDisplay
        error={createPatrolError}
        message={t(
          "Error occured while creating new patrol, please try again or contact us."
        )}
        codes={{
          BAD_USER_INPUT: t("Provided data is incorrect."),
        }}
      />
    </>
  );
}
