import { Box, Typography } from "@material-ui/core";
import React from "react";
import { PropsChild } from "../../../../interfaces/PropsChild";

interface Props extends PropsChild {
  title: string;
}
export default function FilterWrapper({ title, children }: Props): JSX.Element {
  return (
    <>
      <Box mb={0.5}>
        <Typography variant="caption">{title}</Typography>
      </Box>
      {children}
    </>
  );
}
