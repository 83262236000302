import { ApolloProvider } from "@apollo/client";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import "moment-duration-format";
import "moment/locale/pl";
import React from "react";
import { CookiesProvider } from "react-cookie";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { BrowserRouter } from "react-router-dom";
import RouterComponent from "./routing/RouterComponent";
import { useAppApolloClient } from "./utils/apolloClient";
import ContactWidget from "./utils/ContactWidget/ContactWidget";
import theme from "./utils/theme";

function App(): JSX.Element {
  moment.locale("pl");
  const client = useAppApolloClient();
  return (
    <CookiesProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="pl">
            <BrowserRouter>
              <RouterComponent />
              <ContactWidget />
            </BrowserRouter>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ApolloProvider>
    </CookiesProvider>
  );
}

export default App;
