import { Box, CircularProgress } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomSnackbar from "../../../components/CustomSnackbar/CustomSnackbar";
import { CompanyContext } from "../../../context/CompanyContext/CompanyContextProvider";
import { Permissions, Workplace } from "../../../generated/types";
import useIsUserAuthorised from "../../../utils/AuthUtils/useIsUserAuthorised";
import useLazyIsUserAuthorised from "../../../utils/AuthUtils/useLazyIsUserAuthorised";
import { useGetCompanyWorkplacesQuery } from "../graphql/query.generated";
import AddWorkplaceDialog from "./AddWorkplaceDialog";
import EditWorkplaceDialog from "./EditWorkplaceDialog";
import WorkplaceCard from "./WorkplaceCard";

export default function Workplaces(): JSX.Element {
  const { t } = useTranslation();
  const { chosenCompanyId } = useContext(CompanyContext);
  const { isUserAuthorised } = useLazyIsUserAuthorised();
  const canUserAddNewWorkplace = useIsUserAuthorised(Permissions.CreateWorkplace);

  const [openedWorkplaceData, setOpenedWorkplaceData] = useState<Workplace | null>(null);
  const [workplaceDialogOpened, setWorkplaceDialogOpened] = useState<boolean>(false);

  const { data, loading, error } = useGetCompanyWorkplacesQuery({
    variables: {
      companyId: chosenCompanyId,
    },
  });

  const onDialogClose = (): void => {
    setOpenedWorkplaceData(null);
    setWorkplaceDialogOpened(false);
  };

  const editWorkplace = (workplaceData: Workplace): void => {
    setOpenedWorkplaceData(workplaceData);
    setWorkplaceDialogOpened(true);
  };

  const addWorkplace = (): void => {
    setWorkplaceDialogOpened(true);
  };

  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return (
      <CustomSnackbar
        message={t("Error occured while fetching your workplaces.")}
        open={!!error}
      />
    );
  }

  return (
    <>
      <Box display="flex" flexWrap="wrap">
        {data?.getWorkplaces
          .filter((workplace) =>
            isUserAuthorised(Permissions.EditWorkplace, workplace.id)
          )
          .map((workplace) => (
            <Box pb={5} pr={5} key={workplace.id}>
              <WorkplaceCard
                workplaceName={workplace.name}
                workplaceShortName={workplace.shortName}
                onCardClick={(): void => {
                  editWorkplace(workplace);
                }}
              />
            </Box>
          ))}
        {canUserAddNewWorkplace && (
          <Box pb={5} pr={5}>
            <WorkplaceCard isAddCard onCardClick={(): void => addWorkplace()} />
          </Box>
        )}
      </Box>
      {workplaceDialogOpened && chosenCompanyId && (
        <AddWorkplaceDialog onClose={onDialogClose} companyId={chosenCompanyId} />
      )}
      {workplaceDialogOpened && chosenCompanyId && openedWorkplaceData && (
        <EditWorkplaceDialog
          workplaceData={openedWorkplaceData}
          onClose={onDialogClose}
        />
      )}
    </>
  );
}
