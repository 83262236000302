import { makeStyles, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import moment from "moment";
import React from "react";
import theme, { successColor } from "../../utils/theme";

const useStyles = makeStyles({
  success: {
    color: successColor,
    display: "flex",
  },
  icon: {
    paddingRight: theme.spacing(0.5),
    display: "block",
  },
});

export default function FinishDateDisplay({
  finishDate,
}: {
  finishDate: string;
}): JSX.Element {
  const classes = useStyles();

  const convertDate = (dateToConvert: string): string => {
    return moment(dateToConvert).format("DD-MM-YYYY");
  };

  return (
    <div className={classes.success}>
      <CheckCircleOutlineIcon className={classes.icon} fontSize="small" />
      <Typography variant="body2">{convertDate(finishDate)}</Typography>
    </div>
  );
}
