import { gql } from "@apollo/client";
import {
  archivedTaskListFragment,
  commentAuthorFragment,
  taskAttachmentFragment,
  taskDetailsFragment,
  taskListDataFragment,
  workplaceUser,
} from "./fragments";

export const GET_WORKPLACE_USERS_FOR_TASK = gql`
  ${workplaceUser}
  query GetWorkplaceUsersForTask($includeInactive: Boolean, $workplaceId: UUID!) {
    getWorkplaceUsers(includeInactive: $includeInactive, workplaceId: $workplaceId) {
      ...WorkplaceUser
    }
  }
`;

export const GET_WORKPLACE_TERMS = gql`
  query GetWorkplaceTerms($companyId: UUID!) {
    getCategories(companyId: $companyId) {
      id
      name
    }
    getLabels(companyId: $companyId) {
      id
      name
    }
  }
`;

export const GET_WORKPLACE_CATEGORIES = gql`
  query GetWorkplaceCategories($companyId: UUID!) {
    getCategories(companyId: $companyId) {
      id
      name
    }
  }
`;

export const GET_WORKPLACE_LABELS = gql`
  query GetWorkplaceLabels($companyId: UUID!) {
    getLabels(companyId: $companyId) {
      id
      name
    }
  }
`;

export const GET_WORKPLACE_TASK_STATUSES = gql`
  query GetWorkplaceTaskStatuses($workplaceId: UUID!) {
    getWorkplaceTaskStatuses(workplaceId: $workplaceId) {
      id
      name
      order
      type
    }
  }
`;

export const GET_TASKS_LIST_DATA = gql`
  ${taskListDataFragment}
  query GetTasksListData($filters: TaskFilters, $workplaceId: UUID!) {
    getWorkplaceTasks(filters: $filters, workplaceId: $workplaceId) {
      ...TaskListData
    }
  }
`;

export const GET_TASK_DETAILS = gql`
  ${taskDetailsFragment}
  query GetTaskDetails($id: UUID!) {
    getTask(id: $id) {
      ...TaskDetails
    }
  }
`;

// TODO this is unused, delete that shit
export const GET_WORKPLACE_SHORTNAME = gql`
  query GetWorkplaceShortName($id: UUID!) {
    getWorkplace(id: $id) {
      id
      shortName
    }
  }
`;

export const GET_TASK_COMMENTS = gql`
  ${commentAuthorFragment}
  query GetTaskComments($taskId: UUID!) {
    getTaskComments(taskId: $taskId) {
      id
      content
      author {
        ...CommentAuthor
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_TASK_ATTACHMENTS = gql`
  ${taskAttachmentFragment}
  query GetTaskAttachments($taskId: UUID!) {
    getTaskAttachments(taskId: $taskId) {
      ...TaskAttachment
    }
  }
`;

export const GET_WORKPLACE_ARCHIVED_TASKS = gql`
  ${archivedTaskListFragment}
  query GetWorkplaceArchivedTasks(
    $filters: ArchivedTaskFilters
    $paginationData: PaginationData!
    $workplaceId: UUID!
  ) {
    getWorkplaceArchivedTasks(
      filters: $filters
      paginationData: $paginationData
      workplaceId: $workplaceId
    ) {
      items {
        ...ArchivedTaskList
      }
      totalCount
    }
  }
`;
