import { gql } from "@apollo/client";

export const notificationFragment = gql`
  fragment Notification on Notification {
    id
    workplace {
      id
    }
    sourceUser {
      id
      firstName
      lastName
    }
    patrol {
      id
      startDate
      endDate
      location
    }
    task {
      id
      name
      friendlyId
      dueDate
      status {
        id
        name
      }
    }
    read
    sourceType
    type
    updatedAt
    customData {
      commentId
    }
  }
`;
