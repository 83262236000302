import Box from "@material-ui/core/Box";
import React from "react";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SettingsSystemDaydreamIcon from "@material-ui/icons/SettingsSystemDaydream";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { NotificationSourceType } from "../../../generated/types";
import { QueryVariablesEnum } from "../../../interfaces/QueryVariables";
import { WORKPLACE, PATROL, TASKS } from "../../../routing/paths";
import theme, { black100Opacity } from "../../../utils/theme";
import TaskFriendlyId from "../../Tasks/common/TaskFriendlyId";
import useGetWorkplaceDisplayData from "../../../utils/useGetWorkplaceDisplayData";

interface Props {
  sourceType: NotificationSourceType;
  read: boolean;
  onMarkAsRead: () => void;
  patrolStartDate?: string;
  patrolId?: string;
  taskId?: string;
  taskFriendlyId?: number;
  workplaceId: string;
}
export default function NotificationHeader({
  sourceType,
  read,
  onMarkAsRead,
  patrolId,
  patrolStartDate,
  taskFriendlyId,
  taskId,
  workplaceId,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { currentWorkplaceShortName } = useGetWorkplaceDisplayData(workplaceId);

  const notificationIcon = (): JSX.Element => {
    switch (sourceType) {
      case NotificationSourceType.Patrol:
        return <DirectionsCarIcon fontSize="small" />;
      case NotificationSourceType.Task:
        return <ListAltIcon fontSize="small" />;
      case NotificationSourceType.System:
        return <SettingsSystemDaydreamIcon fontSize="small" />;
      default:
        return <NotificationsIcon fontSize="small" />;
    }
  };

  const notificationHeader = (): string => {
    switch (sourceType) {
      case NotificationSourceType.Patrol:
        return t("Patrol");
      case NotificationSourceType.Task:
        return t("Task");
      case NotificationSourceType.System:
        return t("System");
      default:
        return t("Notification");
    }
  };

  const notificationLink = (): string => {
    if (patrolId && sourceType === NotificationSourceType.Patrol) {
      return workplaceId
        ? `${WORKPLACE + PATROL}?${QueryVariablesEnum.WORKPLACEID}=${workplaceId}&${
            QueryVariablesEnum.PATROLID
          }=${patrolId}`
        : "/";
    }
    if (taskId && sourceType === NotificationSourceType.Task) {
      return workplaceId
        ? `${WORKPLACE + TASKS}?${QueryVariablesEnum.WORKPLACEID}=${workplaceId}&${
            QueryVariablesEnum.TASKID
          }=${taskId}`
        : "/";
    }
    return "/";
  };

  return (
    <Box
      px={2}
      py={1}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      style={{
        backgroundColor: read ? theme.palette.primary.light : theme.palette.primary.main,
      }}
    >
      <NavLink style={{ color: black100Opacity }} to={notificationLink}>
        <Box display="flex" alignItems="center">
          <Box mr={1}>{notificationIcon()}</Box>
          <Typography variant="h6">{notificationHeader()}</Typography>
          {patrolStartDate && (
            <Box ml={1}>
              <Typography variant="body2">
                {moment(patrolStartDate).format("DD.MM, HH:mm")}
              </Typography>
            </Box>
          )}
          {taskFriendlyId && (
            <Box ml={1}>
              <TaskFriendlyId
                friendlyId={taskFriendlyId}
                workplaceShortName={currentWorkplaceShortName}
              />
            </Box>
          )}
        </Box>
      </NavLink>

      <Box>
        {read ? (
          <Tooltip title={t("Notification read") || ""}>
            <IconButton size="small">
              <CheckBoxIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={t("Mark as read") || ""}>
            <IconButton size="small" onClick={(): void => onMarkAsRead()}>
              <CheckBoxOutlineBlankIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}
