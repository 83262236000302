import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { WorkplaceContext } from "../../context/WorkplaceContext/WorkplaceContextProvider";
import { Permissions } from "../../generated/types";
import { QueryVariablesEnum } from "../../interfaces/QueryVariables";
import useLazyIsUserAuthorised from "../../utils/AuthUtils/useLazyIsUserAuthorised";
import theme from "../../utils/theme";
import {
  KANBAN,
  PATROLS_LIST,
  TASKS,
  TASKS_ARCHIVE,
  TASKS_LIST,
  TEAM,
  WORKPLACE,
  WORKPLACE_SETTINGS,
} from "../../routing/paths";

interface MenuLink {
  name: string;
  url: string;
  permission?: Permissions;
  subMenu?: { name: string; url: string; permission?: Permissions }[];
}
const useStyles = makeStyles({
  navLink: {
    display: "block",
  },
  navLinkActive: {
    "& > *": {
      color: `${theme.palette.primary.main} !important`,
      opacity: `1 !important`,
    },
  },
  menuItem: {
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 900,
    marginBottom: theme.spacing(2),
    opacity: 0.6,
    "&:hover": {
      opacity: 1,
      color: `${theme.palette.primary.light} !important`,
    },
  },
  menuSubItem: {
    marginBottom: theme.spacing(1),
    color: "#fff",
    opacity: 0.6,
    "&:hover": {
      opacity: 1,
      color: `${theme.palette.primary.light} !important`,
    },
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
});

export default function TopMenu(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { chosenWorkplaceId } = useContext(WorkplaceContext);
  const { isUserAuthorised } = useLazyIsUserAuthorised();

  const links: MenuLink[] = [
    {
      name: t("Tasks"),
      url: chosenWorkplaceId
        ? `${WORKPLACE + TASKS}?${QueryVariablesEnum.WORKPLACEID}=${chosenWorkplaceId}`
        : "",
      subMenu: [
        {
          name: t("Kanban"),
          url: chosenWorkplaceId
            ? `${WORKPLACE + TASKS + KANBAN}?${
                QueryVariablesEnum.WORKPLACEID
              }=${chosenWorkplaceId}`
            : "",
        },
        {
          name: t("Task list"),
          url: chosenWorkplaceId
            ? `${WORKPLACE + TASKS + TASKS_LIST}?${
                QueryVariablesEnum.WORKPLACEID
              }=${chosenWorkplaceId}`
            : "",
        },
        {
          name: t("Archive"),
          url: chosenWorkplaceId
            ? `${WORKPLACE + TASKS_ARCHIVE}?${
                QueryVariablesEnum.WORKPLACEID
              }=${chosenWorkplaceId}`
            : "",
        },
      ],
    },
    {
      name: t("Patrols"),
      url: chosenWorkplaceId
        ? `${WORKPLACE + PATROLS_LIST}?${
            QueryVariablesEnum.WORKPLACEID
          }=${chosenWorkplaceId}`
        : "",
    },
    {
      name: t("Team"),
      url: chosenWorkplaceId
        ? `${WORKPLACE + TEAM}?${QueryVariablesEnum.WORKPLACEID}=${chosenWorkplaceId}`
        : "",
      permission: Permissions.ViewWorkplaceUsers,
    },
    {
      name: t("Workplace settings"),
      url: chosenWorkplaceId
        ? `${WORKPLACE + WORKPLACE_SETTINGS}?${
            QueryVariablesEnum.WORKPLACEID
          }=${chosenWorkplaceId}`
        : "",
      permission: Permissions.EditWorkplace,
    },
  ];

  return (
    <div>
      {links.map((topLink, topLinkIndex) => (
        <div key={`topMenuItem${topLinkIndex}`}>
          {isUserAuthorised(topLink.permission || Permissions.ViewWorkplace) && (
            <Box mb={2}>
              <Box mb={2}>
                <NavLink
                  to={topLink.url}
                  className={classes.navLink}
                  activeClassName={classes.navLinkActive}
                >
                  <Typography variant="body2" className={classes.menuItem}>
                    {topLink.name}
                  </Typography>
                </NavLink>
                {topLink.subMenu?.length && (
                  <Box pl={1}>
                    {topLink.subMenu.map((subLink, subMenuIndex) => {
                      if (
                        isUserAuthorised(subLink.permission || Permissions.ViewWorkplace)
                      ) {
                        return (
                          <NavLink
                            key={`topMenuSubLink${subMenuIndex}`}
                            to={subLink.url}
                            className={classes.navLink}
                            activeClassName={classes.navLinkActive}
                          >
                            <Typography variant="caption" className={classes.menuSubItem}>
                              {subLink.name}
                            </Typography>
                          </NavLink>
                        );
                      }
                      return <></>;
                    })}
                  </Box>
                )}
              </Box>

              <Divider className={classes.divider} />
            </Box>
          )}
        </div>
      ))}
    </div>
  );
}
