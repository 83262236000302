import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { PropsChild } from "../../interfaces/PropsChild";

interface Props extends PropsChild {
  mr?: number;
}

const useStyles = makeStyles({
  wrapper: {
    boxSizing: "border-box",
    maxHeight: "100%",
    height: "100%",
  },
});

export default function FullHeightNoScrollPage({ mr, children }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Box pl={3} pt={2} mr={mr || 0} className={classes.wrapper}>
      {children}
    </Box>
  );
}
