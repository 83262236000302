import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { black15Opacity } from "../../../utils/theme";
import displayNotificationTittle from "./displayNotificationTittle";
import { NotificationFragment } from "../graphql/fragments.generated";
import NotificationHeader from "./NotificationHeader";

interface Props {
  notification: NotificationFragment;
  onMarkAsRead: () => void;
}
export default function SingleNotification({
  notification: { type, read, sourceType, patrol, task, workplace },
  onMarkAsRead,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const backgroundColor = read ? black15Opacity : "#fff";
  return (
    <Paper variant="outlined">
      <Box pb={1.5} style={{ backgroundColor }}>
        <Box mb={1}>
          <NotificationHeader
            onMarkAsRead={onMarkAsRead}
            sourceType={sourceType}
            read={read}
            patrolStartDate={patrol?.startDate}
            patrolId={patrol?.id}
            taskId={task?.id}
            taskFriendlyId={task?.friendlyId}
            workplaceId={workplace && workplace.id}
          />
        </Box>
        <Box px={2}>
          <Typography variant="body2">{displayNotificationTittle(type, t)}</Typography>
        </Box>
      </Box>
    </Paper>
  );
}
