import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@material-ui/core";
import { TFunction } from "i18next";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import CustomDialog from "../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FormTextFieldWrapper from "../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import { Workplace } from "../../../generated/types";
import { useUpdateWorkplaceMutation } from "../graphql/mutation.generated";
import {
  requiredStringMaxLengthValidator,
  requiredStringValidator,
} from "../../../utils/formValidators";

interface Props {
  workplaceData: Workplace;
  onClose: () => void;
}

interface FormData {
  name: string;
  shortName: string;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    name: requiredStringValidator(t),
    shortName: requiredStringMaxLengthValidator(4, t),
  });

export default function EditWorkplaceDialog({
  workplaceData,
  onClose,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [updateWorkplaceMutation, { loading, error }] = useUpdateWorkplaceMutation();

  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      name: workplaceData.name,
      shortName: workplaceData.shortName,
    },
  });

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    try {
      const res = await updateWorkplaceMutation({
        variables: {
          updateWorkplaceData: {
            id: workplaceData.id,
            name: formData.name,
            shortName: formData.shortName.toUpperCase(),
          },
        },
      });

      if (res.data) {
        setValue("shortName", formData.shortName.toUpperCase());
        onClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <CustomDialog open onClose={onClose} maxWidth="md" loading={loading}>
      <CustomDialogContent heading={t("Edit workplace")}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormTextFieldWrapper>
            <TextField
              name="name"
              label={t("Workplace name")}
              fullWidth
              variant="outlined"
              inputRef={register}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </FormTextFieldWrapper>
          <Box mb={5}>
            <TextField
              name="shortName"
              label={t("Workplace shortname")}
              fullWidth
              variant="outlined"
              inputRef={register}
              error={!!errors.shortName}
              helperText={errors.shortName?.message}
            />
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              size="large"
            >
              {t("Submit changes")}
            </Button>
          </Box>
        </form>
      </CustomDialogContent>
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while editing your workplace, please try again or contact us."
        )}
      />
    </CustomDialog>
  );
}
