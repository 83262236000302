import { Box, Tab, Tabs } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import BasicMenuLayout from "../../layouts/BasicMenuLayout/BasicMenuLayout";
import StandardContentPaddingsLayout from "../../layouts/StandardContentPaddingsLayout/StandardContentPaddingsLayout";
import TaskStatuses from "./TaskStatuses/TaskStatuses";

export default function WorkplaceSettings(): JSX.Element {
  const { t } = useTranslation();
  return (
    <BasicMenuLayout>
      <StandardContentPaddingsLayout>
        <Box mb={4}>
          <Tabs value="taskStatuses" indicatorColor="primary" textColor="secondary">
            <Tab value="taskStatuses" label={t("Task statuses")} />
          </Tabs>
        </Box>
        <TaskStatuses />
      </StandardContentPaddingsLayout>
    </BasicMenuLayout>
  );
}
