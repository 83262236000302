import moment from "moment";
import {
  black100Opacity,
  dangerColor,
  dangerColorBackground,
  successBackground,
  successColor,
  warningColor,
  warningColorBackground,
} from "./theme";

interface ReturnObject {
  getBackgroundColor: (checkedDate?: any, finishedDate?: any) => string;
  getFontColor: (checkedDate?: any, finishedDare?: any) => string;
}

export default function useGetStatusStyles(): ReturnObject {
  const warningHours = 72;
  const dangerHours = 24;

  const getBackgroundColor = (checkedDate?: any, finishedDate?: any): string => {
    if (finishedDate) {
      return successBackground;
    }

    if (checkedDate) {
      const diff = moment(checkedDate).diff(moment(), "hours");
      if (diff < dangerHours) {
        return dangerColorBackground;
      }

      if (diff < warningHours) {
        return warningColorBackground;
      }
    }

    return "#fff";
  };

  const getFontColor = (checkedDate?: any, finishedDate?: any): string => {
    if (finishedDate) {
      return successColor;
    }

    if (checkedDate) {
      const diff = moment(checkedDate).diff(moment(), "hours");
      if (diff < dangerHours) {
        return dangerColor;
      }

      if (diff < warningHours) {
        return warningColor;
      }
    }

    return black100Opacity;
  };

  return {
    getFontColor,
    getBackgroundColor,
  };
}
