import { Box, Button, Paper, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { DangerButton } from "../../../components/Buttons/DangerButton/DangerButton";
import CustomSnackbar from "../../../components/CustomSnackbar/CustomSnackbar";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FullScreenLoader from "../../../components/FullScreenLoader/FullScreenLoader";
import { WorkplaceContext } from "../../../context/WorkplaceContext/WorkplaceContextProvider";
import { EditTaskStatusData } from "../../../generated/types";
import { useUpdateTaskStatusesOrderMutation } from "../graphql/mutation.generated";
import { GET_WORKPLACE_TASK_STATUSES_FOR_SETTINGS } from "../graphql/query";
import {
  GetWorkplaceTaskStatusesForSettingsQuery,
  useGetWorkplaceTaskStatusesForSettingsLazyQuery,
} from "../graphql/query.generated";
import DeleteTaskStatusDialog from "./DeleteTaskStatusDialog";
import statusTypeValueDisplay from "./statusTypeValueDisplay";
import TaskStatusDialog from "./TaskStatusDialog";

export default function TaskStatuses(): JSX.Element {
  const { t } = useTranslation();
  const { chosenWorkplaceId, loading: workplaceContextLoading } = useContext(
    WorkplaceContext
  );

  const [addNewStatusOpened, setAddNewStatusOpened] = useState<boolean>(false);
  const [editedStatusData, setEditedStatusData] = useState<EditTaskStatusData | null>(
    null
  );
  const [afterStatusActionMsg, setAfterStatusActionMsg] = useState<string>("");
  const [deleteStatusId, setDeleteStatusId] = useState<string>("");
  const [statusDeletedSucessfully, setStatusDeletedSucessfully] = useState<boolean>(
    false
  );

  const [
    getTaskStatusesQuery,
    {
      data: taskStatusesData,
      loading: taskStatusesLoading,
      error: taskStatusesFetchingError,
    },
  ] = useGetWorkplaceTaskStatusesForSettingsLazyQuery();

  const [
    updateTaskStatusesOrderMutation,
    { loading: updatingStatusesOrderLoading, error: updatingStatusesOrderError },
  ] = useUpdateTaskStatusesOrderMutation();

  useEffect(() => {
    if (chosenWorkplaceId) {
      getTaskStatusesQuery({ variables: { workplaceId: chosenWorkplaceId } });
    }
  }, [chosenWorkplaceId, getTaskStatusesQuery]);

  const onDragEnd = async (result: DropResult): Promise<void> => {
    if (
      taskStatusesData?.getWorkplaceTaskStatuses.length &&
      result.destination &&
      result.source.index !== result.destination.index
    ) {
      const oldArray = taskStatusesData.getWorkplaceTaskStatuses
        .map((ts) => ({
          id: ts.id,
          order: ts.order,
        }))
        .sort((a, b) => a.order - b.order);
      const changedStatusId = result.draggableId;
      const newIndex = result.destination.index;
      let newOrdersArray = oldArray.filter((ts) => ts.id !== changedStatusId);
      newOrdersArray.splice(newIndex, 0, {
        id: changedStatusId,
        order: newIndex,
      });
      newOrdersArray = newOrdersArray.map((statusOrder, index) => ({
        ...statusOrder,
        order: index,
      }));

      try {
        await updateTaskStatusesOrderMutation({
          variables: { updateTaskStatusesOrderData: newOrdersArray },
          optimisticResponse: { updateTaskStatusesOrder: true },
          update: (cache, mutationResult) => {
            if (mutationResult.data?.updateTaskStatusesOrder) {
              const cachedStatuses = cache.readQuery<GetWorkplaceTaskStatusesForSettingsQuery>(
                {
                  query: GET_WORKPLACE_TASK_STATUSES_FOR_SETTINGS,
                  variables: { workplaceId: chosenWorkplaceId },
                }
              );

              if (cachedStatuses) {
                const newQuery: GetWorkplaceTaskStatusesForSettingsQuery = {
                  ...cachedStatuses,
                  getWorkplaceTaskStatuses: [...cachedStatuses.getWorkplaceTaskStatuses],
                };

                newQuery.getWorkplaceTaskStatuses = newQuery.getWorkplaceTaskStatuses
                  .map((status) => {
                    const newOrder =
                      newOrdersArray.find((order) => order.id === status.id)?.order || 0;
                    return {
                      ...status,
                      order: newOrder,
                    };
                  })
                  .sort((a, b) => a.order - b.order);

                cache.writeQuery({
                  query: GET_WORKPLACE_TASK_STATUSES_FOR_SETTINGS,
                  variables: { workplaceId: chosenWorkplaceId },
                  data: newQuery,
                });
              }
            }
          },
        });
      } catch (error) {
        //
      }
    }
  };

  return (
    <>
      {(taskStatusesLoading ||
        workplaceContextLoading ||
        updatingStatusesOrderLoading) && <FullScreenLoader open />}
      <Box mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={(): void => setAddNewStatusOpened(true)}
        >
          {t("Add new task status")}
        </Button>
      </Box>
      <Box>
        {taskStatusesData?.getWorkplaceTaskStatuses.length ? (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="taskStatusesOrderDroppable">
                {(providedDroppable): JSX.Element => (
                  <div
                    ref={providedDroppable.innerRef}
                    {...providedDroppable.droppableProps}
                  >
                    {taskStatusesData.getWorkplaceTaskStatuses.map((status) => {
                      return (
                        <Draggable
                          key={status.id}
                          draggableId={status.id}
                          index={status.order}
                        >
                          {(providedDraggable): JSX.Element => (
                            <div
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                            >
                              <Box mb={0.5}>
                                <Paper variant="outlined">
                                  <Box display="flex" alignItems="center" px={3} py={2}>
                                    <Box width="25%">
                                      <Typography>{status.name}</Typography>
                                    </Box>
                                    <Box width="25%">
                                      <Typography variant="body2" color="textSecondary">
                                        {statusTypeValueDisplay(status.type, t)}
                                      </Typography>
                                    </Box>
                                    <Box width="10%">
                                      <Typography variant="body2">
                                        {t("Order")}: {status.order}
                                      </Typography>
                                    </Box>
                                    <Box
                                      width="40%"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="flex-end"
                                    >
                                      <Box mr={2}>
                                        <DangerButton
                                          onClick={(): void =>
                                            setDeleteStatusId(status.id)
                                          }
                                        >
                                          {t("Delete")}
                                        </DangerButton>
                                      </Box>
                                      <Button
                                        onClick={(): void =>
                                          setEditedStatusData({
                                            id: status.id,
                                            name: status.name,
                                            type: status.type,
                                          })
                                        }
                                        variant="outlined"
                                        color="secondary"
                                      >
                                        {t("Edit")}
                                      </Button>
                                    </Box>
                                  </Box>
                                </Paper>
                              </Box>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {providedDroppable.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {deleteStatusId && (
              <DeleteTaskStatusDialog
                open
                onClose={(success): void => {
                  setDeleteStatusId("");
                  setStatusDeletedSucessfully(Boolean(success));
                  window.location.reload();
                }}
                taskStatusId={deleteStatusId}
                allTaskStatuses={taskStatusesData.getWorkplaceTaskStatuses}
              />
            )}
          </>
        ) : (
          <Typography>{t("No task statuses to display")}</Typography>
        )}
      </Box>
      {addNewStatusOpened && (
        <TaskStatusDialog
          open
          workplaceId={chosenWorkplaceId}
          onClose={(successMsg): void => {
            setAddNewStatusOpened(false);
            if (successMsg) {
              setAfterStatusActionMsg(successMsg);
            }
          }}
        />
      )}
      {editedStatusData && (
        <TaskStatusDialog
          open
          editTaskStatusData={editedStatusData}
          workplaceId={chosenWorkplaceId}
          onClose={(successMsg): void => {
            setEditedStatusData(null);
            if (successMsg) {
              setAfterStatusActionMsg(successMsg);
            }
          }}
        />
      )}

      <ErrorDisplay
        error={taskStatusesFetchingError}
        message={t(
          "Error occured while getting tasks statuses, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={updatingStatusesOrderError}
        message={t(
          "Error occured while updating statuses order, please try again or contact us."
        )}
      />

      <CustomSnackbar
        open={Boolean(afterStatusActionMsg)}
        message={afterStatusActionMsg}
        handleClose={(): void => setAfterStatusActionMsg("")}
        severity="success"
      />
      <CustomSnackbar
        open={statusDeletedSucessfully}
        message={t("Status deleted!")}
        handleClose={(): void => setStatusDeletedSucessfully(false)}
        severity="success"
      />
    </>
  );
}
