import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Pagination } from "@material-ui/lab";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FullScreenLoader from "../../../components/FullScreenLoader/FullScreenLoader";
import UserIcon from "../../../components/UserIcon/UserIcon";
import { WorkplaceContext } from "../../../context/WorkplaceContext/WorkplaceContextProvider";
import { Permissions } from "../../../generated/types";
import { QueryVariablesEnum } from "../../../interfaces/QueryVariables";
import BasicMenuLayout from "../../../layouts/BasicMenuLayout/BasicMenuLayout";
import FullHeightNoScrollPage from "../../../layouts/FullHeightNoScrollPage/FullHeightNoScrollPage";
import { PATROL, WORKPLACE } from "../../../routing/paths";
import IsUserAuthorised from "../../../utils/AuthUtils/IsUserAuthorised";
import { SHORT_DATE_FORMAT_WITH_HOUR } from "../../../utils/consts";
import generateUserNameString from "../../../utils/generateUserNameString";
import theme from "../../../utils/theme";
import useManageUrlQueryVariables from "../../../utils/useManageUrlQueryVariables";
import AddPatrolDialog from "../AddPatrolDialog/AddPatrolDialog";
import { useGetPatrolsLazyQuery } from "../graphql/query.generated";
import PatrolFilters from "./PatrolFilters";

const PATROLS_PAGE_LENGTH = 10;

const useStyles = makeStyles({
  tableHead: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.secondary.main,
  },
  content: {
    maxHeight: `calc(100% - ${theme.spacing(14)}px)`,
    height: `calc(100% - ${theme.spacing(14)}px)`,
  },
  patrolItem: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.secondary.light,
  },
  patrolDate: {
    width: "20%",
  },
  patrolAssignees: { width: "20%" },
  patrolLocation: {
    width: "25%",
  },
  patrolDetails: {
    width: "15%",
  },
});

export default function PatrolsListPage(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { chosenWorkplaceId, loading: workplaceContextLoading } = useContext(
    WorkplaceContext
  );
  const {
    setUrlQueryVariables,
    pageNumber: urlPageNumber,
    pageSize: urlPageSize,
    patrolFilters,
  } = useManageUrlQueryVariables();

  const [addPatrolDialogOpen, setAddPatrolDialogOpen] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(PATROLS_PAGE_LENGTH);
  const [pageNumber, setPageNumber] = useState<number>(1);

  useEffect(() => {
    if (urlPageNumber) {
      setPageNumber(Number(urlPageNumber));
    }
    if (urlPageSize) {
      setPageSize(Number(urlPageSize));
    }
  }, [urlPageNumber, urlPageSize]);

  const [
    getPatrolsQuery,
    { data: patrolsData, loading: patrolsLoading, error: patrolsLoadingError },
  ] = useGetPatrolsLazyQuery({ fetchPolicy: "network-only" });

  const getPatrolsQueryWithVariables = useCallback(() => {
    const filters = patrolFilters ? JSON.parse(patrolFilters) : null;
    getPatrolsQuery({
      variables: {
        paginationData: { limit: pageSize, offset: pageSize * (pageNumber - 1) },
        workplaceId: chosenWorkplaceId,
        filters,
      },
    });
  }, [chosenWorkplaceId, getPatrolsQuery, pageNumber, pageSize, patrolFilters]);

  useEffect(() => {
    if (chosenWorkplaceId) {
      getPatrolsQueryWithVariables();
    }
  }, [
    patrolFilters,
    chosenWorkplaceId,
    pageNumber,
    getPatrolsQuery,
    pageSize,
    getPatrolsQueryWithVariables,
  ]);

  const pagesCount = useMemo<number>(() => {
    if (patrolsData?.getPatrols.totalCount) {
      return Math.ceil(patrolsData.getPatrols.totalCount / pageSize);
    }
    return 0;
  }, [pageSize, patrolsData]);

  const onPageSizeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUrlQueryVariables(QueryVariablesEnum.PAGESIZE, e.target.value);
  };

  const onPageNumberChange = (event, page: number): void => {
    setUrlQueryVariables(QueryVariablesEnum.PAGENUMBER, String(page));
  };

  // TODO rethink that -> this approach generates more backend queries
  const onNewPatrolAdded = (): void => {
    getPatrolsQueryWithVariables();
  };

  return (
    <BasicMenuLayout>
      <FullScreenLoader open={patrolsLoading || workplaceContextLoading} />
      <FullHeightNoScrollPage mr={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <PatrolFilters />
          <IsUserAuthorised
            checkedWorkplaceId={chosenWorkplaceId}
            permission={Permissions.CreatePatrol}
          >
            <Box>
              <Button
                color="primary"
                variant="contained"
                onClick={(): void => setAddPatrolDialogOpen(true)}
              >
                <AddIcon fontSize="small" />
                &nbsp;
                {t("Add new patrol")}
              </Button>
            </Box>
          </IsUserAuthorised>
        </Box>
        <Box display="flex" px={3} pb={1.5} className={classes.tableHead}>
          <Box className={classes.patrolDate}>
            <Typography variant="caption">{t("Start date")}</Typography>
          </Box>
          <Box className={classes.patrolDate}>
            <Typography variant="caption">{t("Finish date")}</Typography>
          </Box>
          <Box className={classes.patrolAssignees}>
            <Typography variant="caption">{t("Patrol assignees")}</Typography>
          </Box>
          <Box className={classes.patrolLocation}>
            <Typography variant="caption">{t("Patrol location")}</Typography>
          </Box>
        </Box>
        {patrolsData?.getPatrols?.items && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            className={classes.content}
          >
            <Box>
              {patrolsData.getPatrols.items.map((patrol) => (
                <Box
                  key={`patrolonlist${patrol.id}`}
                  display="flex"
                  px={3}
                  pt={1.8}
                  pb={1}
                  className={classes.patrolItem}
                  alignItems="center"
                >
                  <Box className={classes.patrolDate}>
                    <Typography variant="body2">
                      {moment(patrol.startDate).format(SHORT_DATE_FORMAT_WITH_HOUR)}
                    </Typography>
                  </Box>
                  <Box className={classes.patrolDate}>
                    {patrol.endDate ? (
                      <Typography variant="body2">
                        {moment(patrol.endDate).format(SHORT_DATE_FORMAT_WITH_HOUR)}
                      </Typography>
                    ) : (
                      <Typography variant="body2">{t("No finish date")}</Typography>
                    )}
                  </Box>
                  <Box className={classes.patrolAssignees} display="flex">
                    {patrol.assignees?.map((assignee) => (
                      <Box key={`patrol-assigne${assignee.id}`} mr={0.5}>
                        <UserIcon name={generateUserNameString(assignee)} />
                      </Box>
                    ))}
                  </Box>
                  <Box className={classes.patrolLocation}>
                    <Typography variant="body2">{patrol.location}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    className={classes.patrolDetails}
                  >
                    <NavLink
                      to={
                        chosenWorkplaceId
                          ? `${WORKPLACE + PATROL}?${
                              QueryVariablesEnum.WORKPLACEID
                            }=${chosenWorkplaceId}&${QueryVariablesEnum.PATROLID}=${
                              patrol.id
                            }`
                          : ""
                      }
                    >
                      <Button variant="contained" size="small" color="primary">
                        {t("Details")}
                      </Button>
                    </NavLink>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box display="flex" py={2} justifyContent="space-between">
              <Box width="80px">
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  label={t("Page size")}
                  value={pageSize}
                  size="small"
                  onChange={onPageSizeChange}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </TextField>
              </Box>
              <Pagination
                count={pagesCount}
                color="primary"
                page={pageNumber}
                onChange={onPageNumberChange}
              />
            </Box>
          </Box>
        )}
      </FullHeightNoScrollPage>
      <ErrorDisplay
        error={patrolsLoadingError}
        message={t(
          "Error occured while getting patrols, please try again or contact us."
        )}
      />
      <AddPatrolDialog
        open={addPatrolDialogOpen}
        onClose={(): void => setAddPatrolDialogOpen(false)}
        onNewPatrolAdded={onNewPatrolAdded}
      />
    </BasicMenuLayout>
  );
}
