import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@material-ui/core";
import { TFunction } from "i18next";
import moment from "moment";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";
import CustomDateTimePicker from "../../../../components/CustomDateTimePicker/CustomDateTimePicker";
import CustomDialog from "../../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import FormTextFieldWrapper from "../../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import { PatrolEvent } from "../../../../generated/types";
import { requiredStringValidator } from "../../../../utils/formValidators";
import { useCreatePatrolEventMutation } from "../../graphql/mutation.generated";
import { GET_PATROL } from "../../graphql/query";
import { GetPatrolQuery } from "../../graphql/query.generated";

interface Props {
  onClose: () => void;
  open: boolean;
  patrolId: string;
  patrolEvent?: PatrolEvent;
}
interface FormData {
  eventDate: string;
  name?: string;
  description?: string;
  location?: string;
  action?: string;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    eventDate: requiredStringValidator(t),
  });

export default function AddPatrolEventDialog({
  onClose,
  open,
  patrolId,
  patrolEvent,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [confirmCloseOpen, setConfirmCloseOpen] = useState<boolean>(false);

  const [
    createPatrolEventMutation,
    { loading: createEventLoading, error: createEventError },
  ] = useCreatePatrolEventMutation();

  const { register, handleSubmit, errors, control, getValues } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      eventDate: patrolEvent?.eventDate || "",
      name: patrolEvent?.name || "",
      action: patrolEvent?.action || "",
      description: patrolEvent?.description || "",
      location: patrolEvent?.location || "",
    },
  });

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    try {
      const res = await createPatrolEventMutation({
        variables: {
          createPatrolEventData: {
            patrolId,
            ...formData,
          },
        },
        update: (cache, response) => {
          if (response.data?.createPatrolEvent) {
            const cachedPatrol = cache.readQuery<GetPatrolQuery>({
              query: GET_PATROL,
              variables: { id: patrolId },
            });
            if (cachedPatrol) {
              const createdPatrolEvent = response.data.createPatrolEvent;

              let newPatrolEvents = cachedPatrol.getPatrolEvents?.length
                ? [...cachedPatrol.getPatrolEvents, createdPatrolEvent]
                : [createdPatrolEvent];

              if (newPatrolEvents.length > 1) {
                newPatrolEvents = newPatrolEvents.sort((a, b) => {
                  if (moment(a.eventDate).isBefore(moment(b.eventDate))) {
                    return -1;
                  }
                  return 1;
                });
              }

              const newPatrolQuery: GetPatrolQuery = {
                ...cachedPatrol,
                getPatrolEvents: newPatrolEvents,
              };
              cache.writeQuery<GetPatrolQuery>({
                query: GET_PATROL,
                variables: { id: patrolId },
                data: newPatrolQuery,
              });
            }
          }
        },
      });
      if (res.data) {
        onClose();
      }
    } catch (e) {
      //
    }
  };

  const onTryToClose = (): void => {
    const values = getValues();
    const entredValues = Object.values(values).filter((v) => {
      if (Array.isArray(v)) {
        return Boolean(v.length);
      }
      return Boolean(v);
    });
    if (entredValues.length > 2 || values.description) {
      setConfirmCloseOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      <CustomDialog open={open} loading={createEventLoading} onClose={onTryToClose}>
        <CustomDialogContent heading={t("Add new patrol event")}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <FormTextFieldWrapper>
              <Controller
                control={control}
                name="eventDate"
                render={({ value, onChange }): JSX.Element => (
                  <CustomDateTimePicker
                    label={`${t("Event date")}*`}
                    ampm={false}
                    format="DD MMM, YYYY, HH:mm"
                    value={value || null}
                    onChange={onChange}
                    fullWidth
                    inputVariant="outlined"
                    error={Boolean(errors.eventDate)}
                    helperText={errors.eventDate?.message}
                    openTo="hours"
                    showTodayButton
                    autoFocus
                  />
                )}
              />
            </FormTextFieldWrapper>
            <FormTextFieldWrapper>
              <TextField
                name="name"
                label={t("Event name")}
                fullWidth
                variant="outlined"
                inputRef={register}
              />
            </FormTextFieldWrapper>
            <FormTextFieldWrapper>
              <TextField
                name="location"
                label={t("Location")}
                fullWidth
                variant="outlined"
                inputRef={register}
              />
            </FormTextFieldWrapper>
            <FormTextFieldWrapper>
              <TextField
                name="description"
                label={t("Description")}
                fullWidth
                variant="outlined"
                inputRef={register}
                multiline
                rows={3}
              />
            </FormTextFieldWrapper>
            <FormTextFieldWrapper>
              <TextField
                name="action"
                label={t("Action taken")}
                fullWidth
                variant="outlined"
                inputRef={register}
                multiline
                rows={3}
              />
            </FormTextFieldWrapper>
            <Box>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
                size="large"
              >
                {t("Add event")}
              </Button>
            </Box>
          </form>
        </CustomDialogContent>
      </CustomDialog>
      <ConfirmDialog
        open={confirmCloseOpen}
        onConfirm={(): void => {
          setConfirmCloseOpen(false);
          onClose();
        }}
        onDecline={(): void => setConfirmCloseOpen(false)}
        dialogContent="You are about to close task creation dialog, all entered data will be lost, are you sure?"
        dialogTitle="Cancel task creation?"
      />
      <ErrorDisplay
        error={createEventError}
        message={t(
          "Error occured while creating new patrol event, please try again or contact us."
        )}
      />
    </>
  );
}
