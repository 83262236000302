import { Box, CircularProgress } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import AssigneesSelect from "../../../../components/AssigneesSelect/AssigneesSelect";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { useChangePatrolAssigneesMutation } from "../../graphql/mutation.generated";

interface Props {
  disabled?: boolean;
  assigneesIds: string[];
  workplaceId: string;
  patrolId: string;
}
export default function PatrolAssignees({
  assigneesIds,
  workplaceId,
  disabled,
  patrolId,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [
    changeAssigneesMutation,
    { loading, error },
  ] = useChangePatrolAssigneesMutation();

  const changePatrolAssignees = async (aIds: string[]): Promise<void> => {
    try {
      await changeAssigneesMutation({
        variables: { changePatrolAssigneesData: { assigneesIds: aIds, id: patrolId } },
      });
    } catch (e) {
      //
    }
  };

  return (
    <>
      <Box position="relative">
        <AssigneesSelect
          disabled={disabled}
          assigneesIds={assigneesIds}
          workplaceId={workplaceId}
          onAssigneesChange={(a): Promise<void> => changePatrolAssignees(a)}
        />
        <Box position="absolute" top="50%" left="50%">
          {loading && <CircularProgress size={20} />}
        </Box>
      </Box>
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing patrol assignees, please try again or contact us."
        )}
      />
    </>
  );
}
