import { makeStyles, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";
import theme from "../../../../../utils/theme";
import { useUpdatePatrolEventDescriptionMutation } from "../../../graphql/mutation.generated";

interface Props {
  patrolEventId: string;
  eventDescription?: string;
  disabled?: boolean;
}

const useStyles = makeStyles({
  textField: {
    // "& .MuiInputBase-root": {
    //   backgroundColor: "rgba(249, 225, 159, 0.4)",
    // },
    // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //   borderColor: blueColor,
    // },
    "& .MuiInputLabel-root.Mui-focused": {
      fontSize: "1rem",
      color: theme.palette.secondary.main,
    },
  },
});

export default function PatrolEventDescription({
  eventDescription,
  patrolEventId,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  const [
    updateDescriptionMutation,
    { loading, error },
  ] = useUpdatePatrolEventDescriptionMutation();

  const [descriptionState, setDescriptionState] = useState<string | undefined>(
    eventDescription
  );

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDescriptionState(event.target.value);
  };

  const onDescriptionBlur = async (): Promise<void> => {
    if (descriptionState !== eventDescription) {
      try {
        await updateDescriptionMutation({
          variables: {
            updatePatrolEventDescriptionData: {
              description: descriptionState,
              id: patrolEventId,
            },
          },
        });
      } catch (e) {
        //
      }
    }
  };

  return (
    <>
      <TextField
        fullWidth
        multiline
        rows={2}
        rowsMax={4}
        variant="outlined"
        value={descriptionState}
        onChange={onDescriptionChange}
        onBlur={onDescriptionBlur}
        placeholder={t("Describe what happened")}
        label={t("Event description")}
        disabled={disabled || loading}
        className={classes.textField}
      />
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing description, please try again or contact us."
        )}
      />
    </>
  );
}
