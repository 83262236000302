import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, MenuItem, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { TFunction } from "i18next";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import CustomDateTimePicker from "../../../components/CustomDateTimePicker/CustomDateTimePicker";
import CustomDialog from "../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FormTextFieldWrapper from "../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import MultiSelectWithSearch from "../../../components/MultiSelectWithSearch/MultiSelectWithSearch";
import UserNameDisplay from "../../../components/UserNameDisplay/UserNameDisplay";
import { CompanyContext } from "../../../context/CompanyContext/CompanyContextProvider";
import { WorkplaceContext } from "../../../context/WorkplaceContext/WorkplaceContextProvider";
import { CreateTaskData } from "../../../generated/types";
import { requiredStringValidator } from "../../../utils/formValidators";
import { TaskWithoutChildrenAndParentsFragment } from "../graphql/fragments.generated";
import { useCreateTaskMutation } from "../graphql/mutation.generated";
import { GET_TASKS_LIST_DATA } from "../graphql/query";
import {
  useGetWorkplaceTaskStatusesLazyQuery,
  useGetWorkplaceTermsLazyQuery,
  useGetWorkplaceUsersForTaskLazyQuery,
} from "../graphql/query.generated";

interface FormData extends CreateTaskData {
  categoriesIds?: { id: string }[];
  labelsIds?: { id: string }[];
  assigneesIds?: { id: string }[];
}
interface Props {
  onClose: (task?: TaskWithoutChildrenAndParentsFragment) => void;
  open: boolean;
  predefinedTaskValues?: FormData;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    name: requiredStringValidator(t),
  });

export default function AddTaskDialog({
  onClose,
  open,
  predefinedTaskValues,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { chosenCompanyId } = useContext(CompanyContext);
  const { chosenWorkplaceId, loading: workplaceContextLoading } = useContext(
    WorkplaceContext
  );

  const [confirmCloseOpen, setConfirmCloseOpen] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
  } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      reportedDate: predefinedTaskValues?.reportedDate || new Date(),
      dueDate: predefinedTaskValues?.dueDate || null,
      name: predefinedTaskValues?.name || "",
      workplaceId: "",
      categoriesIds: predefinedTaskValues?.categoriesIds || [],
      labelsIds: predefinedTaskValues?.labelsIds || [],
      assigneesIds: predefinedTaskValues?.assigneesIds || [],
      reporterId: predefinedTaskValues?.reporterId || null,
      description: predefinedTaskValues?.description || "",
      location: predefinedTaskValues?.location || "",
      patrolEventId: predefinedTaskValues?.patrolEventId || "",
      taskStatusId: "",
    },
  });

  const [
    createTaskMutation,
    { loading: createTaskLoading, error: createTaskError },
  ] = useCreateTaskMutation();

  const [
    getWorkplaceTermsQuery,
    {
      data: workplaceTermsData,
      loading: workplaceTermsLoading,
      error: workplaceTermsError,
    },
  ] = useGetWorkplaceTermsLazyQuery();

  const [
    getWorkplaceUsersQuery,
    {
      data: workplaceUsersData,
      loading: workplaceUsersLoading,
      error: workplaceUsersError,
    },
  ] = useGetWorkplaceUsersForTaskLazyQuery();

  const [
    getWorkplaceTaskStatuses,
    { data: taskStatusesData, loading: taskStatusesLoading, error: taskStatusesError },
  ] = useGetWorkplaceTaskStatusesLazyQuery();

  useEffect(() => {
    if (chosenCompanyId) {
      getWorkplaceTermsQuery({ variables: { companyId: chosenCompanyId } });
    }
  }, [getWorkplaceTermsQuery, chosenCompanyId]);

  useEffect(() => {
    if (chosenWorkplaceId) {
      getWorkplaceUsersQuery({
        variables: { workplaceId: chosenWorkplaceId, includeInactive: true }, // todo change this to not include inactive
      });
      getWorkplaceTaskStatuses({
        variables: { workplaceId: chosenWorkplaceId },
      });
    }
  }, [chosenWorkplaceId, getWorkplaceTaskStatuses, getWorkplaceUsersQuery]);

  useEffect(() => {
    if (taskStatusesData?.getWorkplaceTaskStatuses.length) {
      setValue("taskStatusId", taskStatusesData.getWorkplaceTaskStatuses[0].id);
    }
  }, [setValue, taskStatusesData]);

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    if (chosenWorkplaceId) {
      const workplaceId = chosenWorkplaceId;
      const assigneesIds = formData.assigneesIds
        ? formData.assigneesIds.map((a) => a.id)
        : undefined;
      const labelsIds = formData.labelsIds
        ? formData.labelsIds.map((l) => l.id)
        : undefined;
      const categoriesIds = formData.categoriesIds
        ? formData.categoriesIds.map((c) => c.id)
        : undefined;
      const reportedDate = formData.reportedDate
        ? moment(formData.reportedDate).toISOString()
        : undefined;
      const dueDate = formData.dueDate
        ? moment(formData.dueDate).toISOString()
        : undefined;
      const patrolEventId = predefinedTaskValues?.patrolEventId || undefined;

      try {
        const res = await createTaskMutation({
          variables: {
            createTaskData: {
              ...formData,
              workplaceId,
              assigneesIds,
              labelsIds,
              categoriesIds,
              reportedDate,
              dueDate,
              patrolEventId,
            },
          },
          // TODO check if this refetch can be deleted
          refetchQueries: [
            {
              query: GET_TASKS_LIST_DATA,
              variables: { workplaceId: chosenWorkplaceId, filters: null },
            },
          ],
        });

        if (res.data) {
          onClose(res.data.createTask);
        }
      } catch (e) {
        //
      }
    }
  };

  const onTryToClose = (): void => {
    const values = getValues();
    const entredValues = Object.values(values).filter((v) => {
      if (Array.isArray(v)) {
        return Boolean(v.length);
      }
      return Boolean(v);
    });
    if (entredValues.length > 3) {
      setConfirmCloseOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      <CustomDialog
        open={open}
        loading={
          createTaskLoading ||
          taskStatusesLoading ||
          workplaceTermsLoading ||
          workplaceUsersLoading ||
          workplaceContextLoading
        }
        onClose={(): void => onTryToClose()}
      >
        <CustomDialogContent heading={t("Add new task")}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <FormTextFieldWrapper>
              <TextField
                name="name"
                label={`${t("Task name")}*`}
                fullWidth
                variant="outlined"
                autoFocus
                inputRef={register}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            </FormTextFieldWrapper>
            <FormTextFieldWrapper>
              <TextField
                name="description"
                label={t("Description")}
                fullWidth
                variant="outlined"
                inputRef={register}
                multiline
                rows={3}
              />
            </FormTextFieldWrapper>
            <FormTextFieldWrapper>
              <TextField
                name="location"
                label={t("Location")}
                fullWidth
                variant="outlined"
                inputRef={register}
              />
            </FormTextFieldWrapper>
            {/* <FormTextFieldWrapper>
            <Button color="primary" variant="outlined" fullWidth size="large">
              {t("Add attachment")}
            </Button>
          </FormTextFieldWrapper> */}
            <FormTextFieldWrapper>
              <Controller
                control={control}
                name="reportedDate"
                render={({ value, onChange }): JSX.Element => (
                  <CustomDateTimePicker
                    label={t("Reported date")}
                    minutesStep={15}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    inputVariant="outlined"
                    clearable
                    showTodayButton
                  />
                )}
              />
            </FormTextFieldWrapper>
            <FormTextFieldWrapper>
              <Controller
                control={control}
                name="dueDate"
                render={({ value, onChange }): JSX.Element => (
                  <CustomDateTimePicker
                    label={t("Deadline")}
                    minutesStep={15}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    inputVariant="outlined"
                    clearable
                    showTodayButton
                  />
                )}
              />
            </FormTextFieldWrapper>

            <FormTextFieldWrapper>
              {workplaceTermsData?.getCategories && (
                <Controller
                  control={control}
                  name="categoriesIds"
                  render={({ onChange, value }): JSX.Element => (
                    <MultiSelectWithSearch<{ id: string }>
                      size="medium"
                      outlined
                      namesToDisplay={workplaceTermsData.getCategories.map(
                        (category) => ({
                          name: category.name,
                          optionId: category.id,
                        })
                      )}
                      onChange={onChange}
                      options={
                        workplaceTermsData.getCategories.map((cat) => ({ id: cat.id })) ||
                        []
                      }
                      pickedValues={value}
                      label={t("Categories")}
                      placeholder={t("+ Add task category")}
                    />
                  )}
                />
              )}
            </FormTextFieldWrapper>

            <FormTextFieldWrapper>
              {workplaceTermsData?.getLabels && (
                <Controller
                  control={control}
                  name="labelsIds"
                  render={({ onChange, value }): JSX.Element => (
                    <MultiSelectWithSearch<{ id: string }>
                      size="medium"
                      outlined
                      namesToDisplay={workplaceTermsData.getLabels.map((label) => ({
                        name: label.name,
                        optionId: label.id,
                      }))}
                      onChange={onChange}
                      options={
                        workplaceTermsData.getLabels.map((lab) => ({ id: lab.id })) || []
                      }
                      pickedValues={value}
                      label={t("Labels")}
                      placeholder={t("+ Add task label")}
                    />
                  )}
                />
              )}
            </FormTextFieldWrapper>

            <FormTextFieldWrapper>
              {workplaceUsersData && (
                <Controller
                  control={control}
                  name="assigneesIds"
                  render={({ value, onChange }): JSX.Element => (
                    <MultiSelectWithSearch<{ id: string }>
                      size="medium"
                      outlined
                      namesToDisplay={workplaceUsersData.getWorkplaceUsers.map(
                        (user) => ({
                          name: `${user.firstName} ${user.lastName}`,
                          optionId: user.id,
                        })
                      )}
                      onChange={onChange}
                      options={
                        workplaceUsersData.getWorkplaceUsers.map((u) => ({ id: u.id })) ||
                        []
                      }
                      pickedValues={value}
                      placeholder={t("+ Add responsible person")}
                      label={t("Responsible people")}
                      renderTags={(selected: { id: string }[]): JSX.Element => (
                        <Box py={1} width="100%">
                          {selected.map((userId) => {
                            const user = workplaceUsersData.getWorkplaceUsers.find(
                              (u) => u.id === userId.id
                            );
                            return (
                              <Box key={userId.id} mb={0.5}>
                                <UserNameDisplay
                                  firstName={user?.firstName || ""}
                                  lastName={user?.lastName || ""}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                    />
                  )}
                />
              )}
            </FormTextFieldWrapper>

            <FormTextFieldWrapper>
              {workplaceUsersData && (
                <Controller
                  control={control}
                  name="reporterId"
                  render={({ value, onChange }): JSX.Element => (
                    <Autocomplete
                      noOptionsText={t("No options")}
                      options={workplaceUsersData.getWorkplaceUsers.map((u) => u.id)}
                      value={value}
                      onChange={(e, v): void => onChange(v)}
                      fullWidth
                      getOptionLabel={(item): string => {
                        const user = workplaceUsersData.getWorkplaceUsers.find(
                          (displayName) => displayName.id === item
                        );
                        return `${user?.firstName} ${user?.lastName}`;
                      }}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          variant="outlined"
                          // className={classes.dataField}
                          label={t("Reporter")}
                        />
                      )}
                    />
                  )}
                />
              )}
            </FormTextFieldWrapper>

            <FormTextFieldWrapper>
              {taskStatusesData?.getWorkplaceTaskStatuses && (
                <Controller
                  control={control}
                  name="taskStatusId"
                  as={
                    <TextField select label={t("Status")} fullWidth variant="outlined">
                      {taskStatusesData.getWorkplaceTaskStatuses.map((status) => (
                        <MenuItem key={status.id} value={status.id}>
                          {status.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                />
              )}
            </FormTextFieldWrapper>

            <Box>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
                size="large"
              >
                {t("Add task")}
              </Button>
            </Box>
          </form>
        </CustomDialogContent>
      </CustomDialog>
      <ConfirmDialog
        open={confirmCloseOpen}
        onConfirm={(): void => {
          setConfirmCloseOpen(false);
          onClose();
        }}
        onDecline={(): void => setConfirmCloseOpen(false)}
        dialogContent="You are about to close task creation dialog, all entered data will be lost, are you sure?"
        dialogTitle="Cancel task creation?"
      />
      <ErrorDisplay
        error={
          taskStatusesError ||
          workplaceTermsError ||
          workplaceUsersError ||
          createTaskError
        }
        message={t("Error occured while creating task, please try again or contact us.")}
        codes={{
          BAD_USER_INPUT: t(
            "Provided data is invalid. Check if provided dates are not colliding with each other."
          ),
        }}
      />
    </>
  );
}
