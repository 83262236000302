import * as Types from "../../../generated/types";

import { gql } from "@apollo/client";
export type WorkplaceUserFragment = {
  __typename?: "User";
  id: any;
  firstName?: Types.Maybe<string>;
  lastName?: Types.Maybe<string>;
};

export type TaskWithoutChildrenAndParentsFragment = {
  __typename?: "Task";
  id: any;
  friendlyId: number;
  name: string;
  description?: Types.Maybe<string>;
  location?: Types.Maybe<string>;
  order: number;
  createdAt: any;
  updatedAt: any;
  reportedDate?: Types.Maybe<any>;
  dueDate?: Types.Maybe<any>;
  finishedDate?: Types.Maybe<any>;
  isArchived: boolean;
  status: {
    __typename?: "TaskStatus";
    id: any;
    name: string;
    type: Types.TaskStatusType;
  };
  categories: Array<{ __typename?: "Category"; id: any; name: string }>;
  labels: Array<{ __typename?: "Label"; id: any; name: string }>;
  createdBy?: Types.Maybe<{
    __typename?: "User";
    id: any;
    firstName?: Types.Maybe<string>;
    lastName?: Types.Maybe<string>;
  }>;
  reporter?: Types.Maybe<{
    __typename?: "User";
    id: any;
    firstName?: Types.Maybe<string>;
    lastName?: Types.Maybe<string>;
  }>;
  assignees: Array<{
    __typename?: "User";
    id: any;
    email: any;
    firstName?: Types.Maybe<string>;
    lastName?: Types.Maybe<string>;
  }>;
};

export type TaskListDataWithoutChildrenFragment = {
  __typename?: "Task";
  id: any;
  friendlyId: number;
  name: string;
  location?: Types.Maybe<string>;
  dueDate?: Types.Maybe<any>;
  finishedDate?: Types.Maybe<any>;
  order: number;
  status: {
    __typename?: "TaskStatus";
    id: any;
    name: string;
    type: Types.TaskStatusType;
  };
  assignees: Array<{
    __typename?: "User";
    id: any;
    firstName?: Types.Maybe<string>;
    lastName?: Types.Maybe<string>;
    email: any;
  }>;
};

export type TaskListDataFragment = {
  __typename?: "Task";
  children: Array<{ __typename?: "Task" } & TaskListDataWithoutChildrenFragment>;
} & TaskListDataWithoutChildrenFragment;

export type TaskDetailsChildFragment = {
  __typename?: "Task";
  id: any;
  friendlyId: number;
  name: string;
  dueDate?: Types.Maybe<any>;
  finishedDate?: Types.Maybe<any>;
  status: {
    __typename?: "TaskStatus";
    id: any;
    name: string;
    type: Types.TaskStatusType;
  };
  assignees: Array<{
    __typename?: "User";
    id: any;
    firstName?: Types.Maybe<string>;
    lastName?: Types.Maybe<string>;
  }>;
};

export type TaskDetailsFragment = {
  __typename?: "Task";
  children: Array<{ __typename?: "Task" } & TaskDetailsChildFragment>;
  parent?: Types.Maybe<{
    __typename?: "Task";
    id: any;
    friendlyId: number;
    isArchived: boolean;
  }>;
} & TaskWithoutChildrenAndParentsFragment;

export type CommentAuthorFragment = {
  __typename?: "User";
  id: any;
  email: any;
  firstName?: Types.Maybe<string>;
  lastName?: Types.Maybe<string>;
};

export type UpdateCommentContentFragment = {
  __typename?: "Comment";
  id: any;
  content: string;
  updatedAt: any;
};

export type AttachmentUserFragment = {
  __typename?: "User";
  id: any;
  email: any;
  firstName?: Types.Maybe<string>;
  lastName?: Types.Maybe<string>;
};

export type TaskAttachmentFragment = {
  __typename?: "TaskAttachment";
  id: any;
  createdAt: any;
  updatedAt: any;
  attachment: {
    __typename?: "Attachment";
    id: any;
    filename: string;
    mimetype: Types.MimeType;
    uri: string;
    createdAt: any;
    updatedAt: any;
    encoding: string;
  };
  createdBy?: Types.Maybe<{ __typename?: "User" } & AttachmentUserFragment>;
};

export type ArchivedTaskListFragment = {
  __typename?: "Task";
  id: any;
  friendlyId: number;
  name: string;
  location?: Types.Maybe<string>;
  finishedDate?: Types.Maybe<any>;
  updatedAt: any;
  assignees: Array<{
    __typename?: "User";
    id: any;
    firstName?: Types.Maybe<string>;
    lastName?: Types.Maybe<string>;
    email: any;
  }>;
};

export const WorkplaceUserFragmentDoc = gql`
  fragment WorkplaceUser on User {
    id
    firstName
    lastName
  }
`;
export const TaskListDataWithoutChildrenFragmentDoc = gql`
  fragment TaskListDataWithoutChildren on Task {
    id
    friendlyId
    name
    status {
      id
      name
      type
    }
    location
    dueDate
    finishedDate
    assignees {
      id
      firstName
      lastName
      email
    }
    order
  }
`;
export const TaskListDataFragmentDoc = gql`
  fragment TaskListData on Task {
    ...TaskListDataWithoutChildren
    children {
      ...TaskListDataWithoutChildren
    }
  }
  ${TaskListDataWithoutChildrenFragmentDoc}
`;
export const TaskWithoutChildrenAndParentsFragmentDoc = gql`
  fragment TaskWithoutChildrenAndParents on Task {
    id
    friendlyId
    name
    description
    location
    status {
      id
      name
      type
    }
    order
    createdAt
    updatedAt
    reportedDate
    dueDate
    finishedDate
    isArchived
    categories {
      id
      name
    }
    labels {
      id
      name
    }
    createdBy {
      id
      firstName
      lastName
    }
    reporter {
      id
      firstName
      lastName
    }
    assignees {
      id
      email
      firstName
      lastName
    }
  }
`;
export const TaskDetailsChildFragmentDoc = gql`
  fragment TaskDetailsChild on Task {
    id
    friendlyId
    name
    dueDate
    finishedDate
    status {
      id
      name
      type
    }
    assignees {
      id
      firstName
      lastName
    }
  }
`;
export const TaskDetailsFragmentDoc = gql`
  fragment TaskDetails on Task {
    ...TaskWithoutChildrenAndParents
    children {
      ...TaskDetailsChild
    }
    parent {
      id
      friendlyId
      isArchived
    }
  }
  ${TaskWithoutChildrenAndParentsFragmentDoc}
  ${TaskDetailsChildFragmentDoc}
`;
export const CommentAuthorFragmentDoc = gql`
  fragment CommentAuthor on User {
    id
    email
    firstName
    lastName
  }
`;
export const UpdateCommentContentFragmentDoc = gql`
  fragment UpdateCommentContent on Comment {
    id
    content
    updatedAt
  }
`;
export const AttachmentUserFragmentDoc = gql`
  fragment AttachmentUser on User {
    id
    email
    firstName
    lastName
  }
`;
export const TaskAttachmentFragmentDoc = gql`
  fragment TaskAttachment on TaskAttachment {
    id
    attachment {
      id
      filename
      mimetype
      uri
      createdAt
      updatedAt
      encoding
    }
    createdBy {
      ...AttachmentUser
    }
    createdAt
    updatedAt
  }
  ${AttachmentUserFragmentDoc}
`;
export const ArchivedTaskListFragmentDoc = gql`
  fragment ArchivedTaskList on Task {
    id
    friendlyId
    name
    location
    finishedDate
    assignees {
      id
      firstName
      lastName
      email
    }
    updatedAt
  }
`;
