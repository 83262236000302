import { Box, Button, Drawer, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AssigneesSelect from "../../../../components/AssigneesSelect/AssigneesSelect";
import StyledDateRangePicker from "../../../../components/StyledDateRangePicker/StyledDateRangePicker";
import { WorkplaceContext } from "../../../../context/WorkplaceContext/WorkplaceContextProvider";
import { DateFilter, TaskFilters } from "../../../../generated/types";
import { QueryVariablesEnum } from "../../../../interfaces/QueryVariables";
import theme from "../../../../utils/theme";
import useManageUrlQueryVariables from "../../../../utils/useManageUrlQueryVariables";
import { useGetTasksListDataLazyQuery } from "../../graphql/query.generated";
import TaskCategoriesSelect from "../TaskSelectFields/TaskCategoriesSelect";
import TaskLabelsSelect from "../TaskSelectFields/TaskLabelsSelect";
import TaskStatusSelect from "../TaskSelectFields/TaskStatusSelect";
import TaskWorkplaceUserSelect from "../TaskSelectFields/TaskWorkplaceUserSelect";
import FilterWrapper from "./FilterWrapper";

interface Props {
  onFiltersClose: () => void;
  open?: boolean;
}

const useStyles = makeStyles({
  fieldWrapper: { marginRight: theme.spacing(2), width: "15rem" },
  closeIcon: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(3),
    cursor: "pointer",
  },
});

export default function TasksFilters({ onFiltersClose, open }: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { chosenWorkplaceId } = useContext(WorkplaceContext);
  const {
    setUrlQueryVariables,
    deleteUrlQueryVariables,
    taskFilters,
  } = useManageUrlQueryVariables();

  const [getTasksListDataQuery] = useGetTasksListDataLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: () => {
      onFiltersClose();
    },
  });

  const urlFilters = useMemo<TaskFilters | null>(() => {
    if (taskFilters) {
      return JSON.parse(taskFilters);
    }
    return null;
  }, [taskFilters]);

  const [focusedDueDateRangeSelect, setFocusedDueDateRangeSelect] = useState<
    "startDate" | "endDate" | null
  >(null);
  const [focusedFinishedDateRangeSelect, setFocusedFinishedDateRangeSelect] = useState<
    "startDate" | "endDate" | null
  >(null);
  const [focusedReportedDateRangeSelect, setFocusedReportedDateRangeSelect] = useState<
    "startDate" | "endDate" | null
  >(null);

  const { control, setValue, handleSubmit, watch, reset } = useForm<TaskFilters>({
    defaultValues: {
      categoriesIds: urlFilters?.categoriesIds || [],
      assigneesIds: urlFilters?.assigneesIds || [],
      labelsIds: urlFilters?.labelsIds || [],
      dueDate: urlFilters?.dueDate || {},
      reportedDate: urlFilters?.reportedDate || {},
      finishedDate: urlFilters?.finishedDate || {},
      createdById: urlFilters?.createdById || "",
      reporterId: urlFilters?.reporterId || "",
      taskStatusId: urlFilters?.taskStatusId || "",
    },
  });
  const watchCategories = watch("categoriesIds", urlFilters?.categoriesIds || []);
  const watchLabels = watch("labelsIds", urlFilters?.labelsIds || []);
  const watchAssignees = watch("assigneesIds", urlFilters?.assigneesIds || []);
  const watchDueDate = watch("dueDate", urlFilters?.dueDate || {});
  const watchReportedDate = watch("reportedDate", urlFilters?.reportedDate || {});
  const watchFinishedDate = watch("finishedDate", urlFilters?.finishedDate || {});
  const watchCreatedBy = watch("createdById", urlFilters?.createdById || undefined);
  const watchReporter = watch("reporterId", urlFilters?.reporterId || undefined);
  const watchStatus = watch("taskStatusId", urlFilters?.taskStatusId || undefined);

  useEffect(() => {
    if (taskFilters && open) {
      const currentFilters = JSON.parse(taskFilters) as TaskFilters;
      Object.entries(currentFilters).forEach((entry) => {
        setValue(entry[0], entry[1]);
      });
    }
  }, [open, setValue, taskFilters]);

  const setFormValueFromSelect = (
    fieldName: string,
    value: string[] | string | null
  ): void => {
    setValue(fieldName, value);
  };

  const onDateRangeChange = (
    fieldName: string,
    start?: moment.Moment,
    end?: moment.Moment
  ): void => {
    const startDate = start?.startOf("day").toISOString();
    const endDate = end?.endOf("day").toISOString();
    const newDateFilter: DateFilter = {
      minDate: startDate || null,
      maxDate: endDate || null,
    };
    setValue(fieldName, newDateFilter);
  };

  const onFilter = (filters: TaskFilters): void => {
    const clearedFilters = clearUnusedFilters(filters);
    if (Object.keys(clearedFilters).length) {
      setUrlQueryVariables(
        QueryVariablesEnum.TASKFILTERS,
        JSON.stringify(clearedFilters)
      );
      getTasksListDataQuery({
        variables: { workplaceId: chosenWorkplaceId, filters: clearedFilters },
      });
    } else {
      onClearFilters();
      onFiltersClose();
    }
  };

  const clearUnusedFilters = (filters: TaskFilters): TaskFilters => {
    const clearedFilters = { ...filters };
    Object.entries(filters).forEach((entry) => {
      if (!entry[1] || !Object.keys(entry[1]).length) {
        delete clearedFilters[entry[0]];
      }
      if (entry[1] && entry[1].minDate === null && entry[1].maxDate === null) {
        delete clearedFilters[entry[0]];
      }
    });

    return clearedFilters;
  };

  const onClearFilters = (): void => {
    deleteUrlQueryVariables(QueryVariablesEnum.TASKFILTERS);
    reset({
      categoriesIds: [],
      assigneesIds: [],
      labelsIds: [],
      dueDate: {},
      reportedDate: {},
      finishedDate: {},
      createdById: "",
      reporterId: "",
      taskStatusId: "",
    });
  };

  return (
    <Drawer anchor="top" open={open} onClose={(): void => onFiltersClose()}>
      <form onSubmit={handleSubmit(onFilter)}>
        <Box py={3} px={2} position="relative">
          <CloseIcon
            className={classes.closeIcon}
            onClick={(): void => onFiltersClose()}
          />

          <Box display="flex" mb={2} justifyContent="center">
            <Box className={classes.fieldWrapper}>
              <FilterWrapper title={t("Filter by assignees")}>
                <Controller
                  control={control}
                  name="assigneesIds"
                  as={
                    <AssigneesSelect
                      assigneesIds={watchAssignees as string[]}
                      workplaceId={chosenWorkplaceId}
                      onAssigneesChange={(val): void =>
                        setFormValueFromSelect("assigneesIds", val)
                      }
                    />
                  }
                />
              </FilterWrapper>
            </Box>
            <Box className={classes.fieldWrapper}>
              <FilterWrapper title={t("Filter by creator")}>
                <Controller
                  control={control}
                  name="createdById"
                  as={
                    <TaskWorkplaceUserSelect
                      onUserChange={(val): void =>
                        setFormValueFromSelect("createdById", val)
                      }
                      workplaceId={chosenWorkplaceId}
                      user={watchCreatedBy}
                    />
                  }
                />
              </FilterWrapper>
            </Box>
            <Box className={classes.fieldWrapper}>
              <FilterWrapper title={t("Filter by reporter")}>
                <Controller
                  control={control}
                  name="reporterId"
                  as={
                    <TaskWorkplaceUserSelect
                      onUserChange={(val): void =>
                        setFormValueFromSelect("reporterId", val)
                      }
                      workplaceId={chosenWorkplaceId}
                      user={watchReporter}
                    />
                  }
                />
              </FilterWrapper>
            </Box>
          </Box>
          <Box display="flex" mb={2} justifyContent="center">
            <Box className={classes.fieldWrapper}>
              {/* TODO - zmien komponent od status selectu tak, aby wyswietal placeholder */}
              <FilterWrapper title={t("Filter by status")}>
                <Controller
                  control={control}
                  name="taskStatusId"
                  as={
                    <TaskStatusSelect
                      taskStatus={watchStatus}
                      onStatusChange={(val): void =>
                        setFormValueFromSelect("taskStatusId", val)
                      }
                      workplaceId={chosenWorkplaceId}
                      withoutBorder
                      withEmptyValue
                    />
                  }
                />
              </FilterWrapper>
            </Box>
            <Box className={classes.fieldWrapper}>
              <FilterWrapper title={t("Filter by categories")}>
                <Controller
                  control={control}
                  name="categoriesIds"
                  as={
                    <TaskCategoriesSelect
                      categoriesIds={watchCategories as string[]}
                      onCategoriesChange={(val): void =>
                        setFormValueFromSelect("categoriesIds", val)
                      }
                    />
                  }
                />
              </FilterWrapper>
            </Box>
            <Box className={classes.fieldWrapper}>
              <FilterWrapper title={t("Filter by labels")}>
                <Controller
                  control={control}
                  name="labelsIds"
                  as={
                    <TaskLabelsSelect
                      labelsIds={watchLabels as string[]}
                      onLabelsChange={(val): void =>
                        setFormValueFromSelect("labelsIds", val)
                      }
                    />
                  }
                />
              </FilterWrapper>
            </Box>
          </Box>

          <Box display="flex" mb={3} justifyContent="center">
            <Box className={classes.fieldWrapper}>
              <FilterWrapper title={t("Filter by due date")}>
                <Controller
                  control={control}
                  name="dueDate"
                  render={(): JSX.Element => (
                    <StyledDateRangePicker
                      startDate={
                        watchDueDate?.minDate ? moment(watchDueDate.minDate) : null
                      }
                      startDateId="dueDateStart"
                      endDate={
                        watchDueDate?.maxDate ? moment(watchDueDate.maxDate) : null
                      }
                      endDateId="dueDateEnd"
                      onDatesChange={({ startDate, endDate }): void =>
                        onDateRangeChange(
                          "dueDate",
                          startDate || undefined,
                          endDate || undefined
                        )
                      }
                      focusedInput={focusedDueDateRangeSelect}
                      onFocusChange={(focusedInput): void =>
                        setFocusedDueDateRangeSelect(focusedInput)
                      }
                    />
                  )}
                />
              </FilterWrapper>
            </Box>
            <Box className={classes.fieldWrapper}>
              <FilterWrapper title={t("Filter by finished date")}>
                <Controller
                  control={control}
                  name="finishedDate"
                  render={(): JSX.Element => (
                    <StyledDateRangePicker
                      startDate={
                        watchFinishedDate?.minDate
                          ? moment(watchFinishedDate.minDate)
                          : null
                      }
                      startDateId="finishedDateStart"
                      endDate={
                        watchFinishedDate?.maxDate
                          ? moment(watchFinishedDate.maxDate)
                          : null
                      }
                      endDateId="finishedDateEnd"
                      onDatesChange={({ startDate, endDate }): void =>
                        onDateRangeChange(
                          "finishedDate",
                          startDate || undefined,
                          endDate || undefined
                        )
                      }
                      focusedInput={focusedFinishedDateRangeSelect}
                      onFocusChange={(focusedInput): void =>
                        setFocusedFinishedDateRangeSelect(focusedInput)
                      }
                    />
                  )}
                />
              </FilterWrapper>
            </Box>
            <Box className={classes.fieldWrapper}>
              <FilterWrapper title={t("Filter by reported date")}>
                <Controller
                  control={control}
                  name="reportedDate"
                  render={(): JSX.Element => (
                    <StyledDateRangePicker
                      startDate={
                        watchReportedDate?.minDate
                          ? moment(watchReportedDate.minDate)
                          : null
                      }
                      startDateId="reportedDateStart"
                      endDate={
                        watchReportedDate?.maxDate
                          ? moment(watchReportedDate.maxDate)
                          : null
                      }
                      endDateId="reportedDateEnd"
                      onDatesChange={({ startDate, endDate }): void =>
                        onDateRangeChange(
                          "reportedDate",
                          startDate || undefined,
                          endDate || undefined
                        )
                      }
                      focusedInput={focusedReportedDateRangeSelect}
                      onFocusChange={(focusedInput): void =>
                        setFocusedReportedDateRangeSelect(focusedInput)
                      }
                    />
                  )}
                />
              </FilterWrapper>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center">
            <Box mr={3} width="200px">
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={(): void => onClearFilters()}
              >
                {t("Clear filters")}
              </Button>
            </Box>
            <Box width="200px">
              <Button type="submit" variant="contained" color="primary" fullWidth>
                {t("Filter")}
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Drawer>
  );
}
