import { useCookies } from "react-cookie";

const TOKEN_NAME = "authToken";

export const useAuthToken = (): [
  { [name: string]: any },
  (authToken: string) => void,
  () => void
] => {
  const [cookies, setCookie, removeCookie] = useCookies([TOKEN_NAME]);

  const setAuthToken = (authToken: string): void => {
    setCookie(TOKEN_NAME, authToken, { path: "/" });
  };

  const removeAuthToken = (): void => {
    removeCookie(TOKEN_NAME, { path: "/" });
  };

  return [cookies[TOKEN_NAME], setAuthToken, removeAuthToken];
};
