import { Box, makeStyles, Paper, Typography } from "@material-ui/core";
import MovieIcon from "@material-ui/icons/Movie";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import React, { useState } from "react";
import { MimeType } from "../../generated/types";
import theme from "../../utils/theme";
import VideoDialog from "../VideoDialog/VideoDialog";
import HoverItem from "./HoverItem";

interface Props {
  fileName: string;
  mimetype: MimeType;
  uri: string;
  createdAt: string;
  creatorName?: string;
  onPhotoGalleryOpen: () => void;
  onDelete: () => void;
  canUserEdit?: boolean;
}

const useStyles = makeStyles({
  attachment: {
    height: "100px",
    boxSizing: "border-box",
    position: "relative",
  },
  img: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  textEllipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  wrapper: {
    cursor: "pointer",
    zIndex: 100,
    color: theme.palette.primary.main,
  },
});

export default function SingleAttachment({
  createdAt,
  fileName,
  mimetype,
  uri,
  onPhotoGalleryOpen,
  onDelete,
  canUserEdit,
}: Props): JSX.Element {
  const classes = useStyles();

  const [openVideoDialog, setOpenVideoDialog] = useState<boolean>(false);

  const onPdfDownload = (): void => {
    const link = document.createElement("a");
    link.href = uri;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box className={classes.wrapper}>
      <Paper className={classes.attachment} elevation={4}>
        {mimetype === MimeType.Mp4 && (
          <>
            <Box height="100%" display="flex" justifyContent="center" alignItems="center">
              <MovieIcon />
              <VideoDialog
                onClose={(): void => setOpenVideoDialog(false)}
                open={openVideoDialog}
                videoSrc={uri}
              />
            </Box>
            <HoverItem
              createdAt={createdAt}
              onClick={(): void => setOpenVideoDialog(true)}
              onDelete={(): void => onDelete()}
              canUserEdit={canUserEdit}
            />
          </>
        )}
        {mimetype === MimeType.Pdf && (
          <>
            <Box height="100%" display="flex" justifyContent="center" alignItems="center">
              <PictureAsPdfIcon />
            </Box>
            <HoverItem
              createdAt={createdAt}
              onClick={(): void => onPdfDownload()}
              onDelete={(): void => onDelete()}
              canUserEdit={canUserEdit}
            />
          </>
        )}
        {mimetype !== MimeType.Pdf && mimetype !== MimeType.Mp4 && (
          <>
            <img alt="uploaded-file" src={uri} className={classes.img} />
            <HoverItem
              createdAt={createdAt}
              onClick={(): void => onPhotoGalleryOpen()}
              onDelete={(): void => onDelete()}
              canUserEdit={canUserEdit}
            />
          </>
        )}
      </Paper>
      <Box
        mt={0.5}
        px={0.5}
        display="flex"
        justifyContent="center"
        className={classes.textEllipsis}
      >
        <Typography
          variant="caption"
          align="center"
          color="textSecondary"
          className={classes.textEllipsis}
        >
          {fileName}
        </Typography>
      </Box>
    </Box>
  );
}
