import { useApolloClient } from "@apollo/client";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DueDateDisplay from "../../../../../components/DueDateDisplay/DueDateDisplay";
import FinishDateDisplay from "../../../../../components/FinishDateDisplay/FinishDateDisplay";
import TaskStatusesDisplay from "../../../../../components/TaskStatusesDisplay/TaskStatusesDisplay";
import UserIcon from "../../../../../components/UserIcon/UserIcon";
import { PatrolEvent } from "../../../../../generated/types";
import generateUserNameString from "../../../../../utils/generateUserNameString";
import AddTaskDialog from "../../../../Tasks/AddTaskDialog/AddTaskDialog";
import TaskFriendlyId from "../../../../Tasks/common/TaskFriendlyId";
import { TaskListDataWithoutChildrenFragment } from "../../../../Tasks/graphql/fragments.generated";
import useOpenTaskDialog from "../../../../Tasks/TaskDetailsDialog/useOpenTaskDialog";
import { PatrolEventTaskFragment } from "../../../graphql/fragments.generated";
import { GET_EXTENDED_PATROL_EVENT_DATA } from "../../../graphql/query";
import { GetExtendedPatrolEventDataQuery } from "../../../graphql/query.generated";

interface Props {
  patrolEventData: PatrolEvent;
  tasks: PatrolEventTaskFragment[];
}
export default function PatrolEventTasks({ patrolEventData, tasks }: Props): JSX.Element {
  const { t } = useTranslation();
  const client = useApolloClient();
  const patrolEventId = patrolEventData.id;
  const { openTaskDialog } = useOpenTaskDialog();

  const [addTaskDialogOpen, setAddTaskDialogOpen] = useState<boolean>(false);

  const onAddTaskDialogClose = (
    createdTask?: TaskListDataWithoutChildrenFragment
  ): void => {
    if (createdTask) {
      const cachedData = client.readQuery<GetExtendedPatrolEventDataQuery>({
        query: GET_EXTENDED_PATROL_EVENT_DATA,
        variables: {
          patrolEventId,
        },
      });

      if (cachedData) {
        const newQuery: GetExtendedPatrolEventDataQuery = {
          ...cachedData,
          getPatrolEventTasks: [...cachedData.getPatrolEventTasks, createdTask],
        };

        client.writeQuery<GetExtendedPatrolEventDataQuery>({
          query: GET_EXTENDED_PATROL_EVENT_DATA,
          variables: {
            patrolEventId,
          },
          data: newQuery,
        });
      }
    }
    setAddTaskDialogOpen(false);
  };

  return (
    <>
      <Box>
        <Box mb={1} display="flex">
          <Button
            size="small"
            variant="outlined"
            onClick={(): void => setAddTaskDialogOpen(true)}
          >
            {t("Create task")}
          </Button>
        </Box>
        {Boolean(tasks.length) && (
          <Box mb={2}>
            {tasks.map((task) => (
              <Box mb={1}>
                <Paper variant="outlined" key={`patrolEventTask${task.id}`}>
                  <Box
                    display="flex"
                    alignItems="center"
                    pb={1.1}
                    pt={1.5}
                    px={2}
                    style={{ cursor: "pointer" }}
                    onClick={(): void => openTaskDialog(task.id)}
                  >
                    <Box width="15%">
                      <TaskFriendlyId friendlyId={task.friendlyId} />
                    </Box>
                    <Box width="25%">
                      <Typography variant="body2">{task.name}</Typography>
                    </Box>
                    <Box width="25%">
                      {task.finishedDate && (
                        <FinishDateDisplay finishDate={task.finishedDate} />
                      )}
                      {!task.finishedDate && task.dueDate && (
                        <DueDateDisplay date={task.dueDate} />
                      )}
                    </Box>
                    <Box width="25%">
                      {task.assignees.map((assignee) => (
                        <Box key={`patroltaskassignee-${assignee.id}`} mr={0.5}>
                          <UserIcon name={generateUserNameString(assignee)} />
                        </Box>
                      ))}
                    </Box>
                    <Box width="10%" display="flex" justifyContent="flex-end">
                      <TaskStatusesDisplay
                        statusName={task.status.name}
                        statusType={task.status.type}
                      />
                    </Box>
                  </Box>
                </Paper>
              </Box>
            ))}
          </Box>
        )}
        <Box />
      </Box>
      {addTaskDialogOpen && (
        <AddTaskDialog
          predefinedTaskValues={{
            name: patrolEventData.name || "",
            workplaceId: "",
            description: patrolEventData.description,
            location: patrolEventData.location,
            patrolEventId,
            reportedDate: patrolEventData.eventDate
              ? moment(patrolEventData.eventDate).toISOString()
              : "",
          }}
          open={addTaskDialogOpen}
          onClose={onAddTaskDialogClose}
        />
      )}
    </>
  );
}
