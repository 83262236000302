import { Box, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { WorkplaceUserFragment } from "../../views/Tasks/graphql/fragments.generated";
import { useGetWorkplaceUsersForTaskLazyQuery } from "../../views/Tasks/graphql/query.generated";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";
import MultiSelectWithSearch from "../MultiSelectWithSearch/MultiSelectWithSearch";
import UserNameDisplay from "../UserNameDisplay/UserNameDisplay";

interface Props {
  workplaceId: string;
  assigneesIds: string[];
  onAssigneesChange: (assignees: string[]) => void;
  disabled?: boolean;
  outlined?: boolean;
  size?: "small" | "medium";
}

export default function AssigneesSelect({
  workplaceId,
  assigneesIds,
  onAssigneesChange,
  disabled,
  outlined,
  size,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [
    getWorkplaceUsersQuery,
    {
      data: workplaceUsersData,
      // loading: workplaceUsersLoading,
      error: workplaceUsersError,
    },
  ] = useGetWorkplaceUsersForTaskLazyQuery();

  useEffect(() => {
    if (workplaceId) {
      getWorkplaceUsersQuery({
        variables: { workplaceId, includeInactive: true },
      });
    }
  }, [getWorkplaceUsersQuery, workplaceId]);

  const usersToSelect = useMemo(() => {
    if (workplaceUsersData?.getWorkplaceUsers) {
      return workplaceUsersData.getWorkplaceUsers.filter(
        (userData) => userData.firstName || userData.lastName
      );
    }
    return [];
  }, [workplaceUsersData]);

  const changeAssignees = (value: WorkplaceUserFragment[]): void => {
    const ids = value.map((user) => user.id);
    onAssigneesChange(ids);
  };

  const unassignUser = (id: string): void => {
    if (workplaceUsersData && workplaceUsersData.getWorkplaceUsers) {
      const newAssigneesIds = [...assigneesIds].filter((assignee) => assignee !== id);
      const newAssignees = workplaceUsersData.getWorkplaceUsers.filter(
        (wpUser) => newAssigneesIds.indexOf(wpUser.id) > -1
      );
      changeAssignees(newAssignees);
    }
  };

  return (
    <>
      {usersToSelect && (
        <MultiSelectWithSearch<WorkplaceUserFragment>
          disabled={disabled}
          outlined={outlined}
          size={size}
          namesToDisplay={usersToSelect.map((user) => ({
            name: `${user.firstName} ${user.lastName}`,
            optionId: user.id,
          }))}
          onChange={changeAssignees}
          options={usersToSelect || []}
          pickedValues={usersToSelect.filter(
            (wpUser) => assigneesIds.indexOf(wpUser.id) > -1
          )}
          placeholder={t("+ Add responsible person")}
          renderTags={(selected: WorkplaceUserFragment[]): JSX.Element => (
            <Box py={1} width="100%" display="flex" alignItems="center">
              <Box>
                {(selected as WorkplaceUserFragment[]).map((user) => {
                  return (
                    <Box
                      key={`taskAssigneesRenderTags${user.id}`}
                      mb={0.5}
                      display="flex"
                      alignItems="center"
                    >
                      <Box mr={1}>
                        <UserNameDisplay
                          firstName={user.firstName || ""}
                          lastName={user.lastName || ""}
                        />
                      </Box>
                      {!disabled && (
                        <IconButton
                          size="small"
                          onClick={(): void => unassignUser(user.id)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        />
      )}
      <CustomSnackbar
        open={!!workplaceUsersError}
        message={t(
          "Error occured while fetching workplace users, please try again or contact us."
        )}
      />
    </>
  );
}
