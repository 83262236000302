import { Box } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDateTimePicker from "../../../../components/CustomDateTimePicker/CustomDateTimePicker";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { useUpdateTaskReportedDateMutation } from "../../graphql/mutation.generated";

interface Props {
  reportedDate: string;
  taskId: string;
  disabled?: boolean;
}

export default function TaskReportedDate({
  reportedDate,
  taskId,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [
    updateTaskReportedDateMutation,
    { error: updateDateError },
  ] = useUpdateTaskReportedDateMutation();

  const changeReportedDate = async (newDate?: MaterialUiPickersDate): Promise<void> => {
    try {
      await updateTaskReportedDateMutation({
        variables: {
          updateTaskReportedDateData: {
            id: taskId,
            reportedDate: newDate?.toISOString() || null,
          },
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <>
      <Box display="flex">
        <CustomDateTimePicker
          disabled={disabled}
          placeholder={t("Pick report date")}
          value={reportedDate}
          onChange={changeReportedDate}
          minutesStep={10}
          InputProps={{
            disableUnderline: true,
          }}
          size="small"
          clearable
          showTodayButton
        />
        {reportedDate && !disabled && (
          <Box ml={1}>
            <HighlightOffIcon
              style={{ cursor: "pointer" }}
              color="secondary"
              onClick={(): Promise<void> => changeReportedDate()}
            />
          </Box>
        )}
      </Box>

      <ErrorDisplay
        error={updateDateError}
        message={t(
          "Error occured while changing report date, please try again or contact us"
        )}
        codes={{
          BAD_USER_INPUT:
            "Provided reported date is incorrect, check if date does not collide with other dates.",
        }}
      />
    </>
  );
}
