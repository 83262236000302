import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@material-ui/core";
import { TFunction } from "i18next";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import CustomDialog from "../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import { Category } from "../../../generated/types";
import { requiredStringValidator } from "../../../utils/formValidators";
import { useUpdateCategoryMutation } from "../graphql/mutation.generated";

interface Props {
  open: boolean;
  onClose: () => void;
  categoryData: Category;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    name: requiredStringValidator(t),
  });

export default function EditCategoryDialog({
  open,
  onClose,
  categoryData,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [updateCategoryMutation, { loading, error }] = useUpdateCategoryMutation();

  const { register, handleSubmit, errors } = useForm<Category>({
    resolver: yupResolver(schema(t)),
    defaultValues: categoryData,
  });

  const onFormSubmit = async (formData: Category): Promise<void> => {
    try {
      const res = await updateCategoryMutation({
        variables: {
          updateCategoryData: {
            categoryId: categoryData.id,
            name: formData.name,
          },
        },
      });

      if (res.data) {
        onClose();
      }
    } catch (e) {
      //
    }
  };

  return (
    <CustomDialog loading={loading} open={open} onClose={onClose} maxWidth="md">
      <CustomDialogContent heading={t("Edit category")}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Box mb={5}>
            <TextField
              name="name"
              label={t("Name")}
              fullWidth
              variant="outlined"
              inputRef={register}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              size="large"
            >
              {t("Edit category")}
            </Button>
          </Box>
        </form>
      </CustomDialogContent>
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while editing category, please try again or contact us."
        )}
      />
    </CustomDialog>
  );
}
