import { gql } from "@apollo/client";
import { notificationFragment } from "./fragments";

export const GET_NOTIFICATIONS = gql`
  ${notificationFragment}
  query getNotifications($paginationData: PaginationData!) {
    getNotifications(paginationData: $paginationData) {
      items {
        ...Notification
      }
      totalCount
    }
  }
`;
