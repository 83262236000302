import { Box, Button, TextField, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "react-router-dom";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FullScreenLoader from "../../../components/FullScreenLoader/FullScreenLoader";
import HelmetWrapper from "../../../components/HelmetWrapper/HelmetWrapper";
import { WorkplaceContext } from "../../../context/WorkplaceContext/WorkplaceContextProvider";
import { Permissions, TaskStatus } from "../../../generated/types";
import BasicMenuLayout from "../../../layouts/BasicMenuLayout/BasicMenuLayout";
import FullHeightNoScrollPage from "../../../layouts/FullHeightNoScrollPage/FullHeightNoScrollPage";
import { KANBAN, TASKS, TASKS_LIST, WORKPLACE } from "../../../routing/paths";
import IsUserAuthorised from "../../../utils/AuthUtils/IsUserAuthorised";
import ProtectedRoute from "../../../utils/AuthUtils/ProtectedRoute";
import { black50Opacity } from "../../../utils/theme";
import useManageUrlQueryVariables from "../../../utils/useManageUrlQueryVariables";
import AddTaskDialog from "../AddTaskDialog/AddTaskDialog";
import ActiveTaskFilters from "../common/TasksFilters/ActiveTaskFilters";
import TasksFilters from "../common/TasksFilters/TasksFilters";
import { TaskListDataFragment } from "../graphql/fragments.generated";
import TaskDetailsDialog from "../TaskDetailsDialog/TaskDetailsDialog";
import Kanban from "./Kanban/Kanban";
import TaskList from "./TaskList/TaskList";

import {
  useGetTasksListDataLazyQuery,
  useGetWorkplaceTaskStatusesLazyQuery,
} from "../graphql/query.generated";

export default function TasksPage(): JSX.Element {
  const { t } = useTranslation();
  const { chosenWorkplaceId, loading: workplaceContextLoading } = useContext(
    WorkplaceContext
  );
  const { taskFilters } = useManageUrlQueryVariables();

  const [addTaskDialogOpen, setAddTaskDialogOpen] = useState<boolean>(false);
  const [filtersOpened, setFiltersOpened] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  const [
    getWorkplaceTaskStatuses,
    { data: taskStatusesData, loading: taskStatusesLoading, error: taskStatusesError },
  ] = useGetWorkplaceTaskStatusesLazyQuery();

  const [
    getTasksListDataQuery,
    { data: taskListData, loading: taskListLoading, error: taskListError },
  ] = useGetTasksListDataLazyQuery();

  useEffect(() => {
    if (chosenWorkplaceId) {
      getWorkplaceTaskStatuses({
        variables: { workplaceId: chosenWorkplaceId },
      });
    }
  }, [chosenWorkplaceId, getTasksListDataQuery, getWorkplaceTaskStatuses]);

  useEffect(() => {
    if (chosenWorkplaceId) {
      const parsedFilters = taskFilters ? JSON.parse(taskFilters) : null;
      getTasksListDataQuery({
        variables: { workplaceId: chosenWorkplaceId, filters: parsedFilters },
      });
    }
  }, [chosenWorkplaceId, getTasksListDataQuery, taskFilters]);

  const statuses = useMemo<TaskStatus[]>(
    () => taskStatusesData?.getWorkplaceTaskStatuses || [],
    [taskStatusesData]
  );

  const filterWithSearchValue = useCallback(
    (allTasks: TaskListDataFragment[]): TaskListDataFragment[] => {
      if (searchValue) {
        return allTasks.filter(
          (tk) => tk.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
        );
      }
      return allTasks;
    },
    [searchValue]
  );

  const tasks = useMemo<TaskListDataFragment[]>(
    () =>
      taskListData?.getWorkplaceTasks
        ? filterWithSearchValue(taskListData.getWorkplaceTasks)
        : [],
    [filterWithSearchValue, taskListData]
  );

  const onFiltersClose = (): void => {
    setFiltersOpened(false);
  };

  const taskSearchSubmit = (e: React.ChangeEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setSearchValue((e.target[0] as HTMLFormElement).value || "");
  };

  const onSearchFieldChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === "" && searchValue) {
      setSearchValue("");
    }
  };

  return (
    <BasicMenuLayout>
      <FullHeightNoScrollPage>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          pr={3}
        >
          <Box display="flex" alignItems="center">
            <form onSubmit={taskSearchSubmit}>
              <Box display="flex">
                <TextField
                  name="search"
                  size="small"
                  InputProps={{
                    endAdornment: <SearchIcon />,
                  }}
                  variant="outlined"
                  onChange={onSearchFieldChange}
                />
                <Box ml={2}>
                  <Button type="submit" variant="contained" color="primary">
                    {t("Search")}
                  </Button>
                </Box>
              </Box>
            </form>
            <Box ml={2}>
              <Button size="small" onClick={(): void => setFiltersOpened(true)}>
                {t("+ Add filters")}
              </Button>
            </Box>
            <Box ml={2}>
              <ActiveTaskFilters />
            </Box>
          </Box>
          <IsUserAuthorised
            checkedWorkplaceId={chosenWorkplaceId}
            permission={Permissions.CreateTask}
          >
            <Box>
              <Button
                color="primary"
                variant="contained"
                onClick={(): void => setAddTaskDialogOpen(true)}
              >
                <AddIcon fontSize="small" />
                &nbsp;
                {t("Add new task")}
              </Button>
            </Box>
          </IsUserAuthorised>
        </Box>

        {Boolean(statuses.length) && Boolean(tasks.length) && (
          <Switch>
            <ProtectedRoute
              exact
              path={[WORKPLACE + TASKS + KANBAN, WORKPLACE + TASKS]}
              render={(): JSX.Element => (
                <HelmetWrapper siteTitle="Tasks - Kanban">
                  <Kanban statuses={statuses} tasks={tasks} />
                </HelmetWrapper>
              )}
            />
            <ProtectedRoute
              exact
              path={[WORKPLACE + TASKS + TASKS_LIST]}
              render={(): JSX.Element => (
                <HelmetWrapper siteTitle="Tasks - List">
                  <TaskList
                    statuses={statuses}
                    tasks={tasks}
                    workplaceId={chosenWorkplaceId}
                  />
                </HelmetWrapper>
              )}
            />
          </Switch>
        )}
        {!tasks.length && !taskListLoading && (
          <Box display="flex" justifyContent="center" pt={10}>
            <Typography variant="h3">{t("There are no tasks to show.")}</Typography>
          </Box>
        )}
      </FullHeightNoScrollPage>
      {filtersOpened && (
        <TasksFilters open={filtersOpened} onFiltersClose={onFiltersClose} />
      )}
      <TaskDetailsDialog />
      {addTaskDialogOpen && (
        <AddTaskDialog open onClose={(): void => setAddTaskDialogOpen(false)} />
      )}
      <ErrorDisplay
        error={taskListError}
        message={t(
          "Error occured while getting workplace tasks, please try again or contact us."
        )}
      />

      <ErrorDisplay
        error={taskStatusesError}
        message={t(
          "Error occured while getting tasks statuses, please try again or contact us."
        )}
      />
      {(taskStatusesLoading || workplaceContextLoading || taskListLoading) && (
        <FullScreenLoader open bgColor={black50Opacity} />
      )}
    </BasicMenuLayout>
  );
}
