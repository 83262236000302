import * as Types from "../../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type CreateCompanyMutationVariables = Types.Exact<{
  companyData: Types.CreateCompanyData;
}>;

export type CreateCompanyMutation = {
  __typename?: "Mutation";
  createCompany: {
    __typename?: "Company";
    id: any;
    name: string;
    vatNumber: any;
    address: string;
    city: string;
    postCode: string;
    country: Types.Country;
  };
};

export type DeleteWorkplaceMutationVariables = Types.Exact<{
  deleteWorkplaceData: Types.DeleteWorkplaceData;
}>;

export type DeleteWorkplaceMutation = {
  __typename?: "Mutation";
  deleteWorkplace: boolean;
};

export const CreateCompanyDocument = gql`
  mutation CreateCompany($companyData: CreateCompanyData!) {
    createCompany(companyData: $companyData) {
      id
      name
      vatNumber
      address
      city
      postCode
      country
    }
  }
`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      companyData: // value for 'companyData'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >
) {
  return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(
    CreateCompanyDocument,
    baseOptions
  );
}
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;
export const DeleteWorkplaceDocument = gql`
  mutation deleteWorkplace($deleteWorkplaceData: DeleteWorkplaceData!) {
    deleteWorkplace(deleteWorkplaceData: $deleteWorkplaceData)
  }
`;
export type DeleteWorkplaceMutationFn = Apollo.MutationFunction<
  DeleteWorkplaceMutation,
  DeleteWorkplaceMutationVariables
>;

/**
 * __useDeleteWorkplaceMutation__
 *
 * To run a mutation, you first call `useDeleteWorkplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkplaceMutation, { data, loading, error }] = useDeleteWorkplaceMutation({
 *   variables: {
 *      deleteWorkplaceData: // value for 'deleteWorkplaceData'
 *   },
 * });
 */
export function useDeleteWorkplaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWorkplaceMutation,
    DeleteWorkplaceMutationVariables
  >
) {
  return Apollo.useMutation<DeleteWorkplaceMutation, DeleteWorkplaceMutationVariables>(
    DeleteWorkplaceDocument,
    baseOptions
  );
}
export type DeleteWorkplaceMutationHookResult = ReturnType<
  typeof useDeleteWorkplaceMutation
>;
export type DeleteWorkplaceMutationResult = Apollo.MutationResult<DeleteWorkplaceMutation>;
export type DeleteWorkplaceMutationOptions = Apollo.BaseMutationOptions<
  DeleteWorkplaceMutation,
  DeleteWorkplaceMutationVariables
>;
