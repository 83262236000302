import React, { useMemo } from "react";
import { TaskStatusFragment } from "../graphql/fragments.generated";
import * as yup from "yup";
import { requiredStringValidator } from "../../../utils/formValidators";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, MenuItem, TextField, Typography } from "@material-ui/core";
import FormTextFieldWrapper from "../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import { useDeleteTaskStatusMutation } from "../graphql/mutation.generated";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";

interface Props {
  open: boolean;
  onClose: (success?: boolean) => void;
  taskStatusId: string;
  allTaskStatuses: TaskStatusFragment[];
}

interface FormData {
  deletedStatusName: string;
  replaceStatusId: string;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    deletedStatusName: requiredStringValidator(t),
    replaceStatusId: requiredStringValidator(t),
  });

export default function DeleteTaskStatusDialog({
  open,
  onClose,
  taskStatusId,
  allTaskStatuses,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const statusesToReplace = useMemo<TaskStatusFragment[]>(
    () => allTaskStatuses.filter((ts) => ts.id !== taskStatusId),
    [allTaskStatuses, taskStatusId]
  );

  const { register, errors, handleSubmit, control } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      replaceStatusId: statusesToReplace[0].id,
    },
  });

  const [deleteTaskStatusMutation, { loading, error }] = useDeleteTaskStatusMutation();

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    const realDeletedStatusName = allTaskStatuses.find((ts) => ts.id === taskStatusId)
      ?.name;
    if (realDeletedStatusName && realDeletedStatusName === formData.deletedStatusName) {
      try {
        const res = await deleteTaskStatusMutation({
          variables: {
            replaceStatusId: formData.replaceStatusId,
            taskStatusId,
          },
          update: (cache, result) => {
            if (result.data?.deleteTaskStatus) {
              cache.modify({
                fields: {
                  getWorkplaceTaskStatuses(existingTaskStatuses = [], { readField }) {
                    return existingTaskStatuses.filter(
                      (eventRef) => taskStatusId !== readField("id", eventRef)
                    );
                  },
                },
              });
            }
          },
        });
        if (res.data?.deleteTaskStatus) {
          onClose(true);
        }
      } catch (e) {
        //
      }
    }
  };

  return (
    <CustomDialog open={open} onClose={(): void => onClose()} loading={loading}>
      <CustomDialogContent heading={t("Delete task status")}>
        <Box mb={4}>
          <Typography variant="h5">{t("WARNING")}!</Typography>
          <Typography>
            {t(
              "You are about to delete task status. This action is irreversible! All tasks with status, that you want to delete will be updated with a replacement status."
            )}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormTextFieldWrapper>
            <TextField
              name="deletedStatusName"
              label={`${t("Confirm deleted status name")}*`}
              fullWidth
              variant="outlined"
              autoFocus
              inputRef={register}
              error={!!errors.deletedStatusName}
              helperText={errors.deletedStatusName?.message}
            />
          </FormTextFieldWrapper>
          <FormTextFieldWrapper>
            <Controller
              control={control}
              name="replaceStatusId"
              as={
                <TextField
                  select
                  label={t("Replacement status*")}
                  fullWidth
                  variant="outlined"
                  error={!!errors.replaceStatusId}
                  helperText={errors.replaceStatusId?.message}
                >
                  {statusesToReplace.map((status) => (
                    <MenuItem
                      key={`replacementStatusIdKey-${status.id}`}
                      value={status.id}
                    >
                      {status.name}
                    </MenuItem>
                  ))}
                </TextField>
              }
            />
          </FormTextFieldWrapper>
          <Box display="flex" justifyContent="space-between">
            <Button onClick={(): void => onClose()}>{t("Cancel")}</Button>
            <Button variant="contained" color="primary" type="submit">
              {t("Delete")}
            </Button>
          </Box>
        </form>
      </CustomDialogContent>
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while deleting task status, please try again later or contact us."
        )}
      />
    </CustomDialog>
  );
}
