import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, makeStyles, TextField, Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import CustomSnackbar from "../../../components/CustomSnackbar/CustomSnackbar";
import FormTextFieldWrapper from "../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import { RESET_PASSWORD, SIGN_UP } from "../../../routing/paths";
import { useAuthToken } from "../../../utils/AuthUtils/useAuthToken";
import { emailValidator, requiredStringValidator } from "../../../utils/formValidators";
import IdentityLayout from "../common/IdentityLayout";
import { useSignInMutation } from "../graphql/mutation.generated";

interface FormData {
  email: string;
  password: string;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    email: emailValidator(t),
    password: requiredStringValidator(t),
  });

const useStyles = makeStyles({
  link: {
    display: "block",
  },
});

export default function SignInPage(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
  });
  const history = useHistory();

  const [signInMutation, { loading }] = useSignInMutation();
  const [errorMsg, setErrorMsg] = useState("");
  const basicSignInErrorMsg = t(
    "An error occurred while signing in, please try again or contact us."
  );
  const setAuthToken = useAuthToken()[1];

  const onFormSubmit = async (data: FormData): Promise<void> => {
    try {
      const res = await signInMutation({
        variables: {
          request: data,
        },
      });
      if (res.data && res.data.signIn.access_token) {
        setAuthToken(res.data.signIn.access_token);
        history.push("/");
      }
    } catch (err) {
      if (err.message?.includes("Invalid email or password")) {
        setErrorMsg(t("Invalid email or password"));
      } else {
        setErrorMsg(basicSignInErrorMsg);
      }
    }
  };

  return (
    <IdentityLayout loading={loading}>
      <Box pb={3}>
        <Typography variant="h6">{t("Sign in")}</Typography>
      </Box>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <FormTextFieldWrapper>
          <TextField
            name="email"
            label={t("Email")}
            fullWidth
            autoFocus
            variant="outlined"
            inputRef={register}
            error={!!errors.email}
            helperText={errors.email?.message}
            InputProps={{
              type: "email",
            }}
          />
        </FormTextFieldWrapper>
        <Box pb={1}>
          <TextField
            name="password"
            label={t("Password")}
            type="password"
            fullWidth
            variant="outlined"
            inputRef={register}
            error={!!errors.password}
            helperText={errors.password?.message}
            InputProps={{
              type: "password",
            }}
          />
        </Box>
        <Box pb={5}>
          <Link to={RESET_PASSWORD} className={classes.link}>
            <Typography variant="caption" color="primary" align="right" display="block">
              {t("Reset password")}
            </Typography>
          </Link>
        </Box>
        <Box pb={2}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            fullWidth
            size="large"
          >
            {t("Sign in")}
          </Button>
        </Box>
        <Box pb={2}>
          <Typography variant="caption" display="block" align="center">
            {t("or")}
          </Typography>
        </Box>
        <Box>
          <Link to={SIGN_UP} className={classes.link}>
            <Button color="primary" variant="outlined" fullWidth size="large">
              {t("Create new account")}
            </Button>
          </Link>
        </Box>
      </form>
      <CustomSnackbar
        message={errorMsg}
        open={!!errorMsg}
        handleClose={(): void => setErrorMsg("")}
      />
    </IdentityLayout>
  );
}
