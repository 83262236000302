import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import MultiSelectWithSearch from "../../../../components/MultiSelectWithSearch/MultiSelectWithSearch";
import { CompanyContext } from "../../../../context/CompanyContext/CompanyContextProvider";
import { useGetWorkplaceLabelsLazyQuery } from "../../graphql/query.generated";

interface Props {
  labelsIds: string[];
  onLabelsChange: (labels: string[]) => void;
  disabled?: boolean;
}
export default function TaskLabelsSelect({
  labelsIds,
  onLabelsChange,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { chosenCompanyId } = useContext(CompanyContext);

  const [
    getWorkplaceLabelsQuery,
    { data: labelsData, error: labelsLoadingError },
  ] = useGetWorkplaceLabelsLazyQuery();

  useEffect(() => {
    if (chosenCompanyId) {
      getWorkplaceLabelsQuery({ variables: { companyId: chosenCompanyId } });
    }
  }, [getWorkplaceLabelsQuery, chosenCompanyId]);

  const onMultiselectChange = async (value: { id: string }[]): Promise<void> => {
    const newValue = value.map((v) => v.id);
    onLabelsChange(newValue);
  };

  return (
    <>
      {labelsData?.getLabels && (
        <MultiSelectWithSearch<{ id: string }>
          disabled={disabled}
          namesToDisplay={labelsData.getLabels.map((label) => ({
            name: label.name,
            optionId: label.id,
          }))}
          onChange={onMultiselectChange}
          options={labelsData.getLabels.map((cat) => ({ id: cat.id })) || []}
          pickedValues={labelsIds.map((id) => ({ id }))}
          placeholder={t("+ Add task label")}
        />
      )}
      <ErrorDisplay
        error={labelsLoadingError}
        message={t("Error occured while loading labels, please try again or contact us.")}
      />
    </>
  );
}
