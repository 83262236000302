import { gql } from "@apollo/client";

export const patrolEventFragment = gql`
  fragment PatrolEvent on PatrolEvent {
    id
    eventDate
    location
    name
    description
    action
    createdAt
    updatedAt
  }
`;

export const patrolFragment = gql`
  ${patrolEventFragment}
  fragment Patrol on Patrol {
    id
    startDate
    endDate
    description
    location
    assignees {
      id
      firstName
      lastName
      email
    }
    createdBy {
      id
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
`;

export const patrolEventTaskFragment = gql`
  fragment PatrolEventTask on Task {
    id
    friendlyId
    name
    status {
      id
      name
      type
    }
    dueDate
    finishedDate
    assignees {
      id
      firstName
      lastName
      email
    }
  }
`;
