import { Document, Font as xd, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { TFunction } from "i18next";
import moment from "moment";
import React from "react";
import { SHORT_DATE_FORMAT_WITH_HOUR } from "../../../../utils/consts";
import generateUserNameString from "../../../../utils/generateUserNameString";
import theme from "../../../../utils/theme";
import { PatrolEventFragment, PatrolFragment } from "../../graphql/fragments.generated";
import font from "./Roboto-Regular.ttf";

interface Props {
  patrolData: PatrolFragment;
  patrolEvents: PatrolEventFragment[];
  t: TFunction;
  companyName: () => string;
  workplaceName: () => string;
}

xd.register({
  family: "Roboto",
  src: font,
});

const sectionMarginBottom = 15;

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontSize: 12,
    padding: 20,
    paddingTop: 25,
    position: "relative",
  },
  caption: {
    top: 5,
    width: "100%",
    paddingLeft: 5,
    position: "absolute",
  },
  companyInfo: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  title: {
    marginBottom: 10,
    fontSize: 35,
    fontWeight: "bold",
  },
  titleDate: {
    marginBottom: sectionMarginBottom,
    fontSize: 20,
    flexDirection: "row",
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.secondary.main,
    marginBottom: sectionMarginBottom,
  },
  patrolInfoSection: {
    flexDirection: "row",
    marginBottom: sectionMarginBottom,
  },
  patrolInfoHeader: {
    marginBottom: 5,
    fontSize: 10,
    fontWeight: 800,
    color: theme.palette.secondary.dark,
  },
  patrolInfoWrapper: {
    width: "32%",
    paddingRight: 30,
  },
  subTitle: {
    fontSize: 15,
    marginBottom: 10,
  },
  patrolEventWrapper: {
    padding: "8 16",
    border: `1 solid ${theme.palette.secondary.main}`,
    marginBottom: 7,
    borderRadius: 2,
  },
  patrolEventHeader: {
    flexDirection: "row",
    marginBottom: 10,
  },
  patrolEventInfoWrapper: {
    width: "32%",
    paddingRight: 30,
  },
  patrolEventDescription: {
    minHeight: 35,
    border: `1 solid ${theme.palette.grey[200]}`,
    padding: "4 6",
    marginBottom: 3,
  },
});

export default function PatrolPdf({
  patrolData,
  patrolEvents,
  t,
  companyName,
  workplaceName,
}: Props): JSX.Element {
  const startDateDisplay = moment(patrolData.startDate).format(
    SHORT_DATE_FORMAT_WITH_HOUR
  );
  const endDateDisplay = patrolData.endDate
    ? moment(patrolData.endDate).format(SHORT_DATE_FORMAT_WITH_HOUR)
    : "";

  const patrollers = patrolData.assignees?.length
    ? patrolData.assignees.map((a) => generateUserNameString(a)).join(", ")
    : "-";

  const patrolInfos = [
    {
      headerText: t("Patrol location"),
      value: patrolData.location || "-",
    },
    {
      headerText: t("Patrollers"),
      value: patrollers,
    },
    {
      headerText: t("Patrol description"),
      value: patrolData.description || "-",
    },
  ];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.caption}>
          <Text
            style={{
              fontSize: 8,
              color: theme.palette.secondary.main,
            }}
          >
            {t("Generated in rids.app")}
          </Text>
        </View>
        <View style={styles.companyInfo}>
          <View>
            <Text>{companyName()}</Text>
            <Text>{workplaceName()}</Text>
          </View>
        </View>

        <View style={styles.title}>
          <Text>{t("Patrol")}</Text>
        </View>
        <View style={styles.titleDate}>
          <Text>{startDateDisplay}</Text>

          <Text style={{ margin: "0 15" }}>{endDateDisplay && t("to")}</Text>

          <Text>{endDateDisplay && ` ${endDateDisplay}`}</Text>
        </View>

        <View style={styles.divider} />

        <View style={styles.patrolInfoSection}>
          {patrolInfos.map((value) => (
            <View key={value.headerText + value.value} style={styles.patrolInfoWrapper}>
              <Text style={styles.patrolInfoHeader}>{value.headerText}:</Text>
              <Text>{value.value}</Text>
            </View>
          ))}
        </View>

        <View style={styles.divider} />

        <View>
          <Text style={styles.subTitle}>{t("Patrol events")}:</Text>
        </View>
        {!patrolEvents.length && <Text>{t("This patrol has no events reported")}</Text>}
        {Boolean(patrolEvents.length) && (
          <View>
            {patrolEvents.map((event) => (
              <View
                key={`pdf-event${event.id}`}
                wrap={false}
                style={styles.patrolEventWrapper}
              >
                <View style={styles.patrolEventHeader}>
                  <View style={styles.patrolEventInfoWrapper}>
                    <Text style={styles.patrolInfoHeader}>{t("Event date")}:</Text>
                    <Text>
                      {moment(event.eventDate).format(SHORT_DATE_FORMAT_WITH_HOUR)}
                    </Text>
                  </View>

                  <View style={styles.patrolEventInfoWrapper}>
                    <Text style={styles.patrolInfoHeader}>{t("Event name")}:</Text>
                    <Text>{event.name || "-"}</Text>
                  </View>

                  <View style={styles.patrolEventInfoWrapper}>
                    <Text style={styles.patrolInfoHeader}>{t("Location")}:</Text>
                    <Text>{event.location || "-"}</Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.patrolInfoHeader}>{t("Event description")}</Text>
                </View>
                <View style={styles.patrolEventDescription}>
                  <Text>{event.description || ""}</Text>
                </View>
                <View style={styles.patrolInfoHeader}>
                  <Text>{t("Action taken")}</Text>
                </View>
                <View style={styles.patrolEventDescription}>
                  <Text>{event.action || ""}</Text>
                </View>
              </View>
            ))}
          </View>
        )}
      </Page>
    </Document>
  );
}
