import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { useUpdateTaskDescriptionMutation } from "../../graphql/mutation.generated";

interface Props {
  description: string;
  taskId: string;
  disabled?: boolean;
}

export default function TaskDescription({
  description,
  taskId,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [descriptionState, setDescriptionState] = useState<string>(description || "");

  const [
    updateTaskDescriptionMutation,
    { error: updateDescriptionError },
  ] = useUpdateTaskDescriptionMutation();

  useEffect(() => {
    if (description) {
      setDescriptionState(description);
    }
  }, [description]);

  const changeDescription = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDescriptionState(event.target.value);
  };

  const onDescriptionBlur = async (
    event: React.FocusEvent<HTMLInputElement>
  ): Promise<void> => {
    const newDescription = event.target.value;
    if (newDescription !== description) {
      try {
        await updateTaskDescriptionMutation({
          variables: {
            updateTaskDescriptionData: {
              id: taskId,
              description: newDescription,
            },
          },
        });
      } catch (e) {
        //
      }
    }
  };

  return (
    <>
      <TextField
        disabled={disabled}
        multiline
        fullWidth
        value={descriptionState}
        onChange={changeDescription}
        onBlur={onDescriptionBlur}
        placeholder={`${t("Add description")}`}
        InputProps={{ disableUnderline: true }}
      />
      <ErrorDisplay
        error={updateDescriptionError}
        message={t(
          "Error occured while updating task description, try again or contact us."
        )}
      />
    </>
  );
}
