import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import TaskCategoriesSelect from "../../common/TaskSelectFields/TaskCategoriesSelect";
import { useChangeTaskCategoriesMutation } from "../../graphql/mutation.generated";

interface Props {
  categoriesIds: string[];
  taskId: string;
  disabled?: boolean;
}
export default function TaskCategories({
  categoriesIds,
  taskId,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [
    changeTaskCategoriesMutation,
    { error: categoriesChangeError },
  ] = useChangeTaskCategoriesMutation();

  const onCategoriesChange = async (value: string[]): Promise<void> => {
    try {
      await changeTaskCategoriesMutation({
        variables: {
          changeTaskCategoriesData: {
            categoriesIds: value,
            id: taskId,
          },
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <>
      <TaskCategoriesSelect
        onCategoriesChange={(ids): Promise<void> => onCategoriesChange(ids)}
        categoriesIds={categoriesIds}
        disabled={disabled}
      />
      <ErrorDisplay
        error={categoriesChangeError}
        message={t(
          "Error occured while changing categories, please try again or contact us."
        )}
      />
    </>
  );
}
