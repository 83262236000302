import { Box, IconButton, Tooltip } from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { useUpdateTaskIsArchivedMutation } from "../../graphql/mutation.generated";

interface Props {
  isTaskArchived: boolean;
  taskId: string;
}

export default function TaskArchiveButton({
  isTaskArchived,
  taskId,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [
    archiveTaskMutation,
    { loading: archiveTaskLoading, error: archiveTaskError },
  ] = useUpdateTaskIsArchivedMutation();

  const onArchiveChange = async (): Promise<void> => {
    try {
      await archiveTaskMutation({
        variables: {
          updateTaskIsArchivedData: {
            id: taskId,
            isArchived: !isTaskArchived,
          },
        },
        update: (cache, res) => {
          if (res.data?.updateTaskIsArchived.isArchived) {
            let archivedTaskRef;
            cache.modify({
              fields: {
                getWorkplaceTasks(existingTasks = [], { readField }) {
                  archivedTaskRef = existingTasks.find(
                    (taskRef) => taskId === readField("id", taskRef)
                  );
                  return existingTasks.filter(
                    (taskRef) => taskId !== readField("id", taskRef)
                  );
                },
              },
            });
            cache.modify({
              fields: {
                getWorkplaceArchivedTasks(existingArchivedTasks = {}) {
                  if (
                    archivedTaskRef &&
                    existingArchivedTasks.items &&
                    existingArchivedTasks.items.length
                  ) {
                    return {
                      ...existingArchivedTasks,
                      items: [archivedTaskRef, ...existingArchivedTasks.items],
                    };
                  }
                  return existingArchivedTasks;
                },
              },
            });
          }

          if (
            res.data?.updateTaskIsArchived &&
            !res.data.updateTaskIsArchived.isArchived
          ) {
            let unArchivedTaskRef;
            cache.modify({
              fields: {
                getWorkplaceArchivedTasks(existingArchivedTasks, { readField }) {
                  unArchivedTaskRef = existingArchivedTasks.items.find(
                    (taskRef) => taskId === readField("id", taskRef)
                  );
                  return {
                    ...existingArchivedTasks,
                    items: existingArchivedTasks.items.filter(
                      (taskRef) => taskId !== readField("id", taskRef)
                    ),
                  };
                },
              },
            });
            cache.modify({
              fields: {
                getWorkplaceTasks(existingTasks = []) {
                  if (unArchivedTaskRef) {
                    return [...existingTasks, unArchivedTaskRef];
                  }
                  return existingTasks;
                },
              },
            });
          }
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <>
      {isTaskArchived ? (
        <Tooltip title={t("Unarchive task") || ""}>
          <Box>
            <IconButton
              disabled={archiveTaskLoading}
              onClick={(): Promise<void> => onArchiveChange()}
            >
              <UnarchiveIcon />
            </IconButton>
          </Box>
        </Tooltip>
      ) : (
        <Tooltip title={t("Archive task") || ""}>
          <Box>
            <IconButton
              disabled={archiveTaskLoading}
              onClick={(): Promise<void> => onArchiveChange()}
            >
              <ArchiveIcon />
            </IconButton>
          </Box>
        </Tooltip>
      )}
      <ErrorDisplay
        error={archiveTaskError}
        message={t("Error occured while archiving task, please try again or contact us.")}
      />
    </>
  );
}
