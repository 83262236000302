import { Box } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDateTimePicker from "../../../../../components/CustomDateTimePicker/CustomDateTimePicker";
import EditableInputAdornment from "../../../../../components/EditableInputAdornment/EditableInputAdornment";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";
import { useUpdatePatrolEventEventDateMutation } from "../../../graphql/mutation.generated";

interface Props {
  eventDate: string;
  disabled?: boolean;
  patrolEventId: string;
}
export default function PatrolEventEventDate({
  eventDate,
  patrolEventId,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [
    updateEventDateMutation,
    { loading, error },
  ] = useUpdatePatrolEventEventDateMutation();

  const onEventDateChange = async (date: MaterialUiPickersDate): Promise<void> => {
    if (date) {
      const newValue = moment(date).toISOString();
      if (newValue !== eventDate) {
        try {
          await updateEventDateMutation({
            variables: {
              updatePatrolEventEventDateData: { eventDate: newValue, id: patrolEventId },
            },
          });
        } catch (e) {
          //
        }
      }
    }
  };
  return (
    <>
      <Box display="flex">
        <CustomDateTimePicker
          disabled={disabled || loading}
          placeholder={t("Pick patrol start date")}
          value={eventDate}
          onChange={(e): Promise<void> => onEventDateChange(e)}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <EditableInputAdornment display={Boolean(eventDate) && !disabled} />
            ),
          }}
          size="small"
        />
      </Box>
      <ErrorDisplay
        error={error}
        codes={{
          BAD_USER_INPUT: t(
            "Provided date is incorrect, please check if event date does not exceed patrol dates."
          ),
        }}
        message={t(
          "Error occured while changing event date, please try again or contact us"
        )}
      />
    </>
  );
}
