import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import TaskLabelsSelect from "../../common/TaskSelectFields/TaskLabelsSelect";
import { useChangeTaskLabelsMutation } from "../../graphql/mutation.generated";

interface Props {
  labelsIds: string[];
  taskId: string;
  disabled?: boolean;
}
export default function TaskLabels({ labelsIds, taskId, disabled }: Props): JSX.Element {
  const { t } = useTranslation();

  const [
    changeTaskLabelsMutation,
    { error: labelsChangeError },
  ] = useChangeTaskLabelsMutation();

  const onLabelsChange = async (value: string[]): Promise<void> => {
    try {
      await changeTaskLabelsMutation({
        variables: {
          changeTaskLabelsData: {
            labelsIds: value,
            id: taskId,
          },
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <>
      <TaskLabelsSelect
        labelsIds={labelsIds}
        onLabelsChange={(l): Promise<void> => onLabelsChange(l)}
        disabled={disabled}
      />
      <ErrorDisplay
        error={labelsChangeError}
        message={t(
          "Error occured while changing labels, please try again or contact us."
        )}
      />
    </>
  );
}
