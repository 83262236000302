import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { TaskStatus } from "../../../../generated/types";
import theme from "../../../../utils/theme";
import { TaskListDataFragment } from "../../graphql/fragments.generated";
import useOpenTaskDialog from "../../TaskDetailsDialog/useOpenTaskDialog";
import TaskGroupHeader from "../common/TaskGroupHeader";
import useTasksBoardDataHandler from "../common/useTasksBoardDataHandler";
import PerfSingleTask from "./PerfSingleTask";

interface Props {
  statuses: TaskStatus[];
  tasks: TaskListDataFragment[];
  workplaceId: string;
}

const useStyles = makeStyles({
  droppableStyle: {
    width: "100%",
    paddingRight: "1rem",
    boxSizing: "border-box",
    marginTop: "1rem",
  },
  content: {
    overflowY: "scroll",
    maxHeight: `calc(100% - ${theme.spacing(8)}px)`,
    height: `calc(100% - ${theme.spacing(8)}px)`,
  },
});

export default function TaskList({ statuses, tasks, workplaceId }: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openTaskDialog } = useOpenTaskDialog();
  const {
    boardData,
    changeGroupCollapse,
    changeTaskStatusError,
  } = useTasksBoardDataHandler({
    statuses,
    tasks,
  });

  return (
    <Box className={classes.content}>
      {boardData.map((group, index) => (
        <div key={`group-list-${group.groupId}`}>
          <TaskGroupHeader
            isCollapsed={group.collapsed}
            onCollapseChange={(): void => changeGroupCollapse(group.groupId, index)}
            onTaskOpen={openTaskDialog}
            taskData={group.parentTask}
          />
          {!group.collapsed && (
            <Box pb={1.5} className={classes.droppableStyle}>
              {statuses.map((statusData) =>
                group.tasks[statusData.id].map((taskCardData: TaskListDataFragment) => (
                  <PerfSingleTask
                    key={`listsingletask${taskCardData.id}`}
                    task={taskCardData}
                    workplaceId={workplaceId}
                    isChild={group.groupId !== "unassigned"}
                  />
                ))
              )}
            </Box>
          )}
        </div>
      ))}
      <ErrorDisplay
        error={changeTaskStatusError}
        message={t(
          "Error occured while changing task data, please try again or contact us."
        )}
      />
    </Box>
  );
}
