import { Box, makeStyles } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { TaskStatus } from "../../../../generated/types";
import theme, { black15Opacity } from "../../../../utils/theme";
import { TaskListDataFragment } from "../../graphql/fragments.generated";
import useOpenTaskDialog from "../../TaskDetailsDialog/useOpenTaskDialog";
import TaskGroupHeader from "../common/TaskGroupHeader";
import useTasksBoardDataHandler from "../common/useTasksBoardDataHandler";
import ColumnHeader from "./ColumnHeader/ColumnHeader";
import ColumnSpacing from "./ColumnSpacing/ColumnSpacing";
import TaskCard from "./TaskCard/TaskCard";

interface Props {
  statuses: TaskStatus[];
  tasks: TaskListDataFragment[];
}

const useStyles = makeStyles({
  topColumns: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: theme.spacing(3),
  },
  content: {
    overflowY: "scroll",
    maxHeight: `calc(100% - ${theme.spacing(13.5)}px)`,
    height: `calc(100% - ${theme.spacing(13.5)}px)`,
    paddingRight: theme.spacing(1),
    overflowX: "hidden",
  },
  droppable: {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    backgroundColor: black15Opacity,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`,
    borderRadius: `${theme.shape.borderRadius}px`,
  },

  groupColumnsWrapper: {
    paddingBottom: theme.spacing(1.5),
    display: "flex",
    justifyContent: "space-between",
  },
});

export default function Kanban({ statuses, tasks }: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openTaskDialog } = useOpenTaskDialog();
  // due to some css shit, this topColumnsFullWidth is not taken, when there is X overflow on whole container, I took single value from each column than and calculate tableTotalWidth
  const { width: topColumnsFullWidth, ref } = useResizeDetector();

  const [columnWidth, setColumnWidth] = useState<number>(0);

  const {
    boardData,
    changeBoardAfterDraging,
    changeGroupCollapse,
    changeTaskStatusError,
  } = useTasksBoardDataHandler({
    statuses,
    tasks,
  });

  const onDragEnd = (result: DropResult): void => {
    changeBoardAfterDraging(result);
  };

  const onResize = (width?: number): void => {
    if (width) {
      setColumnWidth(width);
    }
  };

  const tableTotalWidth = useMemo((): string => {
    if (columnWidth && topColumnsFullWidth) {
      // 7 is a 7px margin between columns in ColumnSpacing component
      const widthOnBasisOfSingleColumn = (columnWidth + 7) * statuses.length;
      if (widthOnBasisOfSingleColumn < topColumnsFullWidth) {
        return `${topColumnsFullWidth + theme.spacing(2)}px`;
      }
      return `${widthOnBasisOfSingleColumn}px`;
    }
    return "100%";
  }, [columnWidth, topColumnsFullWidth, statuses.length]);

  return (
    <>
      <div ref={ref} className={classes.topColumns}>
        {statuses.map((column, i) => (
          <ColumnSpacing
            onResize={i === 0 ? onResize : undefined}
            key={`columnHead-${column.id}`}
          >
            <ColumnHeader title={column.name} />
          </ColumnSpacing>
        ))}
      </div>
      <div className={classes.content} style={{ width: tableTotalWidth }}>
        <Box pb={3}>
          {boardData.map((group, index) => (
            <div key={`group-${group.groupId}`}>
              <TaskGroupHeader
                isCollapsed={group.collapsed}
                onCollapseChange={(): void => changeGroupCollapse(group.groupId, index)}
                onTaskOpen={openTaskDialog}
                taskData={group.parentTask}
              />
              {!group.collapsed && (
                <div className={classes.groupColumnsWrapper}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    {Object.keys(group.tasks).map((statusId) => (
                      <ColumnSpacing key={statusId}>
                        <Droppable droppableId={`${group.groupId}status${statusId}`}>
                          {(providedDroppable /* snapshotDroppable */): JSX.Element => (
                            <div
                              className={classes.droppable}
                              ref={providedDroppable.innerRef}
                              {...providedDroppable.droppableProps}
                            >
                              {group.tasks[statusId].map(
                                (taskCardData: TaskListDataFragment, draggableIndex) => (
                                  <Draggable
                                    key={taskCardData.id}
                                    draggableId={taskCardData.id}
                                    index={draggableIndex}
                                  >
                                    {(
                                      providedDraggable /* snapshotDraggable */
                                    ): JSX.Element => (
                                      <div
                                        ref={providedDraggable.innerRef}
                                        {...providedDraggable.draggableProps}
                                        {...providedDraggable.dragHandleProps}
                                      >
                                        <TaskCard
                                          taskData={taskCardData}
                                          isChild={group.groupId !== "unassigned"}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              )}
                              {providedDroppable.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </ColumnSpacing>
                    ))}
                  </DragDropContext>
                </div>
              )}
            </div>
          ))}
          <ErrorDisplay
            error={changeTaskStatusError}
            message={t(
              "Error occured while changing task data, please try again or contact us."
            )}
          />
        </Box>
      </div>
    </>
  );
}
