import { ExitToApp } from "@material-ui/icons";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import classnames from "classnames";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { UserContext } from "../../context/UserContext/UserContextProvider";
import { Permissions } from "../../generated/types";
import { ACCOUNT_SETTINGS, ADMIN_PANEL, SETTINGS, SIGN_IN } from "../../routing/paths";
import useLazyIsUserAuthorised from "../../utils/AuthUtils/useLazyIsUserAuthorised";
import useLogout from "../../utils/AuthUtils/useLogout";
import theme from "../../utils/theme";
import {
  useGetUnreadNotificationsCountQuery,
  useGetUserFirstNameQuery,
} from "../graphql/query.generated";
import {
  Box,
  CircularProgress,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Notifications from "../../views/Notifications/Notifications";

interface MenuLink {
  name: string;
  url: string;
  icon: () => JSX.Element;
}

const useStyles = makeStyles({
  navLink: {
    display: "block",
    color: `#fff !important`,

    "&.active": {
      color: `${theme.palette.primary.main} !important`,
      opacity: `1 !important`,
    },
    cursor: "pointer",
  },
  navLinkActive: {
    "& > *": {
      color: `${theme.palette.primary.main} !important`,
      opacity: `1 !important`,
    },
  },
  menuItemWrapper: {
    "&:hover > *": {
      color: theme.palette.primary.light,
    },
  },
  icon: {
    marginRight: theme.spacing(1.5),
  },
  logoutIcon: {
    cursor: "pointer",
    color: "#fff",
    "&:hover": {
      color: theme.palette.secondary.light,
    },
  },
});

export default function BottomMenu(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [logout] = useLogout();
  const { isUserAuthorised } = useLazyIsUserAuthorised();
  const { permissions } = useContext(UserContext);

  const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);

  const { data: notificationsCountData } = useGetUnreadNotificationsCountQuery({
    pollInterval: 60000,
  });

  const notificationsCount = useMemo<number>(() => {
    if (notificationsCountData?.getUnreadNotificationsCount) {
      return notificationsCountData.getUnreadNotificationsCount;
    }
    return 0;
  }, [notificationsCountData]);

  const { data, loading } = useGetUserFirstNameQuery();

  const logoutUser = (): void => {
    logout();
    history.push(SIGN_IN);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <div>
      {Boolean(permissions?.systemPermissions.length) && (
        <NavLink to={ADMIN_PANEL} className={classes.navLink}>
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            className={classes.menuItemWrapper}
          >
            <Box>
              <SupervisorAccountIcon className={classes.icon} />
            </Box>
            <Typography variant="body2">Admin panel</Typography>
          </Box>
        </NavLink>
      )}
      {isUserAuthorised(Permissions.CreateTerm) && (
        <NavLink to={SETTINGS} className={classes.navLink}>
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            className={classes.menuItemWrapper}
          >
            <Box>
              <SettingsIcon className={classes.icon} />
            </Box>
            <Typography variant="body2">{t("Settings")}</Typography>
          </Box>
        </NavLink>
      )}

      <Box className={classes.navLink} onClick={(): void => setNotificationsOpen(true)}>
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          className={classes.menuItemWrapper}
        >
          <Box>
            <NotificationsIcon className={classes.icon} />
          </Box>
          <Typography variant="body2">{`${t("Notifications")} ${
            notificationsCount ? `(${notificationsCount})` : ""
          }`}</Typography>
        </Box>
      </Box>
      <Notifications
        open={notificationsOpen}
        onClose={(): void => setNotificationsOpen(false)}
      />

      <Box display="flex" justifyContent="space-between">
        <NavLink to={ACCOUNT_SETTINGS} className={classes.navLink}>
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            className={classes.menuItemWrapper}
          >
            <AccountCircleIcon className={classes.icon} />

            <Typography variant="body2">
              {data?.getUser.firstName ? data?.getUser.firstName : ""}
            </Typography>
          </Box>
        </NavLink>
        <Box onClick={logoutUser}>
          <Tooltip title={t("Logout") || ""} placement="right">
            <ExitToApp className={classnames(classes.icon, classes.logoutIcon)} />
          </Tooltip>
        </Box>
      </Box>
    </div>
  );
}
