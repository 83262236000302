import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EditableInputAdornment from "../../../../../components/EditableInputAdornment/EditableInputAdornment";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";
import { useUpdatePatrolEventNameMutation } from "../../../graphql/mutation.generated";

interface Props {
  patrolEventName?: string;
  patrolEventId: string;
  disabled?: boolean;
}

export default function PatrolEventName({
  patrolEventId,
  patrolEventName,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [name, setName] = useState<string>("");

  const [
    updatePatrolEventNameMutation,
    { loading, error },
  ] = useUpdatePatrolEventNameMutation();

  useEffect(() => {
    if (patrolEventName) {
      setName(patrolEventName);
    }
  }, [patrolEventName]);

  const changeNameState = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value);
  };

  const onNameBlur = async (event: React.FocusEvent<HTMLInputElement>): Promise<void> => {
    const newName = event.target.value;
    if (newName !== patrolEventName) {
      try {
        updatePatrolEventNameMutation({
          variables: { updatePatrolEventNameData: { id: patrolEventId, name: newName } },
        });
      } catch (e) {
        //
      }
    }
  };

  return (
    <>
      <TextField
        fullWidth
        multiline
        InputProps={{
          disableUnderline: true,
          startAdornment: <EditableInputAdornment display={Boolean(name) && !disabled} />,
        }}
        value={name}
        placeholder={t("Add event title")}
        onBlur={onNameBlur}
        onChange={changeNameState}
        disabled={disabled || Boolean(loading)}
      />
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while updating patrol event name, try again or contact us."
        )}
      />
    </>
  );
}
