import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import theme from "../../utils/theme";

interface Props {
  name: string;
  size?: "medium" | "small";
}

const getInitials = (name: string): string => {
  if (name.split(" ").length === 1) {
    return name.split("")[0];
  }
  const firstLetter = name.split(" ")[0].split("")[0];
  const secondLetter = name.split(" ")[1].split("")[0];
  return firstLetter + secondLetter;
};

const useStyles = makeStyles({
  txtWrapper: {
    transform: "translate(-50%,-50%)",
    top: "50%",
    left: "50%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.dark,
    position: "relative",
  },
});

export default function UserIcon({ name, size }: Props): JSX.Element {
  const classes = useStyles();
  const sizeValue = size === "small" ? "0.9rem" : "1.5rem";

  return (
    <Tooltip title={name} placement="right-end" arrow>
      <div
        className={classes.wrapper}
        style={{
          width: sizeValue,
          height: sizeValue,
        }}
      >
        <div className={classes.txtWrapper}>
          <Typography
            variant="caption"
            style={{
              fontSize: size === "small" ? "0.5rem" : theme.typography.caption.fontSize,
            }}
          >
            {getInitials(name).toUpperCase()}
          </Typography>
        </div>
      </div>
    </Tooltip>
  );
}
