import * as Types from "../../../generated/types";

import { gql } from "@apollo/client";
export type NotificationFragment = {
  __typename?: "Notification";
  id: any;
  read: boolean;
  sourceType: Types.NotificationSourceType;
  type: Types.NotificationType;
  updatedAt: any;
  workplace?: Types.Maybe<{ __typename?: "Workplace"; id: any }>;
  sourceUser?: Types.Maybe<{
    __typename?: "User";
    id: any;
    firstName?: Types.Maybe<string>;
    lastName?: Types.Maybe<string>;
  }>;
  patrol?: Types.Maybe<{
    __typename?: "Patrol";
    id: any;
    startDate: any;
    endDate?: Types.Maybe<any>;
    location?: Types.Maybe<string>;
  }>;
  task?: Types.Maybe<{
    __typename?: "Task";
    id: any;
    name: string;
    friendlyId: number;
    dueDate?: Types.Maybe<any>;
    status: { __typename?: "TaskStatus"; id: any; name: string };
  }>;
  customData?: Types.Maybe<{
    __typename?: "NotificationCustomData";
    commentId?: Types.Maybe<any>;
  }>;
};

export const NotificationFragmentDoc = gql`
  fragment Notification on Notification {
    id
    workplace {
      id
    }
    sourceUser {
      id
      firstName
      lastName
    }
    patrol {
      id
      startDate
      endDate
      location
    }
    task {
      id
      name
      friendlyId
      dueDate
      status {
        id
        name
      }
    }
    read
    sourceType
    type
    updatedAt
    customData {
      commentId
    }
  }
`;
