import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EditableInputAdornment from "../../../../../components/EditableInputAdornment/EditableInputAdornment";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";
import { useUpdatePatrolEventLocationMutation } from "../../../graphql/mutation.generated";

interface Props {
  patrolEventId: string;
  eventLocation?: string;
  disabled?: boolean;
}
export default function PatrolEventLocation({
  eventLocation,
  patrolEventId,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [locationState, setLocationState] = useState<string | undefined>(eventLocation);

  const [
    updateLocationMutation,
    { error, loading },
  ] = useUpdatePatrolEventLocationMutation();

  const onLocationChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setLocationState(event.target.value);
  };

  const onLocationBlur = async (): Promise<void> => {
    if (locationState !== eventLocation) {
      try {
        await updateLocationMutation({
          variables: {
            updatePatrolEventLocationData: {
              id: patrolEventId,
              location: locationState,
            },
          },
        });
      } catch (e) {
        //
      }
    }
  };

  return (
    <>
      <TextField
        fullWidth
        multiline
        value={locationState}
        onChange={onLocationChange}
        onBlur={onLocationBlur}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <EditableInputAdornment display={Boolean(locationState) && !disabled} />
          ),
        }}
        placeholder={t("Add location")}
        disabled={disabled || loading}
      />
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing location, please try again or contact us."
        )}
      />
    </>
  );
}
