import * as Types from "../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type GetUserFirstNameQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetUserFirstNameQuery = {
  __typename?: "Query";
  getUser: { __typename?: "User"; id: any; firstName?: Types.Maybe<string> };
};

export type GetUnreadNotificationsCountQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetUnreadNotificationsCountQuery = {
  __typename?: "Query";
  getUnreadNotificationsCount: number;
};

export const GetUserFirstNameDocument = gql`
  query GetUserFirstName {
    getUser {
      id
      firstName
    }
  }
`;

/**
 * __useGetUserFirstNameQuery__
 *
 * To run a query within a React component, call `useGetUserFirstNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFirstNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFirstNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserFirstNameQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserFirstNameQuery,
    GetUserFirstNameQueryVariables
  >
) {
  return Apollo.useQuery<GetUserFirstNameQuery, GetUserFirstNameQueryVariables>(
    GetUserFirstNameDocument,
    baseOptions
  );
}
export function useGetUserFirstNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserFirstNameQuery,
    GetUserFirstNameQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetUserFirstNameQuery, GetUserFirstNameQueryVariables>(
    GetUserFirstNameDocument,
    baseOptions
  );
}
export type GetUserFirstNameQueryHookResult = ReturnType<typeof useGetUserFirstNameQuery>;
export type GetUserFirstNameLazyQueryHookResult = ReturnType<
  typeof useGetUserFirstNameLazyQuery
>;
export type GetUserFirstNameQueryResult = Apollo.QueryResult<
  GetUserFirstNameQuery,
  GetUserFirstNameQueryVariables
>;
export const GetUnreadNotificationsCountDocument = gql`
  query GetUnreadNotificationsCount {
    getUnreadNotificationsCount
  }
`;

/**
 * __useGetUnreadNotificationsCountQuery__
 *
 * To run a query within a React component, call `useGetUnreadNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadNotificationsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnreadNotificationsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUnreadNotificationsCountQuery,
    GetUnreadNotificationsCountQueryVariables
  >
) {
  return Apollo.useQuery<
    GetUnreadNotificationsCountQuery,
    GetUnreadNotificationsCountQueryVariables
  >(GetUnreadNotificationsCountDocument, baseOptions);
}
export function useGetUnreadNotificationsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnreadNotificationsCountQuery,
    GetUnreadNotificationsCountQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetUnreadNotificationsCountQuery,
    GetUnreadNotificationsCountQueryVariables
  >(GetUnreadNotificationsCountDocument, baseOptions);
}
export type GetUnreadNotificationsCountQueryHookResult = ReturnType<
  typeof useGetUnreadNotificationsCountQuery
>;
export type GetUnreadNotificationsCountLazyQueryHookResult = ReturnType<
  typeof useGetUnreadNotificationsCountLazyQuery
>;
export type GetUnreadNotificationsCountQueryResult = Apollo.QueryResult<
  GetUnreadNotificationsCountQuery,
  GetUnreadNotificationsCountQueryVariables
>;
