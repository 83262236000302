import * as Types from "../../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type CreateWorkplaceMutationVariables = Types.Exact<{
  workplaceData: Types.CreateWorkplaceData;
}>;

export type CreateWorkplaceMutation = {
  __typename?: "Mutation";
  createWorkplace: { __typename?: "Workplace"; id: any; name: string; shortName: string };
};

export type CreateLabelMutationVariables = Types.Exact<{
  createLabelData: Types.CreateLabelData;
}>;

export type CreateLabelMutation = {
  __typename?: "Mutation";
  createLabel: { __typename?: "Label"; id: any; name: string };
};

export type UpdateLabelMutationVariables = Types.Exact<{
  updateLabelData: Types.UpdateLabelData;
}>;

export type UpdateLabelMutation = {
  __typename?: "Mutation";
  updateLabel: { __typename?: "Label"; id: any; name: string };
};

export type CreateCategoryMutationVariables = Types.Exact<{
  createCategoryData: Types.CreateCategoryData;
}>;

export type CreateCategoryMutation = {
  __typename?: "Mutation";
  createCategory: { __typename?: "Category"; id: any; name: string };
};

export type UpdateCategoryMutationVariables = Types.Exact<{
  updateCategoryData: Types.UpdateCategoryData;
}>;

export type UpdateCategoryMutation = {
  __typename?: "Mutation";
  updateCategory: { __typename?: "Category"; id: any; name: string };
};

export type AddUserToCompanyMutationVariables = Types.Exact<{
  addUserToCompanyData: Types.AddUserToCompanyData;
}>;

export type AddUserToCompanyMutation = {
  __typename?: "Mutation";
  addUserToCompany: { __typename?: "UserCompanyRole"; id: any };
};

export type AddUserToWorkplaceMutationVariables = Types.Exact<{
  addUserToWorkplaceData: Types.AddUserToWorkplaceData;
}>;

export type AddUserToWorkplaceMutation = {
  __typename?: "Mutation";
  addUserToWorkplace: { __typename?: "UserWorkplaceRole"; id: any };
};

export type ChangeUserWorkplaceRoleMutationVariables = Types.Exact<{
  changeUserWorkplaceRoleData: Types.ChangeUserWorkplaceRoleData;
}>;

export type ChangeUserWorkplaceRoleMutation = {
  __typename?: "Mutation";
  changeUserWorkplaceRole: {
    __typename?: "UserWorkplaceRole";
    id: any;
    workplaceRole: { __typename?: "WorkplaceRole"; id: any; name: string };
    workplace: { __typename?: "Workplace"; id: any };
  };
};

export type ChangeUserCompanyRoleMutationVariables = Types.Exact<{
  changeUserCompanyRoleData: Types.ChangeUserCompanyRoleData;
}>;

export type ChangeUserCompanyRoleMutation = {
  __typename?: "Mutation";
  changeUserCompanyRole: {
    __typename?: "UserCompanyRole";
    id: any;
    companyRole: { __typename?: "CompanyRole"; id: any; name: string };
    company: { __typename?: "Company"; id: any };
  };
};

export type UpdateWorkplaceMutationVariables = Types.Exact<{
  updateWorkplaceData: Types.UpdateWorkplaceData;
}>;

export type UpdateWorkplaceMutation = {
  __typename?: "Mutation";
  updateWorkplace: { __typename?: "Workplace"; id: any; name: string; shortName: string };
};

export type DeleteCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars["UUID"];
}>;

export type DeleteCategoryMutation = { __typename?: "Mutation"; deleteCategory: boolean };

export type DeleteLabelMutationVariables = Types.Exact<{
  id: Types.Scalars["UUID"];
}>;

export type DeleteLabelMutation = { __typename?: "Mutation"; deleteLabel: boolean };

export type DeleteUserWorkplaceRoleMutationVariables = Types.Exact<{
  id: Types.Scalars["UUID"];
}>;

export type DeleteUserWorkplaceRoleMutation = {
  __typename?: "Mutation";
  deleteUserWorkplaceRole: boolean;
};

export type DeleteUserCompanyRoleMutationVariables = Types.Exact<{
  id: Types.Scalars["UUID"];
}>;

export type DeleteUserCompanyRoleMutation = {
  __typename?: "Mutation";
  deleteUserCompanyRole: boolean;
};

export type DeleteUserMutationVariables = Types.Exact<{
  email: Types.Scalars["EmailAddress"];
}>;

export type DeleteUserMutation = { __typename?: "Mutation"; deleteUser: boolean };

export const CreateWorkplaceDocument = gql`
  mutation CreateWorkplace($workplaceData: CreateWorkplaceData!) {
    createWorkplace(workplaceData: $workplaceData) {
      id
      name
      shortName
    }
  }
`;
export type CreateWorkplaceMutationFn = Apollo.MutationFunction<
  CreateWorkplaceMutation,
  CreateWorkplaceMutationVariables
>;

/**
 * __useCreateWorkplaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkplaceMutation, { data, loading, error }] = useCreateWorkplaceMutation({
 *   variables: {
 *      workplaceData: // value for 'workplaceData'
 *   },
 * });
 */
export function useCreateWorkplaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkplaceMutation,
    CreateWorkplaceMutationVariables
  >
) {
  return Apollo.useMutation<CreateWorkplaceMutation, CreateWorkplaceMutationVariables>(
    CreateWorkplaceDocument,
    baseOptions
  );
}
export type CreateWorkplaceMutationHookResult = ReturnType<
  typeof useCreateWorkplaceMutation
>;
export type CreateWorkplaceMutationResult = Apollo.MutationResult<CreateWorkplaceMutation>;
export type CreateWorkplaceMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkplaceMutation,
  CreateWorkplaceMutationVariables
>;
export const CreateLabelDocument = gql`
  mutation CreateLabel($createLabelData: CreateLabelData!) {
    createLabel(createLabelData: $createLabelData) {
      id
      name
    }
  }
`;
export type CreateLabelMutationFn = Apollo.MutationFunction<
  CreateLabelMutation,
  CreateLabelMutationVariables
>;

/**
 * __useCreateLabelMutation__
 *
 * To run a mutation, you first call `useCreateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLabelMutation, { data, loading, error }] = useCreateLabelMutation({
 *   variables: {
 *      createLabelData: // value for 'createLabelData'
 *   },
 * });
 */
export function useCreateLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLabelMutation,
    CreateLabelMutationVariables
  >
) {
  return Apollo.useMutation<CreateLabelMutation, CreateLabelMutationVariables>(
    CreateLabelDocument,
    baseOptions
  );
}
export type CreateLabelMutationHookResult = ReturnType<typeof useCreateLabelMutation>;
export type CreateLabelMutationResult = Apollo.MutationResult<CreateLabelMutation>;
export type CreateLabelMutationOptions = Apollo.BaseMutationOptions<
  CreateLabelMutation,
  CreateLabelMutationVariables
>;
export const UpdateLabelDocument = gql`
  mutation UpdateLabel($updateLabelData: UpdateLabelData!) {
    updateLabel(updateLabelData: $updateLabelData) {
      id
      name
    }
  }
`;
export type UpdateLabelMutationFn = Apollo.MutationFunction<
  UpdateLabelMutation,
  UpdateLabelMutationVariables
>;

/**
 * __useUpdateLabelMutation__
 *
 * To run a mutation, you first call `useUpdateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabelMutation, { data, loading, error }] = useUpdateLabelMutation({
 *   variables: {
 *      updateLabelData: // value for 'updateLabelData'
 *   },
 * });
 */
export function useUpdateLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLabelMutation,
    UpdateLabelMutationVariables
  >
) {
  return Apollo.useMutation<UpdateLabelMutation, UpdateLabelMutationVariables>(
    UpdateLabelDocument,
    baseOptions
  );
}
export type UpdateLabelMutationHookResult = ReturnType<typeof useUpdateLabelMutation>;
export type UpdateLabelMutationResult = Apollo.MutationResult<UpdateLabelMutation>;
export type UpdateLabelMutationOptions = Apollo.BaseMutationOptions<
  UpdateLabelMutation,
  UpdateLabelMutationVariables
>;
export const CreateCategoryDocument = gql`
  mutation CreateCategory($createCategoryData: CreateCategoryData!) {
    createCategory(createCategoryData: $createCategoryData) {
      id
      name
    }
  }
`;
export type CreateCategoryMutationFn = Apollo.MutationFunction<
  CreateCategoryMutation,
  CreateCategoryMutationVariables
>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      createCategoryData: // value for 'createCategoryData'
 *   },
 * });
 */
export function useCreateCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCategoryMutation,
    CreateCategoryMutationVariables
  >
) {
  return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(
    CreateCategoryDocument,
    baseOptions
  );
}
export type CreateCategoryMutationHookResult = ReturnType<
  typeof useCreateCategoryMutation
>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateCategoryMutation,
  CreateCategoryMutationVariables
>;
export const UpdateCategoryDocument = gql`
  mutation UpdateCategory($updateCategoryData: UpdateCategoryData!) {
    updateCategory(updateCategoryData: $updateCategoryData) {
      id
      name
    }
  }
`;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables
>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      updateCategoryData: // value for 'updateCategoryData'
 *   },
 * });
 */
export function useUpdateCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCategoryMutation,
    UpdateCategoryMutationVariables
  >
) {
  return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(
    UpdateCategoryDocument,
    baseOptions
  );
}
export type UpdateCategoryMutationHookResult = ReturnType<
  typeof useUpdateCategoryMutation
>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables
>;
export const AddUserToCompanyDocument = gql`
  mutation AddUserToCompany($addUserToCompanyData: AddUserToCompanyData!) {
    addUserToCompany(addUserToCompanyData: $addUserToCompanyData) {
      id
    }
  }
`;
export type AddUserToCompanyMutationFn = Apollo.MutationFunction<
  AddUserToCompanyMutation,
  AddUserToCompanyMutationVariables
>;

/**
 * __useAddUserToCompanyMutation__
 *
 * To run a mutation, you first call `useAddUserToCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToCompanyMutation, { data, loading, error }] = useAddUserToCompanyMutation({
 *   variables: {
 *      addUserToCompanyData: // value for 'addUserToCompanyData'
 *   },
 * });
 */
export function useAddUserToCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserToCompanyMutation,
    AddUserToCompanyMutationVariables
  >
) {
  return Apollo.useMutation<AddUserToCompanyMutation, AddUserToCompanyMutationVariables>(
    AddUserToCompanyDocument,
    baseOptions
  );
}
export type AddUserToCompanyMutationHookResult = ReturnType<
  typeof useAddUserToCompanyMutation
>;
export type AddUserToCompanyMutationResult = Apollo.MutationResult<AddUserToCompanyMutation>;
export type AddUserToCompanyMutationOptions = Apollo.BaseMutationOptions<
  AddUserToCompanyMutation,
  AddUserToCompanyMutationVariables
>;
export const AddUserToWorkplaceDocument = gql`
  mutation AddUserToWorkplace($addUserToWorkplaceData: AddUserToWorkplaceData!) {
    addUserToWorkplace(addUserToWorkplaceData: $addUserToWorkplaceData) {
      id
    }
  }
`;
export type AddUserToWorkplaceMutationFn = Apollo.MutationFunction<
  AddUserToWorkplaceMutation,
  AddUserToWorkplaceMutationVariables
>;

/**
 * __useAddUserToWorkplaceMutation__
 *
 * To run a mutation, you first call `useAddUserToWorkplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToWorkplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToWorkplaceMutation, { data, loading, error }] = useAddUserToWorkplaceMutation({
 *   variables: {
 *      addUserToWorkplaceData: // value for 'addUserToWorkplaceData'
 *   },
 * });
 */
export function useAddUserToWorkplaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserToWorkplaceMutation,
    AddUserToWorkplaceMutationVariables
  >
) {
  return Apollo.useMutation<
    AddUserToWorkplaceMutation,
    AddUserToWorkplaceMutationVariables
  >(AddUserToWorkplaceDocument, baseOptions);
}
export type AddUserToWorkplaceMutationHookResult = ReturnType<
  typeof useAddUserToWorkplaceMutation
>;
export type AddUserToWorkplaceMutationResult = Apollo.MutationResult<AddUserToWorkplaceMutation>;
export type AddUserToWorkplaceMutationOptions = Apollo.BaseMutationOptions<
  AddUserToWorkplaceMutation,
  AddUserToWorkplaceMutationVariables
>;
export const ChangeUserWorkplaceRoleDocument = gql`
  mutation ChangeUserWorkplaceRole(
    $changeUserWorkplaceRoleData: ChangeUserWorkplaceRoleData!
  ) {
    changeUserWorkplaceRole(changeUserWorkplaceRoleData: $changeUserWorkplaceRoleData) {
      id
      workplaceRole {
        id
        name
      }
      workplace {
        id
      }
    }
  }
`;
export type ChangeUserWorkplaceRoleMutationFn = Apollo.MutationFunction<
  ChangeUserWorkplaceRoleMutation,
  ChangeUserWorkplaceRoleMutationVariables
>;

/**
 * __useChangeUserWorkplaceRoleMutation__
 *
 * To run a mutation, you first call `useChangeUserWorkplaceRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserWorkplaceRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserWorkplaceRoleMutation, { data, loading, error }] = useChangeUserWorkplaceRoleMutation({
 *   variables: {
 *      changeUserWorkplaceRoleData: // value for 'changeUserWorkplaceRoleData'
 *   },
 * });
 */
export function useChangeUserWorkplaceRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUserWorkplaceRoleMutation,
    ChangeUserWorkplaceRoleMutationVariables
  >
) {
  return Apollo.useMutation<
    ChangeUserWorkplaceRoleMutation,
    ChangeUserWorkplaceRoleMutationVariables
  >(ChangeUserWorkplaceRoleDocument, baseOptions);
}
export type ChangeUserWorkplaceRoleMutationHookResult = ReturnType<
  typeof useChangeUserWorkplaceRoleMutation
>;
export type ChangeUserWorkplaceRoleMutationResult = Apollo.MutationResult<ChangeUserWorkplaceRoleMutation>;
export type ChangeUserWorkplaceRoleMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserWorkplaceRoleMutation,
  ChangeUserWorkplaceRoleMutationVariables
>;
export const ChangeUserCompanyRoleDocument = gql`
  mutation ChangeUserCompanyRole($changeUserCompanyRoleData: ChangeUserCompanyRoleData!) {
    changeUserCompanyRole(changeUserCompanyRoleData: $changeUserCompanyRoleData) {
      id
      companyRole {
        id
        name
      }
      company {
        id
      }
    }
  }
`;
export type ChangeUserCompanyRoleMutationFn = Apollo.MutationFunction<
  ChangeUserCompanyRoleMutation,
  ChangeUserCompanyRoleMutationVariables
>;

/**
 * __useChangeUserCompanyRoleMutation__
 *
 * To run a mutation, you first call `useChangeUserCompanyRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserCompanyRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserCompanyRoleMutation, { data, loading, error }] = useChangeUserCompanyRoleMutation({
 *   variables: {
 *      changeUserCompanyRoleData: // value for 'changeUserCompanyRoleData'
 *   },
 * });
 */
export function useChangeUserCompanyRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUserCompanyRoleMutation,
    ChangeUserCompanyRoleMutationVariables
  >
) {
  return Apollo.useMutation<
    ChangeUserCompanyRoleMutation,
    ChangeUserCompanyRoleMutationVariables
  >(ChangeUserCompanyRoleDocument, baseOptions);
}
export type ChangeUserCompanyRoleMutationHookResult = ReturnType<
  typeof useChangeUserCompanyRoleMutation
>;
export type ChangeUserCompanyRoleMutationResult = Apollo.MutationResult<ChangeUserCompanyRoleMutation>;
export type ChangeUserCompanyRoleMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserCompanyRoleMutation,
  ChangeUserCompanyRoleMutationVariables
>;
export const UpdateWorkplaceDocument = gql`
  mutation UpdateWorkplace($updateWorkplaceData: UpdateWorkplaceData!) {
    updateWorkplace(updateWorkplaceData: $updateWorkplaceData) {
      id
      name
      shortName
    }
  }
`;
export type UpdateWorkplaceMutationFn = Apollo.MutationFunction<
  UpdateWorkplaceMutation,
  UpdateWorkplaceMutationVariables
>;

/**
 * __useUpdateWorkplaceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkplaceMutation, { data, loading, error }] = useUpdateWorkplaceMutation({
 *   variables: {
 *      updateWorkplaceData: // value for 'updateWorkplaceData'
 *   },
 * });
 */
export function useUpdateWorkplaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkplaceMutation,
    UpdateWorkplaceMutationVariables
  >
) {
  return Apollo.useMutation<UpdateWorkplaceMutation, UpdateWorkplaceMutationVariables>(
    UpdateWorkplaceDocument,
    baseOptions
  );
}
export type UpdateWorkplaceMutationHookResult = ReturnType<
  typeof useUpdateWorkplaceMutation
>;
export type UpdateWorkplaceMutationResult = Apollo.MutationResult<UpdateWorkplaceMutation>;
export type UpdateWorkplaceMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkplaceMutation,
  UpdateWorkplaceMutationVariables
>;
export const DeleteCategoryDocument = gql`
  mutation DeleteCategory($id: UUID!) {
    deleteCategory(id: $id)
  }
`;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<
  DeleteCategoryMutation,
  DeleteCategoryMutationVariables
>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCategoryMutation,
    DeleteCategoryMutationVariables
  >
) {
  return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(
    DeleteCategoryDocument,
    baseOptions
  );
}
export type DeleteCategoryMutationHookResult = ReturnType<
  typeof useDeleteCategoryMutation
>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteCategoryMutation,
  DeleteCategoryMutationVariables
>;
export const DeleteLabelDocument = gql`
  mutation DeleteLabel($id: UUID!) {
    deleteLabel(id: $id)
  }
`;
export type DeleteLabelMutationFn = Apollo.MutationFunction<
  DeleteLabelMutation,
  DeleteLabelMutationVariables
>;

/**
 * __useDeleteLabelMutation__
 *
 * To run a mutation, you first call `useDeleteLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLabelMutation, { data, loading, error }] = useDeleteLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLabelMutation,
    DeleteLabelMutationVariables
  >
) {
  return Apollo.useMutation<DeleteLabelMutation, DeleteLabelMutationVariables>(
    DeleteLabelDocument,
    baseOptions
  );
}
export type DeleteLabelMutationHookResult = ReturnType<typeof useDeleteLabelMutation>;
export type DeleteLabelMutationResult = Apollo.MutationResult<DeleteLabelMutation>;
export type DeleteLabelMutationOptions = Apollo.BaseMutationOptions<
  DeleteLabelMutation,
  DeleteLabelMutationVariables
>;
export const DeleteUserWorkplaceRoleDocument = gql`
  mutation DeleteUserWorkplaceRole($id: UUID!) {
    deleteUserWorkplaceRole(id: $id)
  }
`;
export type DeleteUserWorkplaceRoleMutationFn = Apollo.MutationFunction<
  DeleteUserWorkplaceRoleMutation,
  DeleteUserWorkplaceRoleMutationVariables
>;

/**
 * __useDeleteUserWorkplaceRoleMutation__
 *
 * To run a mutation, you first call `useDeleteUserWorkplaceRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserWorkplaceRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserWorkplaceRoleMutation, { data, loading, error }] = useDeleteUserWorkplaceRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserWorkplaceRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserWorkplaceRoleMutation,
    DeleteUserWorkplaceRoleMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteUserWorkplaceRoleMutation,
    DeleteUserWorkplaceRoleMutationVariables
  >(DeleteUserWorkplaceRoleDocument, baseOptions);
}
export type DeleteUserWorkplaceRoleMutationHookResult = ReturnType<
  typeof useDeleteUserWorkplaceRoleMutation
>;
export type DeleteUserWorkplaceRoleMutationResult = Apollo.MutationResult<DeleteUserWorkplaceRoleMutation>;
export type DeleteUserWorkplaceRoleMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserWorkplaceRoleMutation,
  DeleteUserWorkplaceRoleMutationVariables
>;
export const DeleteUserCompanyRoleDocument = gql`
  mutation DeleteUserCompanyRole($id: UUID!) {
    deleteUserCompanyRole(id: $id)
  }
`;
export type DeleteUserCompanyRoleMutationFn = Apollo.MutationFunction<
  DeleteUserCompanyRoleMutation,
  DeleteUserCompanyRoleMutationVariables
>;

/**
 * __useDeleteUserCompanyRoleMutation__
 *
 * To run a mutation, you first call `useDeleteUserCompanyRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserCompanyRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserCompanyRoleMutation, { data, loading, error }] = useDeleteUserCompanyRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserCompanyRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserCompanyRoleMutation,
    DeleteUserCompanyRoleMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteUserCompanyRoleMutation,
    DeleteUserCompanyRoleMutationVariables
  >(DeleteUserCompanyRoleDocument, baseOptions);
}
export type DeleteUserCompanyRoleMutationHookResult = ReturnType<
  typeof useDeleteUserCompanyRoleMutation
>;
export type DeleteUserCompanyRoleMutationResult = Apollo.MutationResult<DeleteUserCompanyRoleMutation>;
export type DeleteUserCompanyRoleMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserCompanyRoleMutation,
  DeleteUserCompanyRoleMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($email: EmailAddress!) {
    deleteUser(email: $email)
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >
) {
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    baseOptions
  );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
