import * as Types from "../../../generated/types";

import {
  PatrolFragment,
  PatrolEventFragment,
  PatrolEventTaskFragment,
} from "./fragments.generated";
import { gql } from "@apollo/client";
import {
  PatrolFragmentDoc,
  PatrolEventFragmentDoc,
  PatrolEventTaskFragmentDoc,
} from "./fragments.generated";
import * as Apollo from "@apollo/client";
export type CreatePatrolMutationVariables = Types.Exact<{
  createPatrolData: Types.CreatePatrolData;
}>;

export type CreatePatrolMutation = {
  __typename?: "Mutation";
  createPatrol: { __typename?: "Patrol" } & PatrolFragment;
};

export type UpdatePatrolStartDateMutationVariables = Types.Exact<{
  updatePatrolStartDateData: Types.UpdatePatrolStartDateData;
}>;

export type UpdatePatrolStartDateMutation = {
  __typename?: "Mutation";
  updatePatrolStartDate: { __typename?: "Patrol"; id: any; startDate: any };
};

export type UpdatePatrolEndDateMutationVariables = Types.Exact<{
  updatePatrolEndDateData: Types.UpdatePatrolEndDateData;
}>;

export type UpdatePatrolEndDateMutation = {
  __typename?: "Mutation";
  updatePatrolEndDate: { __typename?: "Patrol"; id: any; endDate?: Types.Maybe<any> };
};

export type UpdatePatrolLocationMutationVariables = Types.Exact<{
  updatePatrolLocationData: Types.UpdatePatrolLocationData;
}>;

export type UpdatePatrolLocationMutation = {
  __typename?: "Mutation";
  updatePatrolLocation: {
    __typename?: "Patrol";
    id: any;
    location?: Types.Maybe<string>;
  };
};

export type UpdatePatrolDescriptionMutationVariables = Types.Exact<{
  updatePatrolDescriptionData: Types.UpdatePatrolDescriptionData;
}>;

export type UpdatePatrolDescriptionMutation = {
  __typename?: "Mutation";
  updatePatrolDescription: {
    __typename?: "Patrol";
    id: any;
    description?: Types.Maybe<string>;
  };
};

export type ChangePatrolAssigneesMutationVariables = Types.Exact<{
  changePatrolAssigneesData: Types.ChangePatrolAssigneesData;
}>;

export type ChangePatrolAssigneesMutation = {
  __typename?: "Mutation";
  changePatrolAssignees: {
    __typename?: "Patrol";
    id: any;
    assignees?: Types.Maybe<
      Array<{
        __typename?: "User";
        id: any;
        firstName?: Types.Maybe<string>;
        lastName?: Types.Maybe<string>;
        email: any;
      }>
    >;
  };
};

export type CreatePatrolEventMutationVariables = Types.Exact<{
  createPatrolEventData: Types.CreatePatrolEventData;
}>;

export type CreatePatrolEventMutation = {
  __typename?: "Mutation";
  createPatrolEvent: { __typename?: "PatrolEvent" } & PatrolEventFragment;
};

export type UploadPatrolEventAttachmentsMutationVariables = Types.Exact<{
  files: Array<Types.Scalars["Upload"]>;
  patrolEventId: Types.Scalars["UUID"];
}>;

export type UploadPatrolEventAttachmentsMutation = {
  __typename?: "Mutation";
  uploadPatrolEventAttachments: Array<{
    __typename?: "PatrolEventAttachment";
    id: any;
    createdAt: any;
    updatedAt: any;
    attachment: {
      __typename?: "Attachment";
      id: any;
      filename: string;
      mimetype: Types.MimeType;
      uri: string;
      createdAt: any;
      updatedAt: any;
      encoding: string;
    };
  }>;
};

export type DeletePatrolEventAttachmentMutationVariables = Types.Exact<{
  id: Types.Scalars["UUID"];
}>;

export type DeletePatrolEventAttachmentMutation = {
  __typename?: "Mutation";
  deletePatrolEventAttachment: boolean;
};

export type UpdatePatrolEventActionMutationVariables = Types.Exact<{
  updatePatrolEventActionData: Types.UpdatePatrolEventActionData;
}>;

export type UpdatePatrolEventActionMutation = {
  __typename?: "Mutation";
  updatePatrolEventAction: {
    __typename?: "PatrolEvent";
    id: any;
    action?: Types.Maybe<string>;
  };
};

export type UpdatePatrolEventDescriptionMutationVariables = Types.Exact<{
  updatePatrolEventDescriptionData: Types.UpdatePatrolEventDescriptionData;
}>;

export type UpdatePatrolEventDescriptionMutation = {
  __typename?: "Mutation";
  updatePatrolEventDescription: {
    __typename?: "PatrolEvent";
    id: any;
    description?: Types.Maybe<string>;
  };
};

export type UpdatePatrolEventEventDateMutationVariables = Types.Exact<{
  updatePatrolEventEventDateData: Types.UpdatePatrolEventEventDateData;
}>;

export type UpdatePatrolEventEventDateMutation = {
  __typename?: "Mutation";
  updatePatrolEventEventDate: { __typename?: "PatrolEvent"; id: any; eventDate: any };
};

export type UpdatePatrolEventLocationMutationVariables = Types.Exact<{
  updatePatrolEventLocationData: Types.UpdatePatrolEventLocationData;
}>;

export type UpdatePatrolEventLocationMutation = {
  __typename?: "Mutation";
  updatePatrolEventLocation: {
    __typename?: "PatrolEvent";
    id: any;
    location?: Types.Maybe<string>;
  };
};

export type UpdatePatrolEventNameMutationVariables = Types.Exact<{
  updatePatrolEventNameData: Types.UpdatePatrolEventNameData;
}>;

export type UpdatePatrolEventNameMutation = {
  __typename?: "Mutation";
  updatePatrolEventName: {
    __typename?: "PatrolEvent";
    id: any;
    name?: Types.Maybe<string>;
  };
};

export type DeletePatrolMutationVariables = Types.Exact<{
  id: Types.Scalars["UUID"];
}>;

export type DeletePatrolMutation = { __typename?: "Mutation"; deletePatrol: boolean };

export type DeletePatrolEventMutationVariables = Types.Exact<{
  id: Types.Scalars["UUID"];
}>;

export type DeletePatrolEventMutation = {
  __typename?: "Mutation";
  deletePatrolEvent: boolean;
};

export const CreatePatrolDocument = gql`
  mutation createPatrol($createPatrolData: CreatePatrolData!) {
    createPatrol(createPatrolData: $createPatrolData) {
      ...Patrol
    }
  }
  ${PatrolFragmentDoc}
`;
export type CreatePatrolMutationFn = Apollo.MutationFunction<
  CreatePatrolMutation,
  CreatePatrolMutationVariables
>;

/**
 * __useCreatePatrolMutation__
 *
 * To run a mutation, you first call `useCreatePatrolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatrolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatrolMutation, { data, loading, error }] = useCreatePatrolMutation({
 *   variables: {
 *      createPatrolData: // value for 'createPatrolData'
 *   },
 * });
 */
export function useCreatePatrolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePatrolMutation,
    CreatePatrolMutationVariables
  >
) {
  return Apollo.useMutation<CreatePatrolMutation, CreatePatrolMutationVariables>(
    CreatePatrolDocument,
    baseOptions
  );
}
export type CreatePatrolMutationHookResult = ReturnType<typeof useCreatePatrolMutation>;
export type CreatePatrolMutationResult = Apollo.MutationResult<CreatePatrolMutation>;
export type CreatePatrolMutationOptions = Apollo.BaseMutationOptions<
  CreatePatrolMutation,
  CreatePatrolMutationVariables
>;
export const UpdatePatrolStartDateDocument = gql`
  mutation UpdatePatrolStartDate($updatePatrolStartDateData: UpdatePatrolStartDateData!) {
    updatePatrolStartDate(updatePatrolStartDateData: $updatePatrolStartDateData) {
      id
      startDate
    }
  }
`;
export type UpdatePatrolStartDateMutationFn = Apollo.MutationFunction<
  UpdatePatrolStartDateMutation,
  UpdatePatrolStartDateMutationVariables
>;

/**
 * __useUpdatePatrolStartDateMutation__
 *
 * To run a mutation, you first call `useUpdatePatrolStartDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatrolStartDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatrolStartDateMutation, { data, loading, error }] = useUpdatePatrolStartDateMutation({
 *   variables: {
 *      updatePatrolStartDateData: // value for 'updatePatrolStartDateData'
 *   },
 * });
 */
export function useUpdatePatrolStartDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatrolStartDateMutation,
    UpdatePatrolStartDateMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdatePatrolStartDateMutation,
    UpdatePatrolStartDateMutationVariables
  >(UpdatePatrolStartDateDocument, baseOptions);
}
export type UpdatePatrolStartDateMutationHookResult = ReturnType<
  typeof useUpdatePatrolStartDateMutation
>;
export type UpdatePatrolStartDateMutationResult = Apollo.MutationResult<UpdatePatrolStartDateMutation>;
export type UpdatePatrolStartDateMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatrolStartDateMutation,
  UpdatePatrolStartDateMutationVariables
>;
export const UpdatePatrolEndDateDocument = gql`
  mutation UpdatePatrolEndDate($updatePatrolEndDateData: UpdatePatrolEndDateData!) {
    updatePatrolEndDate(updatePatrolEndDateData: $updatePatrolEndDateData) {
      id
      endDate
    }
  }
`;
export type UpdatePatrolEndDateMutationFn = Apollo.MutationFunction<
  UpdatePatrolEndDateMutation,
  UpdatePatrolEndDateMutationVariables
>;

/**
 * __useUpdatePatrolEndDateMutation__
 *
 * To run a mutation, you first call `useUpdatePatrolEndDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatrolEndDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatrolEndDateMutation, { data, loading, error }] = useUpdatePatrolEndDateMutation({
 *   variables: {
 *      updatePatrolEndDateData: // value for 'updatePatrolEndDateData'
 *   },
 * });
 */
export function useUpdatePatrolEndDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatrolEndDateMutation,
    UpdatePatrolEndDateMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdatePatrolEndDateMutation,
    UpdatePatrolEndDateMutationVariables
  >(UpdatePatrolEndDateDocument, baseOptions);
}
export type UpdatePatrolEndDateMutationHookResult = ReturnType<
  typeof useUpdatePatrolEndDateMutation
>;
export type UpdatePatrolEndDateMutationResult = Apollo.MutationResult<UpdatePatrolEndDateMutation>;
export type UpdatePatrolEndDateMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatrolEndDateMutation,
  UpdatePatrolEndDateMutationVariables
>;
export const UpdatePatrolLocationDocument = gql`
  mutation UpdatePatrolLocation($updatePatrolLocationData: UpdatePatrolLocationData!) {
    updatePatrolLocation(updatePatrolLocationData: $updatePatrolLocationData) {
      id
      location
    }
  }
`;
export type UpdatePatrolLocationMutationFn = Apollo.MutationFunction<
  UpdatePatrolLocationMutation,
  UpdatePatrolLocationMutationVariables
>;

/**
 * __useUpdatePatrolLocationMutation__
 *
 * To run a mutation, you first call `useUpdatePatrolLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatrolLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatrolLocationMutation, { data, loading, error }] = useUpdatePatrolLocationMutation({
 *   variables: {
 *      updatePatrolLocationData: // value for 'updatePatrolLocationData'
 *   },
 * });
 */
export function useUpdatePatrolLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatrolLocationMutation,
    UpdatePatrolLocationMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdatePatrolLocationMutation,
    UpdatePatrolLocationMutationVariables
  >(UpdatePatrolLocationDocument, baseOptions);
}
export type UpdatePatrolLocationMutationHookResult = ReturnType<
  typeof useUpdatePatrolLocationMutation
>;
export type UpdatePatrolLocationMutationResult = Apollo.MutationResult<UpdatePatrolLocationMutation>;
export type UpdatePatrolLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatrolLocationMutation,
  UpdatePatrolLocationMutationVariables
>;
export const UpdatePatrolDescriptionDocument = gql`
  mutation UpdatePatrolDescription(
    $updatePatrolDescriptionData: UpdatePatrolDescriptionData!
  ) {
    updatePatrolDescription(updatePatrolDescriptionData: $updatePatrolDescriptionData) {
      id
      description
    }
  }
`;
export type UpdatePatrolDescriptionMutationFn = Apollo.MutationFunction<
  UpdatePatrolDescriptionMutation,
  UpdatePatrolDescriptionMutationVariables
>;

/**
 * __useUpdatePatrolDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdatePatrolDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatrolDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatrolDescriptionMutation, { data, loading, error }] = useUpdatePatrolDescriptionMutation({
 *   variables: {
 *      updatePatrolDescriptionData: // value for 'updatePatrolDescriptionData'
 *   },
 * });
 */
export function useUpdatePatrolDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatrolDescriptionMutation,
    UpdatePatrolDescriptionMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdatePatrolDescriptionMutation,
    UpdatePatrolDescriptionMutationVariables
  >(UpdatePatrolDescriptionDocument, baseOptions);
}
export type UpdatePatrolDescriptionMutationHookResult = ReturnType<
  typeof useUpdatePatrolDescriptionMutation
>;
export type UpdatePatrolDescriptionMutationResult = Apollo.MutationResult<UpdatePatrolDescriptionMutation>;
export type UpdatePatrolDescriptionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatrolDescriptionMutation,
  UpdatePatrolDescriptionMutationVariables
>;
export const ChangePatrolAssigneesDocument = gql`
  mutation ChangePatrolAssignees($changePatrolAssigneesData: ChangePatrolAssigneesData!) {
    changePatrolAssignees(changePatrolAssigneesData: $changePatrolAssigneesData) {
      id
      assignees {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
export type ChangePatrolAssigneesMutationFn = Apollo.MutationFunction<
  ChangePatrolAssigneesMutation,
  ChangePatrolAssigneesMutationVariables
>;

/**
 * __useChangePatrolAssigneesMutation__
 *
 * To run a mutation, you first call `useChangePatrolAssigneesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePatrolAssigneesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePatrolAssigneesMutation, { data, loading, error }] = useChangePatrolAssigneesMutation({
 *   variables: {
 *      changePatrolAssigneesData: // value for 'changePatrolAssigneesData'
 *   },
 * });
 */
export function useChangePatrolAssigneesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePatrolAssigneesMutation,
    ChangePatrolAssigneesMutationVariables
  >
) {
  return Apollo.useMutation<
    ChangePatrolAssigneesMutation,
    ChangePatrolAssigneesMutationVariables
  >(ChangePatrolAssigneesDocument, baseOptions);
}
export type ChangePatrolAssigneesMutationHookResult = ReturnType<
  typeof useChangePatrolAssigneesMutation
>;
export type ChangePatrolAssigneesMutationResult = Apollo.MutationResult<ChangePatrolAssigneesMutation>;
export type ChangePatrolAssigneesMutationOptions = Apollo.BaseMutationOptions<
  ChangePatrolAssigneesMutation,
  ChangePatrolAssigneesMutationVariables
>;
export const CreatePatrolEventDocument = gql`
  mutation CreatePatrolEvent($createPatrolEventData: CreatePatrolEventData!) {
    createPatrolEvent(createPatrolEventData: $createPatrolEventData) {
      ...PatrolEvent
    }
  }
  ${PatrolEventFragmentDoc}
`;
export type CreatePatrolEventMutationFn = Apollo.MutationFunction<
  CreatePatrolEventMutation,
  CreatePatrolEventMutationVariables
>;

/**
 * __useCreatePatrolEventMutation__
 *
 * To run a mutation, you first call `useCreatePatrolEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatrolEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatrolEventMutation, { data, loading, error }] = useCreatePatrolEventMutation({
 *   variables: {
 *      createPatrolEventData: // value for 'createPatrolEventData'
 *   },
 * });
 */
export function useCreatePatrolEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePatrolEventMutation,
    CreatePatrolEventMutationVariables
  >
) {
  return Apollo.useMutation<
    CreatePatrolEventMutation,
    CreatePatrolEventMutationVariables
  >(CreatePatrolEventDocument, baseOptions);
}
export type CreatePatrolEventMutationHookResult = ReturnType<
  typeof useCreatePatrolEventMutation
>;
export type CreatePatrolEventMutationResult = Apollo.MutationResult<CreatePatrolEventMutation>;
export type CreatePatrolEventMutationOptions = Apollo.BaseMutationOptions<
  CreatePatrolEventMutation,
  CreatePatrolEventMutationVariables
>;
export const UploadPatrolEventAttachmentsDocument = gql`
  mutation UploadPatrolEventAttachments($files: [Upload!]!, $patrolEventId: UUID!) {
    uploadPatrolEventAttachments(files: $files, patrolEventId: $patrolEventId) {
      id
      attachment {
        id
        filename
        mimetype
        uri
        createdAt
        updatedAt
        encoding
      }
      createdAt
      updatedAt
    }
  }
`;
export type UploadPatrolEventAttachmentsMutationFn = Apollo.MutationFunction<
  UploadPatrolEventAttachmentsMutation,
  UploadPatrolEventAttachmentsMutationVariables
>;

/**
 * __useUploadPatrolEventAttachmentsMutation__
 *
 * To run a mutation, you first call `useUploadPatrolEventAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPatrolEventAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPatrolEventAttachmentsMutation, { data, loading, error }] = useUploadPatrolEventAttachmentsMutation({
 *   variables: {
 *      files: // value for 'files'
 *      patrolEventId: // value for 'patrolEventId'
 *   },
 * });
 */
export function useUploadPatrolEventAttachmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadPatrolEventAttachmentsMutation,
    UploadPatrolEventAttachmentsMutationVariables
  >
) {
  return Apollo.useMutation<
    UploadPatrolEventAttachmentsMutation,
    UploadPatrolEventAttachmentsMutationVariables
  >(UploadPatrolEventAttachmentsDocument, baseOptions);
}
export type UploadPatrolEventAttachmentsMutationHookResult = ReturnType<
  typeof useUploadPatrolEventAttachmentsMutation
>;
export type UploadPatrolEventAttachmentsMutationResult = Apollo.MutationResult<UploadPatrolEventAttachmentsMutation>;
export type UploadPatrolEventAttachmentsMutationOptions = Apollo.BaseMutationOptions<
  UploadPatrolEventAttachmentsMutation,
  UploadPatrolEventAttachmentsMutationVariables
>;
export const DeletePatrolEventAttachmentDocument = gql`
  mutation DeletePatrolEventAttachment($id: UUID!) {
    deletePatrolEventAttachment(id: $id)
  }
`;
export type DeletePatrolEventAttachmentMutationFn = Apollo.MutationFunction<
  DeletePatrolEventAttachmentMutation,
  DeletePatrolEventAttachmentMutationVariables
>;

/**
 * __useDeletePatrolEventAttachmentMutation__
 *
 * To run a mutation, you first call `useDeletePatrolEventAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatrolEventAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatrolEventAttachmentMutation, { data, loading, error }] = useDeletePatrolEventAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePatrolEventAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePatrolEventAttachmentMutation,
    DeletePatrolEventAttachmentMutationVariables
  >
) {
  return Apollo.useMutation<
    DeletePatrolEventAttachmentMutation,
    DeletePatrolEventAttachmentMutationVariables
  >(DeletePatrolEventAttachmentDocument, baseOptions);
}
export type DeletePatrolEventAttachmentMutationHookResult = ReturnType<
  typeof useDeletePatrolEventAttachmentMutation
>;
export type DeletePatrolEventAttachmentMutationResult = Apollo.MutationResult<DeletePatrolEventAttachmentMutation>;
export type DeletePatrolEventAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeletePatrolEventAttachmentMutation,
  DeletePatrolEventAttachmentMutationVariables
>;
export const UpdatePatrolEventActionDocument = gql`
  mutation UpdatePatrolEventAction(
    $updatePatrolEventActionData: UpdatePatrolEventActionData!
  ) {
    updatePatrolEventAction(updatePatrolEventActionData: $updatePatrolEventActionData) {
      id
      action
    }
  }
`;
export type UpdatePatrolEventActionMutationFn = Apollo.MutationFunction<
  UpdatePatrolEventActionMutation,
  UpdatePatrolEventActionMutationVariables
>;

/**
 * __useUpdatePatrolEventActionMutation__
 *
 * To run a mutation, you first call `useUpdatePatrolEventActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatrolEventActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatrolEventActionMutation, { data, loading, error }] = useUpdatePatrolEventActionMutation({
 *   variables: {
 *      updatePatrolEventActionData: // value for 'updatePatrolEventActionData'
 *   },
 * });
 */
export function useUpdatePatrolEventActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatrolEventActionMutation,
    UpdatePatrolEventActionMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdatePatrolEventActionMutation,
    UpdatePatrolEventActionMutationVariables
  >(UpdatePatrolEventActionDocument, baseOptions);
}
export type UpdatePatrolEventActionMutationHookResult = ReturnType<
  typeof useUpdatePatrolEventActionMutation
>;
export type UpdatePatrolEventActionMutationResult = Apollo.MutationResult<UpdatePatrolEventActionMutation>;
export type UpdatePatrolEventActionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatrolEventActionMutation,
  UpdatePatrolEventActionMutationVariables
>;
export const UpdatePatrolEventDescriptionDocument = gql`
  mutation UpdatePatrolEventDescription(
    $updatePatrolEventDescriptionData: UpdatePatrolEventDescriptionData!
  ) {
    updatePatrolEventDescription(
      updatePatrolEventDescriptionData: $updatePatrolEventDescriptionData
    ) {
      id
      description
    }
  }
`;
export type UpdatePatrolEventDescriptionMutationFn = Apollo.MutationFunction<
  UpdatePatrolEventDescriptionMutation,
  UpdatePatrolEventDescriptionMutationVariables
>;

/**
 * __useUpdatePatrolEventDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdatePatrolEventDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatrolEventDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatrolEventDescriptionMutation, { data, loading, error }] = useUpdatePatrolEventDescriptionMutation({
 *   variables: {
 *      updatePatrolEventDescriptionData: // value for 'updatePatrolEventDescriptionData'
 *   },
 * });
 */
export function useUpdatePatrolEventDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatrolEventDescriptionMutation,
    UpdatePatrolEventDescriptionMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdatePatrolEventDescriptionMutation,
    UpdatePatrolEventDescriptionMutationVariables
  >(UpdatePatrolEventDescriptionDocument, baseOptions);
}
export type UpdatePatrolEventDescriptionMutationHookResult = ReturnType<
  typeof useUpdatePatrolEventDescriptionMutation
>;
export type UpdatePatrolEventDescriptionMutationResult = Apollo.MutationResult<UpdatePatrolEventDescriptionMutation>;
export type UpdatePatrolEventDescriptionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatrolEventDescriptionMutation,
  UpdatePatrolEventDescriptionMutationVariables
>;
export const UpdatePatrolEventEventDateDocument = gql`
  mutation UpdatePatrolEventEventDate(
    $updatePatrolEventEventDateData: UpdatePatrolEventEventDateData!
  ) {
    updatePatrolEventEventDate(
      updatePatrolEventEventDateData: $updatePatrolEventEventDateData
    ) {
      id
      eventDate
    }
  }
`;
export type UpdatePatrolEventEventDateMutationFn = Apollo.MutationFunction<
  UpdatePatrolEventEventDateMutation,
  UpdatePatrolEventEventDateMutationVariables
>;

/**
 * __useUpdatePatrolEventEventDateMutation__
 *
 * To run a mutation, you first call `useUpdatePatrolEventEventDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatrolEventEventDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatrolEventEventDateMutation, { data, loading, error }] = useUpdatePatrolEventEventDateMutation({
 *   variables: {
 *      updatePatrolEventEventDateData: // value for 'updatePatrolEventEventDateData'
 *   },
 * });
 */
export function useUpdatePatrolEventEventDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatrolEventEventDateMutation,
    UpdatePatrolEventEventDateMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdatePatrolEventEventDateMutation,
    UpdatePatrolEventEventDateMutationVariables
  >(UpdatePatrolEventEventDateDocument, baseOptions);
}
export type UpdatePatrolEventEventDateMutationHookResult = ReturnType<
  typeof useUpdatePatrolEventEventDateMutation
>;
export type UpdatePatrolEventEventDateMutationResult = Apollo.MutationResult<UpdatePatrolEventEventDateMutation>;
export type UpdatePatrolEventEventDateMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatrolEventEventDateMutation,
  UpdatePatrolEventEventDateMutationVariables
>;
export const UpdatePatrolEventLocationDocument = gql`
  mutation UpdatePatrolEventLocation(
    $updatePatrolEventLocationData: UpdatePatrolEventLocationData!
  ) {
    updatePatrolEventLocation(
      updatePatrolEventLocationData: $updatePatrolEventLocationData
    ) {
      id
      location
    }
  }
`;
export type UpdatePatrolEventLocationMutationFn = Apollo.MutationFunction<
  UpdatePatrolEventLocationMutation,
  UpdatePatrolEventLocationMutationVariables
>;

/**
 * __useUpdatePatrolEventLocationMutation__
 *
 * To run a mutation, you first call `useUpdatePatrolEventLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatrolEventLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatrolEventLocationMutation, { data, loading, error }] = useUpdatePatrolEventLocationMutation({
 *   variables: {
 *      updatePatrolEventLocationData: // value for 'updatePatrolEventLocationData'
 *   },
 * });
 */
export function useUpdatePatrolEventLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatrolEventLocationMutation,
    UpdatePatrolEventLocationMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdatePatrolEventLocationMutation,
    UpdatePatrolEventLocationMutationVariables
  >(UpdatePatrolEventLocationDocument, baseOptions);
}
export type UpdatePatrolEventLocationMutationHookResult = ReturnType<
  typeof useUpdatePatrolEventLocationMutation
>;
export type UpdatePatrolEventLocationMutationResult = Apollo.MutationResult<UpdatePatrolEventLocationMutation>;
export type UpdatePatrolEventLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatrolEventLocationMutation,
  UpdatePatrolEventLocationMutationVariables
>;
export const UpdatePatrolEventNameDocument = gql`
  mutation UpdatePatrolEventName($updatePatrolEventNameData: UpdatePatrolEventNameData!) {
    updatePatrolEventName(updatePatrolEventNameData: $updatePatrolEventNameData) {
      id
      name
    }
  }
`;
export type UpdatePatrolEventNameMutationFn = Apollo.MutationFunction<
  UpdatePatrolEventNameMutation,
  UpdatePatrolEventNameMutationVariables
>;

/**
 * __useUpdatePatrolEventNameMutation__
 *
 * To run a mutation, you first call `useUpdatePatrolEventNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatrolEventNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatrolEventNameMutation, { data, loading, error }] = useUpdatePatrolEventNameMutation({
 *   variables: {
 *      updatePatrolEventNameData: // value for 'updatePatrolEventNameData'
 *   },
 * });
 */
export function useUpdatePatrolEventNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatrolEventNameMutation,
    UpdatePatrolEventNameMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdatePatrolEventNameMutation,
    UpdatePatrolEventNameMutationVariables
  >(UpdatePatrolEventNameDocument, baseOptions);
}
export type UpdatePatrolEventNameMutationHookResult = ReturnType<
  typeof useUpdatePatrolEventNameMutation
>;
export type UpdatePatrolEventNameMutationResult = Apollo.MutationResult<UpdatePatrolEventNameMutation>;
export type UpdatePatrolEventNameMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatrolEventNameMutation,
  UpdatePatrolEventNameMutationVariables
>;
export const DeletePatrolDocument = gql`
  mutation DeletePatrol($id: UUID!) {
    deletePatrol(id: $id)
  }
`;
export type DeletePatrolMutationFn = Apollo.MutationFunction<
  DeletePatrolMutation,
  DeletePatrolMutationVariables
>;

/**
 * __useDeletePatrolMutation__
 *
 * To run a mutation, you first call `useDeletePatrolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatrolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatrolMutation, { data, loading, error }] = useDeletePatrolMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePatrolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePatrolMutation,
    DeletePatrolMutationVariables
  >
) {
  return Apollo.useMutation<DeletePatrolMutation, DeletePatrolMutationVariables>(
    DeletePatrolDocument,
    baseOptions
  );
}
export type DeletePatrolMutationHookResult = ReturnType<typeof useDeletePatrolMutation>;
export type DeletePatrolMutationResult = Apollo.MutationResult<DeletePatrolMutation>;
export type DeletePatrolMutationOptions = Apollo.BaseMutationOptions<
  DeletePatrolMutation,
  DeletePatrolMutationVariables
>;
export const DeletePatrolEventDocument = gql`
  mutation DeletePatrolEvent($id: UUID!) {
    deletePatrolEvent(id: $id)
  }
`;
export type DeletePatrolEventMutationFn = Apollo.MutationFunction<
  DeletePatrolEventMutation,
  DeletePatrolEventMutationVariables
>;

/**
 * __useDeletePatrolEventMutation__
 *
 * To run a mutation, you first call `useDeletePatrolEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatrolEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatrolEventMutation, { data, loading, error }] = useDeletePatrolEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePatrolEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePatrolEventMutation,
    DeletePatrolEventMutationVariables
  >
) {
  return Apollo.useMutation<
    DeletePatrolEventMutation,
    DeletePatrolEventMutationVariables
  >(DeletePatrolEventDocument, baseOptions);
}
export type DeletePatrolEventMutationHookResult = ReturnType<
  typeof useDeletePatrolEventMutation
>;
export type DeletePatrolEventMutationResult = Apollo.MutationResult<DeletePatrolEventMutation>;
export type DeletePatrolEventMutationOptions = Apollo.BaseMutationOptions<
  DeletePatrolEventMutation,
  DeletePatrolEventMutationVariables
>;
