import { gql } from "@apollo/client";
import { taskStatusFragment } from "./fragments";

export const GET_WORKPLACE_TASK_STATUSES_FOR_SETTINGS = gql`
  ${taskStatusFragment}
  query GetWorkplaceTaskStatusesForSettings($workplaceId: UUID!) {
    getWorkplaceTaskStatuses(workplaceId: $workplaceId) {
      ...TaskStatus
    }
  }
`;
