import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, MenuItem, TextField, Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import CustomSnackbar from "../../components/CustomSnackbar/CustomSnackbar";
import CustomDialog from "../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../components/Dialog/CustomDialogContent/CustomDialogContent";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import FormTextFieldWrapper from "../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import { requiredStringValidator } from "../formValidators";
import { useContactUsMutation } from "../graphql/mutations.generated";

interface Props {
  onClose: () => void;
  open?: boolean;
}

interface FormData {
  subject: string;
  email?: string;
  title?: string;
  message: string;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    subject: requiredStringValidator(t),
    message: requiredStringValidator(t),
  });

// todo dodaj mejla jak uzytkownik niezalogowany
export default function ContactUsDialog({ onClose, open }: Props): JSX.Element {
  const { t } = useTranslation();
  const messageSendFailedErrorMsg = t(
    "Error occured while sending your message, please try again or contact us directly on our email address."
  );

  const [thankYouMessageDisplay, setThankYouMessageDisplay] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string>("");
  const [captchaError, setCaptchaError] = useState<boolean>(false);
  const [messageSendFailed, setMessageSendFailed] = useState<boolean>(false);

  const [contactUsMutation, { loading, error }] = useContactUsMutation();

  const { register, handleSubmit, errors, control } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      subject: "question",
      message: "",
    },
  });

  const onCaptchaChange = (value: string): void => {
    setCaptchaToken(value);
  };

  const onCaptchaError = (): void => {
    setCaptchaError(true);
  };

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    if (captchaToken) {
      const siteUrl = window.location.href;
      try {
        const res = await contactUsMutation({
          variables: {
            contactUsData: {
              fromSite: siteUrl,
              message: formData.message,
              subject: formData.subject,
              title: `${formData.email} / ${formData.title}`,
              captcha: captchaToken,
            },
          },
        });

        if (res.data?.contactUs) {
          setThankYouMessageDisplay(true);
        } else if (res.data?.contactUs === false) {
          setMessageSendFailed(true);
        }
      } catch (e) {
        //
      }
    } else {
      setCaptchaError(true);
    }
  };

  return (
    <>
      <CustomDialog
        onClose={onClose}
        open={Boolean(open)}
        fullWidth
        maxWidth="sm"
        loading={loading}
      >
        <CustomDialogContent heading={t("Contact form")} width="100%">
          {!thankYouMessageDisplay ? (
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <FormTextFieldWrapper>
                <TextField
                  autoFocus
                  name="title"
                  label={t("Message title")}
                  variant="outlined"
                  fullWidth
                  inputRef={register}
                />
              </FormTextFieldWrapper>
              <FormTextFieldWrapper>
                <TextField
                  name="email"
                  label={t("Contact email")}
                  variant="outlined"
                  fullWidth
                  inputRef={register}
                />
              </FormTextFieldWrapper>
              <FormTextFieldWrapper>
                <Controller
                  control={control}
                  name="subject"
                  render={({ value, onChange }): JSX.Element => (
                    <TextField
                      select
                      label={t("Message subject")}
                      variant="outlined"
                      fullWidth
                      onChange={onChange}
                      value={value}
                      error={!!errors.subject}
                      helperText={errors.subject?.message}
                    >
                      <MenuItem value="question">
                        {t("Question about application")}
                      </MenuItem>
                      <MenuItem value="error">{t("Error in application")}</MenuItem>
                      <MenuItem value="suggestion">{t("Feature suggestion")}</MenuItem>
                      <MenuItem value="other">{t("Other")}</MenuItem>
                    </TextField>
                  )}
                />
              </FormTextFieldWrapper>
              <FormTextFieldWrapper>
                <TextField
                  multiline
                  rows={6}
                  rowsMax={6}
                  name="message"
                  label={t("Message content")}
                  variant="outlined"
                  fullWidth
                  inputRef={register}
                  error={!!errors.message}
                  helperText={errors.message?.message}
                />
              </FormTextFieldWrapper>
              <FormTextFieldWrapper>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={onCaptchaChange}
                  onErrored={onCaptchaError}
                />
              </FormTextFieldWrapper>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Button variant="contained" color="primary" type="submit">
                  {t("Send message")}
                </Button>
                <Button onClick={(): void => onClose()}>{t("Cancel")}</Button>
              </Box>
            </form>
          ) : (
            <>
              <Box mb={3}>
                <Typography align="center">
                  {t(
                    "Thank You for contacting us, we will reply as soon as it is possible."
                  )}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(): void => onClose()}
                >
                  {t("Close")}
                </Button>
              </Box>
            </>
          )}
        </CustomDialogContent>
      </CustomDialog>
      <ErrorDisplay error={error} message={messageSendFailedErrorMsg} />
      <CustomSnackbar
        open={captchaError}
        message={t("There was a problem with your captcha, please try again.")}
        handleClose={(): void => setCaptchaError(false)}
      />
      <CustomSnackbar
        open={messageSendFailed}
        message={messageSendFailedErrorMsg}
        handleClose={(): void => setMessageSendFailed(false)}
      />
    </>
  );
}
