import { Box } from "@material-ui/core";
import React from "react";
import BasicMenuLayout from "../../layouts/BasicMenuLayout/BasicMenuLayout";
import FullHeightNoScrollPage from "../../layouts/FullHeightNoScrollPage/FullHeightNoScrollPage";
import CreateCompany from "./CreateCompany";
import DeleteWorkplace from "./DeleteWorkplace";
import PickCompany from "./PickCompany";

export default function AdminPanelPage(): JSX.Element {
  return (
    <BasicMenuLayout>
      <FullHeightNoScrollPage>
        <Box pb={5}>
          <Box mb={5}>
            <PickCompany />
          </Box>
          <Box mb={5}>
            <DeleteWorkplace />
          </Box>
          <Box mb={5}>
            <CreateCompany />
          </Box>
        </Box>
      </FullHeightNoScrollPage>
    </BasicMenuLayout>
  );
}
