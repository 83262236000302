import React from "react";
import { useTranslation } from "react-i18next";
import Attachments from "../../../../../components/Attachments/Attachments";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";
import { PatrolEventAttachment } from "../../../../../generated/types";
import { GET_EXTENDED_PATROL_EVENT_DATA } from "../../../graphql/query";
import { GetExtendedPatrolEventDataQuery } from "../../../graphql/query.generated";
import {
  useDeletePatrolEventAttachmentMutation,
  useUploadPatrolEventAttachmentsMutation,
} from "../../../graphql/mutation.generated";

interface Props {
  patrolEventId: string;
  canUserEdit?: boolean;
  attachments: PatrolEventAttachment[];
}
export default function PatrolEventAttachments({
  patrolEventId,
  canUserEdit,
  attachments,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [
    uploadPatrolEventAttachmentsMutation,
    { loading: uploadAttachmentsLoading, error: uploadAttachmentsError },
  ] = useUploadPatrolEventAttachmentsMutation({
    update: (cache, res) => {
      if (res.data?.uploadPatrolEventAttachments) {
        const resUpload = res.data.uploadPatrolEventAttachments;
        const cachedData = cache.readQuery<GetExtendedPatrolEventDataQuery>({
          query: GET_EXTENDED_PATROL_EVENT_DATA,
          variables: { patrolEventId },
        });
        if (cachedData?.getPatrolEventAttachments) {
          const newPatrolEventAttachments = [
            ...cachedData.getPatrolEventAttachments,
            ...resUpload,
          ];
          const newPatrolEventAttachmentsQuery: GetExtendedPatrolEventDataQuery = {
            ...cachedData,
            getPatrolEventAttachments: newPatrolEventAttachments,
          };

          cache.writeQuery({
            query: GET_EXTENDED_PATROL_EVENT_DATA,
            variables: { patrolEventId },
            data: newPatrolEventAttachmentsQuery,
          });
        }
      }
    },
  });

  const [
    deletePatrolEventAttachmentMutation,
    { error: deletePatrolEventAttachmentError },
  ] = useDeletePatrolEventAttachmentMutation();

  const onAttachmentDelete = async (patrolEventAttachmentId: string): Promise<void> => {
    try {
      await deletePatrolEventAttachmentMutation({
        variables: { id: patrolEventAttachmentId },
        update: (cache, res) => {
          if (res.data?.deletePatrolEventAttachment) {
            cache.modify({
              fields: {
                getPatrolEventAttachments(existingAttachments = [], { readField }) {
                  return existingAttachments.filter(
                    (attachmentRef) =>
                      patrolEventAttachmentId !== readField("id", attachmentRef)
                  );
                },
              },
            });
          }
        },
      });
    } catch (e) {
      //
    }
  };

  const onFileUpload = async (files: File[]): Promise<void> => {
    try {
      await uploadPatrolEventAttachmentsMutation({
        variables: {
          files,
          patrolEventId,
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <Attachments
        attachments={
          attachments.map((patrolEventAttachment) => ({
            attachmentWrapperId: patrolEventAttachment.id,
            ...patrolEventAttachment.attachment,
          })) || []
        }
        onAttachmentDelete={onAttachmentDelete}
        onFileUpload={onFileUpload}
        addingLoading={uploadAttachmentsLoading}
        canUserEdit={canUserEdit}
        maxInRow={5}
      />
      <ErrorDisplay
        error={uploadAttachmentsError}
        message={t(
          "Error occured while uploading files, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={deletePatrolEventAttachmentError}
        message={t(
          "Error occured while deleting patrol event attachment, please try again or contact us."
        )}
      />
    </>
  );
}
