import { Box } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDateTimePicker from "../../../../components/CustomDateTimePicker/CustomDateTimePicker";
import EditableInputAdornment from "../../../../components/EditableInputAdornment/EditableInputAdornment";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { useUpdatePatrolStartDateMutation } from "../../graphql/mutation.generated";

interface Props {
  patrolStartDate: string;
  disabled?: boolean;
  patrolId: string;
}
export default function PatrolStartDate({
  patrolStartDate,
  patrolId,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [
    updateStartDateMutation,
    { error, loading },
  ] = useUpdatePatrolStartDateMutation();

  const onStartDateChange = async (date: MaterialUiPickersDate): Promise<void> => {
    if (date) {
      const newValue = moment(date).toISOString();
      try {
        await updateStartDateMutation({
          variables: { updatePatrolStartDateData: { id: patrolId, startDate: newValue } },
        });
      } catch (e) {
        //
      }
    }
  };
  return (
    <>
      <Box display="flex">
        <CustomDateTimePicker
          disabled={disabled || loading}
          placeholder={t("Pick patrol start date")}
          value={patrolStartDate}
          onChange={(e): Promise<void> => onStartDateChange(e)}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <EditableInputAdornment display={Boolean(patrolStartDate) && !disabled} />
            ),
          }}
          size="small"
        />
      </Box>
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing start date, please try again or contact us"
        )}
        codes={{
          BAD_USER_INPUT: t(
            "Provided date is invalid, check if date is not exceeding patrol end date."
          ),
        }}
      />
    </>
  );
}
