import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, makeStyles, TextField, Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { RESET_PASSWORD_EMAIL_SENT, SIGN_IN } from "../../../../routing/paths";
import { emailValidator } from "../../../../utils/formValidators";
import IdentityLayout from "../../common/IdentityLayout";
import { useRequestPasswordResetMailMutation } from "../../graphql/mutation.generated";

interface FormData {
  email: string;
}

const schema = (
  t: TFunction
): yup.ObjectSchema<yup.Shape<object | undefined, object>, object> =>
  yup.object().shape({
    email: emailValidator(t),
  });

const useStyles = makeStyles({
  link: {
    display: "block",
  },
});

export default function ResetPasswordInitializePage(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
  });
  const [
    requestPasswordResetMailMutation,
    { loading, error },
  ] = useRequestPasswordResetMailMutation({});

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    const { email } = formData;
    try {
      const res = await requestPasswordResetMailMutation({
        variables: { email },
      });
      if (res.data?.requestPasswordResetMail) {
        history.push(RESET_PASSWORD_EMAIL_SENT);
      }
    } catch (err) {
      //
    }
  };

  return (
    <IdentityLayout loading={loading}>
      <Box pb={1}>
        <Typography variant="h6">{t("Reset Password")}</Typography>
      </Box>
      <Box pb={5}>
        <Typography variant="body1">
          {t("Enter your email address to reset a password")}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Box pb={5}>
          <TextField
            name="email"
            label={t("Email")}
            fullWidth
            autoFocus
            variant="outlined"
            inputRef={register}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Box>
        <Box pb={2}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            fullWidth
            size="large"
          >
            {t("Reset password")}
          </Button>
        </Box>
        <Box pb={2}>
          <Typography variant="caption" display="block" align="center">
            {t("or")}
          </Typography>
        </Box>
        <Box>
          <Link to={SIGN_IN} className={classes.link}>
            <Button color="primary" variant="outlined" fullWidth size="large">
              {t("Sign in")}
            </Button>
          </Link>
        </Box>
      </form>
      <ErrorDisplay
        error={error}
        message={t(
          "There was an error while requesting password reset. Please try again or contact us: support@rids.app"
        )}
      />
    </IdentityLayout>
  );
}
