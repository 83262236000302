import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DataListItemDisplay from "../../../../components/DataListItemDisplay/DataListItemDisplay";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { PatrolEvent } from "../../../../generated/types";
import theme from "../../../../utils/theme";
import { useGetExtendedPatrolEventDataLazyQuery } from "../../graphql/query.generated";
import PatrolEventAttachments from "./patrolEventAttachments/PatrolEventAttachments";
import PatrolEventAction from "./patrolEventFields/PatrolEventAction";
import PatrolEventDescription from "./patrolEventFields/PatrolEventDescription";
import PatrolEventEventDate from "./patrolEventFields/PatrolEventEventDate";
import PatrolEventLocation from "./patrolEventFields/PatrolEventLocation";
import PatrolEventName from "./patrolEventFields/PatrolEventName";
import PatrolEventMoreMenu from "./PatrolEventMoreMenu";
import PatrolEventTasks from "./patrolEventTasks/PatrolEventTasks";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

interface Props {
  eventData: PatrolEvent;
  canUserEdit?: boolean;
}

const useStyles = makeStyles({
  attachments: {
    backgroundColor: theme.palette.grey[100],
  },
  divider: {
    backgroundColor: theme.palette.divider,
    width: "1px",
  },
});

export default function SinglePatrolEvent({
  eventData,
  canUserEdit,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  const [moreInfoExpanded, setMoreInfoExpanded] = useState<boolean>(false);

  const [
    getExtendedDataQuery,
    {
      data: extendedEventData,
      loading: extendedEventDataLoading,
      error: gettingExtendedDataError,
    },
  ] = useGetExtendedPatrolEventDataLazyQuery();

  const onExpandMoreButtonClick = async (): Promise<void> => {
    if (moreInfoExpanded) {
      setMoreInfoExpanded(false);
    } else {
      if (!extendedEventData) {
        await getExtendedDataQuery({ variables: { patrolEventId: eventData.id } });
      }
      setMoreInfoExpanded(true);
    }
  };

  return (
    <>
      <Paper variant="outlined" className={classes.attachments}>
        <Box px={3} py={1}>
          <Box pb={1} display="flex" justifyContent="flex-end">
            <Box>
              <PatrolEventMoreMenu patrolEventId={eventData.id} />
            </Box>
          </Box>
          <Grid container>
            <Grid item xs={12} md={4}>
              <DataListItemDisplay headText={t("Title")} globalMb={2}>
                <PatrolEventName
                  patrolEventId={eventData.id}
                  patrolEventName={eventData.name || undefined}
                  disabled={!canUserEdit}
                />
              </DataListItemDisplay>
            </Grid>
            <Grid item xs={12} md={4}>
              <DataListItemDisplay headText={t("Event date")} globalMb={2}>
                <PatrolEventEventDate
                  eventDate={eventData.eventDate}
                  patrolEventId={eventData.id}
                  disabled={!canUserEdit}
                />
              </DataListItemDisplay>
            </Grid>
            <Grid item xs={12} md={4}>
              <DataListItemDisplay headText={t("Location")} globalMb={2}>
                <PatrolEventLocation
                  patrolEventId={eventData.id}
                  eventLocation={eventData.location || undefined}
                  disabled={!canUserEdit}
                />
              </DataListItemDisplay>
            </Grid>
          </Grid>
          <Box mb={2}>
            <Box mb={2}>
              <PatrolEventDescription
                eventDescription={eventData.description || undefined}
                patrolEventId={eventData.id}
                disabled={!canUserEdit}
              />
            </Box>
            <Box>
              <PatrolEventAction
                patrolEventId={eventData.id}
                eventAction={eventData.action || undefined}
                disabled={!canUserEdit}
              />
            </Box>
          </Box>
          <Box textAlign="center" mb={1}>
            <Button size="small" onClick={(): Promise<void> => onExpandMoreButtonClick()}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box mr={1}>
                  {moreInfoExpanded ? (
                    <Typography variant="caption">{t("Collapse")}</Typography>
                  ) : (
                    <Typography variant="caption">{t("Expand more")}</Typography>
                  )}
                </Box>
                {moreInfoExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Box>
            </Button>
          </Box>
          {moreInfoExpanded && (
            <Box>
              {extendedEventDataLoading && (
                <Box display="flex" justifyContent="center">
                  <CircularProgress size={30} />
                </Box>
              )}
              {extendedEventData && (
                <>
                  <DataListItemDisplay headText={t("Event attachments")} globalMb={2}>
                    <PatrolEventAttachments
                      patrolEventId={eventData.id}
                      canUserEdit={canUserEdit}
                      attachments={extendedEventData.getPatrolEventAttachments}
                    />
                  </DataListItemDisplay>
                  <DataListItemDisplay headText={t("Associated tasks")} globalMb={2}>
                    <PatrolEventTasks
                      tasks={extendedEventData.getPatrolEventTasks}
                      patrolEventData={eventData}
                    />
                  </DataListItemDisplay>
                </>
              )}
            </Box>
          )}
        </Box>
      </Paper>
      <ErrorDisplay
        error={gettingExtendedDataError}
        message={t(
          "Error occured while getting patrol event data, please try again or contact us."
        )}
      />
    </>
  );
}
