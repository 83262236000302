import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import theme from "../../../../utils/theme";
import { useGetTaskCommentsLazyQuery } from "../../graphql/query.generated";
import CreateComment from "./CreateComment";
import OwnComment from "./OwnComment";
import SingleComment from "./SingleComment";

interface Props {
  taskId: string;
  userId?: string | null;
  notDeletableComments?: boolean;
}
const useStyles = makeStyles({
  wrapper: {
    maxHeight: "400px",
    overflowY: "auto",
    paddingRight: theme.spacing(1),
  },
});

export default function TaskComments({
  taskId,
  userId,
  notDeletableComments,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const [
    taskCommentsQuery,
    { data: commentsData, error: commentsLoadingError, loading: commentsLoading },
  ] = useGetTaskCommentsLazyQuery();

  useEffect(() => {
    if (taskId) {
      taskCommentsQuery({
        variables: {
          taskId,
        },
      });
    }
  }, [taskCommentsQuery, taskId]);

  const scrollToBottom = (): void => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollBy(0, messagesEndRef.current.clientHeight);
    }
  };

  useEffect(scrollToBottom, [commentsData?.getTaskComments]);

  return (
    <>
      {commentsLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="150px">
          <CircularProgress size={40} />
        </Box>
      )}
      {commentsData && Boolean(commentsData.getTaskComments.length) && (
        <div ref={messagesEndRef} className={classes.wrapper}>
          {commentsData.getTaskComments.map((commentData) => {
            const { id, content, createdAt, updatedAt, author } = commentData;
            if (commentData.author && commentData.author.id === userId) {
              return (
                <OwnComment
                  key={`owncomment${id}`}
                  id={id}
                  content={content}
                  createdAt={createdAt}
                  updatedAt={updatedAt}
                  notDeletable={notDeletableComments}
                />
              );
            }
            return (
              <SingleComment
                key={`singlecomment${id}`}
                author={author || undefined}
                content={content}
                createdAt={createdAt}
                updatedAt={updatedAt}
              />
            );
          })}
        </div>
      )}
      {commentsData && commentsData.getTaskComments.length === 0 && (
        <Box mb={2} display="flex" justifyContent="center" alignItems="center">
          <Box mr={1}>
            <SpeakerNotesOffIcon fontSize="small" color="secondary" />
          </Box>
          <Typography variant="body2" color="secondary" align="center">
            {t("Comments are empty.")}
          </Typography>
        </Box>
      )}
      {userId && (
        <Box mt={3}>
          <CreateComment taskId={taskId} />
        </Box>
      )}
      <ErrorDisplay
        error={commentsLoadingError}
        message={t(
          "Error occured while getting task's comments, please try again or contact us."
        )}
      />
    </>
  );
}
