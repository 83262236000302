import React from "react";
import { useResizeDetector } from "react-resize-detector";
import { PropsChild } from "../../../../../interfaces/PropsChild";

interface Props extends PropsChild {
  onResize?: (width?: number | undefined, height?: number | undefined) => void;
}

export default function ColumnSpacing({ children, onResize }: Props): JSX.Element {
  const { ref } = useResizeDetector({ onResize });

  return (
    <div ref={ref} style={{ width: "31%", minWidth: "240px", marginRight: "7px" }}>
      {children}
    </div>
  );
}
