import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { SHORT_DATE_FORMAT_WITH_HOUR } from "../../../../utils/consts";
import theme, { blueColorBackground } from "../../../../utils/theme";
import { updateCommentContentFragment } from "../../graphql/fragments";
import { UpdateCommentContentFragment } from "../../graphql/fragments.generated";
import EditCommentDialog from "./EditCommentDialog";
import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  useDeleteCommentMutation,
  useUpdateCommentMutation,
} from "../../graphql/mutation.generated";

interface Props {
  id: string;
  content: string;
  createdAt: string;
  updatedAt?: string;
  notDeletable?: boolean;
}

const useStyles = makeStyles({
  commentWrapper: {
    minWidth: "60%",
    maxWidth: "85%",
  },
  commentValue: {
    backgroundColor: blueColorBackground,
    borderRadius: theme.shape.borderRadius,
  },
  captions: {
    fontSize: "10px",
    fontWeight: 500,
  },
});

export default function OwnComment({
  content,
  updatedAt,
  createdAt,
  id,
  notDeletable,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  const [deleteCommentDialogOpen, setDeleteCommentDialogOpen] = useState<boolean>(false);
  const [editCommentDialogOpen, setEditCommentDialogOpen] = useState<boolean>(false);

  const [
    deleteCommentMutation,
    { loading: deleteCommentLoading, error: deleteCommentError },
  ] = useDeleteCommentMutation({
    update: (cache, response) => {
      if (response.data?.deleteComment) {
        cache.modify({
          fields: {
            getTaskComments(existingComments = [], { readField }) {
              return existingComments.filter(
                (commentRef) => id !== readField("id", commentRef)
              );
            },
          },
        });
      }
    },
  });

  const [
    updateCommentMutation,
    { loading: updateCommentLoading, error: updateCommentError },
  ] = useUpdateCommentMutation({
    update: (cache, response) => {
      if (response.data?.updateComment) {
        const {
          content: updatedContent,
          updatedAt: updatedUpdatedDate,
        } = response.data.updateComment;
        const commentFragmentId = `Comment:${id}`;
        const commentFragment: UpdateCommentContentFragment | null = cache.readFragment({
          id: commentFragmentId,
          fragment: updateCommentContentFragment,
        });

        if (commentFragment) {
          const updatedCommentFragment: UpdateCommentContentFragment = {
            ...commentFragment,
            content: updatedContent,
            updatedAt: updatedUpdatedDate,
          };
          cache.writeFragment({
            id: commentFragmentId,
            fragment: updateCommentContentFragment,
            data: updatedCommentFragment,
          });
        }
      }
    },
  });

  const deleteComment = async (): Promise<void> => {
    setDeleteCommentDialogOpen(false);
    try {
      await deleteCommentMutation({ variables: { id } });
    } catch (err) {
      //
    }
  };

  const updateCommentContent = async (newContent: string): Promise<void> => {
    setEditCommentDialogOpen(false);
    try {
      await updateCommentMutation({
        variables: {
          updateCommentData: {
            content: newContent,
            id,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            {(deleteCommentLoading || updateCommentLoading) && (
              <CircularProgress size={20} />
            )}
          </Box>
          <Box>
            <Tooltip title={t("Edit comment") || ""}>
              <IconButton
                size="small"
                onClick={(): void => setEditCommentDialogOpen(true)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          {!notDeletable && (
            <Box>
              <Tooltip title={t("Delete comment") || ""}>
                <IconButton
                  onClick={(): void => setDeleteCommentDialogOpen(true)}
                  size="small"
                >
                  <DeleteForeverIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
        <Box className={classes.commentWrapper}>
          <Box>
            <Typography variant="caption" color="secondary" className={classes.captions}>
              {t("You wrote")}:
            </Typography>
          </Box>
          <Box className={classes.commentValue} py={1} px={2}>
            <Typography variant="body2">{content}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={-0.5}>
            <Box mr={2}>
              <Typography
                variant="caption"
                color="secondary"
                className={classes.captions}
              >
                {t("Created at")} {moment(createdAt).format(SHORT_DATE_FORMAT_WITH_HOUR)}
              </Typography>
            </Box>
            {updatedAt && (
              <Box>
                <Typography
                  variant="caption"
                  color="secondary"
                  className={classes.captions}
                >
                  {t("Updated at")}{" "}
                  {moment(createdAt).format(SHORT_DATE_FORMAT_WITH_HOUR)}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <ConfirmDialog
        open={deleteCommentDialogOpen}
        onDecline={(): void => setDeleteCommentDialogOpen(false)}
        onConfirm={deleteComment}
        dialogContent={t(
          "Your comment will be deleted, this action can not be reversed, are you sure?"
        )}
        dialogTitle={t("Are you sure?")}
      />
      {editCommentDialogOpen && (
        <EditCommentDialog
          content={content}
          onClose={(): void => setEditCommentDialogOpen(false)}
          open
          onSubmit={updateCommentContent}
        />
      )}
      <ErrorDisplay
        error={deleteCommentError}
        message={t(
          "Error occured while deleting comment, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={updateCommentError}
        message={t(
          "Error occured while updating comment, please try again or contact us."
        )}
      />
    </>
  );
}
