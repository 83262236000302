import { makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { useTranslation } from "react-i18next";
import theme from "../../utils/theme";

interface Props<T> {
  options: T[];
  pickedValues: T[];
  namesToDisplay: {
    name: string;
    optionId: string;
  }[];
  onChange: (value: T[]) => void;
  renderTags?: (values: T[]) => JSX.Element;
  placeholder: string;
  size?: "medium" | "small";
  outlined?: boolean;
  label?: string;
  disabled?: boolean;
}

const useStyles = makeStyles({
  dataField: {
    "& .MuiInputBase-input, & .MuiAutocomplete-input, & .MuiInputLabel-root": {
      fontSize: theme.typography.body2.fontSize,
    },
  },
});

export default function MultiSelectWithSearch<T extends { id: string }>({
  onChange,
  options,
  pickedValues,
  namesToDisplay,
  renderTags,
  placeholder,
  size,
  outlined,
  label,
  disabled,
}: Props<T>): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  const disableUnderline = outlined ? {} : { disableUnderline: true };

  return (
    <Autocomplete
      noOptionsText={t("No options")}
      renderTags={renderTags}
      options={options}
      value={pickedValues?.length ? pickedValues : []}
      onChange={(e, v): void => onChange(v)}
      multiple
      fullWidth
      size={size || "small"}
      disableClearable
      getOptionLabel={(user: T): string =>
        namesToDisplay.find((displayName) => displayName.optionId === user.id)?.name || ""
      }
      getOptionSelected={(option: T, value: T): boolean => {
        const valueStringed = JSON.stringify(value);
        return valueStringed.indexOf(option.id) > -1;
      }}
      disabled={disabled}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          disabled={disabled}
          // this any must stay (material ui shit)
          variant={outlined ? ("outlined" as any) : ("standard" as any)}
          className={classes.dataField}
          placeholder={placeholder}
          label={label}
          InputProps={{
            ...disableUnderline,
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}
