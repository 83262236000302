import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import TaskStatusSelect from "../../common/TaskSelectFields/TaskStatusSelect";
import { useChangeTaskStatusMutation } from "../../graphql/mutation.generated";

interface Props {
  taskStatus: string;
  workplaceId: string;
  taskId: string;
  disabled?: boolean;
}

export default function TaskStatus({
  taskStatus,
  workplaceId,
  taskId,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [
    changeTaskStatusMutation,
    { error: changeTaskStatusError, loading },
  ] = useChangeTaskStatusMutation();

  const onStatusChange = async (value: string): Promise<void> => {
    try {
      await changeTaskStatusMutation({
        variables: {
          changeTaskStatusData: {
            id: taskId,
            taskStatusId: value,
          },
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <>
      <TaskStatusSelect
        onStatusChange={(status): void => {
          if (status) {
            onStatusChange(status);
          }
        }}
        workplaceId={workplaceId}
        taskStatus={taskStatus}
        disabled={disabled || loading}
      />
      <ErrorDisplay
        error={changeTaskStatusError}
        message={t(
          "Error occured while changing tasks status, please try again or contact us."
        )}
      />
    </>
  );
}
