import { Box, IconButton } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDateTimePicker from "../../../../components/CustomDateTimePicker/CustomDateTimePicker";
import EditableInputAdornment from "../../../../components/EditableInputAdornment/EditableInputAdornment";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { useUpdatePatrolEndDateMutation } from "../../graphql/mutation.generated";

interface Props {
  patrolFinishDate: string;
  disabled?: boolean;
  patrolId: string;
}
export default function PatrolFinishDate({
  patrolFinishDate,
  patrolId,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [updateEndDateMutation, { error, loading }] = useUpdatePatrolEndDateMutation();

  const onFinishDateChange = async (date: MaterialUiPickersDate): Promise<void> => {
    const newValue = date ? moment(date).toISOString() : null;
    try {
      await updateEndDateMutation({
        variables: { updatePatrolEndDateData: { id: patrolId, endDate: newValue } },
      });
    } catch (e) {
      //
    }
  };

  const onDateClear = (): void => {
    onFinishDateChange(null);
  };
  return (
    <>
      <Box display="flex">
        <CustomDateTimePicker
          disabled={disabled || loading}
          placeholder={t("Pick patrol finish date")}
          value={patrolFinishDate}
          onChange={(e): Promise<void> => onFinishDateChange(e)}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <EditableInputAdornment display={Boolean(patrolFinishDate) && !disabled} />
            ),
          }}
          size="small"
        />
        {patrolFinishDate && !disabled && (
          <Box display="flex" alignItems="center" pb={0.5}>
            <IconButton size="small" onClick={onDateClear}>
              <CloseOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>

      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing finish date, please try again or contact us"
        )}
        codes={{
          BAD_USER_INPUT: t(
            "Provided date is invalid, check if date is not before patrol start date."
          ),
        }}
      />
    </>
  );
}
