import { makeStyles, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import theme from "../../../../utils/theme";
import { useUpdateTaskNameMutation } from "../../graphql/mutation.generated";

interface Props {
  taskName: string;
  taskId: string;
  disabled?: boolean;
}

const useStyles = makeStyles({
  nameInplace: {
    "& .MuiInputBase-input": {
      ...theme.typography.h4,
    },
  },
});

export default function TaskName({ taskName, taskId, disabled }: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  const [
    updateTaskNameMutation,
    { error: updateTaskNameError },
  ] = useUpdateTaskNameMutation();

  const [name, setName] = useState<string>("");

  useEffect(() => {
    if (taskName) {
      setName(taskName);
    }
  }, [taskName]);

  const changeNameState = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value);
  };

  const onNameBlur = async (event: React.FocusEvent<HTMLInputElement>): Promise<void> => {
    const newName = event.target.value;
    if (newName && newName !== taskName) {
      try {
        await updateTaskNameMutation({
          variables: {
            updateTaskNameData: {
              id: taskId,
              name: newName,
            },
          },
        });
      } catch (e) {
        //
      }
    }
    if (!newName) {
      setName(taskName);
    }
  };

  return (
    <>
      <TextField
        className={classes.nameInplace}
        fullWidth
        InputProps={{ disableUnderline: true }}
        value={name}
        placeholder={t("Task name must be filled!")}
        onBlur={onNameBlur}
        onChange={changeNameState}
        disabled={disabled}
      />
      <ErrorDisplay
        error={updateTaskNameError}
        message={t("Error occured while updating task name, try again or contact us.")}
      />
    </>
  );
}
