import { useApolloClient } from "@apollo/client";
import useClearAllContexts from "../../context/utils/useClearAllContexts";
import { useAuthToken } from "./useAuthToken";

const useLogout = (): [() => void] => {
  const clearAllContexts = useClearAllContexts();
  const [, , removeAuthCookie] = useAuthToken();
  const apolloClient = useApolloClient();

  const logout = (): void => {
    apolloClient.cache.reset();
    apolloClient.clearStore();
    clearAllContexts();
    removeAuthCookie();
    localStorage.clear();
  };

  return [logout];
};

export default useLogout;
