import { TableRow, withStyles } from "@material-ui/core";

export const CustomPaginationFooterRow = withStyles({
  footer: {
    borderBottom: "none",
    "& .MuiTableCell-footer": {
      borderBottom: "none",
    },
  },
})(TableRow);
