import * as Types from "../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type ContactUsMutationVariables = Types.Exact<{
  contactUsData: Types.ContactUsData;
}>;

export type ContactUsMutation = { __typename?: "Mutation"; contactUs: boolean };

export const ContactUsDocument = gql`
  mutation ContactUs($contactUsData: ContactUsData!) {
    contactUs(contactUsData: $contactUsData)
  }
`;
export type ContactUsMutationFn = Apollo.MutationFunction<
  ContactUsMutation,
  ContactUsMutationVariables
>;

/**
 * __useContactUsMutation__
 *
 * To run a mutation, you first call `useContactUsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactUsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactUsMutation, { data, loading, error }] = useContactUsMutation({
 *   variables: {
 *      contactUsData: // value for 'contactUsData'
 *   },
 * });
 */
export function useContactUsMutation(
  baseOptions?: Apollo.MutationHookOptions<ContactUsMutation, ContactUsMutationVariables>
) {
  return Apollo.useMutation<ContactUsMutation, ContactUsMutationVariables>(
    ContactUsDocument,
    baseOptions
  );
}
export type ContactUsMutationHookResult = ReturnType<typeof useContactUsMutation>;
export type ContactUsMutationResult = Apollo.MutationResult<ContactUsMutation>;
export type ContactUsMutationOptions = Apollo.BaseMutationOptions<
  ContactUsMutation,
  ContactUsMutationVariables
>;
