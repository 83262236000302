import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { black15Opacity } from "../../../../../utils/theme";

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: black15Opacity,
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  headerText: {
    textTransform: "uppercase",
  },
});

export default function ColumnHeader({ title }: { title: string }): JSX.Element {
  const classes = useStyles();
  return (
    <Box pt={1.5} pb={1} pl={2} mb={1.5} className={classes.wrapper}>
      <Typography variant="body2" color="secondary" className={classes.headerText}>
        {title}
      </Typography>
    </Box>
  );
}
