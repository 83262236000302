import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  headText: string;
  globalMb?: number;
  headerMb?: number;
  leftCutIn?: number;
  children: JSX.Element;
}
const useStyles = makeStyles({
  fieldHeader: {
    // color: theme.palette.secondary.dark,
    fontSize: "0.85rem",
    fontWeight: 500,
  },
});

export default function DataListItemDisplay({
  headText,
  globalMb,
  headerMb,
  leftCutIn = 1,
  children,
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Box mb={globalMb || 4}>
      <Box mb={headerMb || 0.5}>
        <Typography className={classes.fieldHeader}>{headText}</Typography>
      </Box>
      <Box pl={leftCutIn}>{children}</Box>
    </Box>
  );
}
