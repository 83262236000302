import { makeStyles, Typography } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import React from "react";
import DueDateDisplay from "../../../../../components/DueDateDisplay/DueDateDisplay";
import FinishDateDisplay from "../../../../../components/FinishDateDisplay/FinishDateDisplay";
import UserIcon from "../../../../../components/UserIcon/UserIcon";
import theme from "../../../../../utils/theme";
import useGetStatusStyles from "../../../../../utils/useGetStatusStyles";
import TaskFriendlyId from "../../../common/TaskFriendlyId";
import { TaskListDataFragment } from "../../../graphql/fragments.generated";
import useOpenTaskDialog from "../../../TaskDetailsDialog/useOpenTaskDialog";

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: "#fff",
    boxSizing: "border-box",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: "100px",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  nameRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  taskName: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    flex: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  location: {
    marginBottom: theme.spacing(1),
    display: "flex",
  },
  bottomRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default function TaskCard({
  taskData,
  isChild,
}: {
  taskData: TaskListDataFragment;
  isChild?: boolean;
}): JSX.Element {
  const { getBackgroundColor } = useGetStatusStyles();
  const { name, location, dueDate, assignees, friendlyId, finishedDate } = taskData;
  const classes = useStyles();
  const { openTaskDialog } = useOpenTaskDialog();
  const assigneeName = assignees.length
    ? `${assignees[0].firstName} ${assignees[0].lastName}`
    : "";

  return (
    <div
      className={classes.wrapper}
      onClick={(): void => openTaskDialog(taskData.id)}
      style={{ backgroundColor: getBackgroundColor(dueDate, finishedDate) }}
    >
      <div className={classes.nameRow}>
        <div className={classes.taskName}>
          <Typography variant="body2">{name}</Typography>
        </div>
        {assigneeName && <UserIcon name={assigneeName} />}
      </div>

      <div className={classes.location}>
        {location && (
          <>
            <div style={{ marginRight: theme.spacing(0.5) }}>
              <LocationOnIcon color="secondary" fontSize="small" />
            </div>
            <Typography variant="body2" color="secondary">
              {location}
            </Typography>
          </>
        )}
      </div>
      <div className={classes.bottomRow}>
        {finishedDate ? (
          <FinishDateDisplay finishDate={finishedDate} />
        ) : (
          <DueDateDisplay date={dueDate} />
        )}
        <TaskFriendlyId friendlyId={friendlyId} isChild={isChild} />
      </div>
    </div>
  );
}
