import React from "react";
import { Route, Switch } from "react-router-dom";
import HelmetWrapper from "../components/HelmetWrapper/HelmetWrapper";
import ProtectedRoute from "../utils/AuthUtils/ProtectedRoute";
import PasswordChangedPage from "../views/Identity/PasswordChange/PasswordChangedPage/PasswordChangedPage";
import ResetPasswordEmailSentPage from "../views/Identity/PasswordChange/ResetPasswordEmailSentPage/ResetPasswordEmailSentPage";
import ResetPasswordInitializePage from "../views/Identity/PasswordChange/ResetPasswordInitializePage/ResetPasswordInitializePage";
import SetNewPasswordPage from "../views/Identity/PasswordChange/SetNewPasswordPage/SetNewPasswordPage";
import SignInPage from "../views/Identity/SignInPage/SignInPage";
import ActivateAccountPage from "../views/Identity/SignUp/ActivateAccountPage/ActivateAccountPage";
import SignUpCompletedPage from "../views/Identity/SignUp/SignUpCompletedPage/SignUpCompletedPage";
import SignUpFromLinkPage from "../views/Identity/SignUp/SignUpFromLinkPage/SignUpFromLinkPage";
import SignUpPage from "../views/Identity/SignUp/SignUpPage/SignUpPage";
import VerifyEmailPage from "../views/Identity/SignUp/VerifyEmailPage/VerifyEmailPage";
import SignedInUserRoutes from "./SignedInUserRoutes";
import {
  ACTIVATE_ACCOUNT,
  PASSWORD_CHANGED,
  RESET_PASSWORD,
  RESET_PASSWORD_EMAIL_SENT,
  SET_NEW_PASSWORD,
  SIGN_IN,
  SIGN_UP,
  SIGN_UP_COMPLETED,
  SIGN_UP_FROM_LINK,
  VERIFY_EMAIL,
} from "./paths";

export default function RouterComponent(): JSX.Element {
  return (
    <Switch>
      <Route
        exact
        path={SIGN_IN}
        render={(): JSX.Element => (
          <HelmetWrapper siteTitle="Sign in">
            <SignInPage />
          </HelmetWrapper>
        )}
      />

      <Route
        exact
        path={SIGN_UP}
        render={(): JSX.Element => (
          <HelmetWrapper siteTitle="Sign up">
            <SignUpPage />
          </HelmetWrapper>
        )}
      />
      <Route
        exact
        path={SIGN_UP_FROM_LINK}
        render={(): JSX.Element => (
          <HelmetWrapper siteTitle="Finish sign up">
            <SignUpFromLinkPage />
          </HelmetWrapper>
        )}
      />
      <Route
        exact
        path={SIGN_UP_COMPLETED}
        render={(): JSX.Element => (
          <HelmetWrapper siteTitle="Sign up completed">
            <SignUpCompletedPage />
          </HelmetWrapper>
        )}
      />
      <Route
        exact
        path={VERIFY_EMAIL}
        render={(): JSX.Element => (
          <HelmetWrapper siteTitle="Verify email">
            <VerifyEmailPage />
          </HelmetWrapper>
        )}
      />

      <Route
        exact
        path={RESET_PASSWORD}
        render={(): JSX.Element => (
          <HelmetWrapper siteTitle="Initialize password reset">
            <ResetPasswordInitializePage />
          </HelmetWrapper>
        )}
      />
      <Route
        exact
        path={RESET_PASSWORD_EMAIL_SENT}
        render={(): JSX.Element => (
          <HelmetWrapper siteTitle="Reset password email sent">
            <ResetPasswordEmailSentPage />
          </HelmetWrapper>
        )}
      />
      <Route
        exact
        path={SET_NEW_PASSWORD}
        render={(): JSX.Element => (
          <HelmetWrapper siteTitle="Set new password">
            <SetNewPasswordPage />
          </HelmetWrapper>
        )}
      />
      <Route
        exact
        path={PASSWORD_CHANGED}
        render={(): JSX.Element => (
          <HelmetWrapper siteTitle="Password changed">
            <PasswordChangedPage />
          </HelmetWrapper>
        )}
      />
      <Route
        exact
        path={ACTIVATE_ACCOUNT}
        render={(): JSX.Element => (
          <HelmetWrapper siteTitle="Activate account">
            <ActivateAccountPage />
          </HelmetWrapper>
        )}
      />

      {/* Routes after login */}
      <ProtectedRoute path="/" render={(): JSX.Element => <SignedInUserRoutes />} />
    </Switch>
  );
}
