import { ApolloError } from "@apollo/client";
import { useEffect, useState } from "react";
import { MANAGER_ROLE_ID } from "../../../utils/consts";
import { useGetAllRolesLazyQuery } from "../graphql/query.generated";
import { RoleToPick } from "./interfaces";

interface Props {
  companyId: string | undefined;
}

interface ReturnObject {
  loading?: boolean;
  error?: ApolloError;
  data: RoleToPick[];
}

export default function useGetRolesToPick({ companyId }: Props): ReturnObject {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<RoleToPick[]>([]);
  const [
    getAllRoles,
    { data: rolesData, error, loading: queryLoading },
  ] = useGetAllRolesLazyQuery();

  useEffect(() => {
    if (companyId) {
      getAllRoles({ variables: { companyId } });
    }
  }, [companyId, getAllRoles]);

  useEffect(() => {
    if (!queryLoading) {
      setLoading(false);
    }
  }, [queryLoading]);

  useEffect(() => {
    if (rolesData) {
      const roles: RoleToPick[] = [];
      rolesData.getCompanyRoles.forEach((companyRole) => {
        if (companyRole.id === MANAGER_ROLE_ID) {
          roles.push({
            name: companyRole.name,
            id: companyRole.id,
            isManager: true,
          });
        }
      });
      rolesData.getWorkplaceRoles.forEach((workplaceRole) => {
        roles.push({
          name: workplaceRole.name,
          id: workplaceRole.id,
          isManager: false,
        });
      });
      setData(roles);
    }
  }, [rolesData]);

  return { loading, error, data };
}
