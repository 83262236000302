import { Box, Button, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomSnackbar from "../../../components/CustomSnackbar/CustomSnackbar";
import CustomDialog from "../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import { updatedUserRolesFragment } from "../../Settings/graphql/fragments";
import { UpdateUserRolesFragment } from "../../Settings/graphql/fragments.generated";
import { useDeleteUserFromTeaMutation } from "../graphql/mutation.generated";

interface Props {
  onClose: () => void;
  userId: string;
  userWorkplaceRoleId: string;
  userEmail: string;
}
export default function DeleteTeamUserDialog({
  onClose,
  userId,
  userWorkplaceRoleId,
  userEmail,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [emailValue, setEmailValue] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  const [
    deleteUserFromTeamMutation,
    { error: deleteTeamUserError, loading: deleteTeamUserLoading },
  ] = useDeleteUserFromTeaMutation();

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailValue(event.target.value);
  };

  const deleteUser = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    if (emailValue !== userEmail) {
      setErrorMsg(t("This email address does not match user's email address."));
    } else {
      try {
        const res = await deleteUserFromTeamMutation({
          variables: {
            id: userWorkplaceRoleId,
          },
          update: (cache, response) => {
            if (response.data?.deleteUserWorkplaceRole) {
              cache.modify({
                fields: {
                  getWorkplaceUsers(existingUsers = [], { readField }) {
                    return existingUsers.filter(
                      (userRef) => userId !== readField("id", userRef)
                    );
                  },
                },
              });

              const userFragmentId = `User:${userId}`;
              const userData: UpdateUserRolesFragment | null = cache.readFragment({
                id: userFragmentId,
                fragment: updatedUserRolesFragment,
              });
              if (userData) {
                const filteredRoles = userData.userWorkplaceRoles?.length
                  ? userData.userWorkplaceRoles.filter(
                      (role) => role.id !== userWorkplaceRoleId
                    )
                  : [];
                const newUserData: UpdateUserRolesFragment = {
                  ...userData,
                  userWorkplaceRoles: filteredRoles,
                };
                cache.writeFragment({
                  id: userFragmentId,
                  fragment: updatedUserRolesFragment,
                  data: newUserData,
                });
              }
            }
          },
        });
        if (res.data?.deleteUserWorkplaceRole) {
          onClose();
        }
      } catch (e) {
        //
      }
    }
  };

  return (
    <>
      <CustomDialog loading={deleteTeamUserLoading} open onClose={onClose} maxWidth="md">
        <CustomDialogContent heading={`${t("Remove team user")}`}>
          <Box mb={3}>
            <Typography>
              {t(
                "If you are sure that you want to remove this user from team, enter his/her email address"
              )}
            </Typography>
          </Box>
          <form onSubmit={deleteUser}>
            <Box mb={2}>
              <TextField
                value={emailValue}
                onChange={onEmailChange}
                fullWidth
                variant="outlined"
                placeholder={t("Enter user email address")}
              />
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!emailValue}
            >
              {t("Remove user from team")}
            </Button>
          </form>
        </CustomDialogContent>
      </CustomDialog>
      <ErrorDisplay
        error={deleteTeamUserError}
        message={t(
          "Error occured while removing team user, please try again or contact us."
        )}
      />
      <CustomSnackbar
        open={Boolean(errorMsg)}
        message={errorMsg}
        handleClose={(): void => setErrorMsg("")}
      />
    </>
  );
}
