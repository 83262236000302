import * as Types from "../../../generated/types";

import { TaskStatusFragment } from "./fragments.generated";
import { gql } from "@apollo/client";
import { TaskStatusFragmentDoc } from "./fragments.generated";
import * as Apollo from "@apollo/client";
export type GetWorkplaceTaskStatusesForSettingsQueryVariables = Types.Exact<{
  workplaceId: Types.Scalars["UUID"];
}>;

export type GetWorkplaceTaskStatusesForSettingsQuery = {
  __typename?: "Query";
  getWorkplaceTaskStatuses: Array<{ __typename?: "TaskStatus" } & TaskStatusFragment>;
};

export const GetWorkplaceTaskStatusesForSettingsDocument = gql`
  query GetWorkplaceTaskStatusesForSettings($workplaceId: UUID!) {
    getWorkplaceTaskStatuses(workplaceId: $workplaceId) {
      ...TaskStatus
    }
  }
  ${TaskStatusFragmentDoc}
`;

/**
 * __useGetWorkplaceTaskStatusesForSettingsQuery__
 *
 * To run a query within a React component, call `useGetWorkplaceTaskStatusesForSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplaceTaskStatusesForSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplaceTaskStatusesForSettingsQuery({
 *   variables: {
 *      workplaceId: // value for 'workplaceId'
 *   },
 * });
 */
export function useGetWorkplaceTaskStatusesForSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkplaceTaskStatusesForSettingsQuery,
    GetWorkplaceTaskStatusesForSettingsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetWorkplaceTaskStatusesForSettingsQuery,
    GetWorkplaceTaskStatusesForSettingsQueryVariables
  >(GetWorkplaceTaskStatusesForSettingsDocument, baseOptions);
}
export function useGetWorkplaceTaskStatusesForSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkplaceTaskStatusesForSettingsQuery,
    GetWorkplaceTaskStatusesForSettingsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetWorkplaceTaskStatusesForSettingsQuery,
    GetWorkplaceTaskStatusesForSettingsQueryVariables
  >(GetWorkplaceTaskStatusesForSettingsDocument, baseOptions);
}
export type GetWorkplaceTaskStatusesForSettingsQueryHookResult = ReturnType<
  typeof useGetWorkplaceTaskStatusesForSettingsQuery
>;
export type GetWorkplaceTaskStatusesForSettingsLazyQueryHookResult = ReturnType<
  typeof useGetWorkplaceTaskStatusesForSettingsLazyQuery
>;
export type GetWorkplaceTaskStatusesForSettingsQueryResult = Apollo.QueryResult<
  GetWorkplaceTaskStatusesForSettingsQuery,
  GetWorkplaceTaskStatusesForSettingsQueryVariables
>;
