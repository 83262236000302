import { gql } from "@apollo/client";

export const companyUser = gql`
  fragment CompanyUser on User {
    id
    email
    firstName
    lastName
    isActive
    userCompanyRoles {
      id
      companyRole {
        id
        name
      }
      company {
        id
      }
    }
    userWorkplaceRoles {
      id
      workplaceRole {
        id
        name
      }
      workplace {
        id
      }
    }
  }
`;

export const updatedUserRolesFragment = gql`
  fragment UpdateUserRoles on User {
    userWorkplaceRoles {
      id
    }
  }
`;
