import * as Types from "../../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type GetWorkplacesForAdminQueryVariables = Types.Exact<{
  companyId: Types.Scalars["UUID"];
}>;

export type GetWorkplacesForAdminQuery = {
  __typename?: "Query";
  getWorkplaces: Array<{ __typename?: "Workplace"; id: any; name: string }>;
};

export const GetWorkplacesForAdminDocument = gql`
  query GetWorkplacesForAdmin($companyId: UUID!) {
    getWorkplaces(companyId: $companyId) {
      id
      name
    }
  }
`;

/**
 * __useGetWorkplacesForAdminQuery__
 *
 * To run a query within a React component, call `useGetWorkplacesForAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplacesForAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplacesForAdminQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetWorkplacesForAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkplacesForAdminQuery,
    GetWorkplacesForAdminQueryVariables
  >
) {
  return Apollo.useQuery<GetWorkplacesForAdminQuery, GetWorkplacesForAdminQueryVariables>(
    GetWorkplacesForAdminDocument,
    baseOptions
  );
}
export function useGetWorkplacesForAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkplacesForAdminQuery,
    GetWorkplacesForAdminQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetWorkplacesForAdminQuery,
    GetWorkplacesForAdminQueryVariables
  >(GetWorkplacesForAdminDocument, baseOptions);
}
export type GetWorkplacesForAdminQueryHookResult = ReturnType<
  typeof useGetWorkplacesForAdminQuery
>;
export type GetWorkplacesForAdminLazyQueryHookResult = ReturnType<
  typeof useGetWorkplacesForAdminLazyQuery
>;
export type GetWorkplacesForAdminQueryResult = Apollo.QueryResult<
  GetWorkplacesForAdminQuery,
  GetWorkplacesForAdminQueryVariables
>;
