import { Box, Button, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StyledDateRangePicker from "../../../components/StyledDateRangePicker/StyledDateRangePicker";
import { QueryVariablesEnum } from "../../../interfaces/QueryVariables";
import useManageUrlQueryVariables from "../../../utils/useManageUrlQueryVariables";

export default function PatrolFilters(): JSX.Element {
  const { t } = useTranslation();

  const {
    setUrlQueryVariables,
    deleteUrlQueryVariables,
    patrolFilters,
  } = useManageUrlQueryVariables();

  const [minDate, setMinDate] = useState<moment.Moment | null>(null);
  const [maxDate, setMaxDate] = useState<moment.Moment | null>(null);
  const [focus, setFocus] = useState<"startDate" | "endDate" | null>(null);

  useEffect(() => {
    if (patrolFilters) {
      const filters = JSON.parse(patrolFilters);
      if (filters?.startDate.minDate) {
        const momentedMinDate = moment(filters.startDate.minDate);
        setMinDate(momentedMinDate);
      }
      if (filters?.startDate.maxDate) {
        const momentedMaxDate = moment(filters.startDate.maxDate);
        setMaxDate(momentedMaxDate);
      }
    }
  }, [patrolFilters]);

  const onFilter = (): void => {
    if (minDate || maxDate) {
      setUrlQueryVariables(
        QueryVariablesEnum.PATROLFILTERS,
        JSON.stringify({
          startDate: {
            minDate: minDate ? minDate.startOf("day").toISOString() : null,
            maxDate: maxDate ? maxDate.endOf("day").toISOString() : null,
          },
        })
      );
    } else {
      deleteUrlQueryVariables(QueryVariablesEnum.PATROLFILTERS);
    }
  };

  return (
    <Box>
      <Typography variant="caption">{`${t("Filter by patrol start date")}:`}</Typography>
      <Box display="flex" alignItems="center">
        <Box width="250px">
          <StyledDateRangePicker
            startDate={minDate}
            startDateId="patrolStartDate"
            endDate={maxDate}
            endDateId="patrolFinishDate"
            onDatesChange={({ startDate: sD, endDate: eD }): void => {
              setMinDate(sD);
              setMaxDate(eD);
            }}
            focusedInput={focus}
            onFocusChange={(focusedInput): void => setFocus(focusedInput)}
            startDatePlaceholderText={t("Min date")}
            endDatePlaceholderText={t("Max date")}
          />
        </Box>
        <Box>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={(): void => onFilter()}
          >
            {t("Filter")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
