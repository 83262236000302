import { Box, Divider } from "@material-ui/core";
import React from "react";
import { PropsChild } from "../../../../interfaces/PropsChild";

type Props = PropsChild;

export default function RoleDisplayWrapper({ children }: Props): JSX.Element {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" pb={2}>
        {children}
      </Box>
      <Divider />
    </Box>
  );
}
