import { gql } from "@apollo/client";

export const GET_USER_FIRST_NAME = gql`
  query GetUserFirstName {
    getUser {
      id
      firstName
    }
  }
`;

export const GET_UNREAD_NOTIFICATIONS_COUNT = gql`
  query GetUnreadNotificationsCount {
    getUnreadNotificationsCount
  }
`;
