import { Pagination } from "@material-ui/lab";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import { WorkplaceContext } from "../../../context/WorkplaceContext/WorkplaceContextProvider";
import { QueryVariablesEnum } from "../../../interfaces/QueryVariables";
import BasicMenuLayout from "../../../layouts/BasicMenuLayout/BasicMenuLayout";
import FullHeightNoScrollPage from "../../../layouts/FullHeightNoScrollPage/FullHeightNoScrollPage";
import useManageUrlQueryVariables from "../../../utils/useManageUrlQueryVariables";
import { useGetWorkplaceArchivedTasksLazyQuery } from "../graphql/query.generated";
import TaskDetailsDialog from "../TaskDetailsDialog/TaskDetailsDialog";
import ArchivedTasksTable from "./ArchivedTasksTable";
import ActiveArchiveTaskFilters from "./ArchiveTaskFilters/ActiveArchiveTaskFilters";
import ArchiveTaskFilters from "./ArchiveTaskFilters/ArchiveTaskFilters";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

const PATROLS_PAGE_LENGTH = 10;

export default function ArchivedTasksPage(): JSX.Element {
  const { t } = useTranslation();
  const { chosenWorkplaceId } = useContext(WorkplaceContext);
  const {
    archivedTaskFilters,
    pageNumber: urlPageNumber,
    pageSize: urlPageSize,
    setUrlQueryVariables,
  } = useManageUrlQueryVariables();

  const [filtersOpened, setFiltersOpened] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(PATROLS_PAGE_LENGTH);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [
    archivedTasksQuery,
    { loading: loadingArchivedTasks, error: fetchingTasksError, data: archivedTasksData },
  ] = useGetWorkplaceArchivedTasksLazyQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (chosenWorkplaceId) {
      const parsedFilters = archivedTaskFilters ? JSON.parse(archivedTaskFilters) : null;
      archivedTasksQuery({
        variables: {
          workplaceId: chosenWorkplaceId,
          filters: parsedFilters,
          paginationData: {
            offset: pageSize * (pageNumber - 1),
            limit: pageSize,
          },
        },
      });
    }
  }, [archivedTaskFilters, archivedTasksQuery, chosenWorkplaceId, pageNumber, pageSize]);

  useEffect(() => {
    if (urlPageNumber) {
      setPageNumber(Number(urlPageNumber));
    }
    if (urlPageSize) {
      setPageSize(Number(urlPageSize));
    }
  }, [urlPageNumber, urlPageSize]);

  const pagesCount = useMemo<number>(() => {
    if (archivedTasksData?.getWorkplaceArchivedTasks.totalCount) {
      return Math.ceil(archivedTasksData.getWorkplaceArchivedTasks.totalCount / pageSize);
    }
    return 0;
  }, [archivedTasksData, pageSize]);

  const onPageSizeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUrlQueryVariables(QueryVariablesEnum.PAGESIZE, e.target.value);
  };

  const onPageNumberChange = (event, page: number): void => {
    setUrlQueryVariables(QueryVariablesEnum.PAGENUMBER, String(page));
  };

  const onFiltersClose = (): void => {
    setFiltersOpened(false);
  };

  return (
    <>
      <BasicMenuLayout>
        <FullHeightNoScrollPage mr={3}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            <Box>
              <Box display="flex" alignItems="center" mb={3}>
                <Button size="small" onClick={(): void => setFiltersOpened(true)}>
                  {t("+ Add filters")}
                </Button>
                <Box ml={2}>
                  <ActiveArchiveTaskFilters />
                </Box>
              </Box>
              <Box>
                {archivedTasksData?.getWorkplaceArchivedTasks.items && (
                  <ArchivedTasksTable
                    archivedTasks={archivedTasksData.getWorkplaceArchivedTasks.items}
                  />
                )}
                {archivedTasksData?.getWorkplaceArchivedTasks.items &&
                  !archivedTasksData.getWorkplaceArchivedTasks.items.length && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="500px"
                    >
                      <Typography variant="h4">
                        {t("No archived tasks to display.")}
                      </Typography>
                    </Box>
                  )}
                {loadingArchivedTasks && (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress size={30} />
                  </Box>
                )}
              </Box>
            </Box>

            <Box display="flex" py={2} justifyContent="space-between">
              <Box width="80px">
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  label={t("Page size")}
                  value={pageSize}
                  size="small"
                  onChange={onPageSizeChange}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </TextField>
              </Box>
              <Pagination
                count={pagesCount}
                color="primary"
                page={pageNumber}
                onChange={onPageNumberChange}
              />
            </Box>
          </Box>
        </FullHeightNoScrollPage>
      </BasicMenuLayout>

      <TaskDetailsDialog />
      {filtersOpened && (
        <ArchiveTaskFilters open={filtersOpened} onFiltersClose={onFiltersClose} />
      )}
      <ErrorDisplay
        error={fetchingTasksError}
        message={t(
          "Error occured while getting archived tasks, please try again later or contact us."
        )}
      />
    </>
  );
}
