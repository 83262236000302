import { createMuiTheme } from "@material-ui/core/styles";

export const black15Opacity = "#40403f2c";
export const black50Opacity = "#40403f7c";
export const black100Opacity = "#40403F";

export const successColor = "#22B940";
export const successBackground = "#E3FCEF";

export const warningColor = "#d6a00d";
export const warningColorBackground = "#fff6df";

export const dangerColor = "#B00020";
export const dangerColorBackground = "#fd999269";

export const blueColor = "#1D8FE6";
export const blueColorBackground = "#DEEBFF";

export const placeholderFontSize = "0.87rem";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#F9E19F",
      main: "#F5CB5C",
      dark: "#F2BD2C",
    },
    secondary: {
      light: "#D7D7D6",
      main: "#868683",
      dark: "#676765",
    },
  },
  typography: {
    fontFamily: "Roboto",
    h1: {
      fontSize: "3.75rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "2.75rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "2.25rem",
    },
    h4: {
      fontSize: "1.75rem",
    },
    h5: {
      fontSize: "1.25rem",
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 900,
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        "&::placeholder": {
          fontSize: placeholderFontSize,
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: placeholderFontSize,
      },
    },
    MuiTypography: {
      root: {
        wordWrap: "break-word",
      },
    },
  },
});

export default theme;
