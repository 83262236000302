import { Box, Button, makeStyles, Paper, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import moment from "moment";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import DataListItemDisplay from "../../../components/DataListItemDisplay/DataListItemDisplay";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FullScreenLoader from "../../../components/FullScreenLoader/FullScreenLoader";
import PdfGenerator from "../../../components/PdfGenerator/PdfGenerator";
import { CompanyContext } from "../../../context/CompanyContext/CompanyContextProvider";
import { UserContext } from "../../../context/UserContext/UserContextProvider";
import { WorkplaceContext } from "../../../context/WorkplaceContext/WorkplaceContextProvider";
import { Permissions } from "../../../generated/types";
import { QueryVariablesEnum } from "../../../interfaces/QueryVariables";
import BasicMenuLayout from "../../../layouts/BasicMenuLayout/BasicMenuLayout";
import { PATROLS_LIST, WORKPLACE } from "../../../routing/paths";
import useLazyIsUserAuthorised from "../../../utils/AuthUtils/useLazyIsUserAuthorised";
import theme from "../../../utils/theme";
import useManageUrlQueryVariables from "../../../utils/useManageUrlQueryVariables";
import TaskDetailsDialog from "../../Tasks/TaskDetailsDialog/TaskDetailsDialog";
import DeletePatrolDialog from "../DeletePatrolDialog/DeletePatrolDialog";
import { useGetPatrolQuery } from "../graphql/query.generated";
import AddPatrolEventDialog from "./patrolEvents/AddPatrolEventDialog";
import SinglePatrolEvent from "./patrolEvents/SinglePatrolEvent";
import PatrolAssignees from "./patrolFields/PatrolAssignees";
import PatrolDescription from "./patrolFields/PatrolDescription";
import PatrolFinishDate from "./patrolFields/PatrolFinishDate";
import PatrolLocation from "./patrolFields/PatrolLocation";
import PatrolStartDate from "./patrolFields/PatrolStartDate";
import PatrolPdf from "./patrolPdf/PatrolPdf";

const useStyles = makeStyles({
  infoColumnWrapper: {
    backgroundColor: theme.palette.primary.light,
    boxSizing: "border-box",
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    width: "280px",
  },
  eventWrapper: {
    maxHeight: "100%",
    height: "100%",
    overflowY: "auto",
    boxSizing: "border-box",
  },
});

export default function PatrolPage(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { id: userId } = useContext(UserContext);
  const { chosenWorkplaceId, workplaces } = useContext(WorkplaceContext);
  const { chosenCompanyId, companies } = useContext(CompanyContext);
  const { patrolId, workplaceId } = useManageUrlQueryVariables();
  const { isUserAuthorised } = useLazyIsUserAuthorised();

  const [addEventDialogOpen, setAddEventDialogOpen] = useState<boolean>(false);
  const [deletePatrolDialogOpen, setDeletePatrolDialogOpen] = useState<boolean>(false);

  const {
    data: patrolData,
    loading: loadingPatrolData,
    error: patrolDataLoadingError,
  } = useGetPatrolQuery({ variables: { id: patrolId } });

  // TODO create helper that checks permissions and permissions_own
  const canUserEdit = useMemo<boolean>((): boolean => {
    if (isUserAuthorised(Permissions.EditPatrol, workplaceId)) {
      return true;
    }
    if (patrolData?.getPatrol && userId) {
      const creatorId = patrolData.getPatrol.createdBy?.id;
      const isUserCreator = creatorId === userId;
      const isUserAssigned = patrolData.getPatrol.assignees?.find((a) => a.id === userId);
      if (
        (isUserCreator || isUserAssigned) &&
        isUserAuthorised(Permissions.EditPatrolOwn, workplaceId)
      ) {
        return true;
      }
    }
    return false;
  }, [isUserAuthorised, patrolData, userId, workplaceId]);

  const getCompanyname = useCallback((): string => {
    if (chosenCompanyId && companies) {
      return companies.find((c) => c.id === chosenCompanyId)?.name || "";
    }
    return "";
  }, [chosenCompanyId, companies]);

  const getWorkplaceName = useCallback((): string => {
    if (chosenWorkplaceId && workplaces) {
      return workplaces.find((w) => w.id === chosenWorkplaceId)?.name || "";
    }
    return "";
  }, [chosenWorkplaceId, workplaces]);

  // pdf generator must be in memo when used (some limitations of react-pdf/renderer)
  const pdfGeneratorMemo = useMemo(() => {
    if (patrolData) {
      return (
        <Box mr={2}>
          <PdfGenerator
            pdfContent={PatrolPdf({
              patrolData: patrolData.getPatrol,
              patrolEvents: patrolData.getPatrolEvents,
              t,
              companyName: getCompanyname,
              workplaceName: getWorkplaceName,
            })}
            fileName={`Patrol-${moment(patrolData.getPatrol.startDate).format(
              "DDMMYY-HH:mm"
            )}-rids-app`}
            loadingDisplay={
              <Button variant="outlined" disabled>
                {t("Download PDF")}
              </Button>
            }
          >
            <Button variant="outlined">{t("Download PDF")}</Button>
          </PdfGenerator>
        </Box>
      );
    }
    return <div />;
  }, [getCompanyname, getWorkplaceName, patrolData, t]);

  const onDeletePatrolDialogClosed = (isPatrolDeleted?: boolean): void => {
    setDeletePatrolDialogOpen(false);
    if (isPatrolDeleted && chosenWorkplaceId) {
      history.push(
        `${WORKPLACE + PATROLS_LIST}?${
          QueryVariablesEnum.WORKPLACEID
        }=${chosenWorkplaceId}`
      );
    }
  };

  const onBackClick = (): void => {
    history.push(
      `${WORKPLACE + PATROLS_LIST}?${QueryVariablesEnum.WORKPLACEID}=${chosenWorkplaceId}`
    );
  };

  return (
    <BasicMenuLayout workplaceChooseDisabled>
      <FullScreenLoader open={loadingPatrolData} />
      <Box display="flex" height="100%" maxHeight="100%">
        <Paper elevation={2} className={classes.infoColumnWrapper}>
          <Box mb={3}>
            <Button onClick={(): void => onBackClick()}>
              <Box display="flex">
                <Box mr={1}>
                  <ArrowBackIcon fontSize="small" />
                </Box>
                {t("go back")}
              </Box>
            </Button>
          </Box>
          {/* <Box display="flex" justifyContent="center" mb={1.5}>
              <DirectionsCarIcon style={{ fontSize: "4.5rem", color: black100Opacity }} />
            </Box> */}
          {patrolData?.getPatrol && (
            <Box pl={1}>
              <DataListItemDisplay headText={t("Start date")}>
                <PatrolStartDate
                  patrolId={patrolData.getPatrol.id}
                  patrolStartDate={patrolData.getPatrol.startDate}
                  disabled={!canUserEdit}
                />
              </DataListItemDisplay>
              <DataListItemDisplay headText={t("Finish date")}>
                <PatrolFinishDate
                  patrolId={patrolData.getPatrol.id}
                  patrolFinishDate={patrolData.getPatrol.endDate}
                  disabled={!canUserEdit}
                />
              </DataListItemDisplay>
              <DataListItemDisplay headText={t("Location")}>
                <PatrolLocation
                  patrolId={patrolData.getPatrol.id}
                  location={patrolData.getPatrol.location || ""}
                  disabled={!canUserEdit}
                />
              </DataListItemDisplay>
              <DataListItemDisplay headText={t("Assignees")}>
                <PatrolAssignees
                  patrolId={patrolData.getPatrol.id}
                  assigneesIds={patrolData.getPatrol.assignees?.map((a) => a.id) || []}
                  workplaceId={workplaceId}
                  disabled={!canUserEdit}
                />
              </DataListItemDisplay>
              <DataListItemDisplay headText={t("Description")}>
                <PatrolDescription
                  patrolId={patrolData.getPatrol.id}
                  description={patrolData.getPatrol.description || ""}
                  disabled={!canUserEdit}
                />
              </DataListItemDisplay>
              <Box>
                <Button
                  onClick={(): void => setDeletePatrolDialogOpen(true)}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                >
                  {t("Delete patrol")}
                </Button>
              </Box>
            </Box>
          )}
        </Paper>
        <Box py={2} px={3} width="100%" className={classes.eventWrapper}>
          {canUserEdit && (
            <Box mb={2} display="flex" justifyContent="flex-end">
              {pdfGeneratorMemo}

              <Button
                color="primary"
                variant="contained"
                onClick={(): void => setAddEventDialogOpen(true)}
              >
                <AddIcon fontSize="small" />
                &nbsp;
                {t("Add new event")}
              </Button>
            </Box>
          )}

          <Box px={2}>
            <Box pb={2}>
              <Typography variant="h5">{t("Patrol events")}:</Typography>
            </Box>
            {!!patrolData?.getPatrolEvents?.length &&
              patrolData.getPatrolEvents.map((patrolEvent) => (
                <Box key={`patrolEvent${patrolEvent.id}`} mb={3}>
                  <SinglePatrolEvent eventData={patrolEvent} canUserEdit={canUserEdit} />
                </Box>
              ))}
            {!patrolData?.getPatrolEvents?.length && !loadingPatrolData && (
              <Typography variant="h5" align="center" color="textSecondary">
                {t("No patrol events to display.")}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {deletePatrolDialogOpen && (
        <DeletePatrolDialog onClose={onDeletePatrolDialogClosed} patrolId={patrolId} />
      )}
      {patrolData?.getPatrol && (
        <AddPatrolEventDialog
          onClose={(): void => setAddEventDialogOpen(false)}
          open={addEventDialogOpen}
          patrolId={patrolData.getPatrol.id}
        />
      )}
      <TaskDetailsDialog />

      <ErrorDisplay
        error={patrolDataLoadingError}
        message={t(
          "Error occured while getting patrols, please try again or contact us."
        )}
      />
    </BasicMenuLayout>
  );
}
