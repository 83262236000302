import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, usePagination, useTable } from "react-table";
import { DangerButton } from "../../../components/Buttons/DangerButton/DangerButton";
import { CustomPaginationFooterRow } from "../../../components/CustomTable/CustomPaginationFooterRow/CustomPaginationFooterRow";
import { CustomTableCell } from "../../../components/CustomTable/CustomTableCell/CustomTableCell";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FullScreenLoader from "../../../components/FullScreenLoader/FullScreenLoader";
import { WorkplaceContext } from "../../../context/WorkplaceContext/WorkplaceContextProvider";
import { Permissions } from "../../../generated/types";
import BasicMenuLayout from "../../../layouts/BasicMenuLayout/BasicMenuLayout";
import StandardContentPaddingsLayout from "../../../layouts/StandardContentPaddingsLayout/StandardContentPaddingsLayout";
import IsUserAuthorised from "../../../utils/AuthUtils/IsUserAuthorised";
import { useGetWorkplaceUsersLazyQuery } from "../graphql/query.generated";
import AddTeamUserDialog from "./AddTeamUserDialog";
import DeleteTeamUserDialog from "./DeleteTeamUserDialog";
import EditTeamUserDialog from "./EditTeamUserDialog";
import {
  makeStyles,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
} from "@material-ui/core";

interface UserTableData {
  id: string;
  name: string;
  email: string;
  roleName: string;
  roleId: string;
  userWorkplaceRoleId: string;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  cell: {
    width: "25%",
  },
});

export default function TeamPage(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    workplaces,
    chosenWorkplaceId: workplaceId,
    loading: workplaceContextLoading,
  } = useContext(WorkplaceContext);

  const [addUserDialogOpened, setAddUserDialogOpened] = useState<boolean>(false);
  const [editedUserData, setEditedUserData] = useState<UserTableData | null>(null);
  const [deleteUserData, setDeleteUserData] = useState<{
    userId: string;
    userWorkplaceRoleId: string;
    userEmail: string;
  } | null>(null);

  const wName = useMemo((): string => {
    if (workplaces) {
      const currentWorkplace = workplaces.find((w) => w.id === workplaceId);
      if (currentWorkplace) {
        return currentWorkplace.name;
      }
    }
    return "";
  }, [workplaces, workplaceId]);

  const [
    getWorkplaceUsersQuery,
    { data: usersData, loading, error: getWorkplaceUsersError },
  ] = useGetWorkplaceUsersLazyQuery({
    variables: {
      workplaceId,
      includeInactive: true,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (workplaceId) {
      getWorkplaceUsersQuery();
    }
  }, [getWorkplaceUsersQuery, workplaceId]);

  const data = useMemo((): UserTableData[] => {
    if (usersData && usersData.getWorkplaceUsers) {
      const usersWithPermissions = usersData.getWorkplaceUsers.filter((user) => {
        if (user.userWorkplaceRoles?.length) {
          return user.userWorkplaceRoles.find((r) => r.workplace.id === workplaceId);
        }
        return false;
      });
      return usersWithPermissions.map((uData) => {
        const workplaceUserRole =
          uData.userWorkplaceRoles &&
          uData.userWorkplaceRoles.filter((r) => r.workplace.id === workplaceId)[0];

        const workplaceUserRoleName = workplaceUserRole?.workplaceRole
          ? workplaceUserRole.workplaceRole.name
          : "";

        const roleId = workplaceUserRole?.workplaceRole
          ? workplaceUserRole.workplaceRole.id
          : "";

        const userWorkplaceRoleId = workplaceUserRole?.id;

        const userTableData: UserTableData = {
          id: uData.id,
          name:
            uData.firstName && uData.lastName
              ? `${uData.firstName} ${uData.lastName}`
              : "-",
          email: uData.email,
          roleName: workplaceUserRoleName,
          userWorkplaceRoleId,
          roleId,
        };
        return userTableData;
      });
    }
    return [];
  }, [usersData, workplaceId]);

  const columns: Column<UserTableData>[] = useMemo(
    () => [
      {
        Header: t("Name") as string,
        accessor: "name",
      },
      {
        Header: t("Email") as string,
        accessor: "email",
      },
      {
        Header: t("User role") as string,
        accessor: "roleName",
      },
    ],
    [t]
  );

  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable<UserTableData>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    usePagination
  );

  const handleChangePage = (event, newPage): void => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event): void => {
    setPageSize(Number(event.target.value));
  };

  return (
    <BasicMenuLayout>
      <StandardContentPaddingsLayout>
        <Box>
          <IsUserAuthorised permission={Permissions.CreateWorkplaceUser}>
            <Box display="flex" justifyContent="flex-end" pb={3}>
              <Button
                color="primary"
                variant="contained"
                onClick={(): void => {
                  setAddUserDialogOpened(true);
                }}
              >
                {t("Add new user")}
              </Button>
            </Box>
          </IsUserAuthorised>

          <TableContainer>
            <Table className={classes.table} {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <CustomTableCell size="small" {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </CustomTableCell>
                    ))}
                    <CustomTableCell size="small">{t("Actions")}</CustomTableCell>
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <TableCell className={classes.cell} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <IsUserAuthorised permission={Permissions.EditWorkplaceUserRole}>
                          <Box display="flex">
                            <Box mr={2}>
                              <Button
                                color="secondary"
                                variant="outlined"
                                onClick={(): void => {
                                  setEditedUserData(row.original);
                                }}
                              >
                                {t("Edit roles")}
                              </Button>
                            </Box>
                            <Box>
                              <DangerButton
                                variant="outlined"
                                onClick={(): void =>
                                  setDeleteUserData({
                                    userEmail: row.original.email,
                                    userId: row.original.id,
                                    userWorkplaceRoleId: row.original.userWorkplaceRoleId,
                                  })
                                }
                              >
                                {t("Remove")}
                              </DangerButton>
                            </Box>
                          </Box>
                        </IsUserAuthorised>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

              <TableFooter>
                <CustomPaginationFooterRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: "All", value: data.length }]}
                    count={data.length}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    SelectProps={{
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </CustomPaginationFooterRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </StandardContentPaddingsLayout>
      <FullScreenLoader open={loading || workplaceContextLoading} />
      {addUserDialogOpened && (
        <AddTeamUserDialog
          open
          workplaceId={workplaceId}
          onClose={(): void => setAddUserDialogOpened(false)}
        />
      )}
      {editedUserData && (
        <EditTeamUserDialog
          workplaceName={wName}
          onClose={(): void => setEditedUserData(null)}
          open
          currentWorkplaceRoleId={editedUserData.roleId}
          userName={
            editedUserData.name !== "-" ? editedUserData.name : editedUserData.email
          }
          userWorkplaceRoleId={editedUserData.userWorkplaceRoleId}
        />
      )}
      {deleteUserData && (
        <DeleteTeamUserDialog
          userId={deleteUserData.userId}
          userEmail={deleteUserData.userEmail}
          userWorkplaceRoleId={deleteUserData.userWorkplaceRoleId}
          onClose={(): void => setDeleteUserData(null)}
        />
      )}
      <ErrorDisplay
        error={getWorkplaceUsersError}
        message={t(
          "Error occured while geting workplace users, please try again or contact us."
        )}
      />
    </BasicMenuLayout>
  );
}
