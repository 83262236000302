import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import App from "./App";
import "./index.css";
import "./utils/i18n";
// import * as serviceWorker from "./serviceWorker";

const tagManagerArgs = {
  gtmId: "GTM-W44NXTW",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
