import { IconButton, makeStyles, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useTranslation } from "react-i18next";
import DueDateDisplay from "../../../../components/DueDateDisplay/DueDateDisplay";
import FinishDateDisplay from "../../../../components/FinishDateDisplay/FinishDateDisplay";
import TaskStatusesDisplay from "../../../../components/TaskStatusesDisplay/TaskStatusesDisplay";
import theme from "../../../../utils/theme";
import TaskFriendlyId from "../../common/TaskFriendlyId";
import { TaskListDataFragment } from "../../graphql/fragments.generated";

interface Props {
  onCollapseChange: () => void;
  isCollapsed?: boolean;
  taskData?: TaskListDataFragment;
  onTaskOpen: (id: string) => void;
}

const useStyles = makeStyles({
  wrapper: {
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  nextItemMargin: {
    marginLeft: theme.spacing(2),
  },
});

export default function TaskGroupHeader({
  onCollapseChange,
  isCollapsed,
  taskData,
  onTaskOpen,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div style={{ marginRight: theme.spacing(1) }}>
        <IconButton size="small" onClick={(): void => onCollapseChange()}>
          {isCollapsed ? (
            <ExpandMoreIcon fontSize="small" />
          ) : (
            <ChevronRightIcon fontSize="small" />
          )}
        </IconButton>
      </div>
      {taskData?.friendlyId && (
        <div style={{ marginRight: theme.spacing(1) }}>
          <TaskFriendlyId friendlyId={taskData.friendlyId} />
        </div>
      )}
      <Typography
        variant="body2"
        onClick={(): void => {
          if (taskData) {
            onTaskOpen(taskData.id);
          }
        }}
        style={{
          cursor: taskData ? "pointer" : "default",
          fontWeight: 500,
        }}
      >
        {taskData ? taskData.name : t("Unassigned")}
      </Typography>
      {taskData && (
        <div className={classes.nextItemMargin}>
          <TaskStatusesDisplay
            statusName={taskData.status.name}
            statusType={taskData.status.type}
          />
        </div>
      )}
      {taskData?.finishedDate && (
        <div className={classes.nextItemMargin}>
          <FinishDateDisplay finishDate={taskData.finishedDate} />
        </div>
      )}
      {!taskData?.finishedDate && taskData?.dueDate && (
        <div className={classes.nextItemMargin}>
          <DueDateDisplay date={taskData.dueDate} />
        </div>
      )}
    </div>
  );
}
