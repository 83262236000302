import { ApolloError } from "@apollo/client";
import { useContext, useEffect, useMemo } from "react";
import { CompanyContext } from "../../../context/CompanyContext/CompanyContextProvider";
import { useGetWorkplaceRolesLazyQuery } from "../graphql/query.generated";

interface ReturnObject {
  data: RoleToPick[];
  loading: boolean;
  error: ApolloError | undefined;
}
interface RoleToPick {
  name: string;
  id: string;
}

export default function useGetWorkplacesRoles(): ReturnObject {
  const { chosenCompanyId } = useContext(CompanyContext);

  const [
    getWorkplaceRolesQuery,
    {
      data: workplaceRolesData,
      loading: workplaceRolesLoading,
      error: workplaceRolesError,
    },
  ] = useGetWorkplaceRolesLazyQuery();

  useEffect(() => {
    if (chosenCompanyId) {
      getWorkplaceRolesQuery({ variables: { companyId: chosenCompanyId } });
    }
  }, [chosenCompanyId, getWorkplaceRolesQuery]);

  const workplacesRoles = useMemo(() => {
    if (workplaceRolesData) {
      const workplaceRoles: RoleToPick[] = [];
      workplaceRolesData.getWorkplaceRoles.forEach((r) => {
        workplaceRoles.push({ name: r.name, id: r.id });
      });
      return workplaceRoles;
    }
    return [];
  }, [workplaceRolesData]);

  return {
    data: workplacesRoles,
    loading: !!workplaceRolesLoading || false,
    error: workplaceRolesError,
  };
}
