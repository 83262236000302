import { gql } from "@apollo/client";
import { companyUser } from "./fragments";

export const GET_COMPANY_WORKPLACES = gql`
  query GetCompanyWorkplaces($companyId: UUID!) {
    getWorkplaces(companyId: $companyId) {
      id
      name
      shortName
    }
  }
`;

export const GET_LABELS = gql`
  query GetLabels($companyId: UUID!) {
    getLabels(companyId: $companyId) {
      id
      name
    }
  }
`;

export const GET_CATEGORIES = gql`
  query GetCategories($companyId: UUID!) {
    getCategories(companyId: $companyId) {
      id
      name
    }
  }
`;

export const GET_COMPANY_USERS = gql`
  ${companyUser}
  query GetCompanyUsers($companyId: UUID!) {
    getCompanyUsers(companyId: $companyId) {
      ...CompanyUser
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query GetAllRoles($companyId: UUID!) {
    getCompanyRoles(companyId: $companyId) {
      id
      name
    }
    getWorkplaceRoles(companyId: $companyId) {
      id
      name
    }
  }
`;
