import { Box, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch, useHistory } from "react-router";
import HelmetWrapper from "../../../components/HelmetWrapper/HelmetWrapper";
import { Permissions } from "../../../generated/types";
import BasicMenuLayout from "../../../layouts/BasicMenuLayout/BasicMenuLayout";
import StandardContentPaddingsLayout from "../../../layouts/StandardContentPaddingsLayout/StandardContentPaddingsLayout";
import ProtectedRoute from "../../../utils/AuthUtils/ProtectedRoute";
import useLazyIsUserAuthorised from "../../../utils/AuthUtils/useLazyIsUserAuthorised";
import Categories from "../Categories/Categories";
import Labels from "../Labels/Labels";
import Users from "../Users/Users";
import Workplaces from "../Workplaces/Workplaces";
import {
  SETTINGS,
  SETTINGS_CATEGORIES,
  SETTINGS_LABELS,
  SETTINGS_USERS,
  SETTINGS_WORKPLACES,
} from "../../../routing/paths";

export default function SettingsPage(): JSX.Element {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<string>(SETTINGS_WORKPLACES);
  const history = useHistory();
  const { isUserAuthorised } = useLazyIsUserAuthorised();

  useEffect(() => {
    if (history.location.pathname === SETTINGS) {
      history.push(SETTINGS + SETTINGS_WORKPLACES);
    }
  }, [history, history.location.pathname]);

  useEffect(() => {
    const path = history.location.pathname;
    setCurrentTab(path.substring(path.lastIndexOf("/")));
  }, [history.location.pathname]);

  const tabChange = (tabLink: string): void => {
    setCurrentTab(tabLink);
    history.push(SETTINGS + tabLink);
  };

  return (
    <BasicMenuLayout>
      <StandardContentPaddingsLayout>
        <Box mb={4}>
          <Tabs value={currentTab} indicatorColor="primary" textColor="secondary">
            {isUserAuthorised(Permissions.CreateTerm) && (
              <Tab
                value={SETTINGS_WORKPLACES}
                label={t("Workplaces")}
                onClick={(): void => tabChange(SETTINGS_WORKPLACES)}
              />
            )}
            {isUserAuthorised(Permissions.ViewCompanyUsers) && (
              <Tab
                value={SETTINGS_USERS}
                label={t("Users")}
                onClick={(): void => tabChange(SETTINGS_USERS)}
              />
            )}

            {isUserAuthorised(Permissions.CreateTerm) && (
              <Tab
                value={SETTINGS_LABELS}
                label={t("Labels")}
                onClick={(): void => tabChange(SETTINGS_LABELS)}
              />
            )}

            {isUserAuthorised(Permissions.CreateTerm) && (
              <Tab
                value={SETTINGS_CATEGORIES}
                label={t("Categories")}
                onClick={(): void => tabChange(SETTINGS_CATEGORIES)}
              />
            )}
          </Tabs>
        </Box>
        <Switch>
          {isUserAuthorised(Permissions.CreateTerm) && (
            <ProtectedRoute
              exact
              path={[SETTINGS + SETTINGS_WORKPLACES, SETTINGS]}
              render={(): JSX.Element => (
                <HelmetWrapper siteTitle="Workplaces settings">
                  <Workplaces />
                </HelmetWrapper>
              )}
            />
          )}

          {isUserAuthorised(Permissions.ViewCompanyUsers) && (
            <ProtectedRoute
              exact
              path={SETTINGS + SETTINGS_USERS}
              render={(): JSX.Element => (
                <HelmetWrapper siteTitle="Users settings">
                  <Users />
                </HelmetWrapper>
              )}
            />
          )}
          {isUserAuthorised(Permissions.CreateTerm) && (
            <ProtectedRoute
              exact
              path={SETTINGS + SETTINGS_LABELS}
              render={(): JSX.Element => (
                <HelmetWrapper siteTitle="Labels settings">
                  <Labels />
                </HelmetWrapper>
              )}
            />
          )}
          {isUserAuthorised(Permissions.CreateTerm) && (
            <ProtectedRoute
              exact
              path={SETTINGS + SETTINGS_CATEGORIES}
              render={(): JSX.Element => (
                <HelmetWrapper siteTitle="Categories settings">
                  <Categories />
                </HelmetWrapper>
              )}
            />
          )}
        </Switch>
      </StandardContentPaddingsLayout>
    </BasicMenuLayout>
  );
}
