import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, makeStyles, TextField, Typography } from "@material-ui/core";
import { ArrowLeft } from "@material-ui/icons";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import CustomSnackbar from "../../../../components/CustomSnackbar/CustomSnackbar";
import FormTextFieldWrapper from "../../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import { SIGN_IN, SIGN_UP_COMPLETED } from "../../../../routing/paths";
import IdentityLayout from "../../common/IdentityLayout";
import { useSignUpFromInvitationMutation } from "../../graphql/mutation.generated";
import {
  phoneValidator,
  repeatPasswordValidator,
  requiredStringValidator,
} from "../../../../utils/formValidators";

interface FormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  repeatPassword: string;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    firstName: requiredStringValidator(t),
    lastName: requiredStringValidator(t),
    phoneNumber: phoneValidator(t),
    password: requiredStringValidator(t),
    repeatPassword: repeatPasswordValidator(t),
  });

const useStyles = makeStyles({
  link: {
    display: "block",
  },
});

export default function SignUpFromLinkPage(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const [errorMsg, setErrorMsg] = useState<string>("");

  const [signUpFromInvitationLink, { loading }] = useSignUpFromInvitationMutation({});

  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
  });

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    const { firstName, lastName, password, phoneNumber } = formData;

    try {
      const res = await signUpFromInvitationLink({
        variables: {
          signUpFromInvitationData: {
            firstName,
            lastName,
            password,
            token,
            phoneNumber,
          },
        },
      });

      if (res.errors) {
        setErrorMsg(res.errors[0].message);
      }
      if (res.data && res.data.signUpFromInvitation) {
        history.push(SIGN_UP_COMPLETED);
      }
      if (res.data && !res.data.signUpFromInvitation) {
        setErrorMsg(t("Error occured while finishing your sign up. Try to sign in."));
      }
    } catch (e) {
      setErrorMsg(e.message);
    }
  };

  return (
    <IdentityLayout loading={loading}>
      <Box pb={1}>
        <Typography variant="h4">{t("Hello!")}</Typography>
      </Box>
      <Box pb={3}>
        <Typography variant="body1">
          {t("Please complete your registration process")}
        </Typography>
      </Box>
      <Box pb={3}>
        <Typography variant="h6">{t("Sign up")}</Typography>
      </Box>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <FormTextFieldWrapper>
          <TextField
            name="firstName"
            label={t("First name")}
            fullWidth
            autoFocus
            variant="outlined"
            inputRef={register}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
          />
        </FormTextFieldWrapper>
        <FormTextFieldWrapper>
          <TextField
            name="lastName"
            label={t("Last name")}
            fullWidth
            variant="outlined"
            inputRef={register}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
          />
        </FormTextFieldWrapper>
        <FormTextFieldWrapper>
          <TextField
            name="phoneNumber"
            label={t("Phone number")}
            fullWidth
            variant="outlined"
            inputRef={register}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
            InputProps={{
              type: "tel",
            }}
          />
        </FormTextFieldWrapper>
        <FormTextFieldWrapper>
          <TextField
            name="password"
            label={t("Password")}
            type="password"
            fullWidth
            variant="outlined"
            inputRef={register}
            error={!!errors.password}
            helperText={errors.password?.message}
            InputProps={{
              type: "password",
            }}
          />
        </FormTextFieldWrapper>
        <Box pb={5}>
          <TextField
            name="repeatPassword"
            label={t("Repeat password")}
            type="password"
            fullWidth
            variant="outlined"
            inputRef={register}
            error={!!errors.repeatPassword}
            helperText={errors.repeatPassword?.message}
            InputProps={{
              type: "password",
            }}
          />
        </Box>
        <Box pb={3}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            fullWidth
            size="large"
          >
            {t("Submit")}
          </Button>
        </Box>
        <Box>
          <Link to={SIGN_IN} className={classes.link}>
            <Box display="flex" alignItems="center">
              <ArrowLeft fontSize="default" color="secondary" />
              <Typography variant="caption" color="secondary">
                {t("Sign in page")}
              </Typography>
            </Box>
          </Link>
        </Box>
      </form>
      <CustomSnackbar
        open={!!errorMsg}
        message={t(
          "Error occured while creating your account. Please try again or contact us."
        )}
        handleClose={(): void => setErrorMsg("")}
      />
    </IdentityLayout>
  );
}
