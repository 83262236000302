import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import FinishDateDisplay from "../../../components/FinishDateDisplay/FinishDateDisplay";
import UserIcon from "../../../components/UserIcon/UserIcon";
import generateUserNameString from "../../../utils/generateUserNameString";
import theme from "../../../utils/theme";
import TaskFriendlyId from "../common/TaskFriendlyId";
import { ArchivedTaskListFragment } from "../graphql/fragments.generated";
import useOpenTaskDialog from "../TaskDetailsDialog/useOpenTaskDialog";

interface Props {
  archivedTasks: ArchivedTaskListFragment[];
}

const useStyles = makeStyles({
  headWrapper: {
    // borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  singleTask: {
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: theme.shape.borderRadius,
    borderBottom: "none",
    "&:last-of-type": {
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
    cursor: "pointer",
  },
});

export default function ArchivedTasksTable({ archivedTasks }: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openTaskDialog } = useOpenTaskDialog();
  return (
    <>
      {Boolean(archivedTasks.length) && (
        <Box display="flex" pb={0.5} px={3.5} mb={1} className={classes.headWrapper}>
          <Box width="10%">
            <Typography variant="caption">{t("Id")}</Typography>
          </Box>
          <Box width="35%">
            <Typography variant="caption">{t("Name")}</Typography>
          </Box>
          <Box width="25%">
            <Typography variant="caption">{t("Location")}</Typography>
          </Box>
          <Box width="15%">
            <Typography variant="caption">{t("Finish date")}</Typography>
          </Box>
          <Box width="15%" display="flex" justifyContent="flex-end">
            <Typography variant="caption">{t("Assignees")}</Typography>
          </Box>
        </Box>
      )}
      <Box>
        {archivedTasks.map((task) => (
          <Box
            key={`archivedtaskstable${task.id}`}
            px={3.5}
            pt={2.5}
            pb={1.5}
            display="flex"
            alignItems="center"
            className={classes.singleTask}
            onClick={(): void => openTaskDialog(task.id)}
          >
            <Box width="10%">
              <TaskFriendlyId friendlyId={task.friendlyId} isArchived />
            </Box>
            <Box width="35%">
              <Typography variant="body2">{task.name}</Typography>
            </Box>
            <Box width="25%">
              <Typography variant="body2" color="textSecondary">
                {task.location}
              </Typography>
            </Box>
            <Box width="15%">
              {task.finishedDate && <FinishDateDisplay finishDate={task.finishedDate} />}
            </Box>
            <Box width="15%" display="flex" justifyContent="flex-end">
              {task.assignees.map((assignee) => (
                <Box mr={0.5}>
                  <UserIcon name={generateUserNameString(assignee)} />
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
}
