import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import TaskWorkplaceUserSelect from "../../common/TaskSelectFields/TaskWorkplaceUserSelect";
import { useChangeTaskReporterMutation } from "../../graphql/mutation.generated";

interface Props {
  workplaceId: string;
  reporter: string | undefined;
  taskId: string;
  disabled?: boolean;
}

export default function TaskReporter({
  reporter,
  workplaceId,
  taskId,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [
    changeTaskReporterMutation,
    { error: changeReporterError },
  ] = useChangeTaskReporterMutation();

  const onReporterChange = async (value: string | null): Promise<void> => {
    try {
      await changeTaskReporterMutation({
        variables: {
          changeTaskReporterData: {
            id: taskId,
            reporterId: value,
          },
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <>
      <TaskWorkplaceUserSelect
        onUserChange={onReporterChange}
        workplaceId={workplaceId}
        user={reporter}
        disabled={disabled}
      />
      <ErrorDisplay
        error={changeReporterError}
        message={t(
          "Error occured while changing reporter, please try again or contact us."
        )}
      />
    </>
  );
}
