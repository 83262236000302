import { Box, Chip } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ArchivedTaskFilters } from "../../../../generated/types";
import { QueryVariablesEnum } from "../../../../interfaces/QueryVariables";
import useManageUrlQueryVariables from "../../../../utils/useManageUrlQueryVariables";

export default function ActiveArchiveTaskFilters(): JSX.Element {
  const { t } = useTranslation();
  const {
    setUrlQueryVariables,
    deleteUrlQueryVariables,
    archivedTaskFilters,
  } = useManageUrlQueryVariables();

  const filters = useMemo<ArchivedTaskFilters | null>(() => {
    if (archivedTaskFilters) {
      return JSON.parse(archivedTaskFilters);
    }
    return null;
  }, [archivedTaskFilters]);

  const filtersNamesTable = {
    createdById: t("Created by"),
    dueDate: t("Due date"),
    finishedDate: t("Finished date"),
    isArchived: t("Is archived"),
    reportedDate: t("Reported date"),
    reporterId: t("Reporter"),
    taskStatusId: t("Status"),
  };

  const handleDeleteLabel = (labelName: string): void => {
    const newFilters = { ...filters };
    delete newFilters[labelName];
    if (!Object.keys(newFilters).length) {
      deleteUrlQueryVariables(QueryVariablesEnum.ARCHIVEDTASKFILTERS);
    } else {
      setUrlQueryVariables(
        QueryVariablesEnum.ARCHIVEDTASKFILTERS,
        JSON.stringify(newFilters)
      );
    }
  };

  return (
    <Box display="flex">
      {filters &&
        Object.keys(filters).map((key, index) => (
          <Box key={`active_archive_task_filter-${key}-${index}`} mr={1}>
            <Chip
              label={filtersNamesTable[key]}
              onDelete={(): void => handleDeleteLabel(key)}
              variant="outlined"
              size="small"
            />
          </Box>
        ))}
    </Box>
  );
}
