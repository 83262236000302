import * as Types from "../../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type AddUserToTeamMutationVariables = Types.Exact<{
  addUserToWorkplaceData: Types.AddUserToWorkplaceData;
}>;

export type AddUserToTeamMutation = {
  __typename?: "Mutation";
  addUserToWorkplace: { __typename?: "UserWorkplaceRole"; id: any };
};

export type ChangeUserTeamRoleMutationVariables = Types.Exact<{
  changeUserWorkplaceRoleData: Types.ChangeUserWorkplaceRoleData;
}>;

export type ChangeUserTeamRoleMutation = {
  __typename?: "Mutation";
  changeUserWorkplaceRole: {
    __typename?: "UserWorkplaceRole";
    id: any;
    workplaceRole: { __typename?: "WorkplaceRole"; id: any; name: string };
    workplace: { __typename?: "Workplace"; id: any };
  };
};

export type DeleteUserFromTeaMutationVariables = Types.Exact<{
  id: Types.Scalars["UUID"];
}>;

export type DeleteUserFromTeaMutation = {
  __typename?: "Mutation";
  deleteUserWorkplaceRole: boolean;
};

export const AddUserToTeamDocument = gql`
  mutation AddUserToTeam($addUserToWorkplaceData: AddUserToWorkplaceData!) {
    addUserToWorkplace(addUserToWorkplaceData: $addUserToWorkplaceData) {
      id
    }
  }
`;
export type AddUserToTeamMutationFn = Apollo.MutationFunction<
  AddUserToTeamMutation,
  AddUserToTeamMutationVariables
>;

/**
 * __useAddUserToTeamMutation__
 *
 * To run a mutation, you first call `useAddUserToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToTeamMutation, { data, loading, error }] = useAddUserToTeamMutation({
 *   variables: {
 *      addUserToWorkplaceData: // value for 'addUserToWorkplaceData'
 *   },
 * });
 */
export function useAddUserToTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserToTeamMutation,
    AddUserToTeamMutationVariables
  >
) {
  return Apollo.useMutation<AddUserToTeamMutation, AddUserToTeamMutationVariables>(
    AddUserToTeamDocument,
    baseOptions
  );
}
export type AddUserToTeamMutationHookResult = ReturnType<typeof useAddUserToTeamMutation>;
export type AddUserToTeamMutationResult = Apollo.MutationResult<AddUserToTeamMutation>;
export type AddUserToTeamMutationOptions = Apollo.BaseMutationOptions<
  AddUserToTeamMutation,
  AddUserToTeamMutationVariables
>;
export const ChangeUserTeamRoleDocument = gql`
  mutation ChangeUserTeamRole(
    $changeUserWorkplaceRoleData: ChangeUserWorkplaceRoleData!
  ) {
    changeUserWorkplaceRole(changeUserWorkplaceRoleData: $changeUserWorkplaceRoleData) {
      id
      workplaceRole {
        id
        name
      }
      workplace {
        id
      }
    }
  }
`;
export type ChangeUserTeamRoleMutationFn = Apollo.MutationFunction<
  ChangeUserTeamRoleMutation,
  ChangeUserTeamRoleMutationVariables
>;

/**
 * __useChangeUserTeamRoleMutation__
 *
 * To run a mutation, you first call `useChangeUserTeamRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserTeamRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserTeamRoleMutation, { data, loading, error }] = useChangeUserTeamRoleMutation({
 *   variables: {
 *      changeUserWorkplaceRoleData: // value for 'changeUserWorkplaceRoleData'
 *   },
 * });
 */
export function useChangeUserTeamRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUserTeamRoleMutation,
    ChangeUserTeamRoleMutationVariables
  >
) {
  return Apollo.useMutation<
    ChangeUserTeamRoleMutation,
    ChangeUserTeamRoleMutationVariables
  >(ChangeUserTeamRoleDocument, baseOptions);
}
export type ChangeUserTeamRoleMutationHookResult = ReturnType<
  typeof useChangeUserTeamRoleMutation
>;
export type ChangeUserTeamRoleMutationResult = Apollo.MutationResult<ChangeUserTeamRoleMutation>;
export type ChangeUserTeamRoleMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserTeamRoleMutation,
  ChangeUserTeamRoleMutationVariables
>;
export const DeleteUserFromTeaDocument = gql`
  mutation DeleteUserFromTea($id: UUID!) {
    deleteUserWorkplaceRole(id: $id)
  }
`;
export type DeleteUserFromTeaMutationFn = Apollo.MutationFunction<
  DeleteUserFromTeaMutation,
  DeleteUserFromTeaMutationVariables
>;

/**
 * __useDeleteUserFromTeaMutation__
 *
 * To run a mutation, you first call `useDeleteUserFromTeaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserFromTeaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserFromTeaMutation, { data, loading, error }] = useDeleteUserFromTeaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserFromTeaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserFromTeaMutation,
    DeleteUserFromTeaMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteUserFromTeaMutation,
    DeleteUserFromTeaMutationVariables
  >(DeleteUserFromTeaDocument, baseOptions);
}
export type DeleteUserFromTeaMutationHookResult = ReturnType<
  typeof useDeleteUserFromTeaMutation
>;
export type DeleteUserFromTeaMutationResult = Apollo.MutationResult<DeleteUserFromTeaMutation>;
export type DeleteUserFromTeaMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserFromTeaMutation,
  DeleteUserFromTeaMutationVariables
>;
