import Box from "@material-ui/core/Box";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, usePagination, useTable } from "react-table";
import { DangerButton } from "../../../components/Buttons/DangerButton/DangerButton";
import { CustomPaginationFooterRow } from "../../../components/CustomTable/CustomPaginationFooterRow/CustomPaginationFooterRow";
import { CustomTableCell } from "../../../components/CustomTable/CustomTableCell/CustomTableCell";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FullScreenLoader from "../../../components/FullScreenLoader/FullScreenLoader";
import { CompanyContext } from "../../../context/CompanyContext/CompanyContextProvider";
import { useGetCompanyUsersLazyQuery } from "../graphql/query.generated";
import AddUserDialog from "./AddUserDialog";
import DeleteUserDialog from "./DeleteUserDialog";
import EditUserRolesDialog from "./EditUserRoles/EditUserRolesDialog";
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";

interface UserTableData {
  id: string;
  name: string;
  email: string;
  role: string;
  companyUserRoleId: string;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  cell: {
    width: "25%",
  },
});

export default function Users(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { chosenCompanyId } = useContext(CompanyContext);

  const [addUserDialogOpen, setAddUserDialogOpen] = useState<boolean>(false);
  const [editedUserId, setEditedUserId] = useState<string | null>(null);
  const [deleteUserData, setDeleteUserData] = useState<{
    userId: string;
    companyUserRoleId: string;
    email: string;
  } | null>(null);

  const [
    getCompanyUsers,
    { data: usersData, loading: usersLoading, error: getUsersError },
  ] = useGetCompanyUsersLazyQuery({ fetchPolicy: "network-only" });

  useEffect(() => {
    if (chosenCompanyId) {
      getCompanyUsers({
        variables: {
          companyId: chosenCompanyId,
        },
      });
    }
  }, [getCompanyUsers, chosenCompanyId]);

  const data = React.useMemo((): UserTableData[] => {
    if (usersData && usersData.getCompanyUsers) {
      return usersData.getCompanyUsers.map((userData) => {
        const userTableData = {
          id: userData.id,
          name:
            userData.firstName && userData.lastName
              ? `${userData.firstName} ${userData.lastName}`
              : "-",
          email: userData.email,
          role: userData?.userCompanyRoles
            ? userData?.userCompanyRoles[0].companyRole.name
            : "",
          companyUserRoleId: userData?.userCompanyRoles
            ? userData?.userCompanyRoles[0].id
            : "",
        };
        return userTableData;
      });
    }
    return [];
  }, [usersData]);

  const columns: Column<UserTableData>[] = React.useMemo(
    () => [
      {
        Header: t("Name") as string,
        accessor: "name",
      },
      {
        Header: t("Email") as string,
        accessor: "email",
      },
      {
        Header: t("User role") as string,
        accessor: "role",
      },
    ],
    [t]
  );

  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable<UserTableData>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    usePagination
  );

  const handleChangePage = (event, newPage): void => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event): void => {
    setPageSize(Number(event.target.value));
  };

  const openEditUserDialog = (userId: string): void => {
    if (usersData?.getCompanyUsers) {
      setEditedUserId(userId);
    }
  };

  const closeEditUserDialog = (): void => {
    setEditedUserId(null);
  };

  return (
    <Box>
      <Box pb={3}>
        <Button
          color="primary"
          variant="contained"
          onClick={(): void => setAddUserDialogOpen(true)}
        >
          {t("Add new user")}
        </Button>
      </Box>
      <TableContainer>
        <Table className={classes.table} {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <CustomTableCell size="small" {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </CustomTableCell>
                ))}
                <CustomTableCell size="small">{t("Actions")}</CustomTableCell>
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell className={classes.cell} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <Box display="flex">
                      <Box mr={2}>
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={(): void => openEditUserDialog(row.original.id)}
                        >
                          {t("Edit roles")}
                        </Button>
                      </Box>
                      <Box>
                        <DangerButton
                          variant="outlined"
                          onClick={(): void =>
                            setDeleteUserData({
                              companyUserRoleId: row.original.companyUserRoleId,
                              email: row.original.email,
                              userId: row.original.id,
                            })
                          }
                        >
                          {t("Remove")}
                        </DangerButton>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          <TableFooter>
            <CustomPaginationFooterRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: data.length }]}
                count={data.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </CustomPaginationFooterRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <FullScreenLoader open={usersLoading} />
      {addUserDialogOpen && (
        <AddUserDialog
          open={addUserDialogOpen}
          onClose={(): void => setAddUserDialogOpen(false)}
          companyId={chosenCompanyId}
        />
      )}
      {editedUserId && (
        <EditUserRolesDialog
          open={Boolean(editedUserId)}
          onClose={closeEditUserDialog}
          userData={
            usersData
              ? usersData.getCompanyUsers.find((userData) => userData.id === editedUserId)
              : null
          }
          companyId={chosenCompanyId}
        />
      )}
      {deleteUserData && (
        <DeleteUserDialog
          userEmail={deleteUserData.email}
          userCompanyRoleId={deleteUserData.companyUserRoleId}
          onClose={(): void => setDeleteUserData(null)}
          userId={deleteUserData.userId}
        />
      )}
      <ErrorDisplay
        error={getUsersError}
        message={t("Error occured while geting users, please try again or contact us.")}
      />
    </Box>
  );
}
