import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import MultiSelectWithSearch from "../../../../components/MultiSelectWithSearch/MultiSelectWithSearch";
import { CompanyContext } from "../../../../context/CompanyContext/CompanyContextProvider";
import { useGetWorkplaceCategoriesLazyQuery } from "../../graphql/query.generated";

interface Props {
  categoriesIds?: string[];
  onCategoriesChange: (categories: string[]) => void;
  disabled?: boolean;
}
export default function TaskCategoriesSelect({
  categoriesIds,
  onCategoriesChange,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { chosenCompanyId } = useContext(CompanyContext);

  const [
    getWorkplaceCategoriesQuery,
    { data: categoriesData, error: categoriesLoadingError },
  ] = useGetWorkplaceCategoriesLazyQuery();

  useEffect(() => {
    if (chosenCompanyId) {
      getWorkplaceCategoriesQuery({ variables: { companyId: chosenCompanyId } });
    }
  }, [getWorkplaceCategoriesQuery, chosenCompanyId]);

  const onMultiselectChange = (value: { id: string }[]): void => {
    const newValue = value.map((v) => v.id);
    onCategoriesChange(newValue);
  };

  return (
    <>
      {categoriesData?.getCategories && (
        <MultiSelectWithSearch<{ id: string }>
          disabled={disabled}
          namesToDisplay={categoriesData.getCategories.map((category) => ({
            name: category.name,
            optionId: category.id,
          }))}
          onChange={onMultiselectChange}
          options={categoriesData.getCategories.map((cat) => ({ id: cat.id })) || []}
          pickedValues={categoriesIds ? categoriesIds.map((id) => ({ id })) : []}
          placeholder={t("+ Add task category")}
        />
      )}
      <ErrorDisplay
        error={categoriesLoadingError}
        message={t(
          "Error occured while loading categories, please try again or contact us."
        )}
      />
    </>
  );
}
