import { TFunction } from "i18next";
import { NotificationType } from "../../../generated/types";

export default function displayNotificationTittle(
  notificationType: NotificationType,
  t: TFunction
): string {
  switch (notificationType) {
    case NotificationType.ArchivedTask:
      return t("Task archived");
    case NotificationType.AssignedReporterToTask:
      return t("You have been assigned to task as a reporter");
    case NotificationType.AssignedToPatrol:
      return t("You have been assigned to patrol");
    case NotificationType.AssignedToTask:
      return t("You have been assigned to task");
    case NotificationType.ChangedTaskDueDate:
      return t("Task due date has been changed");
    case NotificationType.ChangedTaskStatus:
      return t("Task status has been changed");
    case NotificationType.CommentedTask:
      return t("Comment has been added to task");
    case NotificationType.DeletedPatrol:
      return t("Patrol has been deleted");
    case NotificationType.DeletedTask:
      return t("Task has been deleted");
    case NotificationType.UnassignedFromPatrol:
      return t("You have been unassigned from patrol");
    case NotificationType.UnassignedFromTask:
      return t("You have been unassigned from task");
    case NotificationType.UnassignedReporterFromTask:
      return t("You are no longer reporter of task.");
    default:
      return t("Nameless notification");
  }
}
