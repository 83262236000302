import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import * as yup from "yup";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FullScreenLoader from "../../../components/FullScreenLoader/FullScreenLoader";
import BasicMenuLayout from "../../../layouts/BasicMenuLayout/BasicMenuLayout";
import StandardContentPaddingsLayout from "../../../layouts/StandardContentPaddingsLayout/StandardContentPaddingsLayout";
import { RESET_PASSWORD } from "../../../routing/paths";
import useLogout from "../../../utils/AuthUtils/useLogout";
import { phoneValidator, requiredStringValidator } from "../../../utils/formValidators";
import { useUpdateUserMutation } from "../graphql/mutation.generated";
import { useGetUserDataQuery } from "../graphql/query.generated";

interface FormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    firstName: requiredStringValidator(t),
    lastName: requiredStringValidator(t),
    phoneNumber: phoneValidator(t),
  });

export default function AccountSettingsPage(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const { register, handleSubmit, setValue, formState, reset } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
  });
  const [logout] = useLogout();

  const { data: userData, loading: userDataLoading } = useGetUserDataQuery({
    onCompleted: (fetchedData) => {
      setValue("firstName", fetchedData.getUser.firstName || "");
      setValue("lastName", fetchedData.getUser.lastName || "");
      setValue("phoneNumber", fetchedData.getUser.phoneNumber || "");
    },
  });

  const [
    updateUserMutation,
    { loading: userUpdateLoading, error: userUpdateError },
  ] = useUpdateUserMutation({});

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    try {
      const res = await updateUserMutation({
        variables: {
          updateUserData: {
            ...formData,
          },
        },
      });

      if (res.data) {
        reset({ ...formData });
      }
    } catch (e) {
      //
    }
  };

  const changePassword = (): void => {
    logout();
    history.push(RESET_PASSWORD);
  };

  return (
    <BasicMenuLayout>
      <FullScreenLoader open={userDataLoading || userUpdateLoading} />
      <StandardContentPaddingsLayout>
        <Box display="flex" justifyContent="center">
          <Box width="50%">
            <Box mb={8}>
              <Typography variant="h3" align="center">
                {t("Account settings")}
              </Typography>
            </Box>
            <Box display="flex">
              <Box width="50%" px={2}>
                <Box pb={3}>
                  <TextField
                    name="email"
                    label={t("Email")}
                    fullWidth
                    variant="outlined"
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={!userDataLoading && userData?.getUser.email}
                  />
                </Box>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                  <Box pb={3}>
                    <TextField
                      name="firstName"
                      label={t("First name")}
                      fullWidth
                      variant="outlined"
                      inputRef={register}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box pb={3}>
                    <TextField
                      name="lastName"
                      label={t("Last name")}
                      fullWidth
                      variant="outlined"
                      inputRef={register}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box pb={3}>
                    <TextField
                      name="phoneNumber"
                      label={t("Phone number")}
                      fullWidth
                      variant="outlined"
                      inputRef={register}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                  <Button
                    disabled={!formState.isDirty}
                    color="primary"
                    variant="contained"
                    type="submit"
                    fullWidth
                    size="large"
                  >
                    {t("Submit changes")}
                  </Button>
                </form>
              </Box>
              <Box width="50%" px={2}>
                <Box mb={3}>
                  <Typography variant="body1">
                    {t(
                      "If you wish to change your password, click button below and follow instructions."
                    )}
                  </Typography>
                </Box>
                <Button
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  size="large"
                  onClick={changePassword}
                >
                  {t("Change my password")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </StandardContentPaddingsLayout>
      <ErrorDisplay
        error={userUpdateError}
        message={t(
          "Error occured while updating user data, please try again or contact us."
        )}
      />
    </BasicMenuLayout>
  );
}
