import { Box, Dialog, IconButton, makeStyles } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import classNames from "classnames";
import React, { useMemo } from "react";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import { Attachment } from "../../generated/types";

interface Props {
  onClose: () => void;
  open: boolean;
  photosData: Attachment[];
  startPhotoId?: string;
}

const useStyles = makeStyles({
  img: {
    maxWidth: "100%",
    maxHeight: "65vh",
    height: "65vh",
    width: "auto",
  },
  wrapper: {
    position: "relative",
    "& .fullscreen img.fullscreenImg": {
      maxHeight: "85vh",
      height: "85vh",
    },
  },
  closeIcon: { position: "absolute", right: 0, zIndex: 10 },
});

export default function PhotoGalleryDialog({
  onClose,
  open,
  photosData,
  startPhotoId,
}: Props): JSX.Element {
  const classes = useStyles();
  const photos = useMemo<ReactImageGalleryItem[]>(
    () =>
      photosData.map((image) => ({
        original: image.uri,
        thumbnail: image.uri,
        originalAlt: image.filename,
        originalTitle: image.filename,
        renderItem: (): JSX.Element => (
          <img
            src={image.uri}
            alt={image.filename}
            className={classNames(classes.img, "fullscreenImg")}
          />
        ),
      })),
    [classes.img, photosData]
  );

  const startIndex = useMemo<number>((): number => {
    if (startPhotoId && photosData) {
      const photoIndex = photosData.findIndex((photo) => photo.id === startPhotoId);
      return photoIndex;
    }
    return 0;
  }, [photosData, startPhotoId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <Box maxHeight="100%" className={classes.wrapper}>
        <Box className={classes.closeIcon}>
          <IconButton onClick={(): void => onClose()}>
            <HighlightOffIcon color="secondary" />
          </IconButton>
        </Box>
        <Box pt={3}>
          <ImageGallery items={photos} showPlayButton={false} startIndex={startIndex} />;
        </Box>
      </Box>
    </Dialog>
  );
}
