import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@material-ui/core";
import { TFunction } from "i18next";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import CustomDialog from "../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import { Label } from "../../../generated/types";
import { requiredStringValidator } from "../../../utils/formValidators";
import { useCreateLabelMutation } from "../graphql/mutation.generated";
import { GET_LABELS } from "../graphql/query";
import { GetLabelsQuery, GetLabelsQueryVariables } from "../graphql/query.generated";

interface Props {
  open: boolean;
  companyId: string | undefined;
  onClose: () => void;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    name: requiredStringValidator(t),
  });

export default function AddLabelDialog({ open, companyId, onClose }: Props): JSX.Element {
  const { t } = useTranslation();
  const [createLabelMutation, { loading, error }] = useCreateLabelMutation();

  const { register, handleSubmit, errors } = useForm<Label>({
    resolver: yupResolver(schema(t)),
  });

  const onFormSubmit = async (formData: Label): Promise<void> => {
    if (companyId) {
      try {
        const res = await createLabelMutation({
          variables: {
            createLabelData: {
              name: formData.name,
              companyId,
            },
          },
          update: (cache, mutationResult) => {
            const labels = cache.readQuery<GetLabelsQuery, GetLabelsQueryVariables>({
              query: GET_LABELS,
              variables: {
                companyId,
              },
            });

            const newLabel = mutationResult.data?.createLabel;
            if (labels?.getLabels && newLabel) {
              cache.writeQuery<GetLabelsQuery, GetLabelsQueryVariables>({
                query: GET_LABELS,
                variables: {
                  companyId,
                },
                data: {
                  getLabels: [...labels.getLabels, newLabel],
                },
              });
            }
          },
        });

        if (res.data) {
          onClose();
        }
      } catch (e) {
        //
      }
    }
  };

  return (
    <CustomDialog loading={loading} open={open} onClose={onClose} maxWidth="md">
      <CustomDialogContent heading={t("Add new label")}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Box mb={5}>
            <TextField
              name="name"
              label={t("Label name")}
              fullWidth
              variant="outlined"
              autoFocus
              inputRef={register}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              size="large"
            >
              {t("Add label")}
            </Button>
          </Box>
        </form>
      </CustomDialogContent>
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while adding new label, please try again or contact us."
        )}
      />
    </CustomDialog>
  );
}
