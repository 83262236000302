import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@material-ui/core";
import { TFunction } from "i18next";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import CustomDialog from "../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FormTextFieldWrapper from "../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import { CompanyContext } from "../../../context/CompanyContext/CompanyContextProvider";
import { emailValidator, requiredStringValidator } from "../../../utils/formValidators";
import { GET_COMPANY_USERS } from "../../Settings/graphql/query";
import { useAddUserToTeamMutation } from "../graphql/mutation.generated";
import { GET_WORKPLACE_USERS } from "../graphql/query";
import useGetWorkplacesRoles from "./useGetWorkplacesRoles";

interface Props {
  open: boolean;
  onClose: () => void;
  workplaceId: string;
}

interface FormData {
  email: string;
  role: string;
  firstName: string;
  lastName: string;
}

interface RoleToPick {
  name: string;
  id: string;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    role: requiredStringValidator(t),
    firstName: requiredStringValidator(t),
    lastName: requiredStringValidator(t),
    email: emailValidator(t),
  });

export default function AddTeamUserDialog({
  open,
  onClose,
  workplaceId,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { chosenCompanyId } = useContext(CompanyContext);
  const [
    addUserToTeamMutation,
    { loading: addUserLoading, error: addUserError },
  ] = useAddUserToTeamMutation();

  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      role: "",
      email: "",
    },
  });

  const {
    data: rolesToPick,
    loading: workplaceRolesLoading,
    error: gettingWorkplaceRolesError,
  } = useGetWorkplacesRoles();

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    const { email, role, firstName, lastName } = formData;
    try {
      const res = await addUserToTeamMutation({
        variables: {
          addUserToWorkplaceData: {
            email,
            workplaceRoleId: role,
            workplaceId,
            firstName,
            lastName,
          },
        },
        refetchQueries: [
          {
            query: GET_WORKPLACE_USERS,
            variables: { workplaceId, includeInactive: true },
          },
          {
            query: GET_COMPANY_USERS,
            variables: { companyId: chosenCompanyId },
          },
        ],
      });

      if (res.data) {
        onClose();
      }
    } catch (e) {
      //
    }
  };

  return (
    <CustomDialog
      loading={workplaceRolesLoading || addUserLoading}
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <CustomDialogContent heading={t("Add new team member")}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormTextFieldWrapper>
            <TextField
              name="firstName"
              label={t("User first name")}
              fullWidth
              variant="outlined"
              inputRef={register}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              autoFocus
              autoComplete="off"
            />
          </FormTextFieldWrapper>
          <FormTextFieldWrapper>
            <TextField
              name="lastName"
              label={t("User last name")}
              fullWidth
              variant="outlined"
              inputRef={register}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              autoComplete="off"
            />
          </FormTextFieldWrapper>
          <FormTextFieldWrapper>
            <TextField
              name="email"
              label={t("User email")}
              fullWidth
              variant="outlined"
              inputRef={register}
              error={!!errors.email}
              helperText={errors.email?.message}
              autoComplete="off"
            />
          </FormTextFieldWrapper>
          <Box mb={5}>
            {rolesToPick.length && (
              <TextField
                select
                SelectProps={{
                  native: true,
                }}
                name="role"
                label={t("User role")}
                fullWidth
                variant="outlined"
                inputRef={register}
                error={!!errors.role}
                helperText={errors.role?.message}
              >
                {rolesToPick.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </TextField>
            )}
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              size="large"
            >
              {t("Add team member")}
            </Button>
          </Box>
        </form>
      </CustomDialogContent>

      <ErrorDisplay
        error={gettingWorkplaceRolesError}
        message={t(
          "Error occured while getting workplaces roles, please try again or contact us."
        )}
      />

      <ErrorDisplay
        error={addUserError}
        message={t(
          "Error occured while adding new user, please try again or contact us."
        )}
      />
    </CustomDialog>
  );
}
