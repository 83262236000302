import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EditableInputAdornment from "../../../../components/EditableInputAdornment/EditableInputAdornment";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { useUpdatePatrolLocationMutation } from "../../graphql/mutation.generated";

interface Props {
  patrolId: string;
  location: string;
  disabled?: boolean;
}
export default function PatrolLocation({
  location,
  patrolId,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [locationState, setLocationState] = useState<string>(location);

  const [updateLocationMutation, { error, loading }] = useUpdatePatrolLocationMutation();

  const onLocationChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setLocationState(event.target.value);
  };

  const onLocationBlur = async (): Promise<void> => {
    try {
      await updateLocationMutation({
        variables: {
          updatePatrolLocationData: { id: patrolId, location: locationState },
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <>
      <TextField
        fullWidth
        value={locationState}
        onChange={onLocationChange}
        onBlur={onLocationBlur}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <EditableInputAdornment display={Boolean(locationState) && !disabled} />
          ),
        }}
        placeholder={t("Add location")}
        disabled={disabled || loading}
      />
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing location, please try again or contact us."
        )}
      />
    </>
  );
}
