import { QueryVariablesEnum } from "../../../interfaces/QueryVariables";
import useManageUrlQueryVariables from "../../../utils/useManageUrlQueryVariables";

interface ReturnObject {
  openTaskDialog: (openTaskId: string) => void;
  closeTaskDialog: () => void;
}

export default function useOpenTaskDialog(): ReturnObject {
  const {
    taskId,
    setUrlQueryVariables,
    deleteUrlQueryVariables,
  } = useManageUrlQueryVariables();
  const openTaskDialog = (openTaskId: string): void => {
    setUrlQueryVariables(QueryVariablesEnum.TASKID, openTaskId);
  };

  const closeTaskDialog = (): void => {
    if (taskId) {
      deleteUrlQueryVariables(QueryVariablesEnum.TASKID);
    }
  };

  return {
    openTaskDialog,
    closeTaskDialog,
  };
}
