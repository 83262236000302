import { Box, Button, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { SIGN_IN } from "../../../../routing/paths";
import IdentityLayout from "../../common/IdentityLayout";
import { useActivateAccountMutation } from "../../graphql/mutation.generated";

const useStyles = makeStyles({
  link: {
    display: "block",
  },
});

export default function ActivateAccountPage(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const [activated, setActivated] = useState(false);
  const [activateAccountMutation, { loading, error }] = useActivateAccountMutation({});
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    (async function activateAccount(): Promise<void> {
      try {
        const res = await activateAccountMutation({
          variables: {
            token,
          },
        });
        if (res.data?.activateAccount) {
          setActivated(true);
        }
      } catch (err) {
        //
      }
    })();
  }, [activateAccountMutation, token]);

  if (!loading && activated) {
    return (
      <IdentityLayout>
        <Box pb={2}>
          <Typography variant="h6">{t("Success!")}</Typography>
        </Box>
        <Box pb={5}>
          <Typography variant="body1">
            {t("Your account has been activated, you can now sign in.")}
          </Typography>
        </Box>
        <Link to={SIGN_IN} className={classes.link}>
          <Button color="primary" variant="contained" fullWidth size="large">
            {t("Sign in")}
          </Button>
        </Link>
      </IdentityLayout>
    );
  }
  return (
    <IdentityLayout>
      <Box py={7} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while activiting your account. Maybe your account is already activated or link is incorrect."
        )}
      />
    </IdentityLayout>
  );
}
