import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, makeStyles, TextField, Typography } from "@material-ui/core";
import { ArrowLeft } from "@material-ui/icons";
import { TFunction } from "i18next";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import FormTextFieldWrapper from "../../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import { SignUpData } from "../../../../generated/types";
import { SIGN_IN, VERIFY_EMAIL } from "../../../../routing/paths";
import IdentityLayout from "../../common/IdentityLayout";
import { useSignUpMutation } from "../../graphql/mutation.generated";
import {
  emailValidator,
  passwordValidator,
  phoneValidator,
  repeatPasswordValidator,
  requiredStringValidator,
} from "../../../../utils/formValidators";

interface FormData {
  email: string;
  companyName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  repeatPassword: string;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    email: emailValidator(t),
    companyName: yup.string(),
    firstName: requiredStringValidator(t),
    lastName: requiredStringValidator(t),
    phoneNumber: phoneValidator(t),
    password: passwordValidator(t),
    repeatPassword: repeatPasswordValidator(t),
  });

const useStyles = makeStyles({
  link: {
    display: "block",
  },
});

export default function SignUpPage(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
  });

  const [signUpMutation, { loading, error }] = useSignUpMutation();

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    const { email, password, firstName, lastName, phoneNumber, companyName } = formData;
    const request: SignUpData = {
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
      companyName,
    };
    try {
      const res = await signUpMutation({
        variables: {
          request,
        },
      });
      if (res.data?.signUp) {
        history.push(VERIFY_EMAIL);
      }
    } catch (err) {
      //
    }
  };

  return (
    <IdentityLayout loading={loading}>
      <FormTextFieldWrapper>
        <Typography variant="h6">{t("Sign up")}</Typography>
      </FormTextFieldWrapper>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <FormTextFieldWrapper>
          <TextField
            name="email"
            label={t("Email")}
            fullWidth
            autoFocus
            variant="outlined"
            inputRef={register}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </FormTextFieldWrapper>

        <FormTextFieldWrapper>
          <TextField
            name="firstName"
            label={t("First name")}
            fullWidth
            variant="outlined"
            inputRef={register}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
          />
        </FormTextFieldWrapper>
        <FormTextFieldWrapper>
          <TextField
            name="lastName"
            label={t("Last name")}
            fullWidth
            variant="outlined"
            inputRef={register}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
          />
        </FormTextFieldWrapper>
        <FormTextFieldWrapper>
          <TextField
            name="companyName"
            label={t("Company name")}
            fullWidth
            variant="outlined"
            inputRef={register}
          />
        </FormTextFieldWrapper>
        <FormTextFieldWrapper>
          <TextField
            name="phoneNumber"
            label={t("Phone number")}
            fullWidth
            variant="outlined"
            inputRef={register}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
            InputProps={{
              type: "tel",
            }}
          />
        </FormTextFieldWrapper>
        <FormTextFieldWrapper>
          <TextField
            name="password"
            label={t("Password")}
            type="password"
            fullWidth
            variant="outlined"
            inputRef={register}
            error={!!errors.password}
            helperText={errors.password?.message}
            InputProps={{
              type: "password",
            }}
          />
        </FormTextFieldWrapper>
        <Box pb={5}>
          <TextField
            name="repeatPassword"
            label={t("Repeat password")}
            type="password"
            fullWidth
            variant="outlined"
            inputRef={register}
            error={!!errors.repeatPassword}
            helperText={errors.repeatPassword?.message}
            InputProps={{
              type: "password",
            }}
          />
        </Box>
        <Box mb={3}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            fullWidth
            size="large"
          >
            {t("Submit")}
          </Button>
        </Box>
        <Box>
          <Link to={SIGN_IN} className={classes.link}>
            <Box display="flex" alignItems="center">
              <ArrowLeft fontSize="default" color="secondary" />
              <Typography variant="caption" color="secondary">
                {t("Sign in page")}
              </Typography>
            </Box>
          </Link>
        </Box>
      </form>
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while creating your account. Please try again or contact us."
        )}
      />
    </IdentityLayout>
  );
}
