import { Box, makeStyles, Paper, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import theme from "../../../utils/theme";

interface Props {
  isAddCard?: boolean;
  workplaceName?: string;
  workplaceShortName?: string;
  onCardClick: () => void;
  isNotClickable?: boolean;
}

const useStyles = makeStyles({
  card: {
    cursor: "pointer",
  },
  workplaceCard: {
    backgroundColor: theme.palette.primary.light,
  },
  addCard: {
    backgroundColor: theme.palette.secondary.light,
  },
  wpName: {
    textOverflow: "elipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
});

export default function WorkplaceCard({
  isAddCard,
  workplaceName,
  workplaceShortName,
  onCardClick,
  isNotClickable,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Paper elevation={2}>
      <Box
        width="220px"
        height="140px"
        py={4}
        px={3}
        className={classnames(
          !isNotClickable && classes.card,
          isAddCard ? classes.addCard : classes.workplaceCard
        )}
        onClick={(): void => {
          if (!isNotClickable) {
            onCardClick();
          }
        }}
      >
        {isAddCard ? (
          <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <AddIcon fontSize="large" />
          </Box>
        ) : (
          <Box>
            <Box mb={3}>
              <Typography className={classes.wpName} variant="h4">
                {workplaceName}
              </Typography>
            </Box>
            <Typography variant="body2" display="inline">
              {t("Shorten name")}
            </Typography>
            <span>:&nbsp;</span>
            <Typography variant="h6" display="inline">
              {workplaceShortName}
            </Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
}
