import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@material-ui/core";
import { TFunction } from "i18next";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import CustomDialog from "../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FormTextFieldWrapper from "../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import { AddUserToCompanyData, AddUserToWorkplaceData } from "../../../generated/types";
import { emailValidator, requiredStringValidator } from "../../../utils/formValidators";
import { GET_COMPANY_USERS } from "../graphql/query";
import { useGetCompanyWorkplacesLazyQuery } from "../graphql/query.generated";
import useGetRolesToPick from "./useGetRolesToPick";

import {
  useAddUserToCompanyMutation,
  useAddUserToWorkplaceMutation,
} from "../graphql/mutation.generated";

interface Props {
  open: boolean;
  onClose: () => void;
  companyId: string | undefined;
}

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  workplace?: string;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    role: requiredStringValidator(t),
    email: emailValidator(t),
    firstName: requiredStringValidator(t),
    lastName: requiredStringValidator(t),
  });

export default function AddUserDialog({ open, onClose, companyId }: Props): JSX.Element {
  const { t } = useTranslation();
  const [displayWorkplacePicker, setDisplayWorkplacePicker] = useState<boolean>(false);
  const {
    data: rolesToPick,
    loading: rolesLoading,
    error: rolesGettingError,
  } = useGetRolesToPick({ companyId });

  const { register, handleSubmit, errors, setError } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      role: "",
      email: "",
      workplace: "",
    },
  });

  const [
    getCompanyWorkplaces,
    { data: workplacesData, loading: workplacesLoading, error: workplacesGettingError },
  ] = useGetCompanyWorkplacesLazyQuery();

  const [
    addUserToCompanyMutation,
    { loading: companyUserAddingLoading, error: companyUserAddingError },
  ] = useAddUserToCompanyMutation();
  const [
    addUserToWorkplaceMutation,
    { loading: workplaceUserAddingLoading, error: workplaceUserAddingError },
  ] = useAddUserToWorkplaceMutation();

  useEffect(() => {
    if (companyId) {
      getCompanyWorkplaces({ variables: { companyId } });
    }
  }, [companyId, getCompanyWorkplaces]);

  const onFormSubmit = (formData: FormData): void => {
    const isManagerRole = rolesToPick.filter((role) => role.id === formData.role)[0]
      .isManager;
    if (isManagerRole) {
      addUserToCompany({
        companyId,
        companyRoleId: formData.role,
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
      });
    } else if (!formData.workplace) {
      setError("workplace", { message: t("Workplace must be picked for this user.") });
    } else {
      addUserToWorkplace({
        workplaceId: formData.workplace,
        email: formData.email,
        workplaceRoleId: formData.role,
        firstName: formData.firstName,
        lastName: formData.lastName,
      });
    }
  };

  const addUserToWorkplace = async (userData: AddUserToWorkplaceData): Promise<void> => {
    try {
      const res = await addUserToWorkplaceMutation({
        variables: {
          addUserToWorkplaceData: userData,
        },
        refetchQueries: [{ query: GET_COMPANY_USERS, variables: { companyId } }],
      });
      if (res.data) {
        onClose();
      }
    } catch (e) {
      //
    }
  };

  const addUserToCompany = async (userData: AddUserToCompanyData): Promise<void> => {
    try {
      const res = await addUserToCompanyMutation({
        variables: {
          addUserToCompanyData: userData,
        },
        refetchQueries: [{ query: GET_COMPANY_USERS, variables: { companyId } }],
      });
      if (res.data) {
        onClose();
      }
    } catch (e) {
      //
    }
  };

  const onUserRoleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const pickedRoleId = event.target.value;
    const pickedRole = rolesToPick.filter((role) => role.id === pickedRoleId)[0];
    if (pickedRole.isManager && displayWorkplacePicker) {
      setDisplayWorkplacePicker(false);
    } else if (!pickedRole.isManager && !displayWorkplacePicker) {
      setDisplayWorkplacePicker(true);
    }
  };

  return (
    <CustomDialog
      loading={
        rolesLoading ||
        workplacesLoading ||
        workplaceUserAddingLoading ||
        companyUserAddingLoading
      }
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <CustomDialogContent heading={t("Add new user")}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormTextFieldWrapper>
            <TextField
              name="firstName"
              label={t("User first name")}
              fullWidth
              variant="outlined"
              inputRef={register}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              autoFocus
              autoComplete="off"
            />
          </FormTextFieldWrapper>
          <FormTextFieldWrapper>
            <TextField
              name="lastName"
              label={t("User last name")}
              fullWidth
              variant="outlined"
              inputRef={register}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              autoComplete="off"
            />
          </FormTextFieldWrapper>
          <FormTextFieldWrapper>
            <TextField
              name="email"
              label={t("User email")}
              fullWidth
              variant="outlined"
              inputRef={register}
              error={!!errors.email}
              helperText={errors.email?.message}
              autoComplete="off"
            />
          </FormTextFieldWrapper>
          <FormTextFieldWrapper>
            {Boolean(rolesToPick.length) && (
              <TextField
                select
                SelectProps={{
                  native: true,
                }}
                name="role"
                label={t("User role")}
                fullWidth
                variant="outlined"
                inputRef={register}
                error={!!errors.role}
                helperText={errors.role?.message}
                onChange={onUserRoleChange}
              >
                {rolesToPick.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </TextField>
            )}
          </FormTextFieldWrapper>
          <Box mb={5}>
            {workplacesData && (
              <TextField
                select
                SelectProps={{
                  native: true,
                }}
                name="workplace"
                label={t("User workplace")}
                fullWidth
                variant="outlined"
                inputRef={register}
                error={!!errors.workplace}
                helperText={errors.workplace?.message}
                disabled={!displayWorkplacePicker}
              >
                {workplacesData.getWorkplaces.map((workplace) => (
                  <option key={workplace.id} value={workplace.id}>
                    {workplace.name}
                  </option>
                ))}
              </TextField>
            )}
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              size="large"
            >
              {t("Add user")}
            </Button>
          </Box>
        </form>
      </CustomDialogContent>
      <ErrorDisplay
        error={workplacesGettingError}
        message={t(
          "Error occured while geting companies workplaces, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={rolesGettingError}
        message={t(
          "Error occured while geting user's roles, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={companyUserAddingError}
        message={t(
          "Error occured while adding user to company, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={workplaceUserAddingError}
        message={t(
          "Error occured while adding user to workplace, please try again or contact us."
        )}
      />
    </CustomDialog>
  );
}
