import Box from "@material-ui/core/Box";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, usePagination, useTable } from "react-table";
import { DangerButton } from "../../../components/Buttons/DangerButton/DangerButton";
import { CustomPaginationFooterRow } from "../../../components/CustomTable/CustomPaginationFooterRow/CustomPaginationFooterRow";
import { CustomTableCell } from "../../../components/CustomTable/CustomTableCell/CustomTableCell";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FullScreenLoader from "../../../components/FullScreenLoader/FullScreenLoader";
import { CompanyContext } from "../../../context/CompanyContext/CompanyContextProvider";
import { Label, Permissions } from "../../../generated/types";
import IsUserAuthorised from "../../../utils/AuthUtils/IsUserAuthorised";
import { useDeleteLabelMutation } from "../graphql/mutation.generated";
import { GET_LABELS } from "../graphql/query";
import { GetLabelsQuery, useGetLabelsLazyQuery } from "../graphql/query.generated";
import AddLabelDialog from "./AddLabelDialog";
import EditLabelDialog from "./EditLabelDialog";

import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  cell: {
    width: "30%",
  },
});

export default function Labels(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { chosenCompanyId } = useContext(CompanyContext);

  const [addLabelDialogOpen, setAddLabelDialogOpen] = useState<boolean>(false);
  const [editLabelDialogOpen, setEditLabelDialogOpen] = useState<boolean>(false);
  const [editedLabelData, setEditedLabelData] = useState<Label | null>(null);

  const [
    getLabelsQuery,
    { data: labelsData, loading: loadingLabels, error: loadingLabelsError },
  ] = useGetLabelsLazyQuery();

  const [
    deleteLabelMutation,
    { error: deleteLabelError, loading: deleteLabelLoading },
  ] = useDeleteLabelMutation();

  useEffect(() => {
    if (chosenCompanyId) {
      getLabelsQuery({
        variables: {
          companyId: chosenCompanyId,
        },
      });
    }
  }, [chosenCompanyId, getLabelsQuery]);

  const data = React.useMemo((): Label[] => {
    return labelsData?.getLabels || [];
  }, [labelsData]);

  const columns: Column<Label>[] = React.useMemo(
    () => [
      {
        Header: t("Name") as string,
        accessor: "name",
      },
    ],
    [t]
  );

  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable<Label>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    usePagination
  );

  const handleChangePage = (event, newPage): void => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event): void => {
    setPageSize(Number(event.target.value));
  };

  const openEditUserDialog = (rowData: Label): void => {
    setEditedLabelData(rowData);
    setEditLabelDialogOpen(true);
  };

  const closeEditUserDialog = (): void => {
    setEditedLabelData(null);
    setEditLabelDialogOpen(false);
  };

  const removeLabel = async (id: string): Promise<void> => {
    try {
      await deleteLabelMutation({
        variables: { id },
        update: (cache, res) => {
          if (res.data?.deleteLabel && chosenCompanyId) {
            const labelsQuery: GetLabelsQuery | null = cache.readQuery({
              query: GET_LABELS,
              variables: { companyId: chosenCompanyId },
            });

            if (labelsQuery) {
              const newLabels: GetLabelsQuery = {
                ...labelsQuery,
                getLabels: labelsQuery.getLabels.filter((l) => l.id !== id),
              };

              cache.writeQuery({
                query: GET_LABELS,
                variables: { companyId: chosenCompanyId },
                data: newLabels,
              });
            }
          }
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <Box>
      <Box pb={3} display="flex">
        {/* <Box mr={3}>
          <TextField
            name="search"
            size="small"
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
            variant="outlined"
          />
        </Box> */}
        <Button
          color="primary"
          variant="contained"
          onClick={(): void => setAddLabelDialogOpen(true)}
        >
          {t("Add new label")}
        </Button>
      </Box>
      <TableContainer>
        <Table className={classes.table} {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <CustomTableCell size="small" {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </CustomTableCell>
                ))}
                <CustomTableCell />
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell className={classes.cell} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                  <TableCell align="right">
                    <Box display="flex" justifyContent="flex-end">
                      <Box mr={2}>
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={(): void => openEditUserDialog(row.original)}
                        >
                          {t("Edit")}
                        </Button>
                      </Box>
                      <Box>
                        <IsUserAuthorised permission={Permissions.DeleteTerm}>
                          <DangerButton
                            variant="outlined"
                            onClick={(): Promise<void> => removeLabel(row.original.id)}
                          >
                            {t("Remove")}
                          </DangerButton>
                        </IsUserAuthorised>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          <TableFooter>
            <CustomPaginationFooterRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: data.length }]}
                count={data.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </CustomPaginationFooterRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <FullScreenLoader open={loadingLabels || deleteLabelLoading} />
      <AddLabelDialog
        open={addLabelDialogOpen}
        onClose={(): void => setAddLabelDialogOpen(false)}
        companyId={chosenCompanyId}
      />
      {editedLabelData && (
        <EditLabelDialog
          open={editLabelDialogOpen}
          onClose={(): void => closeEditUserDialog()}
          labelData={editedLabelData}
        />
      )}
      <ErrorDisplay
        error={loadingLabelsError}
        message={t("Error occured while fetching labels, try again or contact us.")}
      />
      <ErrorDisplay
        error={deleteLabelError}
        message={t("Error occured while deleting label, try again or contact us.")}
      />
    </Box>
  );
}
