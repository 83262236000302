import { gql } from "@apollo/client";
import {
  patrolEventFragment,
  patrolEventTaskFragment,
  patrolFragment,
} from "./fragments";

export const GET_PATROLS = gql`
  query getPatrols(
    $paginationData: PaginationData!
    $workplaceId: UUID!
    $filters: PatrolFilters
  ) {
    getPatrols(
      paginationData: $paginationData
      workplaceId: $workplaceId
      filters: $filters
    ) {
      items {
        id
        startDate
        endDate
        location
        assignees {
          id
          firstName
          lastName
        }
      }
      totalCount
    }
  }
`;

export const GET_PATROL = gql`
  ${patrolEventFragment}
  ${patrolFragment}
  query getPatrol($id: UUID!) {
    getPatrol(id: $id) {
      ...Patrol
    }
    getPatrolEvents(patrolId: $id) {
      ...PatrolEvent
    }
  }
`;

export const GET_EXTENDED_PATROL_EVENT_DATA = gql`
  ${patrolEventTaskFragment}
  query getExtendedPatrolEventData($patrolEventId: UUID!) {
    getPatrolEventTasks(patrolEventId: $patrolEventId) {
      ...PatrolEventTask
    }

    getPatrolEventAttachments(patrolEventId: $patrolEventId) {
      id
      attachment {
        id
        filename
        mimetype
        uri
        createdAt
        encoding
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
