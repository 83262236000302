import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import { dangerColor } from "../../../utils/theme";
import { useDeletePatrolMutation } from "../graphql/mutation.generated";

interface Props {
  onClose: (isPatrolDeleted?: boolean) => void;
  patrolId: string;
}
export default function DeletePatrolDialog({ onClose, patrolId }: Props): JSX.Element {
  const { t } = useTranslation();

  const [
    deletePatrolMutation,
    { error: deletePatrolError, loading: deletePatrolLoading },
  ] = useDeletePatrolMutation();

  const onConfirm = async (): Promise<void> => {
    try {
      const res = await deletePatrolMutation({
        variables: {
          id: patrolId,
        },
      });
      if (res.data?.deletePatrol) {
        onClose(true);
      }
    } catch (e) {
      //
    }
  };

  return (
    <>
      <CustomDialog
        loading={deletePatrolLoading}
        open
        onClose={(): void => onClose()}
        maxWidth="md"
      >
        <CustomDialogContent heading={t("Delete patrol")}>
          <Box mb={3}>
            <Typography>{t("Are you sure, this action is final")}</Typography>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Box mr={2}>
              <Button color="secondary" onClick={(): void => onClose()}>
                {t("Cancel action")}
              </Button>
            </Box>
            <Button
              style={{ color: dangerColor }}
              onClick={(): Promise<void> => onConfirm()}
            >
              {t("Confirm")}
            </Button>
          </Box>
        </CustomDialogContent>
      </CustomDialog>
      <ErrorDisplay
        error={deletePatrolError}
        message={t(
          "Error occured while deleting patrol, please try again or contact us."
        )}
      />
    </>
  );
}
