import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import FormTextFieldWrapper from "../../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import { PASSWORD_CHANGED } from "../../../../routing/paths";
import IdentityLayout from "../../common/IdentityLayout";
import { useResetPasswordMutation } from "../../graphql/mutation.generated";
import {
  passwordValidator,
  repeatPasswordValidator,
} from "../../../../utils/formValidators";

interface FormData {
  password: string;
  repeatPassword: string;
}

const schema = (
  t: TFunction
): yup.ObjectSchema<yup.Shape<object | undefined, object>, object> =>
  yup.object().shape({
    password: passwordValidator(t),
    repeatPassword: repeatPasswordValidator(t),
  });

export default function SetNewPasswordPage(): JSX.Element {
  const { t } = useTranslation();
  const { token } = useParams<{ token: string }>();
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
  });
  const [resetPasswordMutation, { loading, error }] = useResetPasswordMutation({});

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    const { password } = formData;
    try {
      const res = await resetPasswordMutation({
        variables: { resetPasswordData: { password, token } },
      });
      if (res.data?.resetPassword) {
        history.push(PASSWORD_CHANGED);
      }
    } catch (err) {
      //
    }
  };

  return (
    <IdentityLayout loading={loading}>
      <Box pb={1}>
        <Typography variant="h6">{t("Reset Password")}</Typography>
      </Box>
      <Box pb={5}>
        <Typography variant="body1">{t("Please type your new password")}</Typography>
      </Box>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <FormTextFieldWrapper>
          <TextField
            name="password"
            label={t("Password")}
            type="password"
            fullWidth
            variant="outlined"
            inputRef={register}
            error={!!errors.password}
            helperText={errors.password?.message}
            InputProps={{
              type: "password",
            }}
          />
        </FormTextFieldWrapper>
        <Box pb={5}>
          <TextField
            name="repeatPassword"
            label={t("Repeat password")}
            type="password"
            fullWidth
            variant="outlined"
            inputRef={register}
            error={!!errors.repeatPassword}
            helperText={errors.repeatPassword?.message}
            InputProps={{
              type: "password",
            }}
          />
        </Box>
        <Box>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            fullWidth
            size="large"
          >
            {t("Confirm")}
          </Button>
        </Box>
      </form>
      <ErrorDisplay
        error={error}
        message={t(
          "Something went wrong while changing your password. Try again or contact us: support@rids.app"
        )}
      />
    </IdentityLayout>
  );
}
