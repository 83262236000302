import { makeStyles, Typography } from "@material-ui/core";
import LabelIcon from "@material-ui/icons/Label";
import React, { useContext, useMemo } from "react";
import { WorkplaceContext } from "../../../context/WorkplaceContext/WorkplaceContextProvider";
import theme, { blueColor, successColor } from "../../../utils/theme";
import useOpenTaskDialog from "../TaskDetailsDialog/useOpenTaskDialog";

interface Props {
  friendlyId: number;
  isChild?: boolean;
  parentId?: string;
  parentFriendlyId?: number;
  hideParent?: boolean;
  isArchived?: boolean;
  isParentArchived?: boolean;
  workplaceShortName?: string;
}

const useStyles = makeStyles({
  childFriendlyId: {
    color: successColor,
  },
  taskFriendly: {
    color: blueColor,
  },
  archived: {
    color: theme.palette.secondary.main,
  },
  childTaskTextWhenDisplayedWithParent: {
    fontWeight: "bold",
  },
});

// TODO - komponent jest gesto uzywany, moze zmienic sposob pobierania tego workplace name
// tak zeby sie pobieralo z contextu czy cos (jezeli to bedzie tworzylo jakies performance issuesy)
export default function TaskFriendlyId({
  friendlyId,
  parentFriendlyId,
  hideParent,
  parentId,
  isChild,
  isArchived,
  isParentArchived,
  workplaceShortName,
}: Props): JSX.Element {
  const classes = useStyles();
  const { openTaskDialog } = useOpenTaskDialog();
  const { chosenWorkplaceId, workplaces } = useContext(WorkplaceContext);

  const displayWorkplaceShortName = useMemo<string>(() => {
    if (workplaceShortName) {
      return workplaceShortName;
    }
    if (chosenWorkplaceId && workplaces) {
      const pickedWorkplaceData = workplaces.find((w) => w.id === chosenWorkplaceId);
      if (pickedWorkplaceData) {
        return pickedWorkplaceData.shortName;
      }
    }
    return "";
  }, [chosenWorkplaceId, workplaceShortName, workplaces]);

  const onParentClick = (): void => {
    if (parentId) {
      openTaskDialog(parentId);
    }
  };

  const getLabelIconClass = (): string => {
    if (isArchived || isParentArchived) {
      return classes.archived;
    }
    if (isChild) {
      return classes.childFriendlyId;
    }
    return classes.taskFriendly;
  };

  const getChildTaskTextClass = (): string => {
    if (!hideParent && parentFriendlyId) {
      return classes.childTaskTextWhenDisplayedWithParent;
    }
    return "";
  };

  return (
    <div style={{ display: "flex" }}>
      {!hideParent && parentFriendlyId && (
        <div
          style={{ cursor: parentId ? "pointer" : "default", display: "flex" }}
          onClick={onParentClick}
        >
          <LabelIcon
            fontSize="small"
            className={isParentArchived ? classes.archived : classes.taskFriendly}
          />
          &nbsp;
          <Typography variant="body2">
            {displayWorkplaceShortName && `${displayWorkplaceShortName}-`}
            {parentFriendlyId} &nbsp; / &nbsp;
          </Typography>
        </div>
      )}
      <div style={{ display: "flex" }}>
        <LabelIcon fontSize="small" className={getLabelIconClass()} />
        &nbsp;
        <Typography variant="body2" className={getChildTaskTextClass()}>
          {displayWorkplaceShortName && `${displayWorkplaceShortName}-`}
          {friendlyId}
        </Typography>
      </div>
    </div>
  );
}
