import { TableCell, withStyles } from "@material-ui/core";
import theme from "../../../utils/theme";

export const CustomTableCell = withStyles({
  head: {
    fontSize: "12px",
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
  footer: {
    borderBottom: "none",
  },
})(TableCell);
