import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@material-ui/core";
import { TFunction } from "i18next";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import CustomDialog from "../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import { Category } from "../../../generated/types";
import { requiredStringValidator } from "../../../utils/formValidators";
import { useCreateCategoryMutation } from "../graphql/mutation.generated";
import { GET_CATEGORIES } from "../graphql/query";
import {
  GetCategoriesQuery,
  GetCategoriesQueryVariables,
} from "../graphql/query.generated";

interface Props {
  open: boolean;
  onClose: () => void;
  companyId: string | undefined;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    name: requiredStringValidator(t),
  });

export default function AddCategoryDialog({
  open,
  onClose,
  companyId,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [createCategoryMutation, { loading, error }] = useCreateCategoryMutation();

  const { register, handleSubmit, errors } = useForm<Category>({
    resolver: yupResolver(schema(t)),
  });

  const onFormSubmit = async (formData: Category): Promise<void> => {
    if (companyId) {
      try {
        const res = await createCategoryMutation({
          variables: {
            createCategoryData: {
              name: formData.name,
              companyId,
            },
          },
          update: (cache, mutationResult) => {
            const categories = cache.readQuery<
              GetCategoriesQuery,
              GetCategoriesQueryVariables
            >({
              query: GET_CATEGORIES,
              variables: {
                companyId,
              },
            });

            const newCategory = mutationResult.data?.createCategory;
            if (categories?.getCategories && newCategory) {
              cache.writeQuery<GetCategoriesQuery, GetCategoriesQueryVariables>({
                query: GET_CATEGORIES,
                variables: {
                  companyId,
                },
                data: {
                  getCategories: [...categories.getCategories, newCategory],
                },
              });
            }
          },
        });

        if (res.data) {
          onClose();
        }
      } catch (e) {
        //
      }
    }
  };

  return (
    <CustomDialog loading={loading} open={open} onClose={onClose} maxWidth="md">
      <CustomDialogContent heading={t("Add new category")}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Box mb={5}>
            <TextField
              name="name"
              label={t("Name")}
              fullWidth
              variant="outlined"
              autoFocus
              inputRef={register}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              size="large"
            >
              {t("Add category")}
            </Button>
          </Box>
        </form>
      </CustomDialogContent>
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while adding new category, please try again or contact us."
        )}
      />
    </CustomDialog>
  );
}
