import { TFunction } from "i18next";

export const MANAGER_ROLE_ID = "0f45b68e-bdcd-494a-a243-9e0e7556bd8c";

export const WORKER_ROLE_ID = "884a06de-0ceb-46a8-8afd-f23f250e9316";

export const standardErrorMsg = (t: TFunction) =>
  t("Error occured, please try again later or contact us.");

export const SHORT_DATE_FORMAT_WITH_HOUR = "DD-MM-YYYY, HH:mm";
