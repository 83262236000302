import * as Types from "../../../generated/types";

import { NotificationFragment } from "./fragments.generated";
import { gql } from "@apollo/client";
import { NotificationFragmentDoc } from "./fragments.generated";
import * as Apollo from "@apollo/client";
export type GetNotificationsQueryVariables = Types.Exact<{
  paginationData: Types.PaginationData;
}>;

export type GetNotificationsQuery = {
  __typename?: "Query";
  getNotifications: {
    __typename?: "GetNotificationsResponse";
    totalCount: number;
    items: Array<{ __typename?: "Notification" } & NotificationFragment>;
  };
};

export const GetNotificationsDocument = gql`
  query getNotifications($paginationData: PaginationData!) {
    getNotifications(paginationData: $paginationData) {
      items {
        ...Notification
      }
      totalCount
    }
  }
  ${NotificationFragmentDoc}
`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      paginationData: // value for 'paginationData'
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >
) {
  return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    baseOptions
  );
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    baseOptions
  );
}
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetNotificationsLazyQuery
>;
export type GetNotificationsQueryResult = Apollo.QueryResult<
  GetNotificationsQuery,
  GetNotificationsQueryVariables
>;
