import { makeStyles, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";
import theme from "../../../../../utils/theme";
import { useUpdatePatrolEventActionMutation } from "../../../graphql/mutation.generated";

interface Props {
  patrolEventId: string;
  eventAction?: string;
  disabled?: boolean;
}

const useStyles = makeStyles({
  textField: {
    // "& .MuiInputBase-root": {
    //   backgroundColor: "rgba(249, 225, 159, 0.4)",
    // },
    // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //   borderColor: blueColor,
    // },
    "& .MuiInputLabel-root.Mui-focused": {
      fontSize: "1rem",
      color: theme.palette.secondary.main,
    },
  },
});

export default function PatrolEventAction({
  eventAction,
  patrolEventId,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  const [
    updateDescriptionMutation,
    { loading, error },
  ] = useUpdatePatrolEventActionMutation();

  const [actionState, setActionState] = useState<string | undefined>(eventAction);

  const onActionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setActionState(event.target.value);
  };

  const onActionBlur = async (): Promise<void> => {
    if (actionState !== eventAction) {
      try {
        await updateDescriptionMutation({
          variables: {
            updatePatrolEventActionData: {
              id: patrolEventId,
              action: actionState,
            },
          },
        });
      } catch (e) {
        //
      }
    }
  };

  return (
    <>
      <TextField
        fullWidth
        multiline
        rows={2}
        rowsMax={4}
        variant="outlined"
        value={actionState}
        onChange={onActionChange}
        onBlur={onActionBlur}
        placeholder={t("Describe what actions have been taken")}
        label={t("Action taken")}
        disabled={disabled || loading}
        className={classes.textField}
      />
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing description, please try again or contact us."
        )}
      />
    </>
  );
}
