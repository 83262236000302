import { InputAdornment, makeStyles, TextField, Tooltip } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import theme from "../../../../utils/theme";
import { useUpdateTaskLocationMutation } from "../../graphql/mutation.generated";

interface Props {
  location: string;
  taskId: string;
  disabled?: boolean;
}

const useStyles = makeStyles({
  locationInput: {
    "& .MuiInputBase-input": {
      padding: 0,
    },
    "& .MuiInputAdornment-root": {
      color: theme.palette.secondary.main,
    },
  },
});

export default function TaskLocation({ location, taskId, disabled }: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  const [locationState, setLocationState] = useState<string>("");

  const [
    updateTaskLocationMutation,
    { error: updateTaskLocationError },
  ] = useUpdateTaskLocationMutation();

  useEffect(() => {
    if (location) {
      setLocationState(location);
    }
  }, [location]);

  const changeLocationState = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setLocationState(event.target.value);
  };

  const onLocationBlur = async (
    event: React.FocusEvent<HTMLInputElement>
  ): Promise<void> => {
    const newLocation = event.target.value;
    if (newLocation !== location) {
      try {
        await updateTaskLocationMutation({
          variables: {
            updateTaskLocationData: {
              id: taskId,
              location: newLocation,
            },
          },
        });
      } catch (e) {
        //
      }
    }
  };

  return (
    <>
      <Tooltip title={t("Location") || ""}>
        <TextField
          disabled={disabled}
          size="small"
          fullWidth
          className={classes.locationInput}
          value={locationState}
          onChange={changeLocationState}
          onBlur={onLocationBlur}
          placeholder={t("Add location")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationOnIcon />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
        />
      </Tooltip>
      <ErrorDisplay
        error={updateTaskLocationError}
        message={t(
          "Error occured while updating task location, try again or contact us."
        )}
      />
    </>
  );
}
