import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@material-ui/core";
import { TFunction } from "i18next";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import CustomDialog from "../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FormTextFieldWrapper from "../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import { useCreateWorkplaceMutation } from "../graphql/mutation.generated";
import { GET_COMPANY_WORKPLACES } from "../graphql/query";
import {
  requiredStringMaxLengthValidator,
  requiredStringValidator,
} from "../../../utils/formValidators";
import {
  GetCompanyWorkplacesQuery,
  GetCompanyWorkplacesQueryVariables,
} from "../graphql/query.generated";

interface Props {
  companyId: string;
  onClose: () => void;
}

interface FormData {
  name: string;
  shortName: string;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    name: requiredStringValidator(t),
    shortName: requiredStringMaxLengthValidator(4, t),
  });

export default function AddWorkplaceDialog({ companyId, onClose }: Props): JSX.Element {
  const { t } = useTranslation();

  const [createWorkplaceMutation, { loading, error }] = useCreateWorkplaceMutation();

  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
  });

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    try {
      const res = await createWorkplaceMutation({
        variables: {
          workplaceData: {
            name: formData.name,
            shortName: formData.shortName.toUpperCase(),
            companyId,
          },
        },
        update: (cache, mutationResult) => {
          const workplaces = cache.readQuery<
            GetCompanyWorkplacesQuery,
            GetCompanyWorkplacesQueryVariables
          >({
            query: GET_COMPANY_WORKPLACES,
            variables: {
              companyId,
            },
          });

          const newWorkplace = mutationResult.data?.createWorkplace;
          if (newWorkplace && workplaces) {
            const updatedWorkplaces = [...workplaces.getWorkplaces];
            updatedWorkplaces.push(newWorkplace);

            cache.writeQuery<
              GetCompanyWorkplacesQuery,
              GetCompanyWorkplacesQueryVariables
            >({
              query: GET_COMPANY_WORKPLACES,
              variables: {
                companyId,
              },
              data: { getWorkplaces: updatedWorkplaces },
            });
          }
        },
      });

      if (res.data) {
        onClose();
      }
    } catch (e) {
      //
    }
  };

  return (
    <CustomDialog open onClose={onClose} maxWidth="md" loading={loading}>
      <CustomDialogContent heading={t("Add new workplace")}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormTextFieldWrapper>
            <TextField
              name="name"
              label={t("Workplace name")}
              fullWidth
              autoFocus
              variant="outlined"
              inputRef={register}
              error={!!errors.name}
              helperText={errors.name?.message}
              autoComplete="off"
            />
          </FormTextFieldWrapper>
          <Box mb={5}>
            <TextField
              name="shortName"
              label={t("Workplace shortname")}
              fullWidth
              variant="outlined"
              inputRef={register}
              error={!!errors.shortName}
              helperText={errors.shortName?.message}
              autoComplete="off"
            />
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              size="large"
            >
              {t("Add workplace")}
            </Button>
          </Box>
        </form>
      </CustomDialogContent>
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while creating your workplace, please try again or contact us."
        )}
      />
    </CustomDialog>
  );
}
