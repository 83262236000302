import React from "react";
import { Switch } from "react-router-dom";
import HelmetWrapper from "../components/HelmetWrapper/HelmetWrapper";
import CompanyContextProvider from "../context/CompanyContext/CompanyContextProvider";
import UserContextProvider from "../context/UserContext/UserContextProvider";
import WorkplaceContextProvider from "../context/WorkplaceContext/WorkplaceContextProvider";
import { Permissions } from "../generated/types";
import IsUserAuthorised from "../utils/AuthUtils/IsUserAuthorised";
import ProtectedRoute from "../utils/AuthUtils/ProtectedRoute";
import AccountSettingsPage from "../views/Account/AccountSettingsPage/AccountSettingsPage";
import AdminPanelPage from "../views/AdminPanel/AdminPanelPage";
import AfterLoginPage from "../views/Identity/AfterLoginPage/AfterLoginPage";
import PatrolPage from "../views/Patrols/PatrolPage/PatrolPage";
import PatrolsListPage from "../views/Patrols/PatrolsListPage/PatrolsListPage";
import SettingsPage from "../views/Settings/SettingsPage/SettingsPage";
import ArchivedTasksPage from "../views/Tasks/TasksArchivePage/ArchivedTasksPage";
import TasksPage from "../views/Tasks/TasksPage/TasksPage";
import TeamPage from "../views/Team/TeamPage/TeamPage";
import WorkplaceSettings from "../views/WorkplaceSettings/WorkplaceSettings";
import {
  ACCOUNT_SETTINGS,
  ADMIN_PANEL,
  PATROL,
  PATROLS_LIST,
  SETTINGS,
  TASKS,
  TASKS_ARCHIVE,
  TEAM,
  WORKPLACE,
  WORKPLACE_SETTINGS,
} from "./paths";

export default function SignedInUserRoutes(): JSX.Element {
  return (
    <UserContextProvider>
      <CompanyContextProvider>
        <WorkplaceContextProvider>
          <Switch>
            <ProtectedRoute
              exact
              path="/"
              render={(): JSX.Element => (
                <HelmetWrapper siteTitle="Start page">
                  <AfterLoginPage />
                </HelmetWrapper>
              )}
            />
            <ProtectedRoute
              exact
              path={ACCOUNT_SETTINGS}
              render={(): JSX.Element => (
                <HelmetWrapper siteTitle="Account settings">
                  <AccountSettingsPage />
                </HelmetWrapper>
              )}
            />

            <ProtectedRoute
              path={SETTINGS}
              render={(): JSX.Element => (
                <IsUserAuthorised permission={Permissions.CreateTerm} display404Page>
                  <HelmetWrapper siteTitle="Settings">
                    <SettingsPage />
                  </HelmetWrapper>
                </IsUserAuthorised>
              )}
            />

            <ProtectedRoute
              path={ADMIN_PANEL}
              render={(): JSX.Element => (
                <IsUserAuthorised
                  permission={Permissions.CreateSystemUser}
                  display404Page
                >
                  <HelmetWrapper siteTitle="Admin panel">
                    <AdminPanelPage />
                  </HelmetWrapper>
                </IsUserAuthorised>
              )}
            />

            {/* Workplace routes */}
            <ProtectedRoute
              path={WORKPLACE}
              render={(routeData): JSX.Element => (
                <>
                  <ProtectedRoute
                    path={routeData.match.url + TASKS}
                    render={(): JSX.Element => (
                      <IsUserAuthorised
                        permission={Permissions.ViewWorkplace}
                        display404Page
                      >
                        <HelmetWrapper siteTitle="Tasks">
                          <TasksPage />
                        </HelmetWrapper>
                      </IsUserAuthorised>
                    )}
                  />

                  <ProtectedRoute
                    path={routeData.match.url + TASKS_ARCHIVE}
                    render={(): JSX.Element => (
                      <IsUserAuthorised
                        permission={Permissions.ViewWorkplace}
                        display404Page
                      >
                        <HelmetWrapper siteTitle="Tasks archive">
                          <ArchivedTasksPage />
                        </HelmetWrapper>
                      </IsUserAuthorised>
                    )}
                  />

                  <ProtectedRoute
                    exact
                    path={routeData.match.url + TEAM}
                    render={(): JSX.Element => (
                      <IsUserAuthorised
                        permission={Permissions.ViewWorkplaceUsers}
                        display404Page
                      >
                        <HelmetWrapper siteTitle="Workplace team">
                          <TeamPage />
                        </HelmetWrapper>
                      </IsUserAuthorised>
                    )}
                  />

                  <ProtectedRoute
                    exact
                    path={routeData.match.url + PATROLS_LIST}
                    render={(): JSX.Element => (
                      <IsUserAuthorised
                        permission={Permissions.ViewWorkplace}
                        display404Page
                      >
                        <HelmetWrapper siteTitle="Patrols - List">
                          <PatrolsListPage />
                        </HelmetWrapper>
                      </IsUserAuthorised>
                    )}
                  />

                  <ProtectedRoute
                    exact
                    path={routeData.match.url + PATROL}
                    render={(): JSX.Element => (
                      <IsUserAuthorised
                        permission={[Permissions.ViewPatrols, Permissions.ViewPatrolsOwn]}
                        display404Page
                      >
                        <HelmetWrapper siteTitle="Patrol">
                          <PatrolPage />
                        </HelmetWrapper>
                      </IsUserAuthorised>
                    )}
                  />

                  <ProtectedRoute
                    exact
                    path={routeData.match.url + WORKPLACE_SETTINGS}
                    render={(): JSX.Element => (
                      <IsUserAuthorised
                        permission={Permissions.EditWorkplace}
                        display404Page
                      >
                        <HelmetWrapper siteTitle="Workplace settings">
                          <WorkplaceSettings />
                        </HelmetWrapper>
                      </IsUserAuthorised>
                    )}
                  />
                </>
              )}
            />
          </Switch>
        </WorkplaceContextProvider>
      </CompanyContextProvider>
    </UserContextProvider>
  );
}
