import { Divider, MenuItem, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import theme from "../../../../utils/theme";
import { useGetWorkplaceUsersForTaskLazyQuery } from "../../graphql/query.generated";

interface Props {
  workplaceId: string;
  user?: string;
  onUserChange: (id: string | null) => void;
  disabled?: boolean;
}

const emptySelectValue = "empty";

export default function TaskWorkplaceUserSelect({
  workplaceId,
  user,
  onUserChange,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [
    getWorkplaceUsersQuery,
    {
      data: workplaceUsersData,
      // loading: workplaceUsersLoading,
      error: workplaceUsersError,
    },
  ] = useGetWorkplaceUsersForTaskLazyQuery();

  useEffect(() => {
    if (workplaceId) {
      getWorkplaceUsersQuery({
        variables: { workplaceId, includeInactive: true },
      });
    }
  }, [getWorkplaceUsersQuery, workplaceId]);

  const usersToPick = useMemo(() => {
    if (workplaceUsersData?.getWorkplaceUsers) {
      return workplaceUsersData.getWorkplaceUsers.filter(
        (userData) => userData.firstName || userData.lastName
      );
    }
    return [];
  }, [workplaceUsersData]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let newValue: string | null = event.target.value;
    if (newValue === emptySelectValue) {
      newValue = null;
    }
    onUserChange(newValue);
  };

  return (
    <>
      {usersToPick.length && (
        <TextField
          select
          InputProps={{ disableUnderline: true }}
          fullWidth
          value={user || emptySelectValue}
          placeholder={t("Select user")}
          onChange={onChange}
          size="small"
          disabled={disabled}
        >
          <MenuItem value={emptySelectValue}>
            <Typography variant="body2" style={{ color: theme.palette.grey[400] }}>
              + {t("Pick user")}
            </Typography>
          </MenuItem>
          <Divider />
          {usersToPick.map((userData) => (
            <MenuItem key={`taskReporter${userData.id}`} value={userData.id}>
              {`${userData.firstName || ""} ${userData.lastName || ""}`}
            </MenuItem>
          ))}
        </TextField>
      )}
      <ErrorDisplay
        error={workplaceUsersError}
        message={t(
          "Error occured while getting available reporters, please try again or contact us."
        )}
      />
    </>
  );
}
