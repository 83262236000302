import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import PdfGenerator from "../../../../components/PdfGenerator/PdfGenerator";
import { black100Opacity } from "../../../../utils/theme";
import useGetWorkplaceDisplayData from "../../../../utils/useGetWorkplaceDisplayData";
import { TaskDetailsFragment } from "../../graphql/fragments.generated";
import { useDeleteTaskMutation } from "../../graphql/mutation.generated";
import TaskPdf from "../../TaskPdf/TaskPdf";
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";

interface Props {
  taskData?: TaskDetailsFragment;
  taskId: string;
  parentTaskId?: string;
  onTaskDeleted: () => void;
  isTaskArchived?: boolean;
  // attachments?: TaskAttachmentFragment[];
}
export default function TaskMoreMenu({
  taskId,
  onTaskDeleted,
  parentTaskId,
  isTaskArchived,
  taskData,
}: // attachments,
Props): JSX.Element {
  const { t } = useTranslation();
  const {
    currentCompanyName,
    currentWorkplaceName,
    currentWorkplaceShortName,
  } = useGetWorkplaceDisplayData();

  const [deleteTaskMutation, { error: deleteTaskError }] = useDeleteTaskMutation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [opened, setOpened] = useState<boolean>(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState<boolean>(false);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setOpened(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const onTryDeleteTask = (): void => {
    setConfirmDeleteDialogOpen(true);
  };

  const onDeleteTask = async (): Promise<void> => {
    try {
      const res = await deleteTaskMutation({
        variables: { id: taskId },
        update: (cache, response) => {
          if (response?.data?.deleteTask) {
            if (parentTaskId) {
              cache.modify({
                id: cache.identify({ __typename: "Task", id: parentTaskId }),
                fields: {
                  children(existingChildren = [], { readField }) {
                    return existingChildren.filter(
                      (taskRef) => taskId !== readField("id", taskRef)
                    );
                  },
                },
              });
            } else {
              cache.modify({
                fields: {
                  getWorkplaceTasks(existingTasks = [], { readField }) {
                    return existingTasks.filter(
                      (taskRef) => taskId !== readField("id", taskRef)
                    );
                  },
                },
              });
            }
          }
        },
      });
      if (res) {
        handleClose();
        onTaskDeleted();
      }
    } catch (e) {
      //
    }
  };

  const pdfDownloadLinkDisplay = (linkTite: string, loading?: boolean): JSX.Element => (
    <MenuItem disabled={loading}>
      <ListItemIcon>
        {loading ? (
          <CircularProgress color="secondary" size={20} />
        ) : (
          <PictureAsPdfIcon />
        )}
      </ListItemIcon>
      <ListItemText style={{ color: black100Opacity }}>{linkTite}</ListItemText>
    </MenuItem>
  );

  // pdf generator must be in memo when used (some limitations of react-pdf/renderer)
  const taskPdfMemo = useMemo(() => {
    if (taskData && opened) {
      return (
        <PdfGenerator
          pdfContent={TaskPdf({
            taskData,
            t,
            companyName: currentCompanyName,
            workplaceName: currentWorkplaceName,
            workplaceShortName: currentWorkplaceShortName,
          })}
          fileName={`Task_${currentWorkplaceShortName}-${taskData.friendlyId}_rids-app`}
          loadingDisplay={pdfDownloadLinkDisplay(t("Task PDF"), true)}
        >
          {pdfDownloadLinkDisplay(t("Task PDF"))}
        </PdfGenerator>
      );
    }
    return <div />;
  }, [
    currentCompanyName,
    currentWorkplaceName,
    currentWorkplaceShortName,
    opened,
    t,
    taskData,
  ]);

  // const taskPdfWithAttachmentsMemo = useMemo(() => {
  //   if (taskData && opened) {
  //     return (
  //       <PdfGenerator
  //         pdfContent={TaskPdf({
  //           taskData,
  //           t,
  //           companyName: currentCompanyName,
  //           workplaceName: currentWorkplaceName,
  //           workplaceShortName: currentWorkplaceShortName,
  //           attachments,
  //         })}
  //         fileName={`Task_${currentWorkplaceShortName}-${taskData.friendlyId}_rids-app`}
  //         loadingDisplay={pdfDownloadLinkDisplay(t("Task PDF with images"), true)}
  //       >
  //         {pdfDownloadLinkDisplay(t("Task PDF with images"))}
  //       </PdfGenerator>
  //     );
  //   }
  //   return <div />;
  // }, [
  //   attachments,
  //   currentCompanyName,
  //   currentWorkplaceName,
  //   currentWorkplaceShortName,
  //   opened,
  //   t,
  //   taskData,
  // ]);

  return (
    <>
      <Tooltip title={t("Menu") || ""}>
        <IconButton
          size="small"
          aria-controls="more-menu"
          aria-haspopup="true"
          onClick={handleButtonClick}
        >
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="more-menu"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {taskPdfMemo}
        <MenuItem onClick={onTryDeleteTask} disabled={isTaskArchived}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>{t("Delete task")}</ListItemText>
        </MenuItem>
      </Menu>
      <ErrorDisplay
        error={deleteTaskError}
        message={t("Error occured while deleting task, please try again or contact us.")}
      />
      <ConfirmDialog
        onConfirm={onDeleteTask}
        onDecline={(): void => setConfirmDeleteDialogOpen(false)}
        open={confirmDeleteDialogOpen}
        dialogContent={t(
          "Are you sure you want to delete task, this action is irreversible."
        )}
        dialogTitle={t("Delete task action")}
        dangerButtonColor
      />
    </>
  );
}
