import { Box, makeStyles, Typography } from "@material-ui/core";
import { ArrowLeft } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SIGN_IN } from "../../../../routing/paths";
import IdentityLayout from "../../common/IdentityLayout";

const useStyles = makeStyles({
  link: {
    display: "block",
  },
});

export default function VerifyEmailPage(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <IdentityLayout>
      <Box pb={2}>
        <Typography variant="h6">{t("Please verify your email")}</Typography>
      </Box>
      <Box pb={5}>
        <Typography variant="body1">
          {t(
            "We sent you an email with verification link. Please click the link or copy and paste it into your browser."
          )}
        </Typography>
      </Box>
      <Box>
        <Link to={SIGN_IN} className={classes.link}>
          <Box display="flex" alignItems="center">
            <ArrowLeft fontSize="default" color="secondary" />
            <Typography variant="caption" color="secondary">
              {t("Sign in page")}
            </Typography>
          </Box>
        </Link>
      </Box>
    </IdentityLayout>
  );
}
