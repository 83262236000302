import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import DueDateDisplay from "../../../../components/DueDateDisplay/DueDateDisplay";
import FinishDateDisplay from "../../../../components/FinishDateDisplay/FinishDateDisplay";
import TaskStatusesDisplay from "../../../../components/TaskStatusesDisplay/TaskStatusesDisplay";
import UserIcon from "../../../../components/UserIcon/UserIcon";
import generateUserNameString from "../../../../utils/generateUserNameString";
import theme from "../../../../utils/theme";
import TaskFriendlyId from "../../common/TaskFriendlyId";
import { TaskListDataFragment } from "../../graphql/fragments.generated";
import useOpenTaskDialog from "../../TaskDetailsDialog/useOpenTaskDialog";

interface Props {
  task: TaskListDataFragment;
  workplaceId: string;
  isChild?: boolean;
}

const useStyles = makeStyles({
  taskWrapper: {
    backgroundColor: theme.palette.grey[100],
    display: "flex",
    alignItems: "center",
    padding: "0.75rem 1.25rem 0.5rem",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
  },
  userIconWrapper: {
    width: "15%",
    display: "flex",
    justifyContent: "center",
  },
});

export default function PerfSingleTask({ task, isChild }: Props): JSX.Element {
  const classes = useStyles();
  const { openTaskDialog } = useOpenTaskDialog();

  return (
    <div
      className={classes.taskWrapper}
      onClick={(): void => openTaskDialog(task.id)}
      role="row"
      tabIndex={0}
    >
      <div style={{ width: "10%" }}>
        <TaskFriendlyId friendlyId={task.friendlyId} isChild={isChild} />
      </div>
      <div style={{ width: "30%", maxWidth: "30%" }}>
        <Typography variant="body1">{task.name}</Typography>
      </div>
      <div style={{ width: "30%" }}>
        <Typography variant="body2" color="secondary">
          {task.location}
        </Typography>
      </div>
      <div style={{ width: "15%" }}>
        {task.finishedDate ? (
          <FinishDateDisplay finishDate={task.finishedDate} />
        ) : (
          <DueDateDisplay date={task.dueDate} />
        )}
      </div>
      <div className={classes.userIconWrapper}>
        {task.assignees.map((assignee) => (
          <div
            key={`taskassignee${assignee.id}`}
            style={{ marginRight: theme.spacing(1.5) }}
          >
            <UserIcon name={generateUserNameString(assignee)} />
          </div>
        ))}
      </div>
      <div style={{ width: "10%" }}>
        <TaskStatusesDisplay
          statusName={task.status.name}
          statusType={task.status.type}
        />
      </div>
    </div>
  );
}
