import { Box, Button, CircularProgress } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Attachment, MimeType } from "../../generated/types";
import PhotoGalleryDialog from "../PhotoGalleryDialog/PhotoGalleryDialog";
import SingleAttachment from "./SingleAttachment";

interface AttachmentWithAddings extends Attachment {
  attachmentWrapperId: string; // for example TaskAttachment ID
}
interface Props {
  attachments: AttachmentWithAddings[];
  onAttachmentDelete: (attachmentId: string) => Promise<void>;
  onFileUpload: (file: File[]) => Promise<void>;
  addingLoading?: boolean;
  fetchingLoading?: boolean;
  canUserEdit?: boolean;
  maxInRow?: number;
}

export default function TaskAttachments({
  attachments,
  onAttachmentDelete,
  onFileUpload,
  addingLoading,
  fetchingLoading,
  canUserEdit,
  maxInRow,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [openPhotoGallery, setOpenPhotoGallery] = useState<string>("");

  const photoAttachments = useMemo<Attachment[]>((): Attachment[] => {
    return attachments.filter(
      (a) => a.mimetype !== MimeType.Mp4 && a.mimetype !== MimeType.Pdf
    );
  }, [attachments]);

  const onFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const { validity, files } = event.target;
    const filesToUpload = Object.values(files || {});
    if (validity.valid && filesToUpload.length) {
      onFileUpload(filesToUpload);
    }
  };

  const onPhotoGalleryOpen = (attachmentId: string): void => {
    setOpenPhotoGallery(attachmentId);
  };

  return (
    <>
      <Box mb={1} display="flex" alignItems="center">
        <Box>
          <label htmlFor="raised-button-file">
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={onFileChange}
            />
            <Button variant="contained" color="primary" component="span" size="small">
              {t("+ Add file")}
            </Button>
          </label>
        </Box>
        <Box ml={1}>{addingLoading && <CircularProgress size={20} />}</Box>
      </Box>

      <Box display="flex" flexWrap="wrap" pl={1}>
        {Boolean(attachments.length) &&
          attachments.map((attachment) => (
            <Box
              key={`attachment${attachment.id}`}
              maxWidth={maxInRow ? `${100 / maxInRow - 2}%` : "23%"}
              mb={1}
              mr="2%"
            >
              <SingleAttachment
                createdAt={attachment.createdAt}
                fileName={attachment.filename}
                mimetype={attachment.mimetype}
                uri={attachment.uri}
                onPhotoGalleryOpen={(): void => onPhotoGalleryOpen(attachment.id)}
                onDelete={(): Promise<void> =>
                  onAttachmentDelete(attachment.attachmentWrapperId)
                }
                canUserEdit={canUserEdit}
              />
            </Box>
          ))}
      </Box>
      {Boolean(photoAttachments.length) && openPhotoGallery && (
        <PhotoGalleryDialog
          onClose={(): void => setOpenPhotoGallery("")}
          open
          photosData={photoAttachments}
          startPhotoId={openPhotoGallery}
        />
      )}
      {fetchingLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100px">
          <CircularProgress size={30} />
        </Box>
      )}
      {/* <CustomSnackbar
        message={t(
          "Error occured while uploading files, please try again or contact us."
        )}
        open={Boolean(updateAttachmentsError)}
      />
      <CustomSnackbar
        message={t(
          "Error occured while getting task attachments, please try again or contact us."
        )}
        open={Boolean(attachmentsFetchingError)}
      />
      <CustomSnackbar
        message={t(
          "Error occured while deleting task attachment, please try again or contact us."
        )}
        open={Boolean(deleteTaskAttachmentError)}
      /> */}
    </>
  );
}
