import { Box, Button, CircularProgress, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { useCreateCommentMutation } from "../../graphql/mutation.generated";
import { GET_TASK_COMMENTS } from "../../graphql/query";
import { GetTaskCommentsQuery } from "../../graphql/query.generated";

interface Props {
  taskId: string;
}

export default function CreateComment({ taskId }: Props): JSX.Element {
  const { t } = useTranslation();

  const [commentContent, setCommentContent] = useState<string>("");

  const [
    createCommentMutation,
    { loading: createCommentLoading, error: createCommentError },
  ] = useCreateCommentMutation();

  const onCommentChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    setCommentContent(event.target.value);

  const onCommentSubmit = async (
    e: React.ChangeEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (commentContent) {
      try {
        const res = await createCommentMutation({
          variables: { createCommentData: { content: commentContent, taskId } },
          update: (cache, response) => {
            const comments: GetTaskCommentsQuery | null = cache.readQuery({
              query: GET_TASK_COMMENTS,
              variables: { taskId },
            });
            const newComment = response.data?.createComment;
            if (comments && newComment) {
              const updatedComments: GetTaskCommentsQuery = {
                ...comments,
                getTaskComments: [...comments.getTaskComments, newComment],
              };

              cache.writeQuery({
                query: GET_TASK_COMMENTS,
                variables: { taskId },
                data: updatedComments,
              });
            }
          },
        });
        if (res.data) {
          setCommentContent("");
        }
      } catch (err) {
        //
      }
    }
  };

  return (
    <Box>
      <form onSubmit={onCommentSubmit}>
        <TextField
          fullWidth
          value={commentContent}
          onChange={onCommentChange}
          disabled={createCommentLoading}
          variant="outlined"
          placeholder={t("New comment content")}
          label={t("Add new comment")}
          multiline
          size="small"
        />
        <Box mt={1} display="flex" alignItems="center">
          <Button type="submit" variant="contained" color="primary" size="small">
            {t("Add comment")}
          </Button>
          {createCommentLoading && (
            <Box ml={2}>
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
      </form>
      <ErrorDisplay
        error={createCommentError}
        message={t(
          "Error occured while adding your comment, please try again or contact us."
        )}
      />
    </Box>
  );
}
