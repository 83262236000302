import React, { createContext, useContext, useEffect, useReducer } from "react";
import { Workplace } from "../../generated/types";
import { PropsChild } from "../../interfaces/PropsChild";
import useManageUrlQueryVariables from "../../utils/useManageUrlQueryVariables";
import { CompanyContext } from "../CompanyContext/CompanyContextProvider";
import { useGetWorkplacesLazyQuery } from "../graphql/query.generated";

interface WorkplaceContextData {
  chosenWorkplaceId: string;
  workplaces?: Workplace[];
  setCurrentWorkplace?: (workplaceId: string) => void;
  clearWorkplacesContext?: () => void;
  loading?: boolean;
}

enum StateActions {
  SET_CURRENT_WORKPLACE = "SET_CURRENT_WORKPLACE",
  SET_WORKPLACES = "SET_WORKPLACES",
  SET_LOADING = "SET_LOADING",
  CLEAR_WORKPLACES_CONTEXT = "CLEAR_WORKPLACES_CONTEXT",
}

export const WorkplaceContext = createContext<WorkplaceContextData>({
  chosenWorkplaceId: "",
});

const reducer = (
  state: WorkplaceContextData,
  action: { type: string; payload?: string | Workplace[] | boolean }
): WorkplaceContextData => {
  switch (action.type) {
    case StateActions.SET_CURRENT_WORKPLACE:
      return { ...state, loading: false, chosenWorkplaceId: action.payload as string };
    case StateActions.SET_WORKPLACES:
      return { ...state, workplaces: action.payload as Workplace[] };
    case StateActions.CLEAR_WORKPLACES_CONTEXT:
      return { chosenWorkplaceId: "" };
    case StateActions.SET_LOADING:
      return { ...state, loading: action.payload as boolean };
    default:
      throw new Error();
  }
};

export default function WorkplaceContextProvider({ children }: PropsChild): JSX.Element {
  const { chosenCompanyId, companies } = useContext(CompanyContext);
  const { workplaceId: urlWorkplaceId } = useManageUrlQueryVariables();
  const [getWorkplacesLazyQuery, { data: workplacesData }] = useGetWorkplacesLazyQuery();

  const initialState: WorkplaceContextData = {
    setCurrentWorkplace: (workplaceId) => {
      localStorage.setItem("lastWorkplaceId", workplaceId);
      dispatch({ type: StateActions.SET_CURRENT_WORKPLACE, payload: workplaceId });
    },
    clearWorkplacesContext: () => {
      dispatch({ type: StateActions.CLEAR_WORKPLACES_CONTEXT });
    },
    loading: true,
    chosenWorkplaceId: "",
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (chosenCompanyId) {
      getWorkplacesLazyQuery({
        variables: {
          companyId: chosenCompanyId,
        },
      });
    }
  }, [chosenCompanyId, getWorkplacesLazyQuery]);

  useEffect(() => {
    if (workplacesData?.getWorkplaces.length) {
      dispatch({
        type: StateActions.SET_WORKPLACES,
        payload: workplacesData.getWorkplaces,
      });
      dispatch({
        type: StateActions.SET_CURRENT_WORKPLACE,
        payload:
          urlWorkplaceId ||
          localStorage.getItem("lastWorkplaceId") ||
          workplacesData.getWorkplaces[0].id,
      });
      dispatch({
        type: StateActions.SET_LOADING,
        payload: false,
      });
    }
    if (workplacesData && !workplacesData.getWorkplaces.length) {
      dispatch({
        type: StateActions.SET_WORKPLACES,
        payload: [],
      });
      dispatch({
        type: StateActions.SET_LOADING,
        payload: false,
      });
    }
  }, [urlWorkplaceId, workplacesData]);

  useEffect(() => {
    if (companies && !companies.length) {
      dispatch({
        type: StateActions.SET_WORKPLACES,
        payload: [],
      });
      dispatch({
        type: StateActions.SET_LOADING,
        payload: false,
      });
    }
  }, [companies]);

  return <WorkplaceContext.Provider value={state}>{children}</WorkplaceContext.Provider>;
}
