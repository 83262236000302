import { Box } from "@material-ui/core";
import React from "react";
import { PropsChild } from "../../interfaces/PropsChild";

interface Props extends PropsChild {
  pt?: number;
}

export default function StandardContentPaddingsLayout({
  children,
  pt,
}: Props): JSX.Element {
  return (
    <Box pt={pt || 2} pl={3} pr={2} pb={5}>
      {children}
    </Box>
  );
}
