import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import FullScreenLoader from "../../../../components/FullScreenLoader/FullScreenLoader";
import { useDeletePatrolEventMutation } from "../../graphql/mutation.generated";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";

interface Props {
  parolEventId: string;
}

export default function PatrolEventMoreMenu({ patrolEventId }): JSX.Element {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [
    deletePatrolEventMutation,
    { error: deletingPatrolEventError, loading: deleteLoading },
  ] = useDeletePatrolEventMutation();

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const onPatrolEventDelete = async (): Promise<void> => {
    try {
      await deletePatrolEventMutation({
        variables: {
          id: patrolEventId,
        },
        update: (cache, res) => {
          if (res.data?.deletePatrolEvent) {
            cache.modify({
              fields: {
                getPatrolEvents(existingEvents = [], { readField }) {
                  return existingEvents.filter(
                    (eventRef) => patrolEventId !== readField("id", eventRef)
                  );
                },
              },
            });
          }
        },
      });
    } catch (e) {
      //
    }
  };
  return (
    <>
      <FullScreenLoader open={deleteLoading} />
      <IconButton
        size="small"
        aria-controls="more-menu"
        aria-haspopup="true"
        onClick={handleButtonClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={onPatrolEventDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>{t("Delete event")}</ListItemText>
        </MenuItem>
      </Menu>
      <ErrorDisplay
        error={deletingPatrolEventError}
        message={t(
          "Error occured while deleting patrol event, please try again or contact us."
        )}
      />
    </>
  );
}
