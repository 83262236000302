import { CircularProgress } from "@material-ui/core";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { PropsChild } from "../../interfaces/PropsChild";

interface Props extends PropsChild {
  pdfContent: JSX.Element;
  fileName: string;
  loadingDisplay?: JSX.Element;
}
export default function PdfGenerator({
  pdfContent,
  children,
  fileName,
  loadingDisplay,
}: Props): JSX.Element {
  // TODO this is a hacky solution, chceck, maybe it works on build or check for the solution from library creators (this is)
  const [displayPdfHack, setDisplayPdfHack] = useState(false);
  useEffect(() => {
    setTimeout(() => setDisplayPdfHack(true), 1000);
  }, []);

  if (displayPdfHack) {
    return (
      <PDFDownloadLink document={pdfContent} fileName={fileName}>
        {({ loading }) => {
          if (loading) {
            return loadingDisplay || <CircularProgress color="secondary" />;
          }
          return <>{children}</>;
        }}
      </PDFDownloadLink>
    );
  }

  return loadingDisplay || <CircularProgress color="secondary" />;
}
