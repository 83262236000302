import { yupResolver } from "@hookform/resolvers/yup";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import DueDateDisplay from "../../../../components/DueDateDisplay/DueDateDisplay";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import FinishDateDisplay from "../../../../components/FinishDateDisplay/FinishDateDisplay";
import TaskStatusesDisplay from "../../../../components/TaskStatusesDisplay/TaskStatusesDisplay";
import UserIcon from "../../../../components/UserIcon/UserIcon";
import { requiredStringValidator } from "../../../../utils/formValidators";
import theme from "../../../../utils/theme";
import TaskFriendlyId from "../../common/TaskFriendlyId";
import { TaskDetailsChildFragment } from "../../graphql/fragments.generated";
import { useCreateTaskMutation } from "../../graphql/mutation.generated";
import { GET_TASK_DETAILS } from "../../graphql/query";
import useOpenTaskDialog from "../useOpenTaskDialog";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";

interface Props {
  taskId: string;
  workplaceId: string;
  childIssues: TaskDetailsChildFragment[];
  canUserEditTask?: boolean;
}
interface FormData {
  name: string;
}
const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    name: requiredStringValidator(t),
  });

export default function TaskChildren({
  taskId,
  childIssues,
  workplaceId,
  canUserEditTask,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [addChildIssueMode, setAddChildIssueMode] = useState(false);
  const { openTaskDialog } = useOpenTaskDialog();

  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
  });

  const [
    createTaskMutation,
    { loading: createTaskLoading, error: createTaskError },
  ] = useCreateTaskMutation();

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    try {
      const res = await createTaskMutation({
        variables: {
          createTaskData: {
            name: formData.name,
            parentId: taskId,
            workplaceId,
          },
        },
        // TODO check if this can be ommited
        refetchQueries: [
          {
            query: GET_TASK_DETAILS,
            variables: {
              id: taskId,
            },
          },
        ],
      });

      if (res.data) {
        setAddChildIssueMode(false);
      }
    } catch (e) {
      //
    }
  };
  return (
    <>
      <Box mb={2}>
        <Paper elevation={1}>
          {childIssues.map((childData) => (
            <Box
              key={`taskChildren${childData.id}`}
              border={`1px solid ${theme.palette.divider}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              py={1.5}
              px={2}
            >
              <Box
                width="45%"
                display="flex"
                style={{ cursor: "pointer" }}
                onClick={(): void => openTaskDialog(childData.id)}
              >
                <Box mr={1}>
                  <TaskFriendlyId friendlyId={childData.friendlyId} hideParent isChild />
                </Box>
                <Typography variant="body2">{childData.name}</Typography>
              </Box>
              <Box width="20%">
                {childData.finishedDate && (
                  <FinishDateDisplay finishDate={childData.finishedDate} />
                )}
                {!childData.finishedDate && childData.dueDate && (
                  <DueDateDisplay date={childData.dueDate} />
                )}
              </Box>
              <Box width="20%" display="flex" justifyContent="center">
                {childData.assignees.map((assignee) => (
                  <Box key={`userIcon-${assignee.id}`} mr={0.5}>
                    <UserIcon name={`${assignee.firstName} ${assignee.lastName}`} />
                  </Box>
                ))}
              </Box>
              <Box width="15%" display="flex" justifyContent="flex-end">
                <TaskStatusesDisplay
                  statusName={childData.status.name}
                  statusType={childData.status.type}
                />
              </Box>
            </Box>
          ))}
        </Paper>
      </Box>
      {addChildIssueMode ? (
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Box display="flex">
            <Box width="60%" mr={3}>
              <TextField
                name="name"
                fullWidth
                inputRef={register}
                placeholder={t("Add child issue name")}
                label={t("Task name")}
                variant="outlined"
                size="small"
                error={!!errors.name}
                helperText={errors.name?.message}
                autoFocus
              />
            </Box>
            <Box mr={1}>
              <Button type="submit" variant="contained" color="primary">
                {t("Add task")}
              </Button>
            </Box>
            <Box>
              <Button
                variant="outlined"
                onClick={(): void => setAddChildIssueMode(false)}
              >
                {t("Cancel")}
              </Button>
            </Box>
            {createTaskLoading && (
              <Box ml={2} display="flex" alignItems="center">
                <CircularProgress size={25} />
              </Box>
            )}
          </Box>
        </form>
      ) : (
        <Button
          disabled={!canUserEditTask}
          variant="contained"
          color="primary"
          size="small"
          onClick={(): void => setAddChildIssueMode(true)}
        >
          {t("Add child issue")}
        </Button>
      )}
      <ErrorDisplay
        error={createTaskError}
        message={t(
          "Error occured while adding new task, please try again or contact us."
        )}
      />
    </>
  );
}
