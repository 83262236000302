import * as Types from "../../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type SetAllNotificationsAsReadMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SetAllNotificationsAsReadMutation = {
  __typename?: "Mutation";
  setAllNotificationsAsRead: boolean;
};

export type SetNotificationAsReadMutationVariables = Types.Exact<{
  id: Types.Scalars["UUID"];
}>;

export type SetNotificationAsReadMutation = {
  __typename?: "Mutation";
  setNotificationAsRead: boolean;
};

export const SetAllNotificationsAsReadDocument = gql`
  mutation SetAllNotificationsAsRead {
    setAllNotificationsAsRead
  }
`;
export type SetAllNotificationsAsReadMutationFn = Apollo.MutationFunction<
  SetAllNotificationsAsReadMutation,
  SetAllNotificationsAsReadMutationVariables
>;

/**
 * __useSetAllNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useSetAllNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAllNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAllNotificationsAsReadMutation, { data, loading, error }] = useSetAllNotificationsAsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetAllNotificationsAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAllNotificationsAsReadMutation,
    SetAllNotificationsAsReadMutationVariables
  >
) {
  return Apollo.useMutation<
    SetAllNotificationsAsReadMutation,
    SetAllNotificationsAsReadMutationVariables
  >(SetAllNotificationsAsReadDocument, baseOptions);
}
export type SetAllNotificationsAsReadMutationHookResult = ReturnType<
  typeof useSetAllNotificationsAsReadMutation
>;
export type SetAllNotificationsAsReadMutationResult = Apollo.MutationResult<SetAllNotificationsAsReadMutation>;
export type SetAllNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<
  SetAllNotificationsAsReadMutation,
  SetAllNotificationsAsReadMutationVariables
>;
export const SetNotificationAsReadDocument = gql`
  mutation SetNotificationAsRead($id: UUID!) {
    setNotificationAsRead(id: $id)
  }
`;
export type SetNotificationAsReadMutationFn = Apollo.MutationFunction<
  SetNotificationAsReadMutation,
  SetNotificationAsReadMutationVariables
>;

/**
 * __useSetNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useSetNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNotificationAsReadMutation, { data, loading, error }] = useSetNotificationAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetNotificationAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetNotificationAsReadMutation,
    SetNotificationAsReadMutationVariables
  >
) {
  return Apollo.useMutation<
    SetNotificationAsReadMutation,
    SetNotificationAsReadMutationVariables
  >(SetNotificationAsReadDocument, baseOptions);
}
export type SetNotificationAsReadMutationHookResult = ReturnType<
  typeof useSetNotificationAsReadMutation
>;
export type SetNotificationAsReadMutationResult = Apollo.MutationResult<SetNotificationAsReadMutation>;
export type SetNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<
  SetNotificationAsReadMutation,
  SetNotificationAsReadMutationVariables
>;
