import * as Types from "../../../generated/types";

import {
  WorkplaceUserFragment,
  TaskListDataFragment,
  TaskDetailsFragment,
  CommentAuthorFragment,
  TaskAttachmentFragment,
  ArchivedTaskListFragment,
} from "./fragments.generated";
import { gql } from "@apollo/client";
import {
  WorkplaceUserFragmentDoc,
  TaskListDataFragmentDoc,
  TaskDetailsFragmentDoc,
  CommentAuthorFragmentDoc,
  TaskAttachmentFragmentDoc,
  ArchivedTaskListFragmentDoc,
} from "./fragments.generated";
import * as Apollo from "@apollo/client";
export type GetWorkplaceUsersForTaskQueryVariables = Types.Exact<{
  includeInactive?: Types.Maybe<Types.Scalars["Boolean"]>;
  workplaceId: Types.Scalars["UUID"];
}>;

export type GetWorkplaceUsersForTaskQuery = {
  __typename?: "Query";
  getWorkplaceUsers: Array<{ __typename?: "User" } & WorkplaceUserFragment>;
};

export type GetWorkplaceTermsQueryVariables = Types.Exact<{
  companyId: Types.Scalars["UUID"];
}>;

export type GetWorkplaceTermsQuery = {
  __typename?: "Query";
  getCategories: Array<{ __typename?: "Category"; id: any; name: string }>;
  getLabels: Array<{ __typename?: "Label"; id: any; name: string }>;
};

export type GetWorkplaceCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars["UUID"];
}>;

export type GetWorkplaceCategoriesQuery = {
  __typename?: "Query";
  getCategories: Array<{ __typename?: "Category"; id: any; name: string }>;
};

export type GetWorkplaceLabelsQueryVariables = Types.Exact<{
  companyId: Types.Scalars["UUID"];
}>;

export type GetWorkplaceLabelsQuery = {
  __typename?: "Query";
  getLabels: Array<{ __typename?: "Label"; id: any; name: string }>;
};

export type GetWorkplaceTaskStatusesQueryVariables = Types.Exact<{
  workplaceId: Types.Scalars["UUID"];
}>;

export type GetWorkplaceTaskStatusesQuery = {
  __typename?: "Query";
  getWorkplaceTaskStatuses: Array<{
    __typename?: "TaskStatus";
    id: any;
    name: string;
    order: number;
    type: Types.TaskStatusType;
  }>;
};

export type GetTasksListDataQueryVariables = Types.Exact<{
  filters?: Types.Maybe<Types.TaskFilters>;
  workplaceId: Types.Scalars["UUID"];
}>;

export type GetTasksListDataQuery = {
  __typename?: "Query";
  getWorkplaceTasks: Array<{ __typename?: "Task" } & TaskListDataFragment>;
};

export type GetTaskDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars["UUID"];
}>;

export type GetTaskDetailsQuery = {
  __typename?: "Query";
  getTask: { __typename?: "Task" } & TaskDetailsFragment;
};

export type GetWorkplaceShortNameQueryVariables = Types.Exact<{
  id: Types.Scalars["UUID"];
}>;

export type GetWorkplaceShortNameQuery = {
  __typename?: "Query";
  getWorkplace: { __typename?: "Workplace"; id: any; shortName: string };
};

export type GetTaskCommentsQueryVariables = Types.Exact<{
  taskId: Types.Scalars["UUID"];
}>;

export type GetTaskCommentsQuery = {
  __typename?: "Query";
  getTaskComments: Array<{
    __typename?: "Comment";
    id: any;
    content: string;
    createdAt: any;
    updatedAt: any;
    author?: Types.Maybe<{ __typename?: "User" } & CommentAuthorFragment>;
  }>;
};

export type GetTaskAttachmentsQueryVariables = Types.Exact<{
  taskId: Types.Scalars["UUID"];
}>;

export type GetTaskAttachmentsQuery = {
  __typename?: "Query";
  getTaskAttachments: Array<{ __typename?: "TaskAttachment" } & TaskAttachmentFragment>;
};

export type GetWorkplaceArchivedTasksQueryVariables = Types.Exact<{
  filters?: Types.Maybe<Types.ArchivedTaskFilters>;
  paginationData: Types.PaginationData;
  workplaceId: Types.Scalars["UUID"];
}>;

export type GetWorkplaceArchivedTasksQuery = {
  __typename?: "Query";
  getWorkplaceArchivedTasks: {
    __typename?: "GetWorkplaceArchivedTasksResponse";
    totalCount: number;
    items: Array<{ __typename?: "Task" } & ArchivedTaskListFragment>;
  };
};

export const GetWorkplaceUsersForTaskDocument = gql`
  query GetWorkplaceUsersForTask($includeInactive: Boolean, $workplaceId: UUID!) {
    getWorkplaceUsers(includeInactive: $includeInactive, workplaceId: $workplaceId) {
      ...WorkplaceUser
    }
  }
  ${WorkplaceUserFragmentDoc}
`;

/**
 * __useGetWorkplaceUsersForTaskQuery__
 *
 * To run a query within a React component, call `useGetWorkplaceUsersForTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplaceUsersForTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplaceUsersForTaskQuery({
 *   variables: {
 *      includeInactive: // value for 'includeInactive'
 *      workplaceId: // value for 'workplaceId'
 *   },
 * });
 */
export function useGetWorkplaceUsersForTaskQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkplaceUsersForTaskQuery,
    GetWorkplaceUsersForTaskQueryVariables
  >
) {
  return Apollo.useQuery<
    GetWorkplaceUsersForTaskQuery,
    GetWorkplaceUsersForTaskQueryVariables
  >(GetWorkplaceUsersForTaskDocument, baseOptions);
}
export function useGetWorkplaceUsersForTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkplaceUsersForTaskQuery,
    GetWorkplaceUsersForTaskQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetWorkplaceUsersForTaskQuery,
    GetWorkplaceUsersForTaskQueryVariables
  >(GetWorkplaceUsersForTaskDocument, baseOptions);
}
export type GetWorkplaceUsersForTaskQueryHookResult = ReturnType<
  typeof useGetWorkplaceUsersForTaskQuery
>;
export type GetWorkplaceUsersForTaskLazyQueryHookResult = ReturnType<
  typeof useGetWorkplaceUsersForTaskLazyQuery
>;
export type GetWorkplaceUsersForTaskQueryResult = Apollo.QueryResult<
  GetWorkplaceUsersForTaskQuery,
  GetWorkplaceUsersForTaskQueryVariables
>;
export const GetWorkplaceTermsDocument = gql`
  query GetWorkplaceTerms($companyId: UUID!) {
    getCategories(companyId: $companyId) {
      id
      name
    }
    getLabels(companyId: $companyId) {
      id
      name
    }
  }
`;

/**
 * __useGetWorkplaceTermsQuery__
 *
 * To run a query within a React component, call `useGetWorkplaceTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplaceTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplaceTermsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetWorkplaceTermsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkplaceTermsQuery,
    GetWorkplaceTermsQueryVariables
  >
) {
  return Apollo.useQuery<GetWorkplaceTermsQuery, GetWorkplaceTermsQueryVariables>(
    GetWorkplaceTermsDocument,
    baseOptions
  );
}
export function useGetWorkplaceTermsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkplaceTermsQuery,
    GetWorkplaceTermsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetWorkplaceTermsQuery, GetWorkplaceTermsQueryVariables>(
    GetWorkplaceTermsDocument,
    baseOptions
  );
}
export type GetWorkplaceTermsQueryHookResult = ReturnType<
  typeof useGetWorkplaceTermsQuery
>;
export type GetWorkplaceTermsLazyQueryHookResult = ReturnType<
  typeof useGetWorkplaceTermsLazyQuery
>;
export type GetWorkplaceTermsQueryResult = Apollo.QueryResult<
  GetWorkplaceTermsQuery,
  GetWorkplaceTermsQueryVariables
>;
export const GetWorkplaceCategoriesDocument = gql`
  query GetWorkplaceCategories($companyId: UUID!) {
    getCategories(companyId: $companyId) {
      id
      name
    }
  }
`;

/**
 * __useGetWorkplaceCategoriesQuery__
 *
 * To run a query within a React component, call `useGetWorkplaceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplaceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplaceCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetWorkplaceCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkplaceCategoriesQuery,
    GetWorkplaceCategoriesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetWorkplaceCategoriesQuery,
    GetWorkplaceCategoriesQueryVariables
  >(GetWorkplaceCategoriesDocument, baseOptions);
}
export function useGetWorkplaceCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkplaceCategoriesQuery,
    GetWorkplaceCategoriesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetWorkplaceCategoriesQuery,
    GetWorkplaceCategoriesQueryVariables
  >(GetWorkplaceCategoriesDocument, baseOptions);
}
export type GetWorkplaceCategoriesQueryHookResult = ReturnType<
  typeof useGetWorkplaceCategoriesQuery
>;
export type GetWorkplaceCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetWorkplaceCategoriesLazyQuery
>;
export type GetWorkplaceCategoriesQueryResult = Apollo.QueryResult<
  GetWorkplaceCategoriesQuery,
  GetWorkplaceCategoriesQueryVariables
>;
export const GetWorkplaceLabelsDocument = gql`
  query GetWorkplaceLabels($companyId: UUID!) {
    getLabels(companyId: $companyId) {
      id
      name
    }
  }
`;

/**
 * __useGetWorkplaceLabelsQuery__
 *
 * To run a query within a React component, call `useGetWorkplaceLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplaceLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplaceLabelsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetWorkplaceLabelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkplaceLabelsQuery,
    GetWorkplaceLabelsQueryVariables
  >
) {
  return Apollo.useQuery<GetWorkplaceLabelsQuery, GetWorkplaceLabelsQueryVariables>(
    GetWorkplaceLabelsDocument,
    baseOptions
  );
}
export function useGetWorkplaceLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkplaceLabelsQuery,
    GetWorkplaceLabelsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetWorkplaceLabelsQuery, GetWorkplaceLabelsQueryVariables>(
    GetWorkplaceLabelsDocument,
    baseOptions
  );
}
export type GetWorkplaceLabelsQueryHookResult = ReturnType<
  typeof useGetWorkplaceLabelsQuery
>;
export type GetWorkplaceLabelsLazyQueryHookResult = ReturnType<
  typeof useGetWorkplaceLabelsLazyQuery
>;
export type GetWorkplaceLabelsQueryResult = Apollo.QueryResult<
  GetWorkplaceLabelsQuery,
  GetWorkplaceLabelsQueryVariables
>;
export const GetWorkplaceTaskStatusesDocument = gql`
  query GetWorkplaceTaskStatuses($workplaceId: UUID!) {
    getWorkplaceTaskStatuses(workplaceId: $workplaceId) {
      id
      name
      order
      type
    }
  }
`;

/**
 * __useGetWorkplaceTaskStatusesQuery__
 *
 * To run a query within a React component, call `useGetWorkplaceTaskStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplaceTaskStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplaceTaskStatusesQuery({
 *   variables: {
 *      workplaceId: // value for 'workplaceId'
 *   },
 * });
 */
export function useGetWorkplaceTaskStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkplaceTaskStatusesQuery,
    GetWorkplaceTaskStatusesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetWorkplaceTaskStatusesQuery,
    GetWorkplaceTaskStatusesQueryVariables
  >(GetWorkplaceTaskStatusesDocument, baseOptions);
}
export function useGetWorkplaceTaskStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkplaceTaskStatusesQuery,
    GetWorkplaceTaskStatusesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetWorkplaceTaskStatusesQuery,
    GetWorkplaceTaskStatusesQueryVariables
  >(GetWorkplaceTaskStatusesDocument, baseOptions);
}
export type GetWorkplaceTaskStatusesQueryHookResult = ReturnType<
  typeof useGetWorkplaceTaskStatusesQuery
>;
export type GetWorkplaceTaskStatusesLazyQueryHookResult = ReturnType<
  typeof useGetWorkplaceTaskStatusesLazyQuery
>;
export type GetWorkplaceTaskStatusesQueryResult = Apollo.QueryResult<
  GetWorkplaceTaskStatusesQuery,
  GetWorkplaceTaskStatusesQueryVariables
>;
export const GetTasksListDataDocument = gql`
  query GetTasksListData($filters: TaskFilters, $workplaceId: UUID!) {
    getWorkplaceTasks(filters: $filters, workplaceId: $workplaceId) {
      ...TaskListData
    }
  }
  ${TaskListDataFragmentDoc}
`;

/**
 * __useGetTasksListDataQuery__
 *
 * To run a query within a React component, call `useGetTasksListDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksListDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksListDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      workplaceId: // value for 'workplaceId'
 *   },
 * });
 */
export function useGetTasksListDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTasksListDataQuery,
    GetTasksListDataQueryVariables
  >
) {
  return Apollo.useQuery<GetTasksListDataQuery, GetTasksListDataQueryVariables>(
    GetTasksListDataDocument,
    baseOptions
  );
}
export function useGetTasksListDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTasksListDataQuery,
    GetTasksListDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetTasksListDataQuery, GetTasksListDataQueryVariables>(
    GetTasksListDataDocument,
    baseOptions
  );
}
export type GetTasksListDataQueryHookResult = ReturnType<typeof useGetTasksListDataQuery>;
export type GetTasksListDataLazyQueryHookResult = ReturnType<
  typeof useGetTasksListDataLazyQuery
>;
export type GetTasksListDataQueryResult = Apollo.QueryResult<
  GetTasksListDataQuery,
  GetTasksListDataQueryVariables
>;
export const GetTaskDetailsDocument = gql`
  query GetTaskDetails($id: UUID!) {
    getTask(id: $id) {
      ...TaskDetails
    }
  }
  ${TaskDetailsFragmentDoc}
`;

/**
 * __useGetTaskDetailsQuery__
 *
 * To run a query within a React component, call `useGetTaskDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTaskDetailsQuery, GetTaskDetailsQueryVariables>
) {
  return Apollo.useQuery<GetTaskDetailsQuery, GetTaskDetailsQueryVariables>(
    GetTaskDetailsDocument,
    baseOptions
  );
}
export function useGetTaskDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaskDetailsQuery,
    GetTaskDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetTaskDetailsQuery, GetTaskDetailsQueryVariables>(
    GetTaskDetailsDocument,
    baseOptions
  );
}
export type GetTaskDetailsQueryHookResult = ReturnType<typeof useGetTaskDetailsQuery>;
export type GetTaskDetailsLazyQueryHookResult = ReturnType<
  typeof useGetTaskDetailsLazyQuery
>;
export type GetTaskDetailsQueryResult = Apollo.QueryResult<
  GetTaskDetailsQuery,
  GetTaskDetailsQueryVariables
>;
export const GetWorkplaceShortNameDocument = gql`
  query GetWorkplaceShortName($id: UUID!) {
    getWorkplace(id: $id) {
      id
      shortName
    }
  }
`;

/**
 * __useGetWorkplaceShortNameQuery__
 *
 * To run a query within a React component, call `useGetWorkplaceShortNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplaceShortNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplaceShortNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkplaceShortNameQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkplaceShortNameQuery,
    GetWorkplaceShortNameQueryVariables
  >
) {
  return Apollo.useQuery<GetWorkplaceShortNameQuery, GetWorkplaceShortNameQueryVariables>(
    GetWorkplaceShortNameDocument,
    baseOptions
  );
}
export function useGetWorkplaceShortNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkplaceShortNameQuery,
    GetWorkplaceShortNameQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetWorkplaceShortNameQuery,
    GetWorkplaceShortNameQueryVariables
  >(GetWorkplaceShortNameDocument, baseOptions);
}
export type GetWorkplaceShortNameQueryHookResult = ReturnType<
  typeof useGetWorkplaceShortNameQuery
>;
export type GetWorkplaceShortNameLazyQueryHookResult = ReturnType<
  typeof useGetWorkplaceShortNameLazyQuery
>;
export type GetWorkplaceShortNameQueryResult = Apollo.QueryResult<
  GetWorkplaceShortNameQuery,
  GetWorkplaceShortNameQueryVariables
>;
export const GetTaskCommentsDocument = gql`
  query GetTaskComments($taskId: UUID!) {
    getTaskComments(taskId: $taskId) {
      id
      content
      author {
        ...CommentAuthor
      }
      createdAt
      updatedAt
    }
  }
  ${CommentAuthorFragmentDoc}
`;

/**
 * __useGetTaskCommentsQuery__
 *
 * To run a query within a React component, call `useGetTaskCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskCommentsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskCommentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTaskCommentsQuery,
    GetTaskCommentsQueryVariables
  >
) {
  return Apollo.useQuery<GetTaskCommentsQuery, GetTaskCommentsQueryVariables>(
    GetTaskCommentsDocument,
    baseOptions
  );
}
export function useGetTaskCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaskCommentsQuery,
    GetTaskCommentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetTaskCommentsQuery, GetTaskCommentsQueryVariables>(
    GetTaskCommentsDocument,
    baseOptions
  );
}
export type GetTaskCommentsQueryHookResult = ReturnType<typeof useGetTaskCommentsQuery>;
export type GetTaskCommentsLazyQueryHookResult = ReturnType<
  typeof useGetTaskCommentsLazyQuery
>;
export type GetTaskCommentsQueryResult = Apollo.QueryResult<
  GetTaskCommentsQuery,
  GetTaskCommentsQueryVariables
>;
export const GetTaskAttachmentsDocument = gql`
  query GetTaskAttachments($taskId: UUID!) {
    getTaskAttachments(taskId: $taskId) {
      ...TaskAttachment
    }
  }
  ${TaskAttachmentFragmentDoc}
`;

/**
 * __useGetTaskAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetTaskAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskAttachmentsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskAttachmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTaskAttachmentsQuery,
    GetTaskAttachmentsQueryVariables
  >
) {
  return Apollo.useQuery<GetTaskAttachmentsQuery, GetTaskAttachmentsQueryVariables>(
    GetTaskAttachmentsDocument,
    baseOptions
  );
}
export function useGetTaskAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaskAttachmentsQuery,
    GetTaskAttachmentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetTaskAttachmentsQuery, GetTaskAttachmentsQueryVariables>(
    GetTaskAttachmentsDocument,
    baseOptions
  );
}
export type GetTaskAttachmentsQueryHookResult = ReturnType<
  typeof useGetTaskAttachmentsQuery
>;
export type GetTaskAttachmentsLazyQueryHookResult = ReturnType<
  typeof useGetTaskAttachmentsLazyQuery
>;
export type GetTaskAttachmentsQueryResult = Apollo.QueryResult<
  GetTaskAttachmentsQuery,
  GetTaskAttachmentsQueryVariables
>;
export const GetWorkplaceArchivedTasksDocument = gql`
  query GetWorkplaceArchivedTasks(
    $filters: ArchivedTaskFilters
    $paginationData: PaginationData!
    $workplaceId: UUID!
  ) {
    getWorkplaceArchivedTasks(
      filters: $filters
      paginationData: $paginationData
      workplaceId: $workplaceId
    ) {
      items {
        ...ArchivedTaskList
      }
      totalCount
    }
  }
  ${ArchivedTaskListFragmentDoc}
`;

/**
 * __useGetWorkplaceArchivedTasksQuery__
 *
 * To run a query within a React component, call `useGetWorkplaceArchivedTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplaceArchivedTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplaceArchivedTasksQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      paginationData: // value for 'paginationData'
 *      workplaceId: // value for 'workplaceId'
 *   },
 * });
 */
export function useGetWorkplaceArchivedTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkplaceArchivedTasksQuery,
    GetWorkplaceArchivedTasksQueryVariables
  >
) {
  return Apollo.useQuery<
    GetWorkplaceArchivedTasksQuery,
    GetWorkplaceArchivedTasksQueryVariables
  >(GetWorkplaceArchivedTasksDocument, baseOptions);
}
export function useGetWorkplaceArchivedTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkplaceArchivedTasksQuery,
    GetWorkplaceArchivedTasksQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetWorkplaceArchivedTasksQuery,
    GetWorkplaceArchivedTasksQueryVariables
  >(GetWorkplaceArchivedTasksDocument, baseOptions);
}
export type GetWorkplaceArchivedTasksQueryHookResult = ReturnType<
  typeof useGetWorkplaceArchivedTasksQuery
>;
export type GetWorkplaceArchivedTasksLazyQueryHookResult = ReturnType<
  typeof useGetWorkplaceArchivedTasksLazyQuery
>;
export type GetWorkplaceArchivedTasksQueryResult = Apollo.QueryResult<
  GetWorkplaceArchivedTasksQuery,
  GetWorkplaceArchivedTasksQueryVariables
>;
