import { Box, Button, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { Workplace } from "../../../../generated/types";
import { MANAGER_ROLE_ID } from "../../../../utils/consts";
import { GET_COMPANY_USERS } from "../../graphql/query";
import { RoleToPick } from "../interfaces";
import RoleDisplayWrapper from "./RoleDisplayWrapper";
import {
  useAddUserToWorkplaceMutation,
  useChangeUserCompanyRoleMutation,
} from "../../graphql/mutation.generated";

interface Props {
  onLoading: (loading: boolean) => void;
  onRoleAdded: () => void;
  workplaces: Workplace[];
  rolesToPick: RoleToPick[];
  companyId: string;
  userEmail: string;
  userCompanyRoleId: string;
}

interface FormData {
  workplaceId: string;
  roleId: string;
}

export default function AddNewUserRole({
  workplaces,
  rolesToPick,
  onLoading,
  companyId,
  onRoleAdded,
  userEmail,
  userCompanyRoleId,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [availableRoles, setAvailableRoles] = useState<RoleToPick[]>([]);

  const [
    addUserToWorkplace,
    { error: addUserToWorkplaceError },
  ] = useAddUserToWorkplaceMutation({});
  const [
    changeUserCompanyRole,
    { error: changeUserCompanyRoleError },
  ] = useChangeUserCompanyRoleMutation({});

  const { control, watch, setValue, handleSubmit } = useForm<FormData>({});
  const roleValue = watch("roleId");

  useEffect(() => {
    if (!workplaces.length) {
      const managerRoles = rolesToPick.filter((r) => r.isManager);
      setAvailableRoles(managerRoles);
    } else {
      setAvailableRoles(rolesToPick);
    }
  }, [rolesToPick, workplaces]);

  useEffect(() => {
    if (availableRoles.length) {
      setValue("roleId", availableRoles[availableRoles.length - 1].id);
    }
  }, [availableRoles, setValue]);

  useEffect(() => {
    if (workplaces.length) {
      setValue("workplaceId", workplaces[0].id);
    }
  }, [setValue, workplaces]);

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    if (formData.roleId === MANAGER_ROLE_ID) {
      onLoading(true);
      try {
        const res = await changeUserCompanyRole({
          variables: {
            changeUserCompanyRoleData: {
              companyRoleId: formData.roleId,
              id: userCompanyRoleId,
            },
          },
          refetchQueries: [{ query: GET_COMPANY_USERS, variables: { companyId } }],
        });

        if (res.data) {
          onLoading(false);
          onRoleAdded();
        }
        if (res.errors) {
          onLoading(false);
        }
      } catch (e) {
        onLoading(false);
      }
    } else if (formData.roleId && formData.workplaceId) {
      onLoading(true);
      try {
        const res = await addUserToWorkplace({
          variables: {
            addUserToWorkplaceData: {
              email: userEmail,
              workplaceId: formData.workplaceId,
              workplaceRoleId: formData.roleId,
            },
          },
          refetchQueries: [{ query: GET_COMPANY_USERS, variables: { companyId } }],
        });

        if (res.data) {
          onLoading(false);
          onRoleAdded();
        }
        if (res.errors) {
          onLoading(false);
        }
      } catch (e) {
        onLoading(false);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <RoleDisplayWrapper>
          {workplaces.length ? (
            <Box width="30%">
              <Controller
                control={control}
                name="workplaceId"
                as={
                  <TextField
                    size="small"
                    select
                    SelectProps={{
                      native: true,
                    }}
                    label={t("Workplace")}
                    fullWidth
                    variant="outlined"
                    disabled={roleValue === MANAGER_ROLE_ID}
                  >
                    {workplaces.map((wp) => (
                      <option key={wp.id} value={wp.id}>
                        {wp.name}
                      </option>
                    ))}
                  </TextField>
                }
              />
            </Box>
          ) : (
            ""
          )}
          <Box width="30%">
            {rolesToPick.length && (
              <Controller
                control={control}
                name="roleId"
                as={
                  <TextField
                    size="small"
                    select
                    SelectProps={{
                      native: true,
                    }}
                    label={t("User role")}
                    fullWidth
                    variant="outlined"
                  >
                    {availableRoles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </TextField>
                }
              />
            )}
          </Box>
          <Box>
            <Button color="primary" variant="contained" type="submit">
              {t("Save")}
            </Button>
          </Box>
        </RoleDisplayWrapper>
      </form>
      <ErrorDisplay
        error={addUserToWorkplaceError}
        message={t(
          "Error occured while adding user to workplace, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={changeUserCompanyRoleError}
        message={t(
          "Error occured while changing user role, please try again or contact us."
        )}
      />
    </>
  );
}
