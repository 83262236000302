import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../../components/Dialog/CustomDialog/CustomDialog";
import CustomDialogContent from "../../../components/Dialog/CustomDialogContent/CustomDialogContent";
import { EditTaskStatusData, TaskStatusType } from "../../../generated/types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { requiredStringValidator } from "../../../utils/formValidators";
import FormTextFieldWrapper from "../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import { Box, Button, MenuItem, TextField } from "@material-ui/core";
import statusTypeValueDisplay from "./statusTypeValueDisplay";
import {
  useCreateTaskStatusMutation,
  useEditTaskStatusMutation,
} from "../graphql/mutation.generated";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import { TFunction } from "i18next";
import { GET_WORKPLACE_TASK_STATUSES_FOR_SETTINGS } from "../graphql/query";
import { GetWorkplaceTaskStatusesForSettingsQuery } from "../graphql/query.generated";

interface Props {
  open: boolean;
  workplaceId: string;
  onClose: (successMsg?: string) => void;
  editTaskStatusData?: EditTaskStatusData;
}

interface FormData {
  name: string;
  type: TaskStatusType;
}

const schema = (t: TFunction): yup.ObjectSchema<object | undefined, object> =>
  yup.object().shape({
    name: requiredStringValidator(t),
  });

export default function TaskStatusDialog({
  open,
  onClose,
  workplaceId,
  editTaskStatusData,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [
    createTaskStatusMutation,
    { loading: createTaskStatusLoading, error: createTaskStatusError },
  ] = useCreateTaskStatusMutation();

  const [
    editTaskStatusMutation,
    { loading: editTaskStatusLoading, error: editTaskStatusError },
  ] = useEditTaskStatusMutation();

  const { register, errors, handleSubmit, control } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      name: editTaskStatusData?.name || "",
      type: editTaskStatusData?.type || TaskStatusType.Todo,
    },
  });

  const onFormSubmit = async (formData: FormData): Promise<void> => {
    if (workplaceId) {
      if (!editTaskStatusData) {
        const statusCreated = await createTaskStatus(formData);
        if (statusCreated) {
          onClose(t("New task status added!"));
        }
      } else if (
        editTaskStatusData.name !== formData.name ||
        editTaskStatusData.type !== formData.type
      ) {
        const statusEdited = await editTaskStatus(formData);
        if (statusEdited) {
          onClose(t("Task status updated successfully!"));
        }
      }
    }
  };

  const createTaskStatus = async (formData: FormData): Promise<boolean> => {
    try {
      const res = await createTaskStatusMutation({
        variables: {
          createTaskStatusData: {
            name: formData.name,
            type: formData.type,
            workplaceId,
          },
        },
        update: (cache, result) => {
          const cachedStatuses = cache.readQuery<GetWorkplaceTaskStatusesForSettingsQuery>(
            {
              query: GET_WORKPLACE_TASK_STATUSES_FOR_SETTINGS,
              variables: { workplaceId },
            }
          );

          if (cachedStatuses && result.data?.createTaskStatus) {
            const newQuery: GetWorkplaceTaskStatusesForSettingsQuery = {
              ...cachedStatuses,
              getWorkplaceTaskStatuses: [...cachedStatuses.getWorkplaceTaskStatuses],
            };

            newQuery.getWorkplaceTaskStatuses.push({
              ...result.data.createTaskStatus,
            });

            cache.writeQuery({
              query: GET_WORKPLACE_TASK_STATUSES_FOR_SETTINGS,
              variables: { workplaceId },
              data: newQuery,
            });
          }
        },
      });
      return Boolean(res.data?.createTaskStatus);
    } catch (error) {
      //
    }
    return false;
  };

  const editTaskStatus = async (formData: FormData): Promise<boolean> => {
    if (editTaskStatusData) {
      try {
        const res = await editTaskStatusMutation({
          variables: {
            editTaskStatusData: {
              id: editTaskStatusData?.id,
              name: formData.name,
              type: formData.type,
            },
          },
        });
        return Boolean(res.data?.editTaskStatus);
      } catch (error) {
        //
      }
    }

    return false;
  };

  return (
    <CustomDialog
      open={open}
      onClose={(): void => onClose()}
      loading={createTaskStatusLoading || editTaskStatusLoading}
    >
      <CustomDialogContent heading={t("Add new task status")}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormTextFieldWrapper>
            <TextField
              name="name"
              label={`${t("Task status name")}*`}
              fullWidth
              variant="outlined"
              autoFocus
              inputRef={register}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </FormTextFieldWrapper>

          <FormTextFieldWrapper>
            <Controller
              control={control}
              name="type"
              as={
                <TextField
                  select
                  label={t("Task status type")}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value={TaskStatusType.Todo}>
                    {statusTypeValueDisplay(TaskStatusType.Todo, t)}
                  </MenuItem>
                  <MenuItem value={TaskStatusType.InProgress}>
                    {statusTypeValueDisplay(TaskStatusType.InProgress, t)}
                  </MenuItem>
                  <MenuItem value={TaskStatusType.Done}>
                    {statusTypeValueDisplay(TaskStatusType.Done, t)}
                  </MenuItem>
                </TextField>
              }
            />
          </FormTextFieldWrapper>
          <Box display="flex" justifyContent="space-between">
            <Button onClick={(): void => onClose()}>{t("Cancel")}</Button>
            <Button variant="contained" color="primary" type="submit">
              {editTaskStatusData ? t("Change") : t("Add")}
            </Button>
          </Box>
        </form>
      </CustomDialogContent>
      <ErrorDisplay
        error={createTaskStatusError}
        message={t(
          "Error occured while adding new task status, please try again later or contact us."
        )}
      />
      <ErrorDisplay
        error={editTaskStatusError}
        message={t(
          "Error occured while updating task status, please try again later or contact us."
        )}
      />
    </CustomDialog>
  );
}
