import { LocationOn } from "@material-ui/icons";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Logo from "../../components/Logo/Logo";
import { WhiteTextField } from "../../components/WhiteTextfield/WhiteTextfield";
import { WorkplaceContext } from "../../context/WorkplaceContext/WorkplaceContextProvider";
import { PropsChild } from "../../interfaces/PropsChild";
import { black100Opacity } from "../../utils/theme";
import useManageUrlQueryVariables from "../../utils/useManageUrlQueryVariables";
import BottomMenu from "./BottomMenu";
import TopMenu from "./TopMenu";
import {
  Box,
  CircularProgress,
  InputAdornment,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";

interface Props extends PropsChild {
  workplaceChooseDisabled?: boolean;
}

const menuWidth = "220px";
const useStyles = makeStyles({
  wrapper: {
    height: "100vh",
    maxHeight: "100vh",
    width: "100%",
    overflow: "hidden",
  },
  menu: {
    height: "100%",
    width: menuWidth,
    maxHeight: "100%",
    backgroundColor: black100Opacity,
    boxSizing: "border-box",
  },
  content: {
    height: "100%",
    width: `calc(100% - ${menuWidth})`,
    maxHeight: "100%",
    overflow: "auto",
  },
  whiteText: {
    color: "#fff",
  },
});

export default function BasicMenuLayout({
  children,
  workplaceChooseDisabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { workplaceId: urlWorkplaceId } = useManageUrlQueryVariables();
  const { chosenWorkplaceId, workplaces, setCurrentWorkplace, loading } = useContext(
    WorkplaceContext
  );

  const onWorkplaceChange = (event): void => {
    if (setCurrentWorkplace) {
      setCurrentWorkplace(event.target.value);
    }
    if (urlWorkplaceId && event.target.value !== urlWorkplaceId) {
      const newPath = history.location.pathname;
      const newSearch = history.location.search.replace(
        urlWorkplaceId,
        event.target.value
      );
      history.push({
        pathname: newPath,
        search: newSearch,
      });
    }
  };

  return (
    <Box className={classes.wrapper} display="flex">
      <Box
        className={classes.menu}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        pb={2}
        px={3}
      >
        <Box width="100%">
          <Box mb={5} display="flex" justifyContent="center">
            <Logo isWhite width="70px" />
          </Box>
          {workplaces && workplaces.length ? (
            <Box>
              <Box pb={3}>
                <WhiteTextField
                  disabled={workplaceChooseDisabled}
                  select
                  value={chosenWorkplaceId}
                  onChange={onWorkplaceChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn style={{ color: "#fff" }} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth
                >
                  {workplaces?.map((workplace) => (
                    <MenuItem key={workplace.id} value={workplace.id}>
                      {workplace.name}
                    </MenuItem>
                  ))}
                </WhiteTextField>
              </Box>

              <TopMenu />
            </Box>
          ) : (
            ""
          )}
          {workplaces && !workplaces.length && (
            <Box py={5}>
              <Typography align="center" className={classes.whiteText}>
                {t("No workplaces to display")}
              </Typography>
            </Box>
          )}
          {loading && (
            <Box py={5} display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
        </Box>
        <Box width="100%">
          <BottomMenu />
        </Box>
      </Box>
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
}
