import { useContext } from "react";
import { UserContext } from "../../context/UserContext/UserContextProvider";
import { WorkplaceContext } from "../../context/WorkplaceContext/WorkplaceContextProvider";
import { Permissions } from "../../generated/types";
import useManageUrlQueryVariables from "../useManageUrlQueryVariables";

export default function useIsUserAuthorised(
  checkedPermissions: Permissions[] | Permissions,
  checkedWorkplaceId?: string
): boolean {
  const preparedCheckedPermissions = Array.isArray(checkedPermissions)
    ? checkedPermissions
    : [checkedPermissions];
  const { permissions: userPermissions } = useContext(UserContext);
  const { workplaceId: urlWorkplaceId } = useManageUrlQueryVariables();
  const { chosenWorkplaceId } = useContext(WorkplaceContext);

  let isUserAuthorised = false;

  for (let i = 0; i < preparedCheckedPermissions.length; i += 1) {
    if (userPermissions?.systemPermissions.length) {
      if (userPermissions.systemPermissions.includes(preparedCheckedPermissions[i])) {
        isUserAuthorised = true;
        break;
      }
    }

    if (userPermissions?.companyPermissions.length) {
      if (userPermissions.companyPermissions.includes(preparedCheckedPermissions[i])) {
        isUserAuthorised = true;
        break;
      }
    }

    if (userPermissions && Object.keys(userPermissions.workplacePermissions).length) {
      if (
        checkedWorkplaceId &&
        userPermissions.workplacePermissions[checkedWorkplaceId]?.includes(
          preparedCheckedPermissions[i]
        )
      ) {
        isUserAuthorised = true;
        break;
      }
      if (
        chosenWorkplaceId &&
        userPermissions.workplacePermissions[chosenWorkplaceId]?.includes(
          preparedCheckedPermissions[i]
        )
      ) {
        isUserAuthorised = true;
        break;
      }
      if (
        urlWorkplaceId &&
        userPermissions.workplacePermissions[urlWorkplaceId]?.includes(
          preparedCheckedPermissions[i]
        )
      ) {
        isUserAuthorised = true;
        break;
      }
    }
  }
  return isUserAuthorised;
}
