import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EditableInputAdornment from "../../../../components/EditableInputAdornment/EditableInputAdornment";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { useUpdatePatrolDescriptionMutation } from "../../graphql/mutation.generated";

interface Props {
  patrolId: string;
  description: string;
  disabled?: boolean;
}
export default function PatrolDescription({
  description,
  patrolId,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [
    updateDescriptionMutation,
    { loading, error },
  ] = useUpdatePatrolDescriptionMutation();

  const [descriptionState, setDescriptionState] = useState<string>(description);

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDescriptionState(event.target.value);
  };

  const onDescriptionBlur = async (): Promise<void> => {
    try {
      await updateDescriptionMutation({
        variables: {
          updatePatrolDescriptionData: { id: patrolId, description: descriptionState },
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <>
      <TextField
        fullWidth
        multiline
        rowsMax={5}
        value={descriptionState}
        onChange={onDescriptionChange}
        onBlur={onDescriptionBlur}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <EditableInputAdornment display={Boolean(descriptionState) && !disabled} />
          ),
        }}
        placeholder={t("Add description")}
        disabled={disabled || loading}
      />
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing description, please try again or contact us."
        )}
      />
    </>
  );
}
