import { Button, withStyles } from "@material-ui/core";
import { dangerColor } from "../../../utils/theme";

export const DangerButton = withStyles({
  root: {
    color: dangerColor,
  },
  text: {
    color: dangerColor,
  },
})(Button);
