import { ApolloError } from "@apollo/client";
import { useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { TaskListDataFragment } from "../../graphql/fragments.generated";
import {
  useChangeTaskStatusMutation,
  useUpdateTaskStatusAndTasksOrdersMutation,
} from "../../graphql/mutation.generated";
import {
  Permissions,
  TaskStatus,
  TaskStatusType,
  UpdateTaskOrderData,
} from "../../../../generated/types";
import useIsUserAuthorised from "../../../../utils/AuthUtils/useIsUserAuthorised";

interface Props {
  tasks: TaskListDataFragment[];
  statuses: TaskStatus[];
}

interface ReturnObject {
  boardData: BoardData;
  changeBoardAfterDraging: (result: DropResult) => void;
  changeGroupCollapse: (groupId: string, index: number) => void;
  changeTaskStatusError: ApolloError | undefined;
}

type BoardData = GroupData[];

interface TasksFilteredByStatus {
  [key: string]: TaskListDataFragment[];
}
interface GroupData {
  collapsed?: boolean;
  groupId: string;
  parentTask?: TaskListDataFragment;
  tasks: TasksFilteredByStatus;
}

export default function useTasksBoardDataHandler({
  statuses,
  tasks,
}: Props): ReturnObject {
  const [boardData, setBoardData] = useState<BoardData>([]);
  const canUserChangeOrder = useIsUserAuthorised([
    Permissions.EditTask,
    Permissions.EditTaskOwn,
  ]);

  const [
    changeTaskStatusAndTasksOrdersMutation,
    { error: changeTaskStatusAndOrderError },
  ] = useUpdateTaskStatusAndTasksOrdersMutation();
  const [
    changeTaskStatusMutation,
    { error: changeTaskStatusError },
  ] = useChangeTaskStatusMutation();

  const filterTasks = (filteredTasks: TaskListDataFragment[]): TaskListDataFragment[] =>
    filteredTasks.sort((a, b) => a.order - b.order);

  useEffect(() => {
    const createTasksObject = (
      inputTasks: TaskListDataFragment[]
    ): TasksFilteredByStatus => {
      const tasksObject: TasksFilteredByStatus = {};
      statuses.forEach((status) => {
        const tasksWithStatus = inputTasks.filter((task) => task.status.id === status.id);
        tasksObject[status.id] = filterTasks(tasksWithStatus);
      });
      return tasksObject;
    };

    const board: BoardData = [];
    const unAssignedTasks = tasks.filter((task) => !task.children.length);
    board.push({
      groupId: "unassigned",
      tasks: createTasksObject(unAssignedTasks),
    });

    tasks
      .filter((task) => task.children.length)
      .forEach((taskGroup) => {
        // TODO this solution below is shitty, make it better (types problem)
        const children = taskGroup.children.map((child) => ({ ...child, children: [] }));
        board.push({
          groupId: taskGroup.id,
          parentTask: taskGroup,
          tasks: createTasksObject(children),
        });
      });

    setBoardData(board);
  }, [statuses, tasks]);

  const changeTaskStatusAndTasksOrders = async (
    taskId: string,
    newStatusId: string,
    newTaskOrders: UpdateTaskOrderData[]
  ): Promise<boolean> => {
    let success = false;
    try {
      const res = await changeTaskStatusAndTasksOrdersMutation({
        variables: {
          changeTaskStatusData: {
            id: taskId,
            taskStatusId: newStatusId,
          },
          updateTasksOrdersData: newTaskOrders,
        },
      });
      if (res.data) {
        success = true;
      }
    } catch (e) {
      success = false;
    }
    return success;
  };

  const changeTaskStatus = async (
    taskId: string,
    newStatusId: string
  ): Promise<boolean> => {
    let success = false;
    try {
      const res = await changeTaskStatusMutation({
        variables: {
          changeTaskStatusData: {
            id: taskId,
            taskStatusId: newStatusId,
          },
        },
      });
      if (res.data) {
        success = true;
      }
    } catch (e) {
      success = false;
    }
    return success;
  };

  const changeBoardAfterDraging = (result: DropResult): void => {
    if (result.destination?.droppableId && boardData) {
      const newStatusId = result.destination.droppableId.split("status")[1];
      const newGroupId = result.destination.droppableId.split("status")[0];
      const newIndex = result.destination.index;
      const oldStatusId = result.source.droppableId.split("status")[1];
      const oldGroupId = result.source.droppableId.split("status")[0];
      const oldIndex = result.source.index;
      if (newStatusId && newGroupId && oldGroupId && oldStatusId) {
        const droppedTask = boardData
          .find((group) => group.groupId === oldGroupId)
          ?.tasks[oldStatusId].find((task) => task.id === result.draggableId);
        if (droppedTask) {
          const newTaskOrders: UpdateTaskOrderData[] = [];
          const newStatusData = statuses.find((status) => status.id === newStatusId);
          const newDroppedtask: TaskListDataFragment = {
            ...droppedTask,
            status: {
              id: newStatusId,
              name: newStatusData?.name || "",
              type: newStatusData?.type || TaskStatusType.Todo,
              __typename: "TaskStatus",
            },
            order: newIndex,
          };

          let changedBoardData: BoardData = [];
          if (newStatusId === oldStatusId && newGroupId === oldGroupId) {
            changedBoardData = boardData.map((group) => {
              if (group.groupId === newGroupId) {
                const newTasks = { ...group.tasks };
                newTasks[newStatusId].splice(oldIndex, 1);
                newTasks[newStatusId].splice(newIndex, 0, newDroppedtask);

                newTasks[newStatusId].forEach((task, index) => {
                  newTaskOrders.push({ id: task.id, order: index });
                });

                return {
                  ...group,
                  tasks: newTasks,
                };
              }

              return group;
            });
          } else if (newGroupId === oldGroupId) {
            changedBoardData = boardData.map((group) => {
              if (group.groupId === newGroupId) {
                const newTasks = { ...group.tasks };
                newTasks[oldStatusId].splice(oldIndex, 1);
                newTasks[newStatusId].splice(newIndex, 0, newDroppedtask);

                newTasks[oldStatusId].forEach((task, index) => {
                  newTaskOrders.push({ id: task.id, order: index });
                });

                newTasks[newStatusId].forEach((task, index) => {
                  newTaskOrders.push({ id: task.id, order: index });
                });

                return {
                  ...group,
                  tasks: newTasks,
                };
              }

              return group;
            });
          }

          if (changedBoardData.length) {
            setBoardData(changedBoardData);
            if (canUserChangeOrder) {
              changeTaskStatusAndTasksOrders(
                result.draggableId,
                newStatusId,
                newTaskOrders
              );
            } else {
              changeTaskStatus(result.draggableId, newStatusId);
            }
          }
        }
      }
    }
  };

  const changeGroupCollapse = (groupId: string, index: number): void => {
    const newBoardData = [...boardData];
    if (newBoardData[index].groupId === groupId) {
      newBoardData[index].collapsed = !newBoardData[index].collapsed;
      setBoardData(newBoardData);
    }
  };

  return {
    boardData,
    changeBoardAfterDraging,
    changeGroupCollapse,
    changeTaskStatusError: changeTaskStatusAndOrderError || changeTaskStatusError,
  };
}
