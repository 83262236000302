import { Box, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { SHORT_DATE_FORMAT_WITH_HOUR } from "../../utils/consts";
import { black50Opacity } from "../../utils/theme";

interface Props {
  onClick: () => void;
  onDelete: () => void;
  createdAt: string;
  canUserEdit?: boolean;
}

const useStyles = makeStyles({
  hoverItem: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    backgroundColor: black50Opacity,
    opacity: 0,
    boxSizing: "border-box",
    "&:hover": {
      opacity: 1,
    },
  },
  textEllipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

export default function HoverItem({
  onClick,
  onDelete,
  createdAt,
  canUserEdit,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const onDeleteClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    onDelete();
  };
  return (
    <Box px={0.7} py={0.5} className={classes.hoverItem} onClick={(): void => onClick()}>
      {canUserEdit && (
        <Box display="flex" justifyContent="flex-end">
          <Tooltip title={t("Delete") || ""} placement="top" arrow>
            <IconButton size="small" onClick={onDeleteClick}>
              <HighlightOffIcon fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Box>
        <Typography variant="caption" color="primary" className={classes.textEllipsis}>
          {t("Added on")}: <br /> {moment(createdAt).format(SHORT_DATE_FORMAT_WITH_HOUR)}
        </Typography>
      </Box>
    </Box>
  );
}
