import * as Types from "../../../generated/types";

import { TaskStatusFragment } from "./fragments.generated";
import { gql } from "@apollo/client";
import { TaskStatusFragmentDoc } from "./fragments.generated";
import * as Apollo from "@apollo/client";
export type CreateTaskStatusMutationVariables = Types.Exact<{
  createTaskStatusData: Types.CreateTaskStatusData;
}>;

export type CreateTaskStatusMutation = {
  __typename?: "Mutation";
  createTaskStatus: {
    __typename?: "TaskStatus";
    id: any;
    name: string;
    type: Types.TaskStatusType;
    order: number;
  };
};

export type UpdateTaskStatusesOrderMutationVariables = Types.Exact<{
  updateTaskStatusesOrderData: Array<Types.UpdateTaskStatusOrderData>;
}>;

export type UpdateTaskStatusesOrderMutation = {
  __typename?: "Mutation";
  updateTaskStatusesOrder: boolean;
};

export type DeleteTaskStatusMutationVariables = Types.Exact<{
  replaceStatusId: Types.Scalars["String"];
  taskStatusId: Types.Scalars["String"];
}>;

export type DeleteTaskStatusMutation = {
  __typename?: "Mutation";
  deleteTaskStatus: boolean;
};

export type EditTaskStatusMutationVariables = Types.Exact<{
  editTaskStatusData: Types.EditTaskStatusData;
}>;

export type EditTaskStatusMutation = {
  __typename?: "Mutation";
  editTaskStatus: { __typename?: "TaskStatus" } & TaskStatusFragment;
};

export const CreateTaskStatusDocument = gql`
  mutation CreateTaskStatus($createTaskStatusData: CreateTaskStatusData!) {
    createTaskStatus(createTaskStatusData: $createTaskStatusData) {
      id
      name
      type
      order
    }
  }
`;
export type CreateTaskStatusMutationFn = Apollo.MutationFunction<
  CreateTaskStatusMutation,
  CreateTaskStatusMutationVariables
>;

/**
 * __useCreateTaskStatusMutation__
 *
 * To run a mutation, you first call `useCreateTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskStatusMutation, { data, loading, error }] = useCreateTaskStatusMutation({
 *   variables: {
 *      createTaskStatusData: // value for 'createTaskStatusData'
 *   },
 * });
 */
export function useCreateTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTaskStatusMutation,
    CreateTaskStatusMutationVariables
  >
) {
  return Apollo.useMutation<CreateTaskStatusMutation, CreateTaskStatusMutationVariables>(
    CreateTaskStatusDocument,
    baseOptions
  );
}
export type CreateTaskStatusMutationHookResult = ReturnType<
  typeof useCreateTaskStatusMutation
>;
export type CreateTaskStatusMutationResult = Apollo.MutationResult<CreateTaskStatusMutation>;
export type CreateTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskStatusMutation,
  CreateTaskStatusMutationVariables
>;
export const UpdateTaskStatusesOrderDocument = gql`
  mutation UpdateTaskStatusesOrder(
    $updateTaskStatusesOrderData: [UpdateTaskStatusOrderData!]!
  ) {
    updateTaskStatusesOrder(updateTaskStatusesOrderData: $updateTaskStatusesOrderData)
  }
`;
export type UpdateTaskStatusesOrderMutationFn = Apollo.MutationFunction<
  UpdateTaskStatusesOrderMutation,
  UpdateTaskStatusesOrderMutationVariables
>;

/**
 * __useUpdateTaskStatusesOrderMutation__
 *
 * To run a mutation, you first call `useUpdateTaskStatusesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskStatusesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskStatusesOrderMutation, { data, loading, error }] = useUpdateTaskStatusesOrderMutation({
 *   variables: {
 *      updateTaskStatusesOrderData: // value for 'updateTaskStatusesOrderData'
 *   },
 * });
 */
export function useUpdateTaskStatusesOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskStatusesOrderMutation,
    UpdateTaskStatusesOrderMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateTaskStatusesOrderMutation,
    UpdateTaskStatusesOrderMutationVariables
  >(UpdateTaskStatusesOrderDocument, baseOptions);
}
export type UpdateTaskStatusesOrderMutationHookResult = ReturnType<
  typeof useUpdateTaskStatusesOrderMutation
>;
export type UpdateTaskStatusesOrderMutationResult = Apollo.MutationResult<UpdateTaskStatusesOrderMutation>;
export type UpdateTaskStatusesOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskStatusesOrderMutation,
  UpdateTaskStatusesOrderMutationVariables
>;
export const DeleteTaskStatusDocument = gql`
  mutation deleteTaskStatus($replaceStatusId: String!, $taskStatusId: String!) {
    deleteTaskStatus(replaceStatusId: $replaceStatusId, taskStatusId: $taskStatusId)
  }
`;
export type DeleteTaskStatusMutationFn = Apollo.MutationFunction<
  DeleteTaskStatusMutation,
  DeleteTaskStatusMutationVariables
>;

/**
 * __useDeleteTaskStatusMutation__
 *
 * To run a mutation, you first call `useDeleteTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskStatusMutation, { data, loading, error }] = useDeleteTaskStatusMutation({
 *   variables: {
 *      replaceStatusId: // value for 'replaceStatusId'
 *      taskStatusId: // value for 'taskStatusId'
 *   },
 * });
 */
export function useDeleteTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskStatusMutation,
    DeleteTaskStatusMutationVariables
  >
) {
  return Apollo.useMutation<DeleteTaskStatusMutation, DeleteTaskStatusMutationVariables>(
    DeleteTaskStatusDocument,
    baseOptions
  );
}
export type DeleteTaskStatusMutationHookResult = ReturnType<
  typeof useDeleteTaskStatusMutation
>;
export type DeleteTaskStatusMutationResult = Apollo.MutationResult<DeleteTaskStatusMutation>;
export type DeleteTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskStatusMutation,
  DeleteTaskStatusMutationVariables
>;
export const EditTaskStatusDocument = gql`
  mutation editTaskStatus($editTaskStatusData: EditTaskStatusData!) {
    editTaskStatus(editTaskStatusData: $editTaskStatusData) {
      ...TaskStatus
    }
  }
  ${TaskStatusFragmentDoc}
`;
export type EditTaskStatusMutationFn = Apollo.MutationFunction<
  EditTaskStatusMutation,
  EditTaskStatusMutationVariables
>;

/**
 * __useEditTaskStatusMutation__
 *
 * To run a mutation, you first call `useEditTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskStatusMutation, { data, loading, error }] = useEditTaskStatusMutation({
 *   variables: {
 *      editTaskStatusData: // value for 'editTaskStatusData'
 *   },
 * });
 */
export function useEditTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditTaskStatusMutation,
    EditTaskStatusMutationVariables
  >
) {
  return Apollo.useMutation<EditTaskStatusMutation, EditTaskStatusMutationVariables>(
    EditTaskStatusDocument,
    baseOptions
  );
}
export type EditTaskStatusMutationHookResult = ReturnType<
  typeof useEditTaskStatusMutation
>;
export type EditTaskStatusMutationResult = Apollo.MutationResult<EditTaskStatusMutation>;
export type EditTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  EditTaskStatusMutation,
  EditTaskStatusMutationVariables
>;
