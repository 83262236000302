import { Box, makeStyles, Tooltip, Typography, Zoom } from "@material-ui/core";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import TelegramIcon from "@material-ui/icons/Telegram";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { SIGN_IN, SIGN_UP, SIGN_UP_FROM_LINK } from "../../routing/paths";
import theme from "../theme";
import ContactUsDialog from "./ContactUsDialog";

const fullPadding = 1.3;

const useStyles = makeStyles({
  wrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    cursor: "pointer",
  },
  widget: {
    backgroundColor: theme.palette.primary.main,
    borderBottomRightRadius: "50%",
    transition: "200ms",
    paddingTop: theme.spacing(fullPadding / 2),
    paddingLeft: theme.spacing(fullPadding / 2),
    paddingBottom: theme.spacing(fullPadding),
    paddingRight: theme.spacing(fullPadding),
    "&:hover": {
      "& .MuiSvgIcon-root": {
        fontSize: theme.spacing(4.4),
      },
    },
  },
  bigWidget: {
    backgroundColor: theme.palette.primary.main,
    borderBottomRightRadius: "50%",
    transition: "200ms",
    paddingTop: theme.spacing(fullPadding),
    paddingLeft: theme.spacing(fullPadding),
    paddingBottom: theme.spacing(2 * fullPadding),
    paddingRight: theme.spacing(2 * fullPadding),
  },
  icon: {
    transition: "200ms",
    fontSize: theme.spacing(3.8),
  },
  bigIcon: {
    transition: "200ms",
    fontSize: theme.spacing(8.6),
  },
  bigSign: {
    width: "300px",
    position: "absolute",
    top: "100%",
    left: "50%",
  },
});

const pagesToDisplayBig = [SIGN_IN, SIGN_UP, SIGN_UP_FROM_LINK];

export default function ContactWidget(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();

  const displayBig = useMemo(() => {
    if (location.pathname) {
      for (let i = 0; i < pagesToDisplayBig.length; i += 1) {
        if (location.pathname.indexOf(pagesToDisplayBig[i]) > -1) {
          return true;
        }
      }
      return false;
    }
    return false;
  }, [location]);

  const [contactDialogOpen, setContactDialogOpen] = useState(false);

  return (
    <>
      <Box className={classes.wrapper} display="flex" alignItems="center">
        {displayBig ? (
          <Box
            className={classes.bigWidget}
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={(): void => setContactDialogOpen(true)}
          >
            <Box>
              <Box display="flex" justifyContent="center">
                <TelegramIcon className={classes.bigIcon} />
              </Box>
            </Box>
            <Box className={classes.bigSign}>
              <Box display="flex" alignItems="center">
                <SubdirectoryArrowRightIcon color="primary" fontSize="large" />
                <Box mt={1}>
                  <Typography variant="h4" color="primary">
                    {t("Contact us!")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Tooltip
            title={t("Contact us") || ""}
            arrow
            interactive
            TransitionComponent={Zoom}
          >
            <Box
              className={classes.widget}
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={(): void => setContactDialogOpen(true)}
            >
              <Box>
                <Box display="flex" justifyContent="center">
                  <TelegramIcon className={classes.icon} />
                </Box>
              </Box>
            </Box>
          </Tooltip>
        )}
      </Box>
      {contactDialogOpen && (
        <ContactUsDialog onClose={(): void => setContactDialogOpen(false)} open />
      )}
    </>
  );
}
