import { Box, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import HelmetWrapper from "../../components/HelmetWrapper/HelmetWrapper";
import { UserContext } from "../../context/UserContext/UserContextProvider";
import { Permissions } from "../../generated/types";
import { PropsChild } from "../../interfaces/PropsChild";
import BasicMenuLayout from "../../layouts/BasicMenuLayout/BasicMenuLayout";
import useIsUserAuthorised from "./useIsUserAuthorised";

interface Props extends PropsChild {
  permission: Permissions | Permissions[];
  display404Page?: boolean;
  checkedWorkplaceId?: string;
}

export default function IsUserAuthorised({
  permission,
  children,
  display404Page,
  checkedWorkplaceId,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const isUserAuthorised = useIsUserAuthorised(permission, checkedWorkplaceId);
  const { loading } = useContext(UserContext);

  if (isUserAuthorised) {
    return <>{children}</>;
  }
  if (!isUserAuthorised && display404Page && !loading) {
    return (
      <HelmetWrapper siteTitle="Unauthorised">
        <BasicMenuLayout>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            width="100%"
          >
            <Box>
              <Typography variant="h1">404</Typography>
              <Typography variant="h3">
                {t("User is unauthorised to display this page.")}
              </Typography>
            </Box>
          </Box>
        </BasicMenuLayout>
      </HelmetWrapper>
    );
  }
  return <div />;
}
