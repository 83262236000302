import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SIGN_IN } from "../../../../routing/paths";
import IdentityLayout from "../../common/IdentityLayout";

const useStyles = makeStyles({
  link: {
    display: "block",
  },
});

export default function ResetPasswordEmailSentPage(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <IdentityLayout>
      <Box pb={2}>
        <Typography variant="h6">{t("Reset password process initialized")}</Typography>
      </Box>
      <Box pb={5}>
        <Typography variant="body1">
          {t("Check your email inbox in order to reset password")}
        </Typography>
      </Box>
      <Link to={SIGN_IN} className={classes.link}>
        <Button color="primary" variant="contained" fullWidth size="large">
          {t("Sign in")}
        </Button>
      </Link>
    </IdentityLayout>
  );
}
