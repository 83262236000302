import { Box, Chip } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TaskFilters } from "../../../../generated/types";
import { QueryVariablesEnum } from "../../../../interfaces/QueryVariables";
import useManageUrlQueryVariables from "../../../../utils/useManageUrlQueryVariables";

export default function ActiveTaskFilters(): JSX.Element {
  const { t } = useTranslation();
  const {
    setUrlQueryVariables,
    deleteUrlQueryVariables,
    taskFilters,
  } = useManageUrlQueryVariables();

  const filters = useMemo<TaskFilters | null>(() => {
    if (taskFilters) {
      return JSON.parse(taskFilters);
    }
    return null;
  }, [taskFilters]);

  const filtersNamesTable = {
    assigneesIds: t("Assignees"),
    categoriesIds: t("Categories"),
    createdById: t("Created by"),
    dueDate: t("Due date"),
    finishedDate: t("Finished date"),
    isArchived: t("Is archived"),
    labelsIds: t("Labels"),
    reportedDate: t("Reported date"),
    reporterId: t("Reporter"),
    taskStatusId: t("Status"),
  };

  const handleDeleteLabel = (labelName: string): void => {
    const newFilters = { ...filters };
    delete newFilters[labelName];
    if (!Object.keys(newFilters).length) {
      deleteUrlQueryVariables(QueryVariablesEnum.TASKFILTERS);
    } else {
      setUrlQueryVariables(QueryVariablesEnum.TASKFILTERS, JSON.stringify(newFilters));
    }
  };

  return (
    <Box display="flex">
      {filters &&
        Object.keys(filters).map((key, index) => (
          <Box key={`active_task_filter-${key}-${index}`} mr={1}>
            <Chip
              label={filtersNamesTable[key]}
              onDelete={(): void => handleDeleteLabel(key)}
              variant="outlined"
              size="small"
            />
          </Box>
        ))}
    </Box>
  );
}
