import { gql } from "@apollo/client";

export const GET_WORKPLACE_USERS = gql`
  query GetWorkplaceUsers($includeInactive: Boolean, $workplaceId: UUID!) {
    getWorkplaceUsers(includeInactive: $includeInactive, workplaceId: $workplaceId) {
      id
      email
      firstName
      lastName
      isActive
      userWorkplaceRoles {
        id
        workplaceRole {
          id
          name
        }
        workplace {
          id
        }
      }
    }
  }
`;

export const GET_WORKPLACE_ROLES = gql`
  query GetWorkplaceRoles($companyId: UUID!) {
    getWorkplaceRoles(companyId: $companyId) {
      id
      name
    }
  }
`;
