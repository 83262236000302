import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DataListItemDisplay from "../../../components/DataListItemDisplay/DataListItemDisplay";
import UserNameDisplay from "../../../components/UserNameDisplay/UserNameDisplay";
import { UserContext } from "../../../context/UserContext/UserContextProvider";
import { WorkplaceContext } from "../../../context/WorkplaceContext/WorkplaceContextProvider";
import { Permissions } from "../../../generated/types";
import useLazyIsUserAuthorised from "../../../utils/AuthUtils/useLazyIsUserAuthorised";
import theme from "../../../utils/theme";
import useManageUrlQueryVariables from "../../../utils/useManageUrlQueryVariables";
import TaskFriendlyId from "../common/TaskFriendlyId";
import TaskAttachments from "./taskAttachments/TaskAttachments";
import TaskComments from "./taskComments/TaskComments";
import TaskAssignees from "./taskFields/TaskAssignees";
import TaskCategories from "./taskFields/TaskCategories";
import TaskChildren from "./taskFields/TaskChildren";
import TaskDescription from "./taskFields/TaskDescription";
import TaskDueDate from "./taskFields/TaskDueDate";
import TaskLabels from "./taskFields/TaskLabels";
import TaskLocation from "./taskFields/TaskLocation";
import TaskName from "./taskFields/TaskName";
import TaskReportedDate from "./taskFields/TaskReportDate";
import TaskReporter from "./taskFields/TaskReporter";
import TaskStatus from "./taskFields/TaskStatus";
import TaskArchiveButton from "./taskOptionsFields/TaskArchiveButton";
import TaskMoreMenu from "./taskOptionsFields/TaskMoreMenu";
import useOpenTaskDialog from "./useOpenTaskDialog";
import {
  useGetTaskAttachmentsLazyQuery,
  useGetTaskDetailsLazyQuery,
} from "../graphql/query.generated";
import {
  Box,
  Dialog,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";

const topBarHeight = "72px";

const useStyles = makeStyles({
  dialog: {
    "& .MuiDialog-paperFullWidth": {
      height: `calc(100% - 64px)`,
    },
  },
  dialogContentWrapper: {
    maxHeight: "100%",
    height: "100%",
    boxSizing: "border-box",
    paddingTop: topBarHeight,
  },
  topBar: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: topBarHeight,
  },
  contentWrapper: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  divider: {
    borderLeft: "1px solid",
    borderColor: theme.palette.divider,
  },
});

export default function TaskDetailsDialog(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isUserAuthorised } = useLazyIsUserAuthorised();
  const { taskId } = useManageUrlQueryVariables();
  const { closeTaskDialog } = useOpenTaskDialog();
  const { chosenWorkplaceId } = useContext(WorkplaceContext);
  const { id: userId } = useContext(UserContext);

  const [open, setOpen] = useState<boolean>(false);

  const [
    getTaskDetailsQuery,
    { data: taskDetailsData, error: taskDetailsError },
  ] = useGetTaskDetailsLazyQuery();
  const [
    getAttachementsQuery,
    {
      data: attachmentsData,
      loading: attachmentsLoading,
      error: attachmentsFetchingError,
    },
  ] = useGetTaskAttachmentsLazyQuery();

  useEffect(() => {
    if (taskId) {
      getAttachementsQuery({ variables: { taskId } });
    }
  }, [getAttachementsQuery, taskId]);

  const canUserEditTask = useMemo<boolean>(() => {
    if (
      taskDetailsData?.getTask.isArchived ||
      taskDetailsData?.getTask.parent?.isArchived
    ) {
      return false;
    }
    if (chosenWorkplaceId) {
      if (isUserAuthorised(Permissions.EditTask, chosenWorkplaceId)) {
        return true;
      }
      if (userId && taskDetailsData) {
        const taskAssigneesIds = taskDetailsData.getTask.assignees.map((a) => a.id);
        if (taskAssigneesIds.includes(userId)) {
          return isUserAuthorised(Permissions.EditTaskOwn, chosenWorkplaceId);
        }
      }
    }
    return false;
  }, [chosenWorkplaceId, userId, taskDetailsData, isUserAuthorised]);

  const canUserEditAttachments = useMemo<boolean>(() => {
    if (chosenWorkplaceId) {
      if (isUserAuthorised(Permissions.EditTask, chosenWorkplaceId)) {
        return true;
      }
      if (userId && taskDetailsData) {
        const taskAssigneesIds = taskDetailsData.getTask.assignees.map((a) => a.id);
        if (taskAssigneesIds.includes(userId)) {
          return isUserAuthorised(Permissions.EditTaskOwn, chosenWorkplaceId);
        }
      }
    }
    return false;
  }, [chosenWorkplaceId, userId, taskDetailsData, isUserAuthorised]);

  const canUserEditTaskStatus = useMemo<boolean>(() => {
    if (
      taskDetailsData?.getTask.isArchived ||
      taskDetailsData?.getTask.parent?.isArchived
    ) {
      return false;
    }
    if (chosenWorkplaceId) {
      if (isUserAuthorised(Permissions.EditTask, chosenWorkplaceId)) {
        return true;
      }
      if (userId && taskDetailsData) {
        const taskAssigneesIds = taskDetailsData.getTask.assignees.map((a) => a.id);
        if (taskAssigneesIds.includes(userId)) {
          return isUserAuthorised(Permissions.EditTaskStatusOwn, chosenWorkplaceId);
        }
      }
    }
    return false;
  }, [chosenWorkplaceId, userId, taskDetailsData, isUserAuthorised]);

  const canUserArchiveTask = useMemo<boolean>(() => {
    // TODO check this approach, when we no longer get tree tasks from backend, it prevents from chancing task when parent is archived
    if (taskDetailsData?.getTask.parent?.isArchived) {
      return false;
    }
    if (chosenWorkplaceId) {
      if (isUserAuthorised(Permissions.ArchiveTask, chosenWorkplaceId)) {
        return true;
      }
      if (userId && taskDetailsData) {
        const taskAssigneesIds = taskDetailsData.getTask.assignees.map((a) => a.id);
        if (taskAssigneesIds.includes(userId)) {
          return isUserAuthorised(Permissions.ArchiveTaskOwn, chosenWorkplaceId);
        }
      }
    }
    return false;
  }, [chosenWorkplaceId, userId, taskDetailsData, isUserAuthorised]);

  const canUserDeleteTask = useMemo<boolean>(() => {
    // TODO check this approach, when we no longer get tree tasks from backend, it prevents from chancing task when parent is archived
    if (taskDetailsData?.getTask.parent?.isArchived) {
      return false;
    }
    if (chosenWorkplaceId) {
      if (isUserAuthorised(Permissions.DeleteTask, chosenWorkplaceId)) {
        return true;
      }
      if (userId && taskDetailsData) {
        const taskAssigneesIds = taskDetailsData.getTask.assignees.map((a) => a.id);
        if (taskAssigneesIds.includes(userId)) {
          return isUserAuthorised(Permissions.DeleteTaskOwn, chosenWorkplaceId);
        }
      }
    }
    return false;
  }, [chosenWorkplaceId, userId, taskDetailsData, isUserAuthorised]);

  useEffect(() => {
    if (taskId) {
      setOpen(true);
      getTaskDetailsQuery({ variables: { id: taskId } });
    } else {
      setOpen(false);
    }
  }, [getTaskDetailsQuery, taskId]);

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      onClose={closeTaskDialog}
      className={classes.dialog}
    >
      {taskDetailsData?.getTask && (
        <Box position="relative" className={classes.dialogContentWrapper}>
          <Box className={classes.topBar}>
            <Box
              px={5}
              height="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <TaskFriendlyId
                friendlyId={taskDetailsData.getTask.friendlyId}
                parentFriendlyId={taskDetailsData.getTask.parent?.friendlyId}
                parentId={taskDetailsData.getTask.parent?.id}
                isChild={!!taskDetailsData.getTask.parent?.id}
                isArchived={taskDetailsData.getTask.isArchived}
                isParentArchived={taskDetailsData.getTask.parent?.isArchived}
              />
              {taskDetailsData.getTask.isArchived && (
                <Box display="flex">
                  <Typography variant="h6">{t("This task is archived.")}</Typography>
                  <Box ml={1}>
                    <Tooltip
                      title={
                        t(
                          "Archived tasks can not be edited. To unarchive this task, click 'Unarchive' button on right."
                        ) || ""
                      }
                    >
                      <InfoIcon fontSize="small" />
                    </Tooltip>
                  </Box>
                </Box>
              )}
              {taskDetailsData.getTask.parent?.isArchived && (
                <Box display="flex">
                  <Typography variant="h6">
                    {t("This child task is archived.")}
                  </Typography>
                  <Box ml={1}>
                    <Tooltip
                      title={
                        t(
                          "Archived tasks can't be edited. Unarchive parent task to edit this task."
                        ) || ""
                      }
                    >
                      <InfoIcon fontSize="small" />
                    </Tooltip>
                  </Box>
                </Box>
              )}
              <Box display="flex" alignItems="center">
                {canUserArchiveTask && !taskDetailsData.getTask.parent && (
                  <Box mr={1}>
                    <TaskArchiveButton
                      isTaskArchived={taskDetailsData.getTask.isArchived}
                      taskId={taskId}
                    />
                  </Box>
                )}
                {canUserDeleteTask && (
                  <Box mr={1}>
                    <TaskMoreMenu
                      taskId={taskId}
                      taskData={taskDetailsData.getTask}
                      onTaskDeleted={closeTaskDialog}
                      isTaskArchived={Boolean(taskDetailsData?.getTask.isArchived)}
                      parentTaskId={
                        taskDetailsData && taskDetailsData.getTask.parent
                          ? taskDetailsData.getTask.parent.id
                          : null
                      }
                      // attachments={attachmentsData?.getTaskAttachments}
                    />
                  </Box>
                )}

                <IconButton onClick={closeTaskDialog}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>

          <Box px={5} className={classes.contentWrapper}>
            <Box
              pt={1}
              pb={4}
              display="flex"
              justifyContent="space-between"
              minHeight="95%"
              boxSizing="border-box"
            >
              <Box width="58%">
                <Box mb={1}>
                  <TaskName
                    disabled={!canUserEditTask}
                    taskId={taskId}
                    taskName={taskDetailsData.getTask.name}
                  />
                </Box>
                <Box mb={2} display="flex">
                  <Box pr={4} width="100%">
                    <TaskLocation
                      disabled={!canUserEditTask}
                      location={taskDetailsData.getTask.location || ""}
                      taskId={taskId}
                    />
                  </Box>
                  {/* TODO add QUANTITY and QUANTITY UNIT */}
                  {/* <Box width="140px">
                    <TaskQuantity quantity="" />
                  </Box> */}
                </Box>
                <Box mb={4}>
                  <TaskAttachments
                    taskId={taskId}
                    canUserEditAttachments={canUserEditAttachments}
                    attachments={attachmentsData?.getTaskAttachments}
                    attachmentsFetchingError={attachmentsFetchingError}
                    attachmentsLoading={attachmentsLoading}
                  />
                </Box>

                <DataListItemDisplay headText={t("Description")}>
                  <TaskDescription
                    disabled={!canUserEditTask}
                    description={taskDetailsData.getTask.description || ""}
                    taskId={taskId}
                  />
                </DataListItemDisplay>

                {!taskDetailsData.getTask.parent?.id && (
                  <DataListItemDisplay headText={t("Child issues")}>
                    <TaskChildren
                      canUserEditTask={canUserEditTask}
                      taskId={taskId}
                      childIssues={taskDetailsData.getTask.children}
                      workplaceId={chosenWorkplaceId}
                    />
                  </DataListItemDisplay>
                )}
                <DataListItemDisplay headText={t("Comments")}>
                  <TaskComments
                    taskId={taskId}
                    userId={userId}
                    notDeletableComments={
                      taskDetailsData.getTask.isArchived ||
                      taskDetailsData.getTask.parent?.isArchived
                    }
                  />
                </DataListItemDisplay>

                <Box />
              </Box>

              <Box width="1px" className={classes.divider} />

              <Box width="35%">
                <Box mb={2} width="150px">
                  <TaskStatus
                    disabled={!canUserEditTaskStatus}
                    taskId={taskId}
                    taskStatus={taskDetailsData.getTask.status.id}
                    workplaceId={chosenWorkplaceId}
                  />
                </Box>

                <DataListItemDisplay headText={t("Responsible")} globalMb={2}>
                  <TaskAssignees
                    disabled={!canUserEditTask}
                    assignees={taskDetailsData.getTask.assignees.map(
                      (assignee) => assignee.id
                    )}
                    taskId={taskId}
                    workplaceId={chosenWorkplaceId}
                  />
                </DataListItemDisplay>

                {taskDetailsData.getTask.finishedDate && (
                  <DataListItemDisplay headText={t("Finished date")} globalMb={2}>
                    <Typography>
                      {moment(taskDetailsData.getTask.finishedDate).format(
                        "DD MMM, YYYY, HH:mm"
                      )}
                    </Typography>
                  </DataListItemDisplay>
                )}

                <DataListItemDisplay headText={t("Due date")} globalMb={2}>
                  <TaskDueDate
                    disabled={!canUserEditTask}
                    dueDate={taskDetailsData.getTask.dueDate}
                    taskId={taskId}
                  />
                </DataListItemDisplay>

                <DataListItemDisplay headText={t("Categories")} globalMb={2}>
                  <TaskCategories
                    disabled={!canUserEditTask}
                    categoriesIds={taskDetailsData.getTask.categories.map((c) => c.id)}
                    taskId={taskId}
                  />
                </DataListItemDisplay>

                <DataListItemDisplay headText={t("Labels")} globalMb={2}>
                  <TaskLabels
                    disabled={!canUserEditTask}
                    labelsIds={taskDetailsData.getTask.labels.map((l) => l.id)}
                    taskId={taskId}
                  />
                </DataListItemDisplay>

                <DataListItemDisplay headText={t("Reporter")} globalMb={2}>
                  <TaskReporter
                    disabled={!canUserEditTask}
                    workplaceId={chosenWorkplaceId}
                    reporter={taskDetailsData.getTask.reporter?.id || ""}
                    taskId={taskId}
                  />
                </DataListItemDisplay>

                <DataListItemDisplay headText={t("Report date")} globalMb={2}>
                  <TaskReportedDate
                    disabled={!canUserEditTask}
                    reportedDate={taskDetailsData.getTask.reportedDate}
                    taskId={taskId}
                  />
                </DataListItemDisplay>

                <DataListItemDisplay headText={t("Created by")} globalMb={2}>
                  <UserNameDisplay userObject={taskDetailsData.getTask.createdBy} />
                </DataListItemDisplay>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <ErrorDisplay
        error={taskDetailsError}
        message={t(
          "Error occured while fetching task data, please try again later or contact us."
        )}
      />
    </Dialog>
  );
}
